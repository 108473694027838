import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import config from "../../../config/defaultSettings";
import Input from "../../..//components/Input/index";
import SendNewLogin from "../../../models/logout";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

export default function NewLoginPassword() {
  const [oneSeePassword, setOneSeePassword] = useState(true);
  const [tweeSeePassword, setTweeSeePassword] = useState(true);
  const [password, setPassword] = useState({
    password: "",
    token: "",
  });
  const Location = useLocation();
  // if (Location.search) {
  const tokenBig = Location.search.split("").splice(7).join("");
  // console.log(token);
  // }
  const {t} = useTranslation();
  const useR = useRouteMatch();
  const history = useHistory();

  const [newpassoword, setNewPassword] = useState({
    password: "",
    newpassword: "",
  });
  //   console.log( "useLocation=> ", Location.search.split("").splice(7).join(""));
  const [restoreEmail, setRestoreEmail] = useState({
    base_url: config.researcherBaseURL,
    email: "",
  });
  useEffect(() => {
    console.log(restoreEmail.email);
  }, [restoreEmail]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (newpassoword.password === newpassoword.newpassword) {
      let data = {
        token: tokenBig,
        password: newpassoword.password,
      };
      //   setPassword(old => ({ ...old, token: token }))
      //   setPassword(old => ({ ...old, passowrd: newpassoword.password }))
      SendNewLogin.newLoginCreate(data).then((res) => {
        if (res.code === 200) {
          history.push("/auth/login");
        }
      });
    }
    // SendEmail.restoreSubmit(restoreEmail)
    //   .then(res => {
    //     console.log(res);

    //   })
    //   .catch(err => {
    //   console.log(err);
    // })
  };

  return (
    <div className="font-body flex ">
      <div
        style={{height: "100vh", width: "100vw"}}
        className="flex font-body justify-center divide-y bg-red-100"
      ></div>
      <div className="rounded-xl h-21 mt-20 m-auto w-3/4 p-3 bg-white flex justify-between items-center">
        {/* <div className="flex space-x-2 items-center">
            {/* <div style={{ height: '55vh'}} className="rounded-2xl flex justify-center items-center w-4/6 mx-auto   my-10 b bg-white">

            </div> */}
        {/* </div> */}
        <div className="h-1/2 w-3/4  m-auto  my-40  text-2xl rounded-lg bg-white shadow-lg">
          <div></div>
          <div className="p-6">
            {/* <button onClick={() => setActivTab(old => ({ ...old, login: true, regstratsiya: false, email:false,companya: false}))}  className="text-gray-400 p-3 -my-5"><ArrowBackIosIcon />{t('back')}</button> */}
            <p></p> <br />
            <div>
              <h2 className="font-extrabold text-xl mb-6">
                {t("Восстановить пароль")}
              </h2>
              <hr />
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col space-y-2">
                  <label className="mt-4" htmlFor="email_id">
                    {t("Новый пароль")}
                  </label>
                  <span className="flex items-center space-x-2 p-0.5 bg-blue-100 my-5 rounded-lg">
                    <Input
                      onChange={(e) =>
                        setNewPassword((old) => ({
                          ...old,
                          password: e.target.value,
                        }))
                      }
                      icon={<LockOpenIcon className="text-blue-500" />}
                      type={oneSeePassword ? "password" : "text"}
                    ></Input>
                    {oneSeePassword ? (
                      <VisibilityOffIcon
                        onClick={() => setOneSeePassword(false)}
                        className="text-blue-500"
                      />
                    ) : (
                      <VisibilityIcon
                        onClick={() => setOneSeePassword(true)}
                        className="text-blue-500"
                      />
                    )}
                  </span>
                </div>
                <div className="flex flex-col space-y-2">
                  <label className="mt-4" htmlFor="email_id">
                    {t("Повторите пароль")}
                  </label>
                  <span className="flex items-center space-x-2 p-0.5 bg-blue-100 my-5 rounded-lg">
                    <Input
                      onChange={(e) =>
                        setNewPassword((old) => ({
                          ...old,
                          newpassword: e.target.value,
                        }))
                      }
                      icon={<LockOpenIcon className="text-blue-500" />}
                      type={tweeSeePassword ? "password" : "text"}
                    ></Input>
                    {tweeSeePassword ? (
                      <VisibilityOffIcon
                        className="text-blue-500"
                        onClick={() => setTweeSeePassword(false)}
                      />
                    ) : (
                      <VisibilityIcon
                        className="text-blue-500"
                        onClick={() => setTweeSeePassword(false)}
                      />
                    )}
                  </span>
                </div>
                <div className="p-6">
                  <button
                    disabled={false}
                    type="submit"
                    className="w-full rounded-md bg-blue-500 p-3 text-white font-semibold active:bg-blue-700"
                  >
                    {t("panelBack")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
