import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import Header from "../../components/Header/Header";
import Button from "../../components/Buttons/index";
import AddIcon from "@material-ui/icons/Add";
import {useTranslation} from "react-i18next";
import {Formik} from "formik";
import {log} from "@craco/craco/lib/logger";
import DeleteIcon from "@material-ui/icons/Delete";
import Input from "../../components/Input/index";
import Form from "../../components/Form/index";
import Card from "../../components/Card";
import Select from "../../components/Select/index";
import {ToastContainer, toast} from "react-toastify";
import * as Yup from "yup";
import validateForm from "../../functions/validateForm";
import Attributs from "../../models/attributs";
import {useHistory} from "react-router-dom";
import {isArray} from "@craco/craco/lib/utils";
import Breadcrumbs from "../../components/Breadcrumb";

const Create = () => {
  const history = useHistory();
  const {t} = useTranslation();
  const {id} = useParams();
  const [isRender, setisRender] = useState(false);
  const ValidationSchema = Yup.object().shape({
    data_type: validateForm("mixed", t),
    input_type: validateForm("mixed", t),
    name: validateForm("default", t),
  });
  const [isRenderCreate, setisRenderCreate] = useState(
    id === undefined ? true : false
  );
  const inputTypes = [
    {label: "Массив", value: "array"},
    {label: "Диапазон", value: "range"},
  ];
  const dataType = [
    {label: "Строка", value: "string"},
    {label: "Число", value: "number"},
    {label: "Дата и время", value: "date_time"},
  ];
  const [input_type, setinputType] = useState(inputTypes);
  const [isRenderCrete, setisRenderCrete] = useState(true);
  const [updateType, setupdateType] = useState(true);
  const [data_type, setdataType] = useState(dataType);
  const [initialValues, setinitialValues] = useState({
    data_type: "number",
    input_type: "array",
    name: "",
  });
  const onSubmit = (data) => {
    if (id) {
      const newdata = {
        data_type: isArray(data.data_type)
          ? data.data_type[0].value
          : data.data_type.value,
        name: data.name,
        input_type: isArray(data.input_type)
          ? data.input_type[0].value
          : data.input_type.value,
      };
      Attributs.updateAttribut(id, newdata).then((res) => {
        if (res.code === 200) {
          toast.success(t("update.attribut"), {
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
          history.push("/home/settings/attribut");
        }
      });
    } else {
      const newdata = {
        data_type: data.data_type.value,
        name: data.name,
        input_type: data.input_type.value,
      };
      Attributs.createAttribut(newdata)
        .then((res) => {
          if (res.code === 200) {
            toast.success(t("newaddattribut"), {
              closeOnClick: true,
              ideProgressBar: false,
              autoClose: 4000,
            });
            history.push("/home/settings/attribut");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const routes = [
    {
      title: t("setting"),
      link: false,
      route: "/home/company",
    },
    {
      title: t(updateType ? t("attribut") : "add"),
      link: true,
      route: "/home/settings/attribut",
    },
    {
      title: t("add"),
      link: false,
    },
  ];
  useEffect(() => {
    Attributs.getItemUpdateAttribut(id).then((res) => {
      setinitialValues(() => ({
        name: res.data.name,
        input_type: input_type.filter((e) => e.value === res.data.input_type),
        data_type: data_type.filter((e) => e.value === res.data.data_type),
      }));
      setisRender(true);
    });
  }, [id]);
  return (
    <div>
      {(id?.length && isRender) || isRenderCreate ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header startAdornment={[<Breadcrumbs routes={routes} />]} />

              <div className="p-4 w-full grid grid-cols-2 gap-4 box-border font-body text-sm">
                <Card title={id ? "Общие сведения" : "Добавить новый атрибут"}>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">{t("Названия")}</div>
                    <div className="w-2/3">
                      <Form.Item name="name" formik={formik}>
                        <Input {...formik.getFieldProps("name")}></Input>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">{t("Тип")}</div>
                    <div className="w-2/3">
                      <Form.Item name="data_type" formik={formik}>
                        <Select
                          onChange={(val) => {
                            formik.setFieldValue("data_type", val);
                          }}
                          value={formik.values?.data_type}
                          options={data_type}
                        ></Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">{t("Данные")}</div>
                    <div className="w-2/3">
                      <Form.Item name="input_type" formik={formik}>
                        <Select
                          onChange={(val) => {
                            formik.setFieldValue("input_type", val);
                          }}
                          hideSelectedOptions
                          options={input_type}
                          value={formik.values?.input_type}
                        />
                      </Form.Item>
                    </div>
                  </div>{" "}
                  <br />
                  <h1 style={{color: "black"}}>Список вариантов</h1>
                  <br />
                  <hr />
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">{t("Вариант 1")}</div>
                    <div className="w-2/3">
                      {/*<Form.Item name="name" formik={formik}>*/}
                      <Input></Input>
                      {/*</Form.Item>*/}
                    </div>
                  </div>
                  <div className="flex w-full justify-end space-x-2 py-2">
                    <Button
                      size="large"
                      shape="outlined"
                      style={{border: "1px solid #E5E9EB"}}
                      onClick={() => history.goBack()}
                    >
                      {t("cancel")}
                    </Button>
                    <Button size="large" shape="filled" type="submit">
                      {id ? t("save") : t("save")}
                    </Button>
                  </div>
                </Card>
              </div>
            </form>
          )}

          {/*</div>*/}
        </Formik>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Create;
