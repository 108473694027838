import {useState, useEffect} from "react";
import {useHistory, NavLink} from "react-router-dom";
import {useDispatch} from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import {makeStyles} from "@material-ui/core/styles";
import "./index.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import urcImg from "../../assets/icons/URecruit.png";
import SettingsIcon from "@material-ui/icons/Settings";
import menu from "./menu";
import Avatar from "../Avatar/Index";
import FormatIndentDecreaseIcon from "@material-ui/icons/FormatIndentDecrease";
import {useTranslation} from "react-i18next";
import Popup from "../Popup/index";
import ProfileIcon from "../../assets/icons/profile.svg";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip placement="right" arrow classes={classes} {...props} />;
}

export default function App() {
  const [anchorEl, setAnchorEl] = useState(false);
  // **** USE-HOOKS ****
  const history = useHistory();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [visible, setVisible] = useState(true);
  const [childrenActiov, setchildrenActiov] = useState(false);
  const [openSidebar, setopenSidebar] = useState(true);
  const [selectedList, setSelectedList] = useState([]);
  const toggleSidebar = () => setVisible((prev) => !prev);

  useEffect(() => {
    if (menu.length) {
      setSelectedList(menu);
    }
  }, [history]);
  useEffect(() => {
    if (anchorEl) {
      setopenSidebar(true);
    }
  }, [anchorEl]);
  useEffect(() => {
    if (!openSidebar) {
      setAnchorEl(false);
    }
  }, [openSidebar]);

  // **** COMPONENTS ****
  const RenderSidebarItems = ({items}) => {
    return (
      <ul className="space-y-2 text-sm mt-5 dashboard_list font-body">
        {items
          .filter((el) => !el.children?.length)
          .map((el, ind) => (
            <li key={el.title}>
              <NavLink activeClassName="is-active" to={el.path}>
                {!openSidebar ? (
                  <BootstrapTooltip title={el.title}>
                    <span
                      style={{borderRadius: "0px 8px 8px 0px"}}
                      className={`spanITem text-white flex items-center space-x-3 p-3 rounded-md font-medium focus:shadow-outline`}
                    >
                      <span>{el.icon}</span>
                      {openSidebar ? (
                        <span id="sidebar-title" className="text-sm ">
                          {el.title}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </BootstrapTooltip>
                ) : (
                  <span
                    style={{borderRadius: "0px 8px 8px 0px"}}
                    className={`spanITem flex items-center space-x-3 text-gray-700 p-3 rounded-md font-medium hover:bg-blue-100 focus:shadow-outline`}
                  >
                    <span>{el.icon}</span>
                    {openSidebar && (
                      <span id="sidebar-title" className="text-sm">
                        {el.title}
                      </span>
                    )}
                  </span>
                )}
              </NavLink>
            </li>
          ))}
        <ul className="space-y-2 text-sm mt-5  dashboard_list font-body">
          <li>
            {openSidebar ? (
              <span
                onClick={() => setchildrenActiov((old) => !old)}
                className="spanITem flex items-center justify-between space-x-3 text-gray-700 p-3 rounded-md font-medium hover:bg-blue-100 focus:shadow-outline"
                style={{borderRadius: "0px 8px 8px 0px"}}
              >
                <div>
                  <SettingsIcon style={{color: "rgba(110, 139, 183, 1)"}} />
                  {openSidebar && (
                    <span id="sidebar-title" className="ml-5">
                      {t("setting")}
                    </span>
                  )}
                </div>
                <span>
                  {!childrenActiov ? (
                    <ExpandMoreIcon style={{color: "#6E8BB7"}} />
                  ) : (
                    <ExpandLessIcon style={{color: "#6E8BB7"}} />
                  )}
                </span>
              </span>
            ) : (
              <BootstrapTooltip title={t("setting")}>
                <span
                  style={{borderRadius: "0px 8px 8px 0px"}}
                  className={`spanITem flex items-center space-x-3 text-gray-700 p-3 rounded-md font-medium hover:bg-blue-500 focus:shadow-outline`}
                >
                  <span className="">
                    <SettingsIcon
                      onClick={() => {
                        if (!childrenActiov) {
                          setchildrenActiov(true);
                        } else {
                          setchildrenActiov(false);
                        }
                      }}
                      style={{color: "rgba(110, 139, 183, 1)"}}
                    />
                  </span>
                </span>
              </BootstrapTooltip>
            )}
            {childrenActiov ? (
              <div style={{background: "#F0F8FF", borderRadius: "2px"}}>
                {items
                  .filter((el) => el.children?.length)
                  .flatMap((elemnt) => elemnt.children)
                  .map((el, index) => (
                    <NavLink
                      activeClassName="is-active"
                      to={el.path}
                      key={index + Math.random()}
                      className="via-blueGray-300"
                    >
                      <div
                        id="root_sidebar"
                        style={{
                          margin: `${
                            openSidebar ? "0.75rem 0.75rem 0.75rem 1.5rem" : "0"
                          }`,
                        }}
                      >
                        {openSidebar ? (
                          <span
                            id="sidebar-title"
                            style={{
                              borderRadius: "0px 8px 8px 0px",
                            }}
                            className={`spanITem flex items-center space-x-3 text-gray-700 p-3 rounded-md font-medium hover:bg-blue-500 focus:shadow-outline`}
                          >
                            {el.icon}
                            <span>{el.title}</span>
                          </span>
                        ) : (
                          <BootstrapTooltip title={el.title}>
                            <span
                              style={{borderRadius: "0px 8px 8px 0px"}}
                              className={`spanITem flex items-center space-x-3 text-gray-700 p-3 rounded-md font-medium hover:bg-blue-500 focus:shadow-outline`}
                            >
                              <span className="">{el.icon}</span>
                            </span>
                          </BootstrapTooltip>
                        )}
                      </div>
                    </NavLink>
                  ))}
              </div>
            ) : (
              ""
            )}
          </li>
          {/*)}*/}
        </ul>
      </ul>
    );
  };

  return (
    <div className="flex h-full" style={{height: "100%"}}>
      <div
        className={
          openSidebar
            ? "h-screen sidebar p-4 bg-white w-60"
            : "h-screen sidebar p-2 bg-white w-16"
        }
        style={{
          height: "100%",
          transition: "all 0.3s ease",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: visible ? "" : "0px",
          opacity: visible ? "1" : "0",
        }}
      >
        <div>
          <div className="flex justify-between items-center w-full">
            <span className="items-center">
              {openSidebar ? (
                <img src={urcImg} alt="this img" />
              ) : (
                <div
                  className="flex text-2xl font-semibold cursor-pointer px-2"
                  onClick={() => setopenSidebar((prev) => !prev)}
                >
                  <span className="text-primary-600">U</span>
                  <span className="text-black">R</span>
                </div>
              )}
            </span>
            <span className="flex items-center mt-1 cursor-pointer">
              {openSidebar && (
                <FormatIndentDecreaseIcon
                  style={{color: "rgba(110, 139, 183, 1)"}}
                  onClick={() => setopenSidebar(false)}
                />
              )}
            </span>
          </div>
          <RenderSidebarItems items={selectedList} /> <br />
        </div>
        {/*  */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
            width: "100%",
          }}
        >
          <ul
            className="space-y-2  dashboard_list transition ease-in-out transform"
            style={{
              padding: "15px 19px",
              transition: "all 0.3s",
              display: "flex",
            }}
          >
            <div onClick={(e) => setAnchorEl((prev) => !prev)}>
              <Avatar
                style={{
                  background: "#fff",
                  border: "1px solid #85b5f9",
                  position: "relative",
                  right: "10px",
                }}
                aria-controls="simple-menu"
                size={40}
                src={ProfileIcon}
                alt="ProfileIcon"
              />
            </div>
            <Popup
              dispatch={dispatch}
              history={history}
              check={false}
              t={t}
              isOpen={anchorEl}
              bottom={5}
              left={20}
              onClose={() => setAnchorEl((prev) => !prev)}
              title={t("logout")}
            />

            {/* {anchorEl ? <div className="h-32 rounded p-2 shadow-2xl w-40 bg-background absolute left-16 bottom-12 z-10">Set cont</div> : '' } */}
          </ul>
        </div>
      </div>
    </div>
  );
}
