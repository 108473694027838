import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Breadcrumbs from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import Compancreate from "../company/companyCreate";

const CompanyCreateList = () => {
  const { t } = useTranslation();
  const [updateType, setupdateType] = useState(false);
  const [createcompany, setcreatecompany] = useState(true);
  const routes = [
    {
      title: t("company.name"),
      link: true,
      route: "/home/company",
    },
    {
      title: t(updateType ? "update" : "add"),
      link: true,
    },
  ];
  return (
    <div>
      <Header title={t("companies")}></Header>
      <div className="m-5">
        <Compancreate createcompany={createcompany}></Compancreate>
      </div>
    </div>
  );
};

export default CompanyCreateList;
