// import Input from "react-select/src/components/Input"
import DatePicker from "../DatePicker";

import CreatableSelect from "react-select/creatable";
import Input from "../Input";
import React, {useEffect} from "react";
import CloseIcon from "@material-ui/icons/Close";
import {Formik, Form, Field, FieldArray} from "formik";
import {useTranslation} from "react-i18next";

// import DataPicker from

const onlyNumber = (event) => {
  let keyCode = event.keyCode ? event.keyCode : event.which;
  if (keyCode < 48 || keyCode > 57) {
    // 46 is dot
    event.preventDefault();
  }
};
export default function FilterFunction({
  AsyncCreatableSelect,
  element,
  onSelect,
  handleInputWatch,
  index,
  promiseOptions,
  defaultOptions,
  onCreateElement,
  arrayStringOptions,
  arrayNumberOptions,
  attributeList,
  filterInput,
  initialValue,
  hendleDelete,
  setAttributeList,
  gender,
  next,
}) {
  const [list, setList] = React.useState();
  const [validation, setValidation] = React.useState({
    min: false,
    max: false,
  });
  const {t} = useTranslation();

  return (
    <Formik
      initialValues={{initialValue}}
      onSubmit={(values, actions) => console.log("submit => ", values)}
      render={({values, handleChange}) => (
        <Form className="w-full">
          <FieldArray
            className="w-full"
            name="inquiry"
            render={() => (
              <div className="w-full">
                {values.initialValue?.inquiry_requirement?.map(
                  (list, index) => (
                    <div
                      key={index + Math.random()}
                      className="w-full flex mt-3"
                    >
                      <div className="w-1/5 items-center">
                        <span className="justify-center">{list?.name}</span>
                      </div>
                      <div className="w-3/5 flex ml-2">
                        <div key={index} className="w-full">
                          {list?.input_type === "array" &&
                          list?.data_type === "string" ? (
                            <CreatableSelect
                              name={`inquiry.${index}`}
                              isClearable
                              // isDisabled={isLoading}
                              // isLoading={isLoading}
                              isMulti
                              onChange={(event) => {
                                onSelect(event, list, "array_string");
                              }}
                              onCreateOption={(event) =>
                                onCreateElement(event, list, "array_string")
                              }
                              options={
                                next
                                  ? gender
                                  : arrayStringOptions.filter(
                                      (item) =>
                                        item?.requirement_attribute_id ===
                                        list?.requirement_attribute?.id
                                    )
                              }
                              value={list.requirement_value.map((item) => ({
                                ...item,
                                label: item.value,
                              }))}
                            />
                          ) : list?.input_type === "range" &&
                            list.data_type === "number" ? (
                            <div className="w-full flex gap-2">
                              <Input
                                type={"number"}
                                error={validation.min}
                                defaultValue={
                                  list.requirement_value?.value?.min
                                }
                                onBlur={(event) => {
                                  if (
                                    event.target.value >= 18 &&
                                    event.target.value <= 99
                                  ) {
                                    handleInputWatch(event, list, "min");
                                    setValidation({
                                      ...validation,
                                      min: false,
                                    });
                                  } else {
                                    setValidation({
                                      ...validation,
                                      min: true,
                                    });
                                  }
                                }}
                              />
                              <Input
                                type={"number"}
                                defaultValue={
                                  list.requirement_value?.value?.max
                                }
                                error={validation.max}
                                className="ml-3"
                                onBlur={(event) => {
                                  if (event.target.value < 101) {
                                    handleInputWatch(event, list, "max");
                                    setValidation({
                                      ...validation,
                                      max: false,
                                    });
                                  } else {
                                    setValidation({
                                      ...validation,
                                      max: true,
                                    });
                                  }
                                }}
                              />
                            </div>
                          ) : list?.input_type === "range" &&
                            list?.data_type === "date_time" ? (
                            <div className="w-full flex gap-2">
                              <input
                                type="date"
                                id="start"
                                name="trip-start"
                                value={list.requirement_value?.value?.start}
                                onChange={(e) =>
                                  handleInputWatch(e, list, "start")
                                }
                              />
                              <input
                                type="date"
                                id="finish"
                                name="trip-start"
                                value={list.requirement_value?.value?.finish}
                                onChange={(e) =>
                                  handleInputWatch(e, list, "finish")
                                }
                              />
                            </div>
                          ) : list?.input_type === "range" &&
                            list?.data_type === "string" ? (
                            <div className="w-full flex gap-2">
                              <Input
                                type={"text"}
                                defaultValue={
                                  list.requirement_value?.value?.start
                                }
                                onBlur={(event) =>
                                  handleInputWatch(event, list, "start")
                                }
                              />
                              <Input
                                type={"text"}
                                defaultValue={
                                  list.requirement_value?.value?.end
                                }
                                className="ml-3"
                                onBlur={(event) =>
                                  handleInputWatch(event, list, "end")
                                }
                              />
                            </div>
                          ) : list?.input_type === "array" &&
                            list?.data_type === "number" ? (
                            <div className="w-full">
                              <CreatableSelect
                                isClearable
                                // isDisabled={isLoading}
                                // isLoading={isLoading}
                                onKeyDown={onlyNumber}
                                isMulti
                                onChange={(event) =>
                                  onSelect(event, list, "array_number")
                                }
                                onCreateOption={(event) =>
                                  onCreateElement(event, list, "array_number")
                                }
                                options={arrayNumberOptions}
                                value={list.requirement_value}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="m-1">
                          <CloseIcon
                            className="text-red-500 bg-red-100 "
                            style={{borderRadius: "5px"}}
                            onClick={() => {
                              hendleDelete(list);
                              setAttributeList((old) =>
                                old.filter((el) => el.id !== list.id)
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          />
        </Form>
      )}
    />
  );
  return "";
}
