function inqueryToState(element, request) {
  if (element.input_type === 'range' && element.data_type === 'number')
    return {
      ...element,
      requirement_attribute: { id: element.id },
      requirement_value: {
        ...element.requirement_values[0],
        value: {
          min: element.requirement_values[0].value.split('/')[0],
          max: element.requirement_values[0].value.split('/')[1],
        },
      },
    }
  if (element.input_type === 'range' && element.data_type === 'date_time')
    return {
      ...element,
      requirement_attribute: { id: element.id },
      requirement_value: {
        ...element.requirement_values[0],
        value: {
          start: element.requirement_values[0].value.split('/')[0],
          finish: element.requirement_values[0].value.split('/')[1],
        },
      },
    }
  if (element.input_type === 'range' && element.data_type === 'string') {
    return {
      ...element,
      requirement_attribute: { id: element.id },
      requirement_value: {
        ...element.requirement_values[0],
        value: {
          start: element.requirement_values[0].value.split('/')[0],
          end: element.requirement_values[0].value.split('/')[1],
        },
      },
    }
  }
  if (element.input_type === 'array' && element.data_type === 'string') {
    request({ requirement_attribute_id: element.id }, 'array_string')
    return {
      ...element,
      requirement_attribute: { id: element.id },
      requirement_value: element.requirement_values.map((el) => ({
        ...el,
        label: el.value,
        value: el.value,
      })),
    }
  }
  if (element.input_type === 'array' && element.data_type === 'date_time')
    return {
      ...element,
      requirement_attribute: { id: element.id },
      requirement_value: element.requirement_values.map((el) => ({
        ...el,
        label: el.value,
        value: el.value,
      })),
    }
  if (element.input_type === 'array' && element.data_type === 'number') {
    request({ requirement_attribute_id: element.id }, 'array_number')
    return {
      ...element,
      requirement_attribute: { id: element.id },
      requirement_value: element.requirement_values.map((el) => ({
        ...el,
        label: el.value,
        value: el.value,
      })),
    }
  }
}

function transformToState(data, state, request) {
  const form = { ...data }
  state((prop) => ({
    ...form,
    inquiry_requirement: form.inquiry_requirement.map((el) =>
      inqueryToState(el, request)
    ),
    // nda: Object.keys(form.nda).map((el) =>  [form.nda[el]] )),
    // nda: Object.keys(form.nda).map((el) => ({ file: { name: form.nda[el] } })),
  }))
}

export default transformToState
