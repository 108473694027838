import request from "../utils/axios";
const base_reUrl= {
    base_res: "/researcher"
}
 const Researchers={
    ItemresearcherList: (id) => {
        return request({
            url: `${base_reUrl.base_res}/${id}`,
            method: "get",
        })
    },
    researcherGetAllList:(params) => (
        request({
            url: `${base_reUrl.base_res}`,
            method: "get",
            params: params
        })
),
     researcherCreate:(data) => {
        return request({
            url: `${base_reUrl.base_res}`,
            method: "post",
            data: data
        })
     },
     researcherUpdate:(id, data) => (
         request({
             url: `${base_reUrl.base_res}/${id}`,
             method: "put",
             data: data
         })
     ),
     researcherDelete:(id) => (
         request({
             url: `${base_reUrl.base_res}/${id}`,
             method: "delete",
         })
     )
}
export default Researchers;
