export const NumberOfCompanies = () => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.00001 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H3.00001C2.45001 0 2.00001 0.45 2.00001 1C2.00001 1.55 2.45001 2 3.00001 2ZM18.16 3.8C18.07 3.34 17.66 3 17.18 3H2.82001C2.34001 3 1.93001 3.34 1.84001 3.8L0.840013 8.8C0.720013 9.42 1.19001 10 1.82001 10H2.00001V15C2.00001 15.55 2.45001 16 3.00001 16H11C11.55 16 12 15.55 12 15V10H16V15C16 15.55 16.45 16 17 16C17.55 16 18 15.55 18 15V10H18.18C18.81 10 19.28 9.42 19.16 8.8L18.16 3.8ZM10 14H4.00001V10H10V14Z"
      fill="#0067F4"
    />
  </svg>
);
export const NumberOfResearches = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8Z"
      fill="#0067F4"
    />
    <path
      d="M8.35 10.01C5.62 9.91 0 11.27 0 14V16H9.54C7.07 13.24 8.31 10.11 8.35 10.01Z"
      fill="#0067F4"
    />
    <path
      d="M17.43 14.02C17.79 13.43 18 12.74 18 12C18 9.79 16.21 8 14 8C11.79 8 10 9.79 10 12C10 14.21 11.79 16 14 16C14.74 16 15.43 15.78 16.02 15.43L18.59 18L20 16.59L17.43 14.02ZM14 14C12.9 14 12 13.1 12 12C12 10.9 12.9 10 14 10C15.1 10 16 10.9 16 12C16 13.1 15.1 14 14 14Z"
      fill="#0067F4"
    />
  </svg>
);
export const NumberOfInquiries = () => (
  <svg
    width="13"
    height="17"
    viewBox="0 0 13 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.58579 0.585786C1.21071 0.960859 1 1.46957 1 2V7.528C2.14308 6.50619 3.63396 5.96074 5.16657 6.00363C6.69917 6.04652 8.15722 6.6745 9.24136 7.75864C10.3255 8.84278 10.9535 10.3008 10.9964 11.8334C11.0393 13.366 10.4938 14.8569 9.472 16H11C11.5304 16 12.0391 15.7893 12.4142 15.4142C12.7893 15.0391 13 14.5304 13 14V5.414C12.9999 4.88361 12.7891 4.37499 12.414 4L9 0.586C8.62501 0.210901 8.11639 0.000113249 7.586 0H3C2.46957 0 1.96086 0.210714 1.58579 0.585786Z"
      fill="#0067F4"
    />
    <path
      fill-rule="evenodd"
      clipRule="evenodd"
      d="M2.99174 8.54032C3.6018 8.18631 4.29462 7.9999 4.99996 8C5.61498 8.00015 6.22169 8.14212 6.77293 8.41487C7.32417 8.68763 7.80508 9.08382 8.1783 9.57266C8.55152 10.0615 8.80699 10.6298 8.92485 11.2335C9.04271 11.8371 9.0198 12.4598 8.85788 13.0531C8.69596 13.6464 8.3994 14.1944 7.99125 14.6545C7.58311 15.1146 7.07437 15.4743 6.50457 15.7058C5.93477 15.9373 5.31927 16.0343 4.7059 15.9892C4.09252 15.9442 3.4978 15.7583 2.96796 15.446L1.70696 16.707C1.61405 16.7998 1.50376 16.8735 1.38239 16.9237C1.26103 16.9739 1.13095 16.9997 0.999605 16.9997C0.868257 16.9997 0.738203 16.9737 0.616871 16.9234C0.495539 16.8731 0.385303 16.7994 0.292459 16.7065C0.199614 16.6136 0.125979 16.5033 0.0757566 16.3819C0.0255347 16.2606 -0.000290332 16.1305 -0.000244079 15.9991C-0.000197587 15.8678 0.0257195 15.7377 0.0760272 15.6164C0.126335 15.4951 0.200049 15.3848 0.292959 15.292L1.55396 14.032C1.19565 13.4245 1.00436 12.733 0.999482 12.0276C0.994608 11.3223 1.17632 10.6283 1.5262 10.0158C1.87608 9.40338 2.38168 8.89434 2.99174 8.54032ZM3.58574 10.5858C3.21067 10.9609 2.99996 11.4696 2.99996 12C2.99996 12.5304 3.21067 13.0391 3.58574 13.4142C3.96082 13.7893 4.46953 14 4.99996 14C5.53039 14 6.0391 13.7893 6.41417 13.4142C6.78924 13.0391 6.99996 12.5304 6.99996 12C6.99996 11.4696 6.78924 10.9609 6.41417 10.5858C6.0391 10.2107 5.53039 10 4.99996 10C4.46953 10 3.96082 10.2107 3.58574 10.5858Z"
      fill="#0067F4"
    />
  </svg>
);
export const NumberOfRespondents = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
      fill="#0067F4"
    />
  </svg>
);
export const Balance = () => (
  <svg
    width="11"
    height="18"
    viewBox="0 0 11 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.80001 7.9C3.53001 7.31 2.80001 6.7 2.80001 5.75C2.80001 4.66 3.81001 3.9 5.50001 3.9C7.28001 3.9 7.94001 4.75 8.00001 6H10.21C10.14 4.28 9.09001 2.7 7.00001 2.19V0H4.00001V2.16C2.06001 2.58 0.500007 3.84 0.500007 5.77C0.500007 8.08 2.41001 9.23 5.20001 9.9C7.70001 10.5 8.20001 11.38 8.20001 12.31C8.20001 13 7.71001 14.1 5.50001 14.1C3.44001 14.1 2.63001 13.18 2.52001 12H0.320007C0.440007 14.19 2.08001 15.42 4.00001 15.83V18H7.00001V15.85C8.95001 15.48 10.5 14.35 10.5 12.3C10.5 9.46 8.07001 8.49 5.80001 7.9Z"
      fill="#0067F4"
    />
  </svg>
);
