import React, {useEffect, useState} from "react";
import Button from "../Buttons/index";
import cls from "./test.module.scss";
import Select from "../Select";
// import { Button } from '@material-ui/core'
// import Input from "../Input";
// import AsyncCreatableSelect from 'react-select/async-creatable';
// import DataPicker from "../DatePicker/index"
import CompanyInqueiry from "../../models/companyinquiry";
import Inquery from "../../models/inquiry";
import CloseIcon from "@material-ui/icons/Close";
import {useTranslation} from "react-i18next";
import {useSelector, useDispatch} from "react-redux";
import {toast} from "react-toastify";
import FilterInput from "./elementType";
import AsyncCreatableSelect from "react-select/async-creatable";
import {useHistory, useParams} from "react-router-dom";
import Input from "../Input";

export default function StepperThreeList({
  initialValue,
  activeStep,
  attributList,
  setattirbutList,
  handleBack,
  arrayStringOptions,
  setCheckFirstStep,
  checkFirstStep,
  request,
  params,
  handleNext,
  arrayNumberOptions,
  setInquiryId,
  setinitialValue,
  checkStatusAttribute,
  next,
  calculateAmount,
  totalCostWithoutNds,
}) {
  const fullData = useSelector((old) => old.steppersItemData);
  const router = useHistory();
  const dispatch = useDispatch();
  const {inquery_id} = useParams();
  const [inputType, setinputType] = useState([]);
  const [filterInput, setFilterInput] = useState();
  const [attributeList, setAttributeList] = useState([]);
  const [autoSelect, setAutoSelect] = useState(false);
  const [defaultOptions, setdefaultOptions] = useState([]);
  const {t} = useTranslation();
  const [checkValue, setCheckValue] = useState(false);
  const [gender, setGender] = useState([
    {
      label: "Мужчина",
      value: "male",
    },
    {
      label: "Женщина",
      value: "female",
    },
  ]);

  const filterItem = (inputValue, index) => {
    return defaultOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const promiseOptions = (inputValue, index, id) => {
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterItem(inputValue, index));
      }, 1000);
      // alert(id)
    });
  };
  const onSubmit = () => {
    const form = {
      ...initialValue,
      company_id: params.company_id,
      region: next ? initialValue.region : {},
      audience: next ? initialValue.audience : {},
      promo_code_money: parseInt(initialValue.promo_code_money),
      inquiry_info: {
        district: initialValue.inquiry_info.district,
        by_interest:
          initialValue.audience.value !== "bdefb0c2-a3b8-405f-904a-a7f2c85a3479"
            ? initialValue.inquiry_info.by_interest
            : [],
      },
      // inquiry_info: initialValue.inquiry_info.map((item) => {
      //   if (initialValue.audience.label === "По демографии") {
      //     return {
      //       ...item,
      //       by_interest: [],
      //     };
      //   }
      // }),
      inquiry_requirement: initialValue.inquiry_requirement.map((el) => {
        if (el.input_type === "range" && el.data_type === "number") {
          return {
            ...el,
            id: el.requirement_attribute.id,
            requirement_values: [
              {
                ...el.requirement_value,
                value: `${el.requirement_value.value.min}/${el.requirement_value.value.max}`,
              },
            ],
          };
        } else if (el.input_type === "range" && el.data_type === "date_time") {
          return {
            ...el,
            id: el.requirement_attribute.id,
            requirement_values: [
              {
                ...el.requirement_value,
                value: `${el.requirement_value.value.start}/${el.requirement_value.value.finish}`,
              },
            ],
          };
        } else if (el.input_type === "range" && el.data_type === "string") {
          return {
            ...el,
            id: el.requirement_attribute.id,
            requirement_values: [
              {
                ...el.requirement_value,
                value: `${el.requirement_value.value.start}/${el.requirement_value.value.end}`,
              },
            ],
          };
        }
        if (el.input_type === "array" && el.data_type === "string") {
          return {
            ...el,
            id: el.requirement_attribute.id,
            requirement_values: el.requirement_value.map((els) => ({
              id: els.id ? els.id : "",
              attribute_id: els.requirement_attribute_id
                ? els.requirement_attribute_id
                : "",
              value: els.value,
            })),
          };
        } else if (el.input_type === "array" && el.data_type === "date_time") {
        } else if (el.input_type === "array" && el.data_type === "number") {
          return {
            ...el,
            id: el.requirement_attribute.id,
            requirement_values: el.requirement_value.map((els) => ({
              id: els.id ? els.id : "",
              attribute_id: els.requirement_attribute_id
                ? els.requirement_attribute_id
                : "",
              value: els.value,
            })),
          };
        }
        return el;
      }),
    };
    if (inquery_id) {
      // alert('sdfas')
      Inquery.updateInquiry(form)
        .then((res) => {
          toast.success("Успешно!", {
            className: "bg-green-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
          handleNext();
        })
        .catch((err) => {
          toast.error(
            `${err.data.error ? t(err.data.error) : "Произошла ошибка!"}`,
            {
              className: "bg-red-500 text-white",
              closeOnClick: true,
              ideProgressBar: false,
              autoClose: 4000,
            }
          );
        });
      return;
    }
    CompanyInqueiry.CompanyInquieryCreate(form)
      .then((res) => {
        if (res.code === 201 && !next) {
          toast.success("Выберите время для встреч", {
            className: "bg-green-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
          dispatch({
            type: "SET_INQUIRY_ID",
            payload: res.data.id,
          });
          handleNext(3);

          // router.push(`/home/company/companyinquirycreate/${params.company_id}/edit/${res.data.id}`)
          // window.location.href = '/home/company/companyinquiry'
        } else {
          toast.success("Успешно", {
            className: "bg-green-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
          dispatch({
            type: "SET_INQUIRY_ID",
            payload: res.data.id,
          });
          handleNext();
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          `${error.data.error ? t(error.data.error) : "Произошла ошибка!"}`,
          {
            className: "bg-red-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          }
        );
      });
  };

  const [createNewItem, setCreaeteNewItem] = useState([]);
  useEffect(() => {
    if (checkStatusAttribute) {
      setCreaeteNewItem((old) => [
        ...old,
        ...attributList.filter(
          (el) =>
            el.value.id === "5858fa83-efe6-4d80-8520-a4df09a99345" ||
            el.value.id === "bf377060-1577-4797-a1b7-a29f59ad2c5d"
        ),
      ]);
      setCreaeteNewItem((old) => [
        ...old,
        ...attributList.filter(
          (el) => el.value.id === "9df5b677-40ae-49f3-969a-ead6411cce95"
        ),
      ]);
      setattirbutList((el) =>
        el.filter(
          (atr) =>
            atr.value.id !== "5858fa83-efe6-4d80-8520-a4df09a99345" ||
            atr.value.id !== "bf377060-1577-4797-a1b7-a29f59ad2c5d"
        )
      );
      setattirbutList((el) =>
        el.filter(
          (atr) => atr.value.id !== "9df5b677-40ae-49f3-969a-ead6411cce95"
        )
      );
    }
  }, [checkStatusAttribute]);

  const alreadyAddNewItem = () => {
    for (let i = 0; i < createNewItem.length; i++) {
      const params = {
        requirement_attribute_id: createNewItem[i].value.id,
        value: createNewItem[i].value.value,
      };
      if (
        createNewItem[i].value.input_type === "array" &&
        createNewItem[i].value.data_type === "string"
      ) {
        request(params, "array_string");
      }
      setinitialValue((prev) => ({
        ...prev,
        inquiry_requirement: [
          ...prev.inquiry_requirement,
          {
            input_type: createNewItem[i].value.input_type,
            name: createNewItem[i].value.name,
            data_type: createNewItem[i].value.data_type,
            requirement_attribute: {
              id: createNewItem[i].value.id,
            },
            requirement_value:
              createNewItem[i].value.input_type !== "array"
                ? {
                    attribute_id: createNewItem[i].value.id,
                    id: "",
                    value: {},
                  }
                : [],
          },
        ],
      }));
    }
  };

  useEffect(() => {
    if (createNewItem.length > 0 && !params.activestep) {
      alreadyAddNewItem();
    }
  }, [createNewItem]);

  const addNewItem = (val) => {
    const params = {
      requirement_attribute_id: val.value.id,
      value: val.value.value,
    };
    const isOk = initialValue.inquiry_requirement.filter(
      (el) => el.requirement_attribute.id === val.value.id
    );
    if (isOk.length) return;

    setattirbutList((el) => el.filter((atr) => atr.value.id !== val.value.id));
    const element = val.value;
    setFilterInput(element);
    setAttributeList((old) => [...old, val.value]);
    setinitialValue((prev) => ({
      ...prev,
      inquiry_requirement: [
        ...prev.inquiry_requirement,
        {
          input_type: val.value.input_type,
          name: val.value.name,
          data_type: val.value.data_type,
          requirement_attribute: {
            id: val.value.id,
          },
          requirement_value:
            val.value.input_type !== "array"
              ? {
                  attribute_id: val.value.id,
                  id: "",
                  value: {},
                }
              : [],
        },
      ],
    }));

    if (element.input_type === "array" && element.data_type === "string") {
      request(params, "array_string");
    } else if (
      element.input_type === "array" &&
      element.data_type === "number"
    ) {
      request(params, "array_number");
    }
  };

  const hendleDelete = (element, index) => {
    if (element.name !== "Возраст" && element.name !== "Пол") {
      setinitialValue((prev) => ({
        ...prev,
        inquiry_requirement: prev.inquiry_requirement.filter(
          (el) =>
            el.requirement_attribute.id !== element.requirement_attribute.id
        ),
      }));
      setattirbutList((el) => [
        ...el,
        {
          label: element.name,
          value: {...element, id: element.requirement_attribute.id},
        },
      ]);
    } else {
      console.log("It cannot delete");
    }
  };

  const handleInputWatch = (event, position, type) => {
    setinitialValue((prev) => ({
      ...prev,
      inquiry_requirement: prev.inquiry_requirement.map((el) => {
        if (
          el?.requirement_attribute?.id === position?.requirement_attribute?.id
        ) {
          return {
            ...el,
            requirement_value: {
              ...el.requirement_value,
              value: {
                ...el.requirement_value.value,
                [type]: event.target.value,
              },
            },
          };
        } else return el;
      }),
    }));
  };
  const onSelect = (item, position, type) => {
    setinitialValue((el) => ({
      ...el,
      inquiry_requirement: el.inquiry_requirement.map((el) => {
        if (el.requirement_attribute.id === position.requirement_attribute.id) {
          return {...el, requirement_value: [...item]};
        } else return el;
      }),
    }));
    setCheckValue(true);
  };
  useEffect(() => {
    if (checkValue) {
      calculateAmount();
      setCheckValue(false);
    }
  }, [checkValue]);

  const onCreateElement = (item, position) => {
    setinitialValue((el) => ({
      ...el,
      inquiry_requirement: el.inquiry_requirement.map((el) => {
        if (el.requirement_attribute.id === position.requirement_attribute.id) {
          return {
            ...el,
            requirement_value: [
              ...el.requirement_value,
              {label: item, value: item},
            ],
          };
        } else return el;
      }),
    }));
  };

  useEffect(() => {
    if (!inquery_id) {
      if (initialValue.inquiry_requirement?.length > 0) {
        if (
          initialValue.inquiry_requirement[1]?.requirement_value[0] &&
          initialValue.inquiry_requirement[0]?.requirement_value?.value?.min &&
          initialValue.inquiry_requirement[0]?.requirement_value?.value?.max
        ) {
          setAutoSelect(true);
        } else {
          setAutoSelect(false);
        }
      }
    } else {
      if (initialValue.inquiry_requirement?.length > 0) {
        if (
          initialValue.inquiry_requirement?.[0]?.requirement_values?.[0] &&
          initialValue.inquiry_requirement?.[1]?.requirement_values?.[0]
        ) {
          setAutoSelect(true);
        } else {
          setAutoSelect(false);
        }
      }
    }
  }, [initialValue.inquiry_requirement]);

  useEffect(() => {
    if (activeStep >= 2) {
      if (autoSelect) {
        setCheckFirstStep({
          ...checkFirstStep,
          third: true,
        });
      } else {
        setCheckFirstStep({
          ...checkFirstStep,
          third: false,
        });
      }
    }
  }, [autoSelect, activeStep]);

  const RenderType = ({type, handleDelete, index, element}) => {
    return (
      <FilterInput
        setAttributeList={setAttributeList}
        hendleDelete={hendleDelete}
        initialValue={initialValue}
        attributeList={attributeList}
        filterInput={filterInput}
        element={element}
        handleInputWatch={handleInputWatch}
        index={index}
        promiseOptions={promiseOptions}
        defaultOptions={defaultOptions}
        AsyncCreatableSelect={AsyncCreatableSelect}
        onSelect={onSelect}
        onCreateElement={onCreateElement}
        arrayStringOptions={arrayStringOptions}
        arrayNumberOptions={arrayNumberOptions}
        attributList={attributList}
        gender={gender}
        next={next}
      />
    );
  };
  useEffect(() => {}, [initialValue]);
  // useEffect(() => {
  //   return () => {
  //     handleNext(4);
  //   };
  // }, []);
  return (
    <>
      <div className={cls.root}>
        <div className="w-full h-full bg-white m-auto p-5">
          <div className="pt-2 flex w-full ">
            <div className="w-full">
              <div className="w-full">
                <div className="w-full bg-white p-3">
                  <div className="pt-2">
                    <RenderType></RenderType>
                  </div>
                  {next && (
                    <div className="w-full py-4 flex items-baseline">
                      <div className="w-1/5 text-head">
                        {t("Стоимость рекрута")}
                      </div>
                      <div className="w-3/5">
                        <Input
                          type={"number"}
                          className="p-0.5"
                          value={totalCostWithoutNds}
                          disabled
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div></div>
              </div>
            </div>
          </div>
          {!next && (
            <div className="w-full">
              <Select
                width={200}
                className="p-5 w-1/4"
                background={"rgba(0, 103, 244, 1)"}
                onChange={addNewItem}
                placeholder={t("add")}
                options={attributList}
              />
            </div>
          )}
        </div>
        <div className="p-3 mr-40 flex justify-end space-x-2">
          {activeStep === 0 ? (
            ""
          ) : (
            <Button
              className="float-right "
              onClick={() => handleBack(1)}
              style={{
                marginRight: "5px",
                color: "gray",
                width: "100px",
                borderRadius: "9px",
                border: "1px solid rgba(229, 233, 235, 1)",
                padding: " 10px",
                background: "white",
              }}
            >
              Назад
            </Button>
          )}
          <Button onClick={onSubmit} size={"large"}>
            {inquery_id ? "Сохранить" : "Продолжить"}
          </Button>
        </div>
      </div>
    </>
  );
}
