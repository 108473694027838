import { pageActionsTypes } from '../actions/currentPage/pageActionTypes'

const initialState = {
  page: 1,
  limit: 10,
}
const counterReducer = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case pageActionsTypes.CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload,
      }
    case pageActionsTypes.CURRENT_TAB:
      return {
        ...state,
        currentTab: payload,
      }
    case pageActionsTypes.CHILD_CURRENT_PAGE:
      return {
        ...state,
        childCurrentPage: payload,
      }
    case pageActionsTypes.CHILD_CURRENT_TAB:
      return {
        ...state,
        childCurrentTab: payload,
      }
    default:
      return state
  }
}
export default counterReducer
