import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
// import TeamsList from "../teams/teamsList/teamsList";
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Header from '../../components/Header/Header'
import {StyledTableCell} from '../../components/DataTable/index'
import EditIcon from '@material-ui/icons/Edit'
import request from '../../models/respondent'
import ExcelFile from '../../models/excel'
import Pagination from '../../components/Pagination'
import {useHistory, useLocation} from 'react-router-dom'
import {DownloadOutlined} from '@ant-design/icons'
import {useDispatch, useSelector} from 'react-redux'
import {pageActionsTypes} from '../../redux/actions/currentPage/pageActionTypes'
import RangePicker from '../../components/DatePicker/RangePicker'
import moment from 'moment'
import Rating from '../../components/Rating/index'
import {Checkbox} from '@material-ui/core'
import axios from '../../utils/axios'
import SvgIcon from '../../components/UploadWitchComment/Icons'

function useQuery() {
  const {search} = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const Index = () => {
  let query = useQuery()
  let from_date = query.get('from_date')
  let to_date = query.get('to_date')
  const {t} = useTranslation()
  const [tableheadername, settableheadername] = useState([
    t('number.icon'),
    t('full.name'),
    t('ИНН'),
    // t('Рейтинг респондента'),
    t('Текущий баланс'),
    t('phone'),
    t('email.name'),
    t('verify'),
  ])
  const history = useHistory()
  const [respondentList, setrespondentList] = useState([])
  const pageCount = 10
  const dispatch = useDispatch()
  const currentPage = useSelector((state) => state.counter)
  const permissions = useSelector((state) => state.auth.permissions)
  const [searchTable, setSearchTable] = useState()
  const [items, setItems] = useState({})
  const [pageLimit, setPageLimit] = useState(pageCount)
  const [checkValue, setCheckValue] = useState(false)
  const [includes, setIncludes] = useState()
  const [loading, setLoading] = useState(false)
  const [verify, setVerify] = useState()
  const [value, setValue] = useState({
    is_checked: 0,
    user_id: '',
  })
  const [date, setDate] = useState({
    from_date: '',
    to_date: '',
  })
  const label = {inputProps: {'aria-label': 'Checkbox demo'}}

  const handleCheck = (e, id) => {
    setValue(() => ({
      is_checked: e.target.checked === true ? 1 : 0,
      user_id: id,
    }))
    setCheckValue(true)
  }
  const handleChange = (val) => {
    val[0] === null
      ? setDate((old) => ({
          from_date: '',
          to_date: '',
        }))
      : setDate((old) => ({
          from_date: moment(val[0]).format('YYYY-MM-DD'),
          to_date: moment(val[1]).format('YYYY-MM-DD'),
        }))
    history.push(
      `?from_date=${val[0].format('YYYY-MM-DD')}&to_date=${val[1].format(
        'YYYY-MM-DD'
      )}`
    )
  }
  const sendCost = (id) => {
    axios
      .put(`/respondent/is-checked`, {
        is_checked: value.is_checked,
        user_id: value.user_id,
      })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (checkValue) {
      sendCost()
      setCheckValue(false)
    }
  }, [checkValue])

  const respondentGetAllList = () => {
    setLoading(true)
    const params = {
      from_date: from_date,
      to_date: to_date,
      search: searchTable,
      page: searchTable ? '1' : pageLimit / pageCount,
      limit: pageCount,
    }

    request
      .respondent(params)
      .then((res) => {
        setrespondentList(res.data.respondents)
        setItems(res.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getExcelFile = () => {
    const param = {
      from_date: date.from_date,
      to_date: date.to_date,
      search: searchTable,
    }
    ExcelFile.respondentGetAllExcelFile(param)
      .then((res) => {
        window.location.href = res.data.url
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const cuttomRowClick = (id) => {
    history.push(`${`/home/respondents/update/${id}`}`)
  }

  useEffect(() => {
    setIncludes(permissions.includes('Редактировать Респонденты'))
  }, [permissions])

  useEffect(() => {
    setVerify(permissions.includes(t('Верификация')))
  }, [permissions])

  useEffect(() => {
    respondentGetAllList()
  }, [searchTable, from_date, to_date, pageLimit])

  return (
    <div>
      <Header title={'Респонденты'}></Header>
      {/*<TeamsList/>*/}
      <div className='fslex p-5 px-5 pb-4' style={{borderRadius: '10px'}}>
        <TableContainer
          elevation={0}
          component={Paper}
          className=' shadow-none rounded-2xl p-6'
          style={{borderRadius: '10px'}}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              marginBottom: '16px',
            }}
          >
            <span>
              <RangePicker
                style={{height: '100%', marginRight: '8px'}}
                hideTimeBlock
                onChange={(val) => handleChange(val)}
                className='p-2 w-full'
              />
            </span>
            <input
              name='search'
              value={searchTable}
              onChange={(e) => {
                setSearchTable(e.target.value)
              }}
              style={{
                border: '1px solid #E5E9EB',
                borderRadius: '8px',
                padding: '8px 12px',
                maxWidth: '200px',
              }}
              placeholder='Поиск'
            />
            <a
              onClick={() => {
                getExcelFile()
              }}
              id='download_file'
            >
              <DownloadOutlined />
            </a>
          </div>
          {loading ? (
            ''
          ) : (
            <Table
              elevation={0}
              aria-label='customized table'
              style={{borderRadius: '10px'}}
            >
              <TableHead>
                <TableRow className='text-black font-body'>
                  {tableheadername?.map((element, index) => (
                    <StyledTableCell style={{textAlign: 'center'}} key={index}>
                      {element}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {respondentList?.map((element, index) => (
                  <TableRow
                    className={`text-black font-body hover:bg-gray-100 ${
                      includes ? 'cursor-pointer' : 'cursor-default'
                    }`}
                    key={index}
                    onClick={() => {
                      if (includes) cuttomRowClick(element.id)
                    }}
                  >
                    <StyledTableCell style={{textAlign: 'center'}}>
                      {parseInt(
                        pageCount * (pageLimit / pageCount - 1) + index + 1
                      )}
                    </StyledTableCell>
                    <StyledTableCell style={{textAlign: 'center'}}>{`${
                      element?.last_name
                    } ${element?.first_name.charAt(0)}.`}</StyledTableCell>
                    <StyledTableCell>{element?.inn}</StyledTableCell>
                    {/* <StyledTableCell style={{textAlign: 'center'}}>
                      <Rating count={element?.average_rating} />
                    </StyledTableCell> */}
                    <StyledTableCell style={{textAlign: 'center'}}>
                      {element.balance}&nbsp;₽
                    </StyledTableCell>
                    <StyledTableCell
                      style={{whiteSpace: 'nowrap', textAlign: 'center'}}
                    >
                      {element.phone}
                    </StyledTableCell>
                    <StyledTableCell style={{textAlign: 'center'}}>
                      {element.email}
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                      style={{
                        borderRight: '1px solid #ddd',
                        textAlign: 'center',
                      }}
                    >
                      <Checkbox
                        {...label}
                        color='primary'
                        defaultChecked={element.is_check === 1 ? true : false}
                        onChange={(e) => {
                          if (verify) handleCheck(e, element.id)
                        }}
                        checkedIcon={<SvgIcon name='check' />}
                        icon={<SvgIcon name='notCheck' />}
                        style={verify ? {} : {pointerEvents: 'none'}}
                      />
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <Pagination
          className='mt-5 text-black-700'
          // size={'large'}
          count={items.count}
          pageCount={pageCount}
          currentPage={
            currentPage.currentPage === undefined
              ? 1
              : currentPage.currentPage.page
          }
          onChange={(val) => {
            dispatch({
              type: pageActionsTypes.CURRENT_PAGE,
              payload: {
                page: parseInt(val / 10),
                limit: 10,
              },
            })
            setPageLimit(val)
          }}
        />
      </div>
    </div>
  )
}
export default Index
