// Here you can set services. Inside Object, you can define
// POST/PUT/GET/DELETE/PATCH operations

import request from '../utils/axios'

const requests = {
  login: (data) => {
    return request({
      method: 'post',
      url: '/auth/standard/login',
      data: data,
    })
  },
  RegistratsiyaCompany: (data) => {
    return request({
      method: 'post',
      url: '/auth/register-company',
      data: data,
    })
  },
  RefreshToken: (data) => {
    return request({
      method: 'post',
      url: '/auth/refresh-token',
      data: data,
    })
  },
  // loginBySuperUser: (data) => request({ method: 'post', url: '/auth/login/super-user', data }),
  // logout: (data) => request({ method: 'post', url: '/auth/logout', data }),
  // refreshToken: (data) => request({ method: 'post', url: '/auth/token/refresh', data })
}
export default requests
