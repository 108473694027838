import React, {useState, useEffect} from "react";
import cls from "./index.module.scss";
// import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import {StyledTableCell} from "../../components/DataTable";
// import moment from "moment";
// import Tag from "../../components/Tag";
import {toast} from "react-toastify";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {StyledPropMenu} from "../../components/custom/StyledPropMenu";
import TableLoader from "../../components/TableLoading/Index";
import Pagination from "../../components/Pagination";
// import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "../../utils/axios";
import moment from "moment";
import {Tooltip, Typography} from "@material-ui/core";
import {NumberFomat} from "../../functions/numberFomat";
import TopPanel from "./TopPanel";
import AddCost from "./AddCost";
import Billing from "../../models/billing";
import {DownloadIcon, UploadIcon} from "../../components/svg/TransactionIcon";
import {useDropzone} from "react-dropzone";

const BillingTable = ({accountNumber, show = true, panel, minus = true}) => {
  const param = useParams();
  const history = useHistory();
  const {t} = useTranslation();
  const [researchersList, setresearchers] = useState([]);
  const [dropList, setDropList] = useState({target: null, id: ""});
  const [update, setUpdate] = useState(false);

  const uploadExcelFile = (props) => {
    const file = props[0];

    const formData = new FormData();
    formData.append(`excel_file`, file);

    axios
      .post(`/excel/import/balance`, formData)
      .then((res) => {
        toast.success("Успешно!", {
          className: "bg-green-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(t(err.data.error), {
          className: "bg-red-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        });
      });
  };

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    multiple: false,
    onDrop: uploadExcelFile,
  });
  const listRender = [
    {
      title: t("delete"),
      icon: <ClearIcon className="bg-red-100 rounded text-red-600 p-0.5" />,
      type: "delete",
    },
  ];
  //   const handleMoreClick = (id, type) => {
  //     // if (type === 'edit') {
  //     //     console.log('edit');
  //     //     setDropList((old) => {
  //     //         return { ...old, target: null };
  //     //     });
  //     //     // history.push(`/home/dashboard/teamsCreate/${dropList.id}`)
  //     // }
  //     if (type === 'delete') {
  //       console.log('delete')
  //       setDropList((old) => {
  //         return { ...old, target: null }
  //       })
  //     }
  //     axios.get(`/billing/transaction`).then((res) => {
  //       if (res.code === 200) {
  //         toast.success('Успех исследования удалить', {
  //           className: 'bg-green-500 text-white',
  //           closeOnClick: true,
  //           ideProgressBar: false,
  //           autoClose: 2000,
  //         })
  //         reseachersGetAll(params)
  //       }
  //     })
  //   }
  const cutomRowClick = (id) => {
    history.push(`/home/company/inquiryitem/${param.id}/${id}`);
  };
  const pageCount = 10;
  const [items, setItems] = useState({});
  const [pageLimit, setPageLimit] = useState(pageCount);
  const [reload, setReload] = useState(null);
  const [tableHeardName, settableHeardName] = useState([
    t("number.icon"),
    t("Дата"),
    t("Номер транзакции"),
    t("Название транзакции"),
    t("Сумма"),
    t("Статус транзакции"),
  ]);
  const transactionGetAll = () => {
    const params = {
      page: pageLimit / pageCount,
      limit: pageCount,
      account_number: `${accountNumber}`,
    };
    Billing.billingGetAllList(params)
      .then((res) => {
        setresearchers(res.data.transactions);
        setItems(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    transactionGetAll();
  }, [pageLimit, reload, accountNumber]);

  return (
    <div className={cls.root}>
      <div className={cls.btnCost}>
        {show && (
          <AddCost setReload={setReload} accountNumber={accountNumber} />
        )}
      </div>
      {!minus && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            gap: "24px",
            marginBottom: "16px",
            paddingRight: "20px",
          }}
        >
          <a
            href={"https://cdn.urecruit.ru/excel/company.xlsx"}
            style={{
              padding: "12px",
              background: "rgba(0, 103, 244, 0.05)",
              borderRadius: "8px",
              display: "flex",
              gap: "16px",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <DownloadIcon />{" "}
            <span
              style={{
                fontWeight: "500",
                lineHeight: "24px",
                fontSize: "14px",
                fontFamily: "Inter",
                color: "#0067F4",
              }}
            >
              Скачать Шаблон
            </span>
          </a>
          <div
            style={{
              padding: "12px",
              background: "#fff",
              borderRadius: "8px",
              display: "flex",
              gap: "16px",
              alignItems: "center",
              cursor: "pointer",
              border: "1px dashed rgba(110, 139, 183, 0.3)",
            }}
            {...getRootProps()}
          >
            <UploadIcon />{" "}
            <span
              style={{
                fontWeight: "500",
                lineHeight: "24px",
                fontSize: "14px",
                fontFamily: "Inter",
                color: "#0067F4",
              }}
            >
              Загрузить Excel
            </span>
            <input {...getInputProps()} />{" "}
          </div>
        </div>
      )}
      <TopPanel minus={minus} panel={panel} show={show} id={param.id} />
      <div className="fslex p-5" style={{borderRadius: "20px"}}>
        <TableContainer
          id="tableBody"
          elevation={0}
          component={Paper}
          className=" shadow-none rounded-2xl"
          style={{borderRadius: "6px"}}
        >
          <Table elevation={0} aria-label="customized table">
            <TableHead style={{borderRadius: "6px"}}>
              <TableRow className="text-black font-body">
                {tableHeardName?.map((element, index) => (
                  <StyledTableCell
                    style={{
                      textAlign: `${
                        element === "Статус транзакции" && "center"
                      }`,
                    }}
                    key={index}
                  >
                    {element}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {researchersList?.map((element, index) => (
                <TableRow
                  className="text-black font-body hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    if (element.object_info.inquiry_id) {
                      cutomRowClick(element.object_info.inquiry_id);
                    }
                  }}
                  key={index}
                >
                  <StyledTableCell>
                    <Typography className="tableRow">
                      {parseInt(
                        pageCount * (pageLimit / pageCount - 1) + index + 1
                      )}{" "}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography className="tableRow">
                      {moment(element?.created_at).format("DD-MM-YYYY")}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography className="tableRow">
                      {element?.transaction_number}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Tooltip
                      title={
                        <h1 style={{fontSize: "14px", color: "#fff"}}>
                          {element?.object_info?.info}
                        </h1>
                      }
                      placement="top"
                    >
                      <Typography id="text_limit" className="tableRow">
                        {element?.object_info?.info} {/* &nbsp; */}
                        {/* {element?.object_info?.from} */}
                      </Typography>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      className="tableRow"
                      style={{
                        color: `${
                          minus
                            ? element?.debit < element?.credit
                              ? "red"
                              : "rgba(34, 195, 72, 1)"
                            : element?.credit < element?.debit
                            ? "red"
                            : "rgba(34, 195, 72, 1)"
                        }`,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {NumberFomat(
                        parseInt(
                          minus
                            ? element?.debit - element?.credit
                            : element?.credit - element?.debit
                        )
                      )}
                      ₽
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{display: "flex", justifyContent: "center"}}
                  >
                    <Typography
                      className="tableStatus"
                      style={{
                        background: `${
                          element.object_info.status == "refilled"
                            ? "linear-gradient( 0deg, rgba(56, 217, 185, 0.2), rgba(56, 217, 185, 0.2)), rgb(255, 255, 255"
                            : element?.object_info?.status == "received"
                            ? "rgba(248, 221, 78, 0.3)"
                            : element?.object_info?.status == "purchased"
                            ? "linear-gradient(0deg, rgba(248, 221, 78, 0.3), rgba(248, 221, 78, 0.3)), #FFFFFF"
                            : element?.object_info?.status == "transfered"
                            ? "rgba(64, 148, 247, 0.15)"
                            : "linear-gradient(0deg, rgba(64, 148, 247, 0.15), rgba(64, 148, 247, 0.15)), #FFFFFF"
                        }`,
                        color: `${
                          element.object_info.status == "refilled"
                            ? "rgb(26, 193, 157)"
                            : element?.object_info?.status == "received"
                            ? "#D29404"
                            : element?.object_info?.status == "purchased"
                            ? "#D29404"
                            : element?.object_info?.status == "transfered"
                            ? "#4094F7"
                            : "#4094F7"
                        }`,
                      }}
                    >
                      {t(
                        `${
                          element?.object_info?.status === undefined
                            ? "refilled"
                            : element?.object_info?.status
                        }`
                      )}
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TableLoader isVisible={loading} /> */}
        <Pagination
          className="mt-5 text-black-700"
          count={items.count}
          pageCount={pageCount}
          onChange={(val) => {
            setPageLimit(val);
          }}
        />
      </div>
    </div>
  );
};

export default BillingTable;
