import {useHistory} from "react-router-dom";
import {useState, useEffect} from "react";
import Auth from "../../services/auth";
import {useTranslation} from "react-i18next";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import Checkbox from "@material-ui/core/Checkbox";
import {useDispatch, useSelector} from "react-redux";
import {ToastContainer, toast, cssTransition} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../components/Input/index";
import {Formik} from "formik";
import * as Yup from "yup";
import ValidationForm from "../../functions/validateForm";
import Form from "../../components/Form/index";
import INN from "../../components/custom/INN/INN";
import {authActionTypes} from "../../redux/actions/authActions/authActionTypes";
import Img from "../../assets/icons/Group.png";
import TextLogo from "../../assets/icons/URecruit.svg";
import moment from "moment";

function App() {
  const history = useHistory();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.auth);
  const [username, setUsername] = useState("");
  const [authPasscode, setPasscode] = useState({
    passcode_token: "",
    passcode: "",
  });
  const [senddate, setsenDate] = useState({
    usename: "",
    data: "admin_panel",
    password: "",
  });
  const bounce = cssTransition({
    enter: "animate__animated animate__bounceIn",
    exit: "animate__animated animate__bounceOut",
  });
  const [seepassword, setSeePassword] = useState(true);
  const [open, setOpen] = useState(false);
  const [checkINN, setCheckINN] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [popapOpen, setpopapOpen] = useState(false);

  useEffect(() => {
    console.log(state);
  }, [state]);
  const validationSchema = Yup.object().shape({
    data: ValidationForm("fullname", t),
    username: ValidationForm("fullname", t),
    password: ValidationForm("password", t),
  });
  const handleLogin = (e) => {
    if (true) {
      Auth.login({...e, username: e.username.toLowerCase()})
        .then((res) => {
          const permissions = res.data.role.permissions.map((el) => el.name);
          localStorage.setItem("token", res.data.token.access_token);
          localStorage.setItem("refresh_token", res.data.token.refresh_token);
          dispatch({
            type: authActionTypes.AUTH_LOGIN,
            payload: {
              userLogin: res.data.user.login,
              phoneNumber: res.data.user.phone,
              accessToken: res.data.token.access_token,
              refreshToken: res.data.token.refresh_token,
              permissions,
            },
          });
          history.push("/home/dashboard");
        })
        .catch((err) => {
          // setButtonDisebl(false)
          setisLoading(false);
          toast.error("Ошибка входа неверное имя пользователя или пароль", {
            className: "bg-red-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
          console.log(err);
        });
    }
  };

  return (
    <div className=" h-full font-body flex bg-blue-500 overflow-y-hidden  items-center relative ">
      <div
        style={{flex: "0 0 50%"}}
        className=" w-full flex h-full font-body justify-center bg-blue-500"
      >
        <div style={{top: "20px", left: "20px"}} className="absolute">
          <img src={TextLogo} alt="" />
        </div>

        <div style={{top: "50%", left: "50%"}}>
          <img src={Img} alt="" />
        </div>
      </div>
      <div style={{flex: "0 0 50%"}} className=" w-full bg-white">
        <div
          style={{height: "100vh"}}
          className="  text-center p-5   w-3/4 m-auto  bg-white"
        >
          <div className="my-20">
            <p>
              <h1 className="text-3xl">{t("register")}</h1>
            </p>
            <span></span>
          </div>
          <br />
          <Formik
            initialValues={senddate}
            onSubmit={handleLogin}
            validationSchema={validationSchema}
          >
            {(formik) => (
              <form className="my-15" onSubmit={formik.handleSubmit}>
                <div>
                  <label htmlFor="" className="w-full float-left text-base">
                    {" "}
                    {t("email.phone")}
                  </label>
                  <br />
                  <br />
                  <span>
                    <Form.Item name="username" formik={formik}>
                      <Input
                        autoComplete="email"
                        {...formik.getFieldProps("username", t)}
                        placeholder={t("email.phone")}
                        icon={<MailOutlineIcon className="text-blue-500" />}
                        className="p-2"
                      />
                    </Form.Item>
                  </span>
                </div>{" "}
                <br />
                <div>
                  <label htmlFor="" className="float-left text-base">
                    {" "}
                    {t("pick.password")}
                  </label>
                  <br />
                  <br />
                  <span>
                    <Form.Item name="password" formik={formik}>
                      <Input
                        autoComplete="password"
                        type={seepassword ? "password" : "text"}
                        placeholder={t("pick.password")}
                        {...formik.getFieldProps("password", t)}
                        // onChange={(e)=> setsenDate(old=> ({...old, password: e.target.value}))}
                        icon={
                          seepassword ? (
                            <VisibilityOffIcon
                              onClick={() => setSeePassword(false)}
                              className="text-blue-500"
                            />
                          ) : (
                            <VisibilityIcon
                              onClick={() => setSeePassword(true)}
                              className="text-blue-500"
                            />
                          )
                        }
                        className="p-2 w-3/4"
                      ></Input>
                    </Form.Item>
                  </span>
                </div>{" "}
                <br />
                <div>
                  <span className="float-left">
                    <Checkbox
                      color="primary"
                      inputProps={{"aria-label": "secondary checkbox"}}
                    />
                    Запомнить меня
                  </span>
                  <a
                    onClick={() => {
                      history.push("/auth/reset-password");
                    }}
                    className="float-right text-blue-500"
                  >
                    Забыли пароль?
                  </a>
                </div>
                <br /> <br />
                <div>
                  <button
                    type="submit"
                    className=" rounded-xl p-3 text-white bg-blue-500 w-full"
                  >
                    {t("to.come.in")}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <INN
          authPasscode={authPasscode}
          setPasscode={setPasscode}
          checkINN={checkINN}
          setCheckINN={setCheckINN}
          setOpen={setOpen}
        />
      </div>
    </div>
  );
}

export default App;
