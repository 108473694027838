import React, {useState, useEffect} from "react";
import Button from "../Buttons/index";
import cls from "./test.module.scss";
import Input from "../Input";
import RangePicker from "../DatePicker/RangePicker";
import Select from "../Select";
import TextArea from "../Textarea/Index";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import companiyInquiry from "../../models/companyinquiry";
import {CircularProgress} from "@material-ui/core";
import DateInput from "../DatePicker/DateInput";
import DatePicker from "../DatePicker";

export default function StepperOneList({
  loader,
  status,
  studyTypes,
  partisipantRoles,
  handleNext,
  interviewTypes,
  initialValue,
  setinitialValue,
  calculateAmount,
  setCheckFirstStep,
  checkFirstStep,
  handleBlur,
  handleBack,
  activeStep,
  setActiveStep,
  type,
  changeBlur,
  setNext,
  next,
  active,
}) {
  const {t} = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const [checkValidation, setCheckValidation] = useState(true);
  const [checkConnection, setCheckConnection] = useState(true);
  const [checkFromDate, setCheckFromDate] = useState(true);
  const [disableDate, setDisableDate] = useState(false);
  const [checkValue, setCheckValue] = useState(false);
  const [date, setDate] = useState("");
  const onSubmit = (data) => {
    let screenner_projec = "";
    const project = {
      auto_next: true,
      is_active: true,
      is_archive: true,
      languages: [
        {
          is_active: true,
          language: "ru",
        },
      ],
      multiple_answers: true,
      record_answers: true,
      return_answers: true,
    };
    if (params.inquery_id) {
      handleNext();
      return;
    }
    companiyInquiry.createProject(project).then((res) => {
      const survey = {
        title: initialValue.title,
      };
      screenner_projec = res.response_data.id;
      companiyInquiry.createStroy(survey, res.response_data.id).then((res) => {
        setinitialValue((el) => ({
          ...el,
          screener_project_id: screenner_projec,
          screener_survey_id: res.response_data.id,
        }));
      });
    });
    handleNext();
  };

  function setValue(key, value) {
    setinitialValue((el) => ({...el, [key]: value}));
  }

  function changeValue(key, key2, value) {
    setinitialValue((el) => ({...el, [key]: {...el[key], [key2]: value}}));
  }

  const handleChange = (val) => {
    const role =
      val.value === "toloka" ? "respondent" : initialValue.partisipant_role;
    console.log("role", role);
    setinitialValue((old) => ({
      ...old,
      study_type: val.value,
      partisipant_role: role,
      type_of_inquiry: {
        group_participants: "1",
        number_of_groups: "1",
        type_of_inquiry: val.value === "group" ? "group" : "usual",
      },
      number_of_partisipants:
        val.value === "group" ? 1 : old.number_of_partisipants,
    }));
    if (val.value === "toloka") {
      setNext(true);
    } else {
      setNext(false);
    }
    setCheckValue(true);
  };

  const groupParticipantsChange = (e) => {
    setinitialValue((old) => ({
      ...old,
      type_of_inquiry: {
        ...old.type_of_inquiry,
        group_participants: e.target.value,
      },
      number_of_partisipants:
        e.target.value * initialValue?.type_of_inquiry?.number_of_groups,
    }));
    setCheckValue(true);
  };

  const numberOfGroupsChange = (e) => {
    setinitialValue((old) => ({
      ...old,
      type_of_inquiry: {
        ...old.type_of_inquiry,
        number_of_groups: e.target.value,
      },
      number_of_partisipants:
        e.target.value * initialValue?.type_of_inquiry?.group_participants,
    }));
    setCheckValue(true);
  };

  useEffect(() => {
    if (checkValue) {
      calculateAmount({
        promo: active ? true : false,
      });
      setCheckValue(false);
    }
  }, [checkValue]);

  console.log("initialValue", initialValue);

  useEffect(() => {
    if (initialValue.title.length < 1) {
      setCheckValidation(true);
    } else {
      setCheckValidation(false);
    }
    if (initialValue.connection_info.length < 1) {
      setCheckConnection(true);
    } else {
      setCheckConnection(false);
    }
    if (initialValue.from_date?.length > 0) {
      setCheckFromDate(false);
    } else {
      setCheckFromDate(true);
    }
  }, [initialValue]);

  useEffect(() => {
    if (
      initialValue.title.length > 0 &&
      initialValue.connection_info.length > 0 &&
      initialValue.from_date?.length > 0
    ) {
      setCheckFirstStep({
        ...checkFirstStep,
        first: true,
      });
    } else if (initialValue.title.length > 0 && next) {
      setCheckFirstStep({
        ...checkFirstStep,
        first: true,
      });
    } else {
      setCheckFirstStep({
        ...checkFirstStep,
        first: false,
      });
    }
  }, [initialValue]);

  useEffect(() => {
    if (params.inquery_id && params.activestep !== 0) {
      setActiveStep(Number(params.activestep));
    }
  }, [params.inquery_id]);
  // useEffect(() => {
  //   if (params.inquery_id && initialValue.inquiry_timetables.length !== 0) {
  //     setDisableDate(true);
  //   } else if (!params.inquery_id) {
  //     setDisableDate(false);
  //   }
  // }, [params.inquery_id, initialValue]);
  useEffect(() => {
    params.inquiry_id === false
      ? setDisableDate(false)
      : next
      ? setDisableDate(true)
      : initialValue.status_id === "72ee7350-4a2b-4fc2-945c-34faa56b13ca"
      ? setDisableDate(false)
      : params.inquery_id && initialValue.inquiry_timetables.length !== 0
      ? setDisableDate(true)
      : setDisableDate(false);
  }, [params.company_id, initialValue]);

  return (
    <>
      <div className={cls.root}>
        {loader ? (
          <CircularProgress hidden={true} />
        ) : (
          <div className="w-full bg-white m-auto p-5">
            <div className="pt-2 flex w-full ">
              <div className="float-right w-full">
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">
                    {t("Название запроса")}
                    <span
                      style={{
                        color: "red",
                        marginLeft: "2px",
                      }}
                    >
                      *
                    </span>
                  </div>
                  <div className="w-2/3">
                    <Input
                      error={checkValidation}
                      placeholder={
                        "Например: Оплата коммунальных услуг по QR коду"
                      }
                      defaultValue={initialValue.title}
                      onBlur={(e) => {
                        params.inquery_id
                          ? handleBlur("title", e.target.value)
                          : setValue("title", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">{t("Статус")}</div>
                  <div className="w-2/3">
                    <Select
                      value={
                        status.filter(
                          (el) => el.value === initialValue.status_id
                        )[0]
                      }
                      options={status}
                      isDisabled
                    />
                  </div>
                </div>
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">
                    {t("Респондент")}
                    <span
                      style={{
                        color: "red",
                        marginLeft: "2px",
                      }}
                    >
                      *
                    </span>
                  </div>
                  <div className="w-2/3">
                    <Select
                      onChange={(val) => {
                        setinitialValue((old) => ({
                          ...old,
                          partisipant_role: val.value,
                        }));
                        setCheckValue(true);
                      }}
                      options={partisipantRoles}
                      // defaultValue={initialValue?.partisipant_role}
                      defaultValue={partisipantRoles.find(
                        (el) => el.value === initialValue.partisipant_role
                      )}
                      isDisabled={
                        initialValue.status_id ===
                        "7b75685f-ee20-4fb9-bc5f-7e40c115e708"
                          ? true
                          : next
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>
                {!next && (
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3"></div>
                    <div className="w-2/3">
                      {initialValue.partisipant_role === "respondent" ? (
                        <p style={{fontWeight: "500"}}>{t("low")}</p>
                      ) : initialValue.partisipant_role === "professional" ? (
                        <p style={{fontWeight: "500"}}>{t("medium")}</p>
                      ) : (
                        <p style={{fontWeight: "500"}}>{t("high")}</p>
                      )}
                    </div>
                  </div>
                )}
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">
                    {t("Вид исследования")}
                    <span
                      style={{
                        color: "red",
                        marginLeft: "2px",
                      }}
                    >
                      *
                    </span>
                  </div>
                  <div className="w-2/3">
                    <Select
                      onChange={handleChange}
                      options={studyTypes}
                      defaultValue={
                        studyTypes.filter(
                          (el) => el.value === initialValue.study_type
                        )[0]
                      }
                    />
                  </div>
                </div>
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">
                    {t("Формат интервью")}
                    <span
                      style={{
                        color: "red",
                        marginLeft: "2px",
                      }}
                    >
                      *
                    </span>
                  </div>
                  <div className="w-2/3">
                    <Select
                      onChange={(val) => {
                        setinitialValue((old) => ({
                          ...old,
                          interview_type: val.value,
                        }));
                      }}
                      options={interviewTypes}
                      defaultValue={
                        interviewTypes.filter(
                          (el) => el.value === initialValue.interview_type
                        )[0]
                      }
                      isDisabled={next ? true : false}
                    />
                  </div>
                </div>
                {initialValue.study_type === "group" ? (
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">
                      {t("Количества групп")}
                      <span
                        style={{
                          color: "red",
                          marginLeft: "2px",
                        }}
                      >
                        *
                      </span>
                    </div>
                    <div className="w-2/3">
                      <Input
                        type="number"
                        defaultValue={
                          initialValue.type_of_inquiry.number_of_groups
                        }
                        onBlur={(e) => {
                          params.inquery_id
                            ? changeBlur(
                                "type_of_inquiry",
                                "number_of_groups",
                                e.target.value
                              )
                            : changeValue(
                                "type_of_inquiry",
                                "number_of_groups",
                                e.target.value
                              );
                        }}
                        onChange={numberOfGroupsChange}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {initialValue.study_type === "group" ? (
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">
                      {t("Количества участников")}
                      <span
                        style={{
                          color: "red",
                          marginLeft: "2px",
                        }}
                      >
                        *
                      </span>
                    </div>
                    <div className="w-2/3">
                      <Input
                        type="number"
                        defaultValue={
                          initialValue.type_of_inquiry.group_participants
                        }
                        onBlur={(e) => {
                          params.inquery_id
                            ? changeBlur(
                                "type_of_inquiry",
                                "group_participants",
                                e.target.value
                              )
                            : changeValue(
                                "type_of_inquiry",
                                "group_participants",
                                e.target.value
                              );
                        }}
                        onChange={groupParticipantsChange}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">
                    {t("Тип соединения")}
                    <span
                      style={{
                        color: "red",
                        marginLeft: "2px",
                      }}
                    >
                      *
                    </span>
                  </div>
                  <div className="w-2/3">
                    <Input
                      placeholder={
                        initialValue.interview_type === "online"
                          ? "Введите Линк или телефон"
                          : "Введите адрес места встречи"
                      }
                      type="text"
                      error={!next ? checkConnection : null}
                      defaultValue={initialValue.connection_info}
                      onBlur={(e) => {
                        params.inquery_id
                          ? handleBlur("connection_info", e.target.value)
                          : setinitialValue((old) => ({
                              ...old,
                              connection_info: e.target.value,
                            }));
                      }}
                      disabled={next ? true : false}
                    />
                  </div>
                </div>
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">{t("Период проведения")}</div>
                  <div className="w-2/3 gap-3 flex">
                    <span>
                      <RangePicker
                        // disabled={disableDate}
                        initialValue={initialValue}
                        hideTimeBlock
                        error={checkFromDate}
                        onChange={(val) => {
                          val[0] === null
                            ? setinitialValue((old) => ({
                                ...old,
                                from_date: moment(new Date()),
                                to_date: moment(new Date()).add(30, "d"),
                              }))
                            : setinitialValue((old) => ({
                                ...old,
                                from_date: moment(val[0]).format("YYYY-MM-DD"),
                                to_date: moment(val[1]).format("YYYY-MM-DD"),
                              }));
                        }}
                        // className='p-2 w-full'
                      />
                    </span>
                  </div>
                </div>
                {/* <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">{t("Период проведения")}</div>
                  <div className="w-2/3 gap-3 flex">
                    <span>
                      <DatePicker
                        value={date}
                        onChange={(e) => {
                          setDate(moment(e).format("YYYY-MM-DD"));
                        }}
                      />
                    </span>
                  </div>
                </div>
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">{t("Период проведения")}</div>
                  <div className="w-2/3 gap-3 flex">
                    <span>
                      <DatePicker
                        value={date}
                        onChange={(e) => {
                          setDate(moment(e).format("YYYY-MM-DD"));
                        }}
                      />
                    </span>
                  </div>
                </div> */}
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">{t("Описание")}</div>
                  <div className="w-2/3">
                    <TextArea
                      style={{padding: "0.125rem 18px"}}
                      placeholder="Опишите подробнее какой именно опыт клиентов вас интересует или может быть вам полезен"
                      defaultValue={initialValue.description}
                      name="description"
                      onBlur={(val) => {
                        params.inquery_id
                          ? handleBlur("description", val.target.value)
                          : setValue("description", val.target.value);
                      }}
                      size={7}
                    />
                  </div>
                </div>
                <div className="flex justify-end">
                  <Button type="button" size={"large"} onClick={onSubmit}>
                    Продолжить
                  </Button>
                  {/*{ activeStep===0?"":<Button onClick={handleBack} style={{padding: "10px", float: "right", marginRight: "8px"}}>Назад</Button>}*/}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
