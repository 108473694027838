export default function Filters({ extra, children , status = false}) {
  return (
    <div
      className="h-15 w-full flex items-center font-body border-t border-b"
      style={{ backgroundColor: "#F8F9FB" }}
    >
      <div  className={`flex ${status ? 'justify-center' : 'justify-between'} w-full items-center px-4`}>
        <div>{children}</div>
        <div>{extra}</div>
      </div>
    </div>
  );
}
