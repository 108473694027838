import request from '../utils/axios'
const base_Url = {
  permisisons: '/permission',
  admin: '/admin',
  roll: '/client/role',
  singleroll: '/role',
}
const requests = {
  getAllPermission: (id) =>
    request({
      url: `${base_Url.permisisons}/${id}`,
      method: 'get',
    }),
  getAllAdmins: (params) =>
    request({
      url: `${base_Url.admin}`,
      method: 'get',
      params: params,
    }),
  getDataAdmin: (id) =>
    request({
      url: `${base_Url.admin}/${id}`,
      method: 'get',
    }),
  updateDataAdmin: (id, data) =>
    request({
      url: `${base_Url.admin}/${id}`,
      method: 'put',
      data: data,
    }),
  createDataAdmin: (data) =>
    request({
      url: `${base_Url.admin}`,
      method: 'post',
      data: data,
    }),
  getAllRolls: (id) =>
    request({
      url: `${base_Url.roll}`,
      method: 'get',
      params: id,
    }),
  getRoll: (id) =>
    request({
      url: `${base_Url.singleroll}/${id}`,
      method: 'get',
    }),
}
export default requests
