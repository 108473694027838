import classes from "./calendar.module.scss";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import CheckIcon from "@material-ui/icons/Check";
import {useHistory} from "react-router-dom";

export default function CalendarContent({
  calendar,
  startTime = 0,
  endTime = 24,
  duration = 30,
  active = false,
  checkCalendar = true,
}) {
  const {t} = useTranslation();
  const history = useHistory();
  const [data, setData] = useState([[]]);

  const [tableHeaders, setTableHeaders] = useState([]);

  function checkRespondent(param1) {
    let equal = calendar?.timetables?.filter((item) => item.id === param1);
    var msDiff =
      new Date(equal?.[0]?.end_time).getTime() +
      18000000 -
      new Date().getTime();
    var daysTill30June2035 = Math.floor(msDiff / 3600000);

    if (checkCalendar) {
      if (
        equal?.[0]?.number_of_applicants ===
          equal?.[0]?.number_of_partisipants &&
        daysTill30June2035 >= 0
      ) {
        switch (1) {
          case 1:
            return "#f1ffa8";
          default:
            return "#f1ffa8";
        }
      }
      if (
        equal?.[0]?.number_of_applicants ===
          equal?.[0]?.number_of_partisipants &&
        daysTill30June2035 < 0
      ) {
        switch (2) {
          case 2:
            return "#3acf37";
          default:
            return "#3acf37";
        }
      }
      if (
        equal?.[0]?.number_of_applicants !==
          equal?.[0]?.number_of_partisipants &&
        daysTill30June2035 < 0
      ) {
        switch (3) {
          case 3:
            return "rgb(60 61 62 / 34%)";
          default:
            return "rgb(60 61 62 / 34%)";
        }
      } else {
        switch (0) {
          case 2:
            return "rgba(0, 103, 244, 0.1";
          default:
            return "rgba(0, 103, 244, 0.1)";
        }
      }
    }
  }

  useEffect(() => {
    formatDataForCalendar(calendar)
      .then((res) => {
        setTableHeaders(res.firstRow);
        setData(res.tableData);
      })
      .catch((err) => {});
  }, [calendar]);
  const formatDataForCalendar = (res) => {
    return new Promise((resolve, reject) => {
      const tableData = [];
      const fromDate = new Date(res.from_date);
      const toDate = new Date(res.to_date);
      const xRange = (toDate - fromDate) / 86400000;
      const yRange = Math.trunc(((endTime - startTime) * 60) / duration);
      const beginDate = moment(res.from_date);
      const beginTime = moment().set("hour", startTime).set("minute", 0);
      let firstRow = [{text: t("time")}];
      let defaultRow = [];
      let rowSpans = {};
      const formattedData = makeFormattedObject(res.timetables);
      // debugger
      for (let i = 0; i <= xRange; i++) {
        defaultRow.push({text: "", date: beginDate.format("YYYY-MM-DD")});
        firstRow.push({text: beginDate.format("dd, DD MMM"), date: beginDate});
        beginDate.add(1, "d");
      }
      for (let i = 0; i < yRange; i++) {
        const start = beginTime.format("HH:mm");
        const end = beginTime.add(duration, "m").format("HH:mm");
        let currentRow = [{text: `${start} - ${end}`}];

        defaultRow.forEach((elm) => {
          if (!(rowSpans[elm.date] && rowSpans[elm.date] > 1)) {
            const isExist = formattedData[start]?.find(
              (el) => elm.date === moment(el.dates).format("YYYY-MM-DD")
            );
            if (isExist) {
              const row_span =
                (new Date(isExist.end_time).getTime() -
                  new Date(isExist.start_time).getTime()) /
                1000 /
                60 /
                +duration;
              rowSpans[elm.date] = row_span;
              currentRow.push({
                ...elm,
                text: checkedElm(isExist.title),
                name: isExist.respondent_name,
                id: isExist.id,
                inquiry_id: isExist.inquiry_id,
                rowSpan: row_span,
              });
            } else {
              currentRow.push({text: ""});
            }
          } else {
            rowSpans[elm.date]--;
          }
        });

        tableData.push(currentRow);
      }

      resolve({firstRow, tableData});
    });
  };
  const makeFormattedObject = (data = []) => {
    if (data.length) {
      let res = {};
      // let row = []
      // let curTime = moment(data[0].start_time).subtract(5, 'h').format('HH:mm')
      data.forEach((elm) => {
        const startTime = moment(elm.start_time)
          .subtract(0, "h")
          .format("HH:mm");
        res[startTime] = res[startTime] ? [...res[startTime], elm] : [elm];
      });
      return res;
    }
    return {};
  };

  // const defineCellExist = (timetables, element, start, end) => {
  //   const item = timetables.find(elm => {
  //     const fromTime = moment(elm.start_time).format('HH:mm')
  //     const toTime = moment(elm.end_time).format('HH:mm')
  //     const date = moment(elm.dates).format('YYYY-MM-DD')
  //     return fromTime === start && element.date === date
  //   })
  //   return item ? { text: checkedElm(item.title), id: item.id } : { text: '' }
  // }

  const checkedElm = (text) => (
    <div className={classes.checked}>
      <p className={`truncate ${classes.text}`}>{text}</p>
    </div>
  );
  const checkedName = (name) => (
    <div className={classes.checked}>
      <p className={`truncate ${classes.text}`}>{name}</p>
    </div>
  );

  const isBooked = (el, calendar) => {
    const time = calendar?.timetables?.find((item) => item.id === el.id);
    return time?.number_of_partisipants === time?.number_of_applicants;
  };

  return (
    <div className={classes["calendar-wrapper"]}>
      <div className={classes.calendar}>
        <table style={{width: "100%"}}>
          <tr
            style={{
              position: "sticky",
              background: "#e8e8e8",
              top: "-1px",
            }}
          >
            {tableHeaders.map((el, i) => (
              <th className={i === 0 ? classes["time-range-head"] : ""}>
                {el.text}
              </th>
            ))}
          </tr>
          {data.map((elm, index) => (
            <tr>
              {elm.map((el, index1) =>
                index1 === 0 ? (
                  <td className={classes["time-range"]}>
                    <div className={classes["table-cell"]}>{el.text}</div>
                  </td>
                ) : (
                  <td rowSpan={el.rowSpan}>
                    {el.text && (
                      <div
                        onClick={() => {
                          isBooked(el, calendar) &&
                            history.push(
                              `/home/calendar/table/${el.id}/${el.inquiry_id}`
                            );
                        }}
                        style={{
                          // background:`${checkCalendar && el.number_of_applications === el.number_of_partisipants ? '#e2c76e' : 'rgba(0, 103, 244, 0.1)'}`,
                          background: `${
                            checkCalendar
                              ? checkRespondent(el.id)
                              : "rgba(0, 103, 244, 0.1)"
                          }`,
                          cursor: `${
                            isBooked(el, calendar) ? "pointer" : "inherit"
                          }`,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        {active ? (
                          el?.name ? (
                            <div className={classes.checked}>
                              <p className={`truncate ${classes.text}`}>
                                {el?.name}
                              </p>
                            </div>
                          ) : (
                            <CheckIcon style={{color: "rgb(0, 103, 244)"}} />
                          )
                        ) : (
                          el.text
                        )}
                      </div>
                    )}
                  </td>
                )
              )}
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
}
