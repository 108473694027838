import request from "../utils/axios";
const base_Url = {
  company: "/company",
};

const requests = {
  companyGetAll: (params) =>
    request({
      url: `${base_Url.company}`,
      method: "get",
      params: params,
    }),
  companyItemGet: (id) =>
    request({
      url: `${base_Url.company}/${id}`,
      method: "get",
    }),
  createCompany: (data) =>
    request({
      url: `${base_Url.company}`,
      method: "post",
      data: data,
    }),
  updateCompany: (id, data) =>
    request({
      url: `${base_Url.company}/${id}`,
      method: "put",
      data: data,
    }),
  deleteCompany: (id) =>
    request({
      url: `${base_Url.company}/${id}`,
      method: "delete",
    }),
};
export default requests;
