import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import logout from '../../models/logout'
import { authActionTypes } from '../../redux/actions/authActions/authActionTypes'
import ProfileIcon from '../../assets/icons/profile.svg'
import cls from './index.module.scss'
import { FilterIcon } from '../svg/Filter'
import { CloseIcons } from '../svg/CloseIcons'
import { Typography } from '@material-ui/core'
import Select from '../Select'
import { ClickAwayListener } from '@material-ui/core'

const Status = [
  {
    name: 'Черновик',
    id: '72ee7350-4a2b-4fc2-945c-34faa56b13ca',
  },
  {
    name: 'Активный',
    id: '7b75685f-ee20-4fb9-bc5f-7e40c115e708',
  },
  {
    name: 'Завершено',
    id: '2e857fbe-9add-4eae-a8c4-fe57fb384347',
  },
  {
    name: 'Отменено',
    id: '2a98c22e-cbee-49f9-90ef-f56429d96333',
  },
]

export default function Header({
  startAdornment = [],
  endAdornment = [],
  title,
  filter = false,
  space = false,
  inquiries = false,
  setSelectStatus = () => {},
  setGetFilter,
  getFilter,
  company,
  researcher,
}) {

  const dispatch = useDispatch()
  const history = useHistory()
  const [openFilter, setOpenFilter] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const userLogout = () => {
    // const token = localStorage.getItem('tokin')
    logout
      .logout()
      .then((res) => {
        if (res.code === 200) {
          localStorage.removeItem('token')
          dispatch({
            type: authActionTypes.AUTH_LOGIN,
            payload: {
              userLogin: '',
              phoneNumber: '',
              accessToken: '',
              refreshToken: '',
              permissions: '',
            },
          })
          history.push('/auth/login')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  // useEffect(() => {
  //   if (openFilter === false) {
  //     setSelectStatus('')
  //     if (inquiries) {
  //       setGetFilter({
  //         company: '',
  //         researcher: '',
  //       })
  //     }
  //   }
  // }, [openFilter])
  return (
    <div className='w-full bg-white flex justify-between relative'>
      <div className='p-4 flex items-center divide-x'>
        {title && title.length ? (
          <div
            className='text-lg leading-6 font-semibold pr-4'
            style={{ color: 'rgba(91, 104, 113, 1)' }}
          >
            {title}
          </div>
        ) : (
          <></>
        )}
        {startAdornment}
      </div>
      <div
        style={{ padding: `${space && '8px 0'}` }}
        className='flex items-center '
      >
        {filter && (
          <div className={cls.filter}>
            <a
              className={cls.filterRow}
              onClick={() => {
                if (openFilter) {
                  setOpenFilter(false)
                } else {
                  setOpenFilter(true)
                }
              }}
            >
              <FilterIcon />
            </a>
            {openFilter &&
              (inquiries ? (
                <ClickAwayListener onClickAway={() => setOpenFilter(false)}>
                  <div className={cls.filterBody}>
                    <div className={cls.selects}>
                      <div className={cls.text}>
                        <Typography className={cls.content}>
                          Компании:
                        </Typography>
                      </div>
                      <Select
                        style={{ width: '100%' }}
                        options={company}
                        onChange={(e) => {
                          setGetFilter((old) => ({
                            ...old,
                            company: e.id,
                          }))
                        }}
                        value={
                          getFilter.company.length > 1
                            ? company.find(
                                (el) => el.id === getFilter.company
                              )
                            : ''
                        }
                      />

                      <a
                        onClick={() => {
                          setGetFilter((old) => ({
                            ...old,
                            company: '',
                          }))
                        }}
                      >
                        <CloseIcons />
                      </a>
                    </div>
                    {/*  */}
                    <div className={cls.selects} style={{ marginTop: '10px' }}>
                      <div className={cls.text}>
                        <Typography className={cls.content}>
                          Иследователь:
                        </Typography>
                      </div>
                      <Select
                        style={{ width: '100%' }}
                        options={researcher}
                        onChange={(e) => {
                          setGetFilter((old) => ({
                            ...old,
                            researcher: e.id,
                          }))
                        }}
                        value={
                          getFilter.researcher.length > 1
                            ? researcher.find(
                                (el) => el.id === getFilter.researcher
                              )
                            : {}
                        }
                      />

                      <a
                        onClick={() => {
                          setGetFilter((old) => ({
                            ...old,
                            researcher: '',
                          }))
                        }}
                      >
                        <CloseIcons />
                      </a>
                    </div>
                  </div>
                </ClickAwayListener>
              ) : (
                <div className={cls.filterBody}>
                  <div className={cls.selects}>
                    <div className={cls.text}>
                      <Typography className={cls.content}>Статус:</Typography>
                    </div>
                    <select
                      onChange={(e) => {
                        setSelectStatus(e.target.value)
                      }}
                      name='cars'
                      id='cars'
                    >
                      <option value=''>Выберите</option>
                      {Status.map((el, ind) => (
                        <option key={ind} value={el.name}>
                          {el.name}
                        </option>
                      ))}
                    </select>

                    <a
                      onClick={() => {
                        setOpenFilter(false)
                      }}
                    >
                      <CloseIcons />
                    </a>
                  </div>
                </div>
              ))}
          </div>
        )}
        {endAdornment && endAdornment.length ? (
          endAdornment.map((Button) => <div className='px-1'>{Button}</div>)
        ) : (
          <></>
        )}
        {/* <a
          onClick={() => {
            if (!open) {
              setOpen(true)
            } else {
              setOpen(false)
            }
          }}
          style={{
            border: '1px solid #000',
            borderRadius: '8px',
            minHeight: '39px',
            minWidth: '50px',
            borderColor: 'rgb(64, 148, 247)',
            marginRight: '10px',
          }}
          className={'flex justify-center p-1 cursor-pointer relative'}
        >
          <img src={ProfileIcon} alt='Profile' />
          {open && (
            <ul
              style={{
                bottom: '-44px',
                left: '-22px',
                borderRadius: '4px',
                background: '#fff',
                border: '1px solid transparent',
              }}
              className={'absolute'}
            >
              <a onClick={userLogout}>
                <li
                  style={{
                    padding: '8px 12px',
                    fontSize: '16px',
                    cursor: 'pointer',
                  }}
                >
                  Выход
                </li>
              </a>
            </ul>
          )}
        </a> */}
      </div>
    </div>
  )
}
