import request from "../utils/axios";
const base_Url = {
  agreement: "/content?type=message",
  getAllAgreements:'/content?type=content'
};

const requests = {
  getAllAgreements: (params) =>
    request({
      url: `${base_Url.agreement}`,
      method: "get",
      params: params,
    }),
    getAllAgreementsList: (params) =>
    request({
      url: `${base_Url.getAllAgreements}`,
      method: "get",
      params: params,
    }),
};
export default requests;
