import React, {useState, useEffect} from "react";
// import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import research from "../../models/researchers";
import ExcelFile from "../../models/excel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import {StyledTableCell} from "../../components/DataTable";
import { DownloadOutlined } from '@ant-design/icons'
// import moment from "moment";
// import Tag from "../../components/Tag";
import {toast} from "react-toastify";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {StyledPropMenu} from "../../components/custom/StyledPropMenu";
import TableLoader from "../../components/TableLoading/Index";
import Pagination from "../../components/Pagination";
// import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import {useHistory} from "react-router-dom";
import request from "../../models/researchers";

const ResearchersItemCompanyList = ({t}) => {
  const param = useParams();
  const [searchTable, setSearchTable] = useState();

  // console.log("fsfsfdfdsfsfsdfsdfsdfdsf", id)
  const history = useHistory();
  const [researchersList, setresearchers] = useState([]);
  const [dropList, setDropList] = useState({target: null, id: ""});

  const [loading, setloading] = useState(false);
  const [items, setItems] = useState({});
  const pageCount = 10;
  const [pageLimit, setPageLimit] = useState(pageCount);
  let count = 1;
  const [tableHeardName, settableHeardName] = useState([
    t("number.icon"),
    t("full.name"),
    t("position"),
    t("Phone"),
    t("email.name"),
  ]);
 
  const cutomRowClick = (id) => {
    history.push(`${"/home/company/researcher/update"}/${id}/${param.id}`);
  };
  const getExcelFile = () => {
    const params = {
      search: searchTable,
      company_id: param.id
    };
    ExcelFile.researcherGetAllExcelFile(params)
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const reseachersGetAll = () => {
    const params = {
      company_id: param.id,
      search: searchTable,
    }
    research.researcherGetAllList(params).then((res) => {
      setresearchers(res.data.researchers);
    });
  };
  useEffect(() => {
    reseachersGetAll();
  }, []);
  useEffect(() => {
    reseachersGetAll()
  }, [pageLimit,searchTable]);
  return (
    <div>
      <div className="fslex p-5 px-5 pb-4" style={{borderRadius: "20px"}}>
        <TableContainer
          elevation={0}
          component={Paper}
          className=" shadow-none rounded-2xl p-5"
          style={{borderRadius: "10px"}}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginBottom: "16px",
            }}
          >
            <input
              name="search"
              value={searchTable}
              onChange={(e) => {
                setSearchTable(e.target.value);
              }}
              style={{
                border: "1px solid #E5E9EB",
                borderRadius: "8px",
                padding: "8px 12px",
                maxWidth: "200px",
              }}
              placeholder="Поиск"
            />
            <a
              onClick={() => {
                getExcelFile();
              }}
              id="download_file"
            >
              <DownloadOutlined />
            </a>
          </div>
          <Table elevation={0} aria-label="customized table">
            <TableHead style={{borderRadius: "10px"}}>
              <TableRow className="text-black font-body">
                {tableHeardName?.map((element, index) => (
                  <StyledTableCell key={index}>{element}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {researchersList?.map((element, index) => (
                <TableRow
                  className="text-black font-body hover:bg-gray-100 cursor-pointer"
                  onClick={() => cutomRowClick(element.id)}
                  key={index}
                >
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell>{element.name}</StyledTableCell>
                  <StyledTableCell>{element.profession_title}</StyledTableCell>
                  {/*<StyledTableCell>{element.owner_name}</StyledTableCell>*/}
                  <StyledTableCell>{element.phone}</StyledTableCell>
                  <StyledTableCell>{element.email}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TableLoader isVisible={loading} />
        <Pagination
          className="mt-5 text-black-700"
          count={items.count}
          pageCount={pageCount}
          onChange={(val) => {
            setPageLimit(val);
          }}
        />
      </div>
    </div>
  );
};

export default ResearchersItemCompanyList;
