import React, { useState, useEffect } from 'react'
import Header from '../../components/Header/Header'
import { useTranslation } from 'react-i18next'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import AddIcon from '@material-ui/icons/Add'
import {
  StyledTableCell,
  StyledTableRow,
} from '../../components/DataTable/index'
import Filter from '../../components/Filter'
import { StyledTabs, StyledTab } from '../../components/StyledTabs/index'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { StyledPropMenu } from '../../components/custom/StyledPropMenu'
import Pagination from '../../components/Pagination'
import Button from '../../components/Buttons/index'
import Reports from '../../models/report'
import { Typography } from '@material-ui/core'
import { TabPanel } from '../../components/Tab/TabBody'
import SecondReportTable from './SecondReportTable'

const ReportList = () => {
  const { t } = useTranslation()
  const [tabvalue, settabvalue] = useState(0)
  const [reportList, setReportList] = useState([])
  const [dropList, setDropList] = useState({ target: null, id: '' })
  const [tableheadername, setTableheadername] = useState([
    t('number.icon'),
    t('company.name'),
    t('Количество исследователей'),
    t('Количество запросов'),
    t('Количество респондент'),
    t('Стоимость'),
  ])

  const routes = [
    {
      title: t('company.name'),
      link: true,
      route: '/home/report',
    },
  ]
  const [items, setItems] = useState({})
  const pageCount = 10
  const [pageLimit, setPageLimit] = useState(pageCount)

  const handleChangeTab = (event, newvalue) => {
    settabvalue(newvalue)
  }

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    }
  }

  const tabLabel = (text, isActive = false) => {
    return <span className='px-1'>{text}</span>
  }

  const reportGetAllList = () => {
    const params = {
      page: pageLimit / pageCount,
      limit: pageCount,
    }
    Reports.reportGetAllList(params)
      .then((res) => {
        console.log(res)
        setItems(res.data)
        setReportList(res.data.reports)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    reportGetAllList()
  }, [])
  useEffect(() => {
    reportGetAllList()
  }, [pageLimit])

  return (
    <div>
      <Header title={t('Отчеты')} />
      <Filter>
        <StyledTabs
          value={tabvalue}
          onChange={handleChangeTab}
          centered={false}
          aria-label='full width tabs example'
          TabIndicatorProps={{ children: <span className='w-2' /> }}
        >
          <StyledTab
            label={tabLabel(t('Отчет компании'))}
            {...a11yProps(0)}
            style={{ width: '150px', fontFamily: 'san-serif' }}
          />
          <StyledTab
            label={tabLabel(t('Отчет респондента'))}
            {...a11yProps(0)}
            style={{ width: '200px' }}
          />
        </StyledTabs>
      </Filter>
      <div>
        <TabPanel value={tabvalue} index={0}>
          <div className='fslex p-5 px-5 pb-4' style={{ borderRadius: '20px' }}>
            <TableContainer
              elevation={0}
              component={Paper}
              className=' shadow-none rounded-2xl p-5'
            >
              <Table
                elevation={0}
                aria-label='customized table'
                style={{ borderRadius: '20px' }}
              >
                <TableHead>
                  <TableRow className='text-black font-body'>
                    {tableheadername.map((element, index) => (
                      <StyledTableCell
                        style={{ textAlign: 'center' }}
                        key={index}
                      >
                        {element}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportList?.map((element, index) => (
                    <TableRow
                      className='text-black font-body hover:bg-gray-100 cursor-pointer'
                      key={index}
                    >
                      <StyledTableCell>
                        {parseInt(
                          pageCount * (pageLimit / pageCount - 1) + index + 1
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography style={{ textAlign: 'center' }}>
                          {element?.company_name}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography style={{ textAlign: 'center' }}>
                          {element?.number_of_researchers}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography style={{ textAlign: 'center' }}>
                          {element?.number_of_inquiries}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography style={{ textAlign: 'center' }}>
                          {element?.number_of_respondents}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography style={{ textAlign: 'center' }}>
                          {element?.cost}
                        </Typography>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/*<TableLoader isVisible={loading}/>*/}

            <Pagination
              className='mt-5 text-black-700'
              size={'large'}
              count={items.count}
              pageCount={pageCount}
              onChange={(val) => {
                console.log(val)
                setPageLimit(val)
              }}
            />
          </div>{' '}
        </TabPanel>
        <TabPanel value={tabvalue} index={1}>
          <SecondReportTable />
        </TabPanel>
      </div>
    </div>
  )
}

export default ReportList
