import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import Header from "../../components/Header/Header";
import Button from "../../components/Buttons";
import Attribut from "../../models/attributs";
import {useDispatch} from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import {StyledTableCell} from "../../components/DataTable/index";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {StyledPropMenu} from "../../components/custom/StyledPropMenu";
import ClearIcon from "@material-ui/icons/Clear";
import {toast} from "react-toastify";
// import Header from "../../components/Header/Header"
const filterIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.541571 1.67498C2.4749 4.15831 5.33324 7.83331 5.33324 7.83331L5.33324 12C5.33324 12.9166 6.08324 13.6666 6.9999 13.6666C7.91657 13.6666 8.66657 12.9166 8.66657 12V7.83331C8.66657 7.83331 11.5249 4.15831 13.4582 1.67498C13.8832 1.12498 13.4916 0.333313 12.7916 0.333313L1.1999 0.333313C0.508238 0.333313 0.116571 1.12498 0.541571 1.67498Z"
      fill="#303940"
    />
  </svg>
);
// const map = new Map(Object.enries({
//     "string": "Строка",
//    "number": "Число",
//     "data_time": "Время данных",
//     "range": "Диапазон"
// }))
const Index = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const [params, setParams] = useState({
    name: "",
    input_type: "",
    data_type: "",
  });
  const dispatch = useDispatch();
  const [dropList, setDropList] = useState({target: null, id: ""});
  const [tableheadername, settableheadername] = useState([
    t("number.icon"),
    t("name"),
    t("data"),
    t("input"),
    "",
  ]);
  const [attributslist, setattibutslist] = useState([]);
  console.log("attributslist", attributslist);
  const [updateType, setupdateType] = useState(true);
  const handleMoreClick = (id, type) => {
    if (type === "edit") {
      history.push(`/home/attributs/update/${dropList.id}`);
    }
    if (type === "delete") {
      console.log("delete", dropList.id);
      Attribut.deleteAttibut(dropList.id).then((res) => {
        console.log(res);
        getAllListAttribut(params);
        setDropList((old) => {
          return {...old, target: null};
        });
        toast.success(t("delete.attribut"), {
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        });
      });
    }
    console.log(id, type);
  };
  const listRender = [
    // {
    //     title: t("edit"),
    //     icon: <EditIcon className="bg-blue-100 rounded text-blue-600 p-0.5" />,
    //     type: "edit",
    // },
    {
      title: t("delete"),
      icon: <ClearIcon className="bg-red-100 rounded text-red-600 p-0.5" />,
      type: "delete",
    },
  ];
  const getAllListAttribut = (params) => {
    Attribut.getAttribut(params).then((res) => {
      setattibutslist(res.data.attributes);
    });
  };
  const customRowClik = (id) => {
    history.push(`${"/home/settings/attribut/update"}/${id}`);
  };
  const hanldeCreate = (e) => {
    console.log(e);
    history.push("/home/settings/attribut/create");
  };
  useEffect(() => {
    getAllListAttribut(params);
  }, []);
  return (
    <div>
      <Header
        title={"Атрибуты"}
        endAdornment={[
          <Button
            size="large"
            icon={<AddIcon style={{fontSize: "20px"}} />}
            onClick={hanldeCreate}
            className="text-indigo-50 w-auto p-1.5 rounded-md"
          >
            Добавить
          </Button>,
        ]}
      />
      <div className="fslex p-5 px-5 pb-4 rounded-2xl">
        <TableContainer
          elevation={0}
          component={Paper}
          className=" shadow-none rounded-2xl p-5"
        >
          <Table
            elevation={0}
            aria-label="customized table"
            className="rounded-2xl"
            style={{borderRadius: "20px"}}
          >
            <TableHead>
              <TableRow className="text-black font-body">
                {tableheadername?.map((element, index) => (
                  <StyledTableCell key={index}>{element}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {attributslist?.map((element, index) => (
                <TableRow
                  onClick={() => customRowClik(element.id)}
                  className="text-black font-body hover:bg-gray-100 cursor-pointer"
                  key={index}
                >
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell>{element.name}</StyledTableCell>
                  <StyledTableCell>{t(element.data_type)}</StyledTableCell>
                  <StyledTableCell>{t(element.input_type)}</StyledTableCell>
                  <StyledTableCell
                    className="w-2  border-gray-300"
                    style={{borderLeft: "1px solid gainsboro"}}
                  >
                    <MoreHorizIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setDropList({
                          target: e.currentTarget,
                          id: element.id,
                        });
                      }}
                      className="cursor-pointer text-blue-500"
                      style={{
                        border: "1px solid gainsboro",
                        borderRadius: "5px",
                      }}
                    />

                    <StyledPropMenu
                      anchorEl={dropList.target}
                      open={Boolean(dropList.target)}
                      onClose={(e) => {
                        e.stopPropagation();
                        setDropList((old) => {
                          return {...old, target: null};
                        });
                      }}
                    >
                      <div className="flex flex-col divide-y font-body text-sm">
                        {listRender.map((el, index) => (
                          <div
                            key={index}
                            className="flex w-full cursor-pointer py-2 mx-2"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleMoreClick(element.id, el.type);
                            }}
                          >
                            <div className="rounded">{el.icon}</div>
                            <div className="my-auto mx-2">{el.title}</div>
                          </div>
                        ))}
                      </div>
                    </StyledPropMenu>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Index;
