import React, {Component} from "react";
import {EditorState, convertToRaw} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import {Button} from "@material-ui/core";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import cls from "./index.module.scss";
import config from "../../../config/defaultSettings";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {toast} from "react-toastify";
import axios from "../../../utils/axios";
import {Typography} from "antd";

class SingleAgreement extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    editorState: EditorState.createEmpty(),
    email: "",
    text: "",
  };
  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
  };

  render() {
    const {editorState, email, text} = this.state;

    const contentRegistration = () => {
      axios
        .post(`/custom/message`, {
          subject: text,
          email: email,
          body: `<html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta http-equiv="X-UA-Compatible" content="IE=edge">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>${text}</title>
          </head>
          <body>
              ${draftToHtml(convertToRaw(editorState.getCurrentContent()))
                .replaceAll("#time", "{{.Time}}")
                .replaceAll("#title", "{{.Title}}")
                .replaceAll("#code", "{{.Code}}")
                .replaceAll("#link", "{{.Link}}")
                .replaceAll("#cost", "{{.Cost}}")
                .replaceAll("#link", "{{.Link}}")
                .replaceAll("#company_name", "{{.CompanyName}}")
                .replaceAll("#researcher_name", "{{.ResearcherName}}")
                .replaceAll("#period", "{{.Period}}")}


          </body>
          </html>`,
        })
        .then((res) => {
          console.log(res);
          toast.success("Успешно загружено", {
            className: "bg-green-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
        })
        .catch((err) => {
          toast.error("Неправильный адрес электронной почты!", {
            className: "bg-red-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
        });
    };

    function uploadImageCallBack(file) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", `${config.baseURL}upload/file`);
        xhr.setRequestHeader(
          `Authorization`,
          `Bearer ${localStorage.getItem("token")}`
        );
        xhr.setRequestHeader(
          `platform-id`,
          "7d4a4c38-dd84-4902-b744-0488b80a4c06"
        );
        const data = new FormData();
        data.append("file", file);
        xhr.send(data);
        xhr.addEventListener("load", () => {
          const response = JSON.parse(xhr.responseText);
          console.log(response);
          resolve(response);
        });
        xhr.addEventListener("error", () => {
          const error = JSON.parse(xhr.responseText);
          console.log(error);
          reject(error);
        });
      });
    }
    return (
      <div className={cls.root}>
        <div className={cls.row} id="editor">
          <div className={cls.body}>
            <div className={cls.flex}>
              <label>E-mail</label>
              <input
                type="text"
                placeholder="E-mail"
                onChange={(e) => {
                  this.setState({
                    ...this.state,
                    email: e.target.value,
                  });
                }}
              />
            </div>
            <div className={cls.flex}>
              <label>Тема письма</label>
              <input
                type="text"
                placeholder="Тема письма"
                onChange={(e) => {
                  this.setState({
                    ...this.state,
                    text: e.target.value,
                  });
                }}
              />
            </div>
            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={this.onEditorStateChange}
              toolbar={{
                image: {
                  uploadCallback: uploadImageCallBack,
                  previewImage: true,
                  alt: {
                    present: true,
                    mandatory: false,
                  },
                },
              }}
            />
            <div className={cls.btnGroup}>
              <Button onClick={contentRegistration} className={cls.btn}>
                Сохранить
              </Button>
            </div>
          </div>
        </div>
         {/* INFORMATION HOW TO USE */}
         <div className={cls.information}>
          <Typography className={cls.title}>
          Вместо слов с решётками, мы поставим данные от базы данных
            </Typography>
          <div className={cls.structure}>
            <Typography className={cls.name}>#time</Typography>
            <Typography className={cls.text}>— Дата и время</Typography>
          </div>
          <div className={cls.structure}>
            <Typography className={cls.name}>#title</Typography>
            <Typography className={cls.text}>— Названия запроса</Typography>
          </div>
          <div className={cls.structure}>
            <Typography className={cls.name}>#code</Typography>
            <Typography className={cls.text}>— Код подтверждения</Typography>
          </div>
          <div className={cls.structure}>
            <Typography className={cls.name}>#link</Typography>
            <Typography className={cls.text}>
              — Ссылка на обновление пароля
            </Typography>
          </div>
          <div className={cls.structure}>
            <Typography className={cls.name}>#cost</Typography>
            <Typography className={cls.text}>— Баланс</Typography>
          </div>
          <div className={cls.structure}>
            <Typography className={cls.name}>#company_name</Typography>
            <Typography className={cls.text}>— Название компании</Typography>
          </div>
          <div className={cls.structure}>
            <Typography className={cls.name}>#researcher_name</Typography>
            <Typography className={cls.text}>— Имя исследователя</Typography>
          </div>
          <div className={cls.structure}>
            <Typography className={cls.name}>#respondent_name</Typography>
            <Typography className={cls.text}>— Имя респондента</Typography>
          </div>
          <div className={cls.structure}>
            <Typography className={cls.name}>#duration</Typography>
            <Typography className={cls.text}>— Продолжительность</Typography>
          </div>
        </div>
      </div>
    );
  }
}
export default SingleAgreement;
