import React, {useState, useEffect} from "react";
import cls from "./index.module.scss";
import {useParams} from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import {StyledTableCell} from "../../components/DataTable";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {StyledPropMenu} from "../../components/custom/StyledPropMenu";
import Pagination from "../../components/Pagination";
import ClearIcon from "@material-ui/icons/Clear";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Typography} from "@material-ui/core";
import {NumberFomat} from "../../functions/numberFomat";
import TopPanel from "./TopPanel";
import Billing from "../../models/billing";
import RemoveCost from "../respondent/RemoveCost";
import {DownloadOutlined} from "@ant-design/icons";
import ExcelFile from "../../models/excel";
import axios from "../../utils/axios";

const RespondentTransaction = ({accountNumber}) => {
  const param = useParams();
  const history = useHistory();
  const {t} = useTranslation();
  const [researchersList, setresearchers] = useState([]);
  const [dropList, setDropList] = useState({target: null, id: ""});
  const [getAfterResponse, setGetAfterResponse] = useState(false);
  const [balance, setBalance] = useState({});
  const listRender = [
    // {
    //     title: t("edit"),
    //     icon: <EditIcon className="bg-blue-100 rounded text-blue-600 p-0.5" />,
    //     type: t("edit"),
    // },
    {
      title: t("delete"),
      icon: <ClearIcon className="bg-red-100 rounded text-red-600 p-0.5" />,
      type: "delete",
    },
  ];

  const [loading, setloading] = useState(false);
  const pageCount = 10;
  const [items, setItems] = useState({});
  const [pageLimit, setPageLimit] = useState(pageCount);
  const [tableHeardName, settableHeardName] = useState([
    t("number.icon"),
    t("Дата"),
    t("Номер транзакции"),
    t("Название транзакции"),
    t("Сумма"),
    t("Статус транзакции"),
  ]);
  const transactionGetAll = () => {
    const params = {
      page: pageLimit / pageCount,
      limit: pageCount,
      account_number: `${accountNumber}`,
    };
    Billing.billingGetAllList(params)
      .then((res) => {
        setresearchers(res.data.transactions);
        setItems(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    transactionGetAll();
  }, [accountNumber]);

  useEffect(() => {
    transactionGetAll();
  }, [pageLimit]);
  useEffect(() => {
    if (getAfterResponse) {
      transactionGetAll();
      setGetAfterResponse(false);
    }
  }, [getAfterResponse]);
  const cutomRowClick = (id) => {
    axios
      .get(`inquiry/${id}`)
      .then((res) => {
        history.push(
          `/home/company/inquiryitem/${res.data.company_id}/${res.data.id}`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getExcelFile = () => {
    ExcelFile.transactionExcelFile(accountNumber)
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className={cls.root}>
      <div className={cls.btnCost}>
        <RemoveCost
          setGetAfterResponse={setGetAfterResponse}
          accountNumber={accountNumber}
          balance={balance}
        />
        <a
          onClick={() => {
            getExcelFile();
          }}
          id="download_file"
        >
          <DownloadOutlined />
        </a>
      </div>

      <TopPanel id={accountNumber} setBalance={setBalance} />
      <div className="fslex p-5" style={{borderRadius: "20px"}}>
        <TableContainer
          id="tableBody"
          elevation={0}
          component={Paper}
          className=" shadow-none rounded-2xl"
          style={{borderRadius: "6px"}}
        >
          <Table elevation={0} aria-label="customized table">
            <TableHead style={{borderRadius: "6px"}}>
              <TableRow className="text-black font-body">
                {tableHeardName?.map((element, index) => (
                  <StyledTableCell
                    style={{
                      textAlign: `${
                        element === "Статус транзакции" && "center"
                      }`,
                    }}
                    key={index}
                  >
                    {element}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {researchersList?.map((element, index) => (
                <TableRow
                  className="text-black font-body hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    if (element.object_info.inquiry_id) {
                      cutomRowClick(element.object_info.inquiry_id);
                    }
                  }}
                  key={index}
                >
                  <StyledTableCell>
                    <Typography className="tableRow">
                      {parseInt(
                        pageCount * (pageLimit / pageCount - 1) + index + 1
                      )}{" "}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography className="tableRow">
                      {moment(element?.created_at).format("DD-MM-YYYY")}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography className="tableRow">
                      {element?.transaction_number}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography className="tableRow">
                      {`${
                        element?.object_info?.inquiry_title
                          ? element?.object_info?.inquiry_title
                          : "-"
                      }`}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      className="tableRow"
                      style={{
                        color: `${
                          element?.debit < element?.credit
                            ? "red"
                            : "rgba(34, 195, 72, 1)"
                        }`,
                      }}
                    >
                      {NumberFomat(parseInt(element?.debit - element?.credit))}{" "}
                      ₽
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{display: "flex", justifyContent: "center"}}
                  >
                    <Typography
                      className="tableStatus"
                      style={{
                        background: `${
                          element.object_info.status == "refilled"
                            ? "linear-gradient( 0deg, rgba(56, 217, 185, 0.2), rgba(56, 217, 185, 0.2)), rgb(255, 255, 255"
                            : element?.object_info?.status == "received"
                            ? "rgba(248, 221, 78, 0.3)"
                            : element?.object_info?.status == "purchased"
                            ? "linear-gradient(0deg, rgba(248, 221, 78, 0.3), rgba(248, 221, 78, 0.3)), #FFFFFF"
                            : element?.object_info?.status == "transfered"
                            ? "rgba(64, 148, 247, 0.15)"
                            : "linear-gradient(0deg, rgba(64, 148, 247, 0.15), rgba(64, 148, 247, 0.15)), #FFFFFF"
                        }`,
                        color: `${
                          element.object_info.status == "refilled"
                            ? "rgb(26, 193, 157)"
                            : element?.object_info?.status == "received"
                            ? "#D29404"
                            : element?.object_info?.status == "purchased"
                            ? "#D29404"
                            : element?.object_info?.status == "transfered"
                            ? "#4094F7"
                            : "#4094F7"
                        }`,
                      }}
                    >
                      {t(
                        `${
                          element?.object_info?.status == undefined
                            ? "refilled"
                            : element?.object_info?.status
                        }`
                      )}
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TableLoader isVisible={loading} /> */}
        <Pagination
          className="mt-5 text-black-700"
          count={items.count}
          pageCount={pageCount}
          onChange={(val) => {
            setPageLimit(val);
          }}
        />
      </div>
    </div>
  );
};

export default RespondentTransaction;
