export default function App(props) {
  return (
    <div
      className='flex bg-background w-full'
      style={{ minHeight: '100vh', height: 'inherit' }}
    >
      <div className='flex-none'>{props.sidebar}</div>
      <div
        className='flex-grow'
        style={{
          maxWidth: '100%',
          overflowX: 'hidden',
          height: '100vh',
          overflowY: 'scroll',
        }}
      >
        <div>{props.children}</div>
        <div>
          {/*<Footer*/}
          {/*/>*/}
        </div>
      </div>
    </div>
  )
}
