import * as Yup from 'yup'

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

export default function validateForm(name, t) {
  const YupValidation = {
    default: Yup.string()
      .trim(t('spaces.error'))
      .strict(true)
      .required(t('required.field.error')),
    mixed: Yup.mixed().strict(true).required(t('required.field.error')),
    fullname: Yup.string()
      .trim(t('spaces.error'))
      .strict(true)
      .required(t('required.field.error')),
    name: Yup.string()
      // .matches(/^[aA-zZ\s]+$/, t('only.latin.letters'))
      .required(t('required.field.error')),
    position: Yup.string()
      // .matches(/^[aA-zZ\s]+$/, t('only.latin.letters'))
      .required(t('required.field.error')),
    last_name: Yup.string()
      .trim(t('spaces.error'))
      .strict(true)
      .required(t('required.field.error')),
    middle_name: Yup.string()
      .trim(t('spaces.error'))
      .strict(true)
      .required(t('required.field.error')),
    login: Yup.string()
      .required(t('required.field.error'))
      .min(6, t('login.too.short'))
      .matches(/[a-zA-Z]/, t('only.latin.letters')),
    password: Yup.string()
      .required(t('required.field.error'))
      .min(6, t('password.too.short')),
    // .matches(/[a-zA-Z]/, t('only.latin.letters')),
    address: Yup.string()
      .trim(t('spaces.error'))
      .strict(true)
      .required(t('required.field.error')),
    email: Yup.string()
      .email(t('invalid.email'))
      .required(t('required.field.error')),
    phone_number: Yup.string()
      .min(7, t('min.number'))
      // .max(20, t('invalid.number'))
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Неправильный номер телефона'
      )
      .required(t('required.field.error')),
    phone: Yup.string()
      .min(7, t('min_number'))
      .max(20, t('max_number'))
      .required(t('required.field.error')),
    passport_number: Yup.string()
      .trim(t('spaces.error'))
      .strict(true)
      .min(9, t('invalid.passport.number'))
      .max(9, t('invalid.passport.number'))
      .required(t('required.field.error')),
    tech_passport_number: Yup.string()
      .trim(t('spaces.error'))
      .strict(true)
      .required(t('required.field.error')),
    driving_license_number: Yup.string()
      .trim(t('spaces.error'))
      .strict(true)
      .required(t('required.field.error')),
    inn: Yup.string()
      .trim(t('spaces.error'))
      .strict(true)
      .min(7, t('invalid.inn'))
      // .matches(/^[a-z]+$/, t('only_number'))
      .matches(/^\d+$/, t('invalid.inn'))
      .required(t('required.field.error')),
    price: Yup.number()
      .required(t('required.field.error'))
      .test(
        'Is positive?',
        t('invalid.price'),
        (value) => value > 0 || value === 0
      ),
  }

  return YupValidation[name]
}
