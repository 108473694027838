export const AdminIcon = () => (
  <svg
    width='18'
    height='20'
    viewBox='0 0 18 20'
    fill='#6E8BB7'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.00016 19.1666C13.631 18.1374 17.3335 13.7683 17.3335 9.16658V4.16658L9.00016 0.833252L0.66683 4.16658V9.16658C0.66683 13.7699 4.36933 18.1374 9.00016 19.1666ZM15.6668 4.99992V9.16658C15.6192 11.0934 14.9415 12.9514 13.7375 14.4565C12.5335 15.9615 10.8695 17.0305 9.00016 17.4999C7.13084 17.0305 5.46682 15.9615 4.26281 14.4565C3.0588 12.9514 2.38112 11.0934 2.3335 9.16658V4.99992L9.00016 2.49992L15.6668 4.99992Z'
      fill='inherit'
    />
    <path
      d='M9.00016 9.16667C7.84957 9.16667 6.91683 8.23393 6.91683 7.08333C6.91683 5.93274 7.84957 5 9.00016 5C10.1508 5 11.0835 5.93274 11.0835 7.08333C11.0835 8.23393 10.1508 9.16667 9.00016 9.16667Z'
      fill='inherit'
    />
    <path
      d='M13.167 12.5C12.7563 13.2485 12.154 13.8744 11.4219 14.3137C10.6898 14.753 9.85406 14.9898 9.00033 15C8.14659 14.9898 7.3109 14.753 6.57877 14.3137C5.84665 13.8744 5.2444 13.2485 4.83366 12.5C4.85449 10.92 7.61866 10 9.00033 10C10.3895 10 13.1462 10.92 13.167 12.5Z'
      fill='inherit'
    />
  </svg>
)
