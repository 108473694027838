import {CircularProgress} from "@material-ui/core";
import "./index.scss";

export default function Button(props) {
  const {
    className,
    children,
    icon = "",
    color = "primary-600",
    shape = "filled",
    size = "medium",
    loading = false,
    type = "button",
    textStyle,
    stopPropagination,
    onClick = () => {},
    disabled,
    ...rest
  } = props;
  const getSize = (key) => {
    switch (key) {
      case "small":
        return {
          size: "px-3 py-1",
          fontSize: "text-xs",
        };
      case "medium":
        return {
          size: "px-3 py-1 min:w-7 min:h-7",
          fontSize: "text-sm",
        };
      case "large":
        return {
          size: "px-6 py-2",
          fontSize: "text-base",
        };

      default:
        break;
    }
  };

  const getShape = (key) => {
    // console.log(key)
    switch (key) {
      case "filled":
        return {
          color: color + ` iconColor-filled hover:opacity-80 bg-${color}`,
        };
      case "outlined":
        return {
          color:
            color +
            ` bg-white iconColor-outlined text-${color} border border-${color} hover:bg-gray-50`,
        };
      case "text":
        return {color: " bg-transparent iconColor-text " + color};

      default:
        return {color: "iconColor-filled"};
    }
  };
  return (
    <button
      type={type}
      style={{
        background: `${
          children === "Назад"
            ? "#fff"
            : children === "Добавить"
            ? "#0067F4"
            : "rgba(64, 148, 247, 1)"
        }`,
      }}
      className={`
                      rounded-md
                      focus:outline-none
                      transition
                      ${children ? "" : "w-9 h-9"}
                      focus:ring focus:border-blue-300 
                      ${getSize(size).size}
                      ${props.disabled ? "opacity-20 cursor-not-allowed" : ""}
                      ${getShape(shape).color}
                      text-white
                      ${className}
                    `}
      onClick={(e) => {
        if (stopPropagination) {
          e.stopPropagation();
        }
        if (!disabled) onClick(e);
      }}
      disabled={disabled && !stopPropagination}
      {...rest}
    >
      <div
        className={`flex justify-around items-center ${
          children ? "space-x-2" : ""
        } font-semibold`}
      >
        {loading && <CircularProgress size={16} color="#fff" />}
        {icon && <div>{icon}</div>}
        <div className={getSize(size).fontSize} style={textStyle}>
          {children}
        </div>
      </div>
    </button>
  );
}
