import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import cls from './INN.module.scss'
import { BackArrow }  from "../../svg/BackArrow"
import { Box, Button, Typography } from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function WaitResponse({
  setCheckINN,
  setWaitResponse,
  waitResponse,
}) {
  const handleClose = () => {
    setCheckINN(false)
  }

  return (
    <div className={cls.root}>
      <form>
        <Dialog
          id='client_info'
          open={waitResponse}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <div className={`${cls.row} ${cls.pt}`}>
            <a
              onClick={() => {
                setWaitResponse(false)
                setCheckINN(true)
              }}
            >
              <div className={cls.title}>
                <BackArrow />{' '}
                <Typography className={cls.text}>Назад</Typography>
              </div>
            </a>
            <Typography className={cls.header}>
              Подождите, мы проверяем ваш ИНН
            </Typography>
          </div>
        </Dialog>
      </form>
    </div>
  )
}
