import React, {useState, useEffect} from "react";
import {StyledTableCell} from "../../components/DataTable";
import Admins from "../../models/permissions";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {StyledPropMenu} from "../../components/custom/StyledPropMenu";
import EditIcon from "@material-ui/icons/Edit";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";

export default function ResearcherTablePermission({t, history}) {
  const [dropList, setDropList] = useState({target: null, id: ""});
  const [tableheadername, settableheadername] = useState([
    t("number.icon"),
    t("names"),
    "",
  ]);
  const listRender = [
    {
      title: t("edit"),
      icon: <EditIcon className="bg-blue-100 rounded text-blue-600 p-0.5" />,
      type: "edit",
    },
    // {
    //   title: t('delete'),
    //   icon: <ClearIcon className='bg-error-100 rounded text-error-600 p-0.5' />,
    //   type: 'delete',
    // },
  ];
  const [getAllRoll, setGetAllRoll] = useState([]);

  const getRoll = () => {
    const param = {
      client_type_id: "5a3818a9-90f0-44e9-a053-3be0ba1e2c03",
    };
    Admins.getAllRolls(param)
      .then((res) => {
        setGetAllRoll(res.data.roles);
      })
      .catch((err) => console.log(err));
  };

  const handleMoreClick = (element, type) => {
    if (type === "edit") {
      setDropList((old) => {
        return {...old, target: null};
      });
      history.push({
        pathname: `/home/researcher/role/update/${dropList.id.id}`,
      });
    }
  };

  useEffect(() => {
    getRoll();
  }, []);
  return (
    <div className="fslex p-5 px-5 pb-4 rounded-2xl">
      <TableContainer
        elevation={0}
        component={Paper}
        className=" shadow-none rounded-2xl p-5"
      >
        <Table
          elevation={0}
          aria-label="customized table"
          className="rounded-2xl"
          style={{borderRadius: "20px"}}
        >
          <TableHead>
            <TableRow className="text-black font-body">
              {tableheadername?.map((element, index) => (
                <StyledTableCell key={index}>{element}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getAllRoll?.map((element, index) => (
              <TableRow
                className="text-black font-body hover:bg-gray-100 cursor-pointer"
                key={index}
              >
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{element.name}</StyledTableCell>
                <StyledTableCell
                  className="w-2  border-gray-300"
                  style={{borderLeft: "1px solid gainsboro"}}
                >
                  <MoreHorizIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropList({
                        target: e.currentTarget,
                        id: element,
                      });
                    }}
                    className="cursor-pointer text-blue-500"
                    style={{
                      border: "1px solid gainsboro",
                      borderRadius: "5px",
                    }}
                  />

                  <StyledPropMenu
                    anchorEl={dropList.target}
                    open={Boolean(dropList.target)}
                    onClose={(e) => {
                      e.stopPropagation();
                      setDropList((old) => {
                        return {...old, target: null};
                      });
                    }}
                  >
                    <div className="flex flex-col divide-y font-body text-sm">
                      {listRender.map((el, index) => (
                        <div
                          key={index}
                          className="flex w-full cursor-pointer py-2 mx-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleMoreClick(element, el.type);
                          }}
                        >
                          <div className="rounded">{el.icon}</div>
                          <div className="my-auto mx-2">{el.title}</div>
                        </div>
                      ))}
                    </div>
                  </StyledPropMenu>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
