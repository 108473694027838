import React, {useState, useEffect} from "react";
import TeamsList from "../teams/teamsList/teamsList";
import Header from "../../components/Header/Header";
// import Table from "../../components/custom/Table/Table";
import company from "../../models/conpany";
import ExcelFile from "../../models/excel";
import {useTranslation} from "react-i18next";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import {StyledTableCell} from "../../components/DataTable/index";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {StyledPropMenu} from "../../components/custom/StyledPropMenu";
import ClearIcon from "@material-ui/icons/Clear";
import {useHistory, useParams} from "react-router-dom";
import Pagination from "../../components/Pagination";
import Button from "../../components/Buttons/index";
import {toast} from "react-toastify";
import {DownloadOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {pageActionsTypes} from "../../redux/actions/currentPage/pageActionTypes";
import moment from "moment";
import RangePicker from "../../components/DatePicker/RangePicker";

const Index = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [companyList, setcompanyList] = useState([]);
  const [searchTable, setSearchTable] = useState();
  const dispatch = useDispatch();
  const [dropList, setDropList] = useState({target: null, id: ""});
  const [tableheadername, setTableheadername] = useState([
    t("number.icon"),
    t("company.name"),
    t("Контактное лицо"),
    t("inn"),
    t("E-mail"),
    t("Текущий баланс"),
    t("Номер телефона"),
  ]);
  const [param, setParams] = useState({
    name: "",
    email: "",
    phone: "",
    inn: "",
  });
  const listRender = [
    // {
    //     title: t("edit"),
    //     icon: <EditIcon className="bg-blue-100 rounded text-blue-600 p-0.5" />,
    //     type: "edit",
    // },
    {
      title: t("delete"),
      icon: <ClearIcon className="bg-red-100 rounded text-red-600 p-0.5" />,
      type: "delete",
    },
  ];
  const routes = [
    {
      title: t("company.name"),
      link: true,
      route: "/home/company",
    },
  ];
  const currentPage = useSelector((state) => state.counter);
  const [items, setItems] = useState({});
  const [createcompany, setcreatecompany] = useState(true);
  const pageCount = 10;
  const [pageLimit, setPageLimit] = useState(10);
  const query = useParams();
  const [date, setDate] = useState({
    from_date: "",
    to_date: "",
  });

  let count = 10;
  const handleMoreClick = (id, type) => {
    if (type === "edit") {
      console.log("edit");
      setDropList((old) => {
        return {...old, target: null};
      });
    }
    if (type === "delete") {
      console.log("delete");
      setDropList((old) => {
        return {...old, target: null};
      });
    }
    company
      .deleteCompany(dropList.id)
      .then((res) => {
        if (res.code === 200) {
          toast.success(t("Успех компании удалить"), {
            className: "bg-green-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
          companyGetAllList(param);
        }
      })
      .catch((error) => {
        toast.error(t("Ошибка компании удалить"), {
          className: "bg-green-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        });
      });
  };
  const cuttomRowClick = (id) => {
    history.push(`${"/home/company/companyinquiry"}/${id}`);
  };
  const companyGetAllList = (params) => {
    setloading(true);
    const param = {
      from_date: date.from_date,
      to_date: date.to_date,
      search: searchTable,
      name: params.name,
      email: params.email,
      phone: params.phone,
      inn: params.inn,
      page:
        currentPage.currentPage === undefined
          ? pageLimit / pageCount
          : currentPage.currentPage.page,
      limit: count,
    };
    company.companyGetAll(param).then((res) => {
      console.log(res);
      setItems(res.data);
      setcompanyList(res.data.companies);
      setloading(false);
    });
  };
  const getExcelFile = () => {
    const param = {
      from_date: date.from_date,
      to_date: date.to_date,
      search: searchTable,
    };
    ExcelFile.companyGetAllExcelFile(param)
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCompanyCreate = () => {
    history.push({
      pathname: "/home/company/create",
      state: {createcompany},
    });
  };
  useEffect(() => {
    companyGetAllList(param);
  }, []);

  useEffect(() => {
    companyGetAllList(param);
  }, [searchTable, pageLimit, date]);
  return (
    <div>
      <Header
        title={t("companies")}
        endAdornment={[
          <Button
            size="large"
            color="primary-600"
            onClick={handleCompanyCreate}
            icon={<AddIcon style={{fontSize: "20px"}} />}
          >
            {t("add")}
          </Button>,
        ]}
      />
      <div className="fslex p-5 px-5 pb-4" style={{borderRadius: "20px"}}>
        <TableContainer
          elevation={0}
          component={Paper}
          className=" shadow-none rounded-2xl p-5"
        >
          <div
            style={{
              position: "sticky",
              right: "0",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginBottom: "16px",
            }}
          >
            <span>
              <RangePicker
                style={{height: "100%", marginRight: "8px"}}
                hideTimeBlock
                onChange={(val) => {
                  val[0] === null
                    ? setDate((old) => ({
                        from_date: "",
                        to_date: "",
                      }))
                    : setDate((old) => ({
                        from_date: moment(val[0]).format("YYYY-MM-DD"),
                        to_date: moment(val[1]).format("YYYY-MM-DD"),
                      }));
                }}
                className="p-2 w-full"
              />
            </span>
            <input
              name="search"
              value={searchTable}
              onChange={(e) => {
                setSearchTable(e.target.value);
              }}
              style={{
                border: "1px solid #E5E9EB",
                borderRadius: "8px",
                padding: "8px 12px",
                maxWidth: "200px",
              }}
              placeholder="Поиск"
            />
            <a
              onClick={() => {
                getExcelFile();
              }}
              id="download_file"
            >
              <DownloadOutlined />
            </a>
          </div>
          <Table
            elevation={0}
            aria-label="customized table"
            style={{borderRadius: "20px"}}
          >
            <TableHead>
              <TableRow className="text-black font-body">
                {tableheadername.map((element, index) => (
                  <StyledTableCell key={index}>{element}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {companyList?.map((element, index) => (
                <TableRow
                  className="text-black font-body hover:bg-gray-100 cursor-pointer"
                  key={element.id}
                  onClick={() => cuttomRowClick(element.id)}
                >
                  <StyledTableCell>
                    {parseInt(
                      pageCount * (pageLimit / pageCount - 1) + index + 1
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{element.name}</StyledTableCell>
                  <StyledTableCell>{element.owner_name}</StyledTableCell>
                  <StyledTableCell>{element.inn}</StyledTableCell>
                  <StyledTableCell>{element.email}</StyledTableCell>
                  <StyledTableCell>{element.balance}</StyledTableCell>
                  <StyledTableCell>{element.phone}</StyledTableCell>

                  {/* <StyledTableCell
                    className='w-2   border-gray-300'
                    style={{ borderLeft: '1px solid gainsboro' }}
                  >
                    <MoreHorizIcon
                      onClick={(e) => {
                        e.stopPropagation()
                        setDropList({
                          target: e.currentTarget,
                          id: element.id,
                        })
                      }}
                      className='cursor-pointer text-blue-500'
                      style={{
                        border: '1px solid gainsboro',
                        borderRadius: '5px',
                      }}
                    />

                    <StyledPropMenu
                      anchorEl={dropList.target}
                      open={Boolean(dropList.target)}
                      onClose={(e) => {
                        e.stopPropagation()
                        setDropList((old) => {
                          return { ...old, target: null }
                        })
                      }}
                    >
                      <div className='flex  flex-col divide-y font-body text-sm'>
                        {listRender.map((el, index) => (
                          <div
                            key={index}
                            className='flex w-full cursor-pointer py-2 mx-2'
                            onClick={(e) => {
                              e.stopPropagation()
                              handleMoreClick(element.id, el.type)
                            }}
                          >
                            <div className='rounded'>{el.icon}</div>
                            <div className='my-auto mx-2'>{el.title}</div>
                          </div>
                        ))}
                      </div>
                    </StyledPropMenu>
                  </StyledTableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/*<TableLoader isVisible={loading}/>*/}

        <Pagination
          className="mt-5 text-black-700"
          size={"large"}
          count={items.count}
          pageCount={pageCount}
          currentPage={
            currentPage.currentPage === undefined
              ? 1
              : currentPage.currentPage.page
          }
          onChange={(val) => {
            dispatch({
              type: pageActionsTypes.CURRENT_PAGE,
              payload: {
                page: parseInt(val / 10),
                limit: 10,
              },
            });
            setPageLimit(val);
          }}
        />
      </div>
      {/*<Table list={companyList} Tableheadername={Tableheadername}></Table>*/}
    </div>
  );
};

export default Index;
