export const Balance = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#F76659" fill-opacity="0.1" />
      <path
        d="M13.3332 5.33337H7.99984C7.2665 5.33337 6.67317 5.93337 6.67317 6.66671L6.6665 17.3334C6.6665 18.0667 7.25984 18.6667 7.99317 18.6667H15.9998C16.7332 18.6667 17.3332 18.0667 17.3332 17.3334V9.33337L13.3332 5.33337ZM13.9998 12H11.3332V12.6667H13.3332C13.6998 12.6667 13.9998 12.9667 13.9998 13.3334V15.3334C13.9998 15.7 13.6998 16 13.3332 16H12.6665V16.6667H11.3332V16H9.99984V14.6667H12.6665V14H10.6665C10.2998 14 9.99984 13.7 9.99984 13.3334V11.3334C9.99984 10.9667 10.2998 10.6667 10.6665 10.6667H11.3332V10H12.6665V10.6667H13.9998V12ZM12.6665 9.33337V6.33337L15.6665 9.33337H12.6665Z"
        fill="#F76659"
      />
    </svg>
  );
  export const Act = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#F8DD4E" fill-opacity="0.3" />
      <path
        d="M13.4467 6H7.33333C6.6 6 6 6.6 6 7.33333V16.6667C6 17.4 6.6 18 7.33333 18H16.6667C17.4 18 18 17.4 18 16.6667V10.5533C18 10.2 17.86 9.86 17.6067 9.61333L14.3867 6.39333C14.14 6.14 13.8 6 13.4467 6ZM9.33333 14H14.6667C15.0333 14 15.3333 14.3 15.3333 14.6667C15.3333 15.0333 15.0333 15.3333 14.6667 15.3333H9.33333C8.96667 15.3333 8.66667 15.0333 8.66667 14.6667C8.66667 14.3 8.96667 14 9.33333 14ZM9.33333 11.3333H14.6667C15.0333 11.3333 15.3333 11.6333 15.3333 12C15.3333 12.3667 15.0333 12.6667 14.6667 12.6667H9.33333C8.96667 12.6667 8.66667 12.3667 8.66667 12C8.66667 11.6333 8.96667 11.3333 9.33333 11.3333ZM9.33333 8.66667H12.6667C13.0333 8.66667 13.3333 8.96667 13.3333 9.33333C13.3333 9.7 13.0333 10 12.6667 10H9.33333C8.96667 10 8.66667 9.7 8.66667 9.33333C8.66667 8.96667 8.96667 8.66667 9.33333 8.66667Z"
        fill="#D29404"
      />
    </svg>
  );
  