import React from "react";
import TimerIcon from "@material-ui/icons/Timer";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import moment from "moment";
import Button from "../Buttons/index";
import {useTranslation} from "react-i18next";
import {Tooltip, Typography} from "@material-ui/core";
import {useHistory, useParams} from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import {toast} from "react-toastify";
import axios from "../../utils/axios";
import {Online} from "../svg/TimeIcon";

const ClientInfo = ({clientInfoData, NumberFormat, dataSourse, getId}) => {
  const {t} = useTranslation();
  const {id} = useParams();
  const history = useHistory();
  const toloka = clientInfoData[0]?.study_type === "toloka";

  const toHour = (min = 0) => {
    var division = min / 60;
    var postfix = division == 1 ? "час" : "часа";
    var hours = Math.floor(division);
    var minutes = Math.round((division % 1) * 60);

    if (division < 1) {
      return `${minutes} минут`;
    } else if (Number.isInteger(division)) {
      return `${division} ${postfix}`;
    } else {
      return `${hours} ${postfix} ${minutes} минут`;
    }
  };

  // Device
  const checkDevice = (text) => {
    switch (text) {
      case "smartphone":
        return "По телефону";
      case "computer":
        return "По компьютеру";
      case "planshet":
        return "По планшету";
      default:
        return "";
    }
  };
  // OS
  const checkOs = (text) => {
    switch (text) {
      case "windows":
        return "Windows";
      case "linux":
        return "Linux";
      case "ios":
        return "IOS";
      case "android":
        return "Android";
      case "macos":
        return "Macos";
      default:
        return "";
    }
  };

  const checkStatus = (status) => {
    switch (status) {
      case "2e857fbe-9add-4eae-a8c4-fe57fb384347":
        return true;
      case "7b75685f-ee20-4fb9-bc5f-7e40c115e708":
        return true;
      default:
        return false;
    }
  };

  const handleCutomRow = (clientInfoData) => {
    axios
      .get(`/inquiry/respondents?inquiry_id=${clientInfoData}`)
      .then((res) => {
        if (res.data.count >= 1) {
          toast.error("У этого запроса есть Респонденты!", {
            className: "bg-red-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
        } else {
          history.push(
            `/home/company/companyinquirycreate/${id}/edit/${clientInfoData}/0`
          );
        }
      });
  };
  return (
    <div style={{borderRadius: "8px"}}>
      {clientInfoData.map((element, index) => (
        <div
          className="w-4/5 bg-white"
          style={{borderTopRightRadius: "", padding: "24px"}}
        >
          <div
            className="bg-white flex p-4"
            style={{justifyContent: "space-between", fontSize: "24px"}}
          >
            <div>
              <b>
                <span style={{lineHeight: "normal"}}>{element.title}</span>
              </b>
            </div>
            <div>
              <span style={{color: "rgba(26, 193, 157, 1)"}}>
                {NumberFormat(element.total_cost)} ₽
              </span>
            </div>
          </div>
          <div
            className="bg-white flex p-4"
            style={{justifyContent: "space-between"}}
          >
            <div>
              <span
                style={{
                  padding: "10px",
                  borderRadius: "28px",
                  background: "rgba(110, 139, 183, 0.12)",
                }}
              >
                {t(`${element.study_type}`)}
              </span>
              {!toloka && (
                <span
                  style={{
                    marginLeft: "16px",
                    padding: "10px",
                    borderRadius: "28px",
                    background: "rgba(110, 139, 183, 0.12)",
                  }}
                >
                  {t(`${element.interview_type}`)}
                </span>
              )}
            </div>
            {!toloka && (
              <div>
                <span
                  className="p-2 bg-"
                  style={{
                    background: "rgba(0, 103, 244, 0.1)",
                    borderRadius: "8px",
                  }}
                >
                  <TimerIcon className="text-blue-600" />
                  <span className="text-blue-600 ml-2">
                    {toHour(element.duration)}
                  </span>
                </span>
              </div>
            )}
          </div>
          <hr />
          <div className="p-4">
            <span>{element?.description}</span>
          </div>
          <hr />
          <div className="w-full flex justify-between mt-3">
            <h1 style={{marginBottom: "24px"}}>
              <b>Требования к респонденту</b>
            </h1>
            <div className="flex flex-col">
              {element?.additional_respondents !== 0 && (
                <h1>
                  <b>
                    {element?.partisipant_role === "respondent"
                      ? `Количество респондентов низкого уровня сложности: ${element?.number_of_partisipants}`
                      : element?.partisipant_role === "professional"
                      ? `Количество респондентов среднего уровня сложности: ${element?.number_of_partisipants}`
                      : element?.partisipant_role === "consultant"
                      ? `Количество респондентов высокого уровня сложности: ${element?.number_of_partisipants}`
                      : ""}
                  </b>
                </h1>
              )}
              {element?.additional_respondents !== 0 && (
                <h1>
                  {element?.additional_respondents === 1 ? (
                    <b>
                      Осталось найти: {element?.additional_respondents}{" "}
                      респондента
                    </b>
                  ) : (
                    <b>
                      Осталось найти: {element?.additional_respondents}{" "}
                      респондентов
                    </b>
                  )}
                </h1>
              )}
            </div>
          </div>
          <div className="m-3" style={{maxWidth: "350px"}}>
            {element?.inquiry_requirement.map((list) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {list?.requirement_values.find(
                  (item) =>
                    item?.requirement_attribute_id ===
                    "bf377060-1577-4797-a1b7-a29f59ad2c5d"
                )?.value === "undefined/undefined" ? (
                  ""
                ) : (
                  <>
                    <div style={{display: "flex", flexDirection: "column"}}>
                      <Typography
                        style={{
                          marginBottom: "16px",
                          fontWeight: "600",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#222",
                          maxWidth: "150px",
                        }}
                      >
                        {list.name}
                      </Typography>
                    </div>
                    <div style={{display: "flex", flexDirection: "column"}}>
                      {list.requirement_values.map((el) => (
                        <Typography
                          style={{
                            marginBottom: `${
                              list.name === "Город" ? "6px" : "16px"
                            }`,
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#303940",
                            maxWidth: "180px",
                            textAlign: "end",
                          }}
                        >
                          {el?.requirement_attribute_id ===
                          "bf377060-1577-4797-a1b7-a29f59ad2c5d"
                            ? `${el?.value.split("/").join("-")}`
                            : el?.value && el?.value === "male"
                            ? "Мужчина"
                            : el?.value === "female"
                            ? "Женщина"
                            : el?.value}
                        </Typography>
                      ))}
                    </div>
                  </>
                )}
              </div>
            ))}
            {!toloka && (
              <>
                {/* DEVICE */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        marginBottom: "16px",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#222",
                        maxWidth: "150px",
                      }}
                    >
                      Устройства
                    </Typography>
                  </div>
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <span style={{color: "black", fontSize: "13px"}}>
                      {checkDevice(element?.device?.[1])}
                    </span>
                    {element?.device?.[2] && (
                      <span style={{color: "black", fontSize: "13px"}}>
                        {checkDevice(element?.device?.[2])}
                      </span>
                    )}
                    {element?.device?.[3] && (
                      <span style={{color: "black", fontSize: "13px"}}>
                        {checkDevice(element?.device?.[3])}
                      </span>
                    )}
                  </div>
                </div>
                {/* OS */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "8px",
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        marginBottom: "16px",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#222",
                        maxWidth: "150px",
                      }}
                    >
                      Операционные системы
                    </Typography>
                  </div>
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <span style={{color: "black", fontSize: "13px"}}>
                      {checkOs(element?.os?.[1])}
                    </span>
                    {element?.os?.[2] && (
                      <span style={{color: "black", fontSize: "13px"}}>
                        {checkOs(element?.os?.[2])}
                      </span>
                    )}
                    {element?.os?.[3] && (
                      <span style={{color: "black", fontSize: "13px"}}>
                        {checkOs(element?.os?.[3])}
                      </span>
                    )}
                    {element?.os?.[4] && (
                      <span style={{color: "black", fontSize: "13px"}}>
                        {checkOs(element?.os?.[4])}
                      </span>
                    )}
                    {element?.os?.[5] && (
                      <span style={{color: "black", fontSize: "13px"}}>
                        {checkOs(element?.os?.[5])}
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
            {toloka && (
              <>
                {/* Region */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "8px",
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        marginBottom: "16px",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#222",
                        maxWidth: "150px",
                      }}
                    >
                      Регион
                    </Typography>
                  </div>
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <span style={{color: "black", fontSize: "13px"}}>
                      {clientInfoData[0]?.region?.label}
                    </span>
                  </div>
                </div>
                {/* Opros */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "8px",
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        marginBottom: "16px",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#222",
                        maxWidth: "150px",
                      }}
                    >
                      Опрос узкой аудитории
                    </Typography>
                  </div>
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <span style={{color: "black", fontSize: "13px"}}>
                      {clientInfoData[0]?.audience?.value ===
                      "1a2eeeac-5722-4908-823c-ea8c0fe47e57"
                        ? "По интересам"
                        : "По демографии"}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "8px",
                  }}
                >
                  <div>
                    <Typography
                      style={{
                        marginBottom: "16px",
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "21px",
                        color: "#222",
                        maxWidth: "150px",
                      }}
                    >
                      Количество респондентов
                    </Typography>
                  </div>
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <span style={{color: "black", fontSize: "13px"}}>
                      {clientInfoData[0]?.number_of_partisipants}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="justify-between flex">
            {!toloka ? (
              <div className="justify-around flex">
                <CalendarTodayIcon
                  size="small"
                  className="text-blue-800 bg-blue-100 mt-3 p-0.5 rounded"
                />
                <div className="ml-2">
                  <span
                    style={{color: "rgba(110, 139, 183, 1)", fontSize: "13px"}}
                  >
                    СПОСОБ ПОДКЛЮЧЕНИЯ
                  </span>{" "}
                  <br />
                  <div className="flex flex-col">
                    {checkStatus(element.status_id) && (
                      <>
                        {element?.connection_info?.slice(0, 5) === "https" ? (
                          <span style={{fontSize: "13px"}}>
                            <a
                              href={element?.connection_info}
                              style={{
                                color: "#2463eb",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              target="_blank"
                            >
                              {element?.connection_info}{" "}
                            </a>
                          </span>
                        ) : (
                          <span style={{color: "black", fontSize: "13px"}}>
                            {element?.connection_info}
                          </span>
                        )}
                      </>
                    )}

                    {checkStatus(element?.status_id) === false && (
                      <span style={{fontSize: "13px"}}>
                        {t(element?.interview_type)}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="justify-around flex">
                <Online
                  size="small"
                  className="text-blue-800 bg-blue-100 mt-3 p-0.5 rounded"
                />
                <div className="ml-2">
                  <span
                    style={{color: "rgba(110, 139, 183, 1)", fontSize: "13px"}}
                  >
                    ОНЛАЙН ОПРОС
                  </span>{" "}
                </div>
              </div>
            )}

            <div className="justify-around flex align-middle">
              <CalendarTodayIcon
                size="small"
                className="text-blue-800 bg-blue-100 mt-3 p-0.5 rounded"
              />
              <div className="ml-2">
                <span
                  style={{color: "rgba(110, 139, 183, 1)", fontSize: "13px"}}
                >
                  ДАТА ПРОВЕДЕНИЯ
                </span>{" "}
                <br />
                {!toloka ? (
                  <span style={{color: "black", fontSize: "13px"}}>
                    {moment(element.from_date).format("DD/MM/YYYY")}-
                    {moment(element.to_date).format("DD/MM/YYYY")}
                  </span>
                ) : (
                  <span style={{color: "black", fontSize: "13px"}}>
                    {moment(element.from_date).format("DD/MM/YYYY")}
                  </span>
                )}
              </div>
              <div className="ml-5 flex">
                <Button
                  onClick={() => handleCutomRow(element.id)}
                  style={{borderRadius: "50%", background: "#229ED9"}}
                >
                  <Tooltip
                    title={
                      <h1 style={{fontSize: "14px", color: "#fff"}}>
                        Редактировать
                      </h1>
                    }
                    placement="right"
                  >
                    <EditIcon className="flex rounded p-1" />
                  </Tooltip>
                </Button>
              </div>
            </div>
            {/* <div className='justify-around flex'>
              <Button>Принять участие</Button>
            </div> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ClientInfo;
