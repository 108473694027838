import { Box, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import cls from './index.module.scss'
import { DollarIcon } from '../../components/svg/Dollar'
import axios from '../../utils/axios'

export default function TopPanel({ id, setBalance }) {
  const [list, setList] = React.useState()
  useEffect(() => {
    axios
      .get(`/respondent/balance_info?account_number=${id}`)
      .then((res) => {
        console.log(res)
        setList(res.data)
        setBalance(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  return (
    <div className={cls.topPanel}>
      <div className={cls.row}>
        <Box className={cls.box}>
          <div className={cls.card}>
            <div className={cls.icon}>
              <DollarIcon arg={'#38D9B9'} />
            </div>
            <Typography className={cls.title}>Баланс</Typography>
            <Typography className={cls.price}>
              {parseInt(list?.cost).toFixed(2)}
            </Typography>
          </div>
          {/*  */}
          <div className={cls.card}>
            <div className={cls.icon}>
              <DollarIcon arg={'#4094F7'} />
            </div>
            <Typography className={cls.title}>Приходы</Typography>
            <Typography className={cls.price}>
              {parseInt(list?.debit).toFixed(2)}
            </Typography>
          </div>
          {/*  */}
          <div className={cls.card}>
            <div className={cls.icon}>
              <DollarIcon arg={'#FF3D00'} />
            </div>
            <Typography className={cls.title}>Расходы</Typography>
            <Typography className={cls.price}>
              {parseInt(list?.credit).toFixed(2)}
            </Typography>
          </div>
          {/*  */}
          {/* <div className={cls.card}>
            <div className={cls.icon}>
              <DollarIcon arg={'#F8C51B'} />
            </div>
            <Typography style={{ color: '#696969' }} className={cls.title}>
              Ожидаемый расход
            </Typography>
            <Typography className={cls.price}>{list?.active_cost}</Typography>
          </div> */}
        </Box>
      </div>
    </div>
  )
}
