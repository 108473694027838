import React, {useEffect, useState} from "react";
import moment from "moment";
import Picker from "rc-calendar/lib/Picker";
import Input from "../../components/Input/index";
import {Formik, Field, useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import Form from "../../components/Form/index";
// import formPhoneNumber from "../../functions/formPhoneNumber";
import Button from "../../components/Buttons";
import validateForm from "../../functions/validateForm";
import InputMask from "react-input-mask";
import Switch from "../../components/Switch/Index";
// import phoneNumber from "../../components/Input/phoneNumber"
import company from "../../models/conpany";
import {toast} from "react-toastify";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import axios from "../../utils/axios";
import {TextField, Typography} from "@material-ui/core";

const CompanyCreate = ({createcompany}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [getitemData, setgetitemData] = useState(false);
  const {id} = useParams();
  const [checkInn, setCheckInn] = useState(false);
  const [getData, setGetData] = useState();
  const [dataUser, setDataUser] = useState({
    email: "",
    inn: "",
    name: "",
    owner_name: "",
    phone: "",
    bik: "",
    bank_subdivision_code: "",
    kpp: 0,
    doc_date: "",
    name_of_bank: "",
    correspondent_account: "",
    active: 1,
    contract_number: "",
  });
  const itemCompanyGetdata = (id) => {
    company.companyItemGet(id).then((res) => {
      setGetData(res.data);
      dispatch({
        type: "Add",
        payload: {
          companyName: res.data.name,
        },
      });
      setgetitemData(true);
    });
  };
  useEffect(() => {
    itemCompanyGetdata(id);
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!id?.length) {
      company
        .createCompany({...dataUser, email: dataUser.email.toLowerCase()})
        .then((res) => {
          toast.success(t("company.create"), {
            className: "bg-green-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
          history.push("/home/company");
        })
        .catch((error) => {
          console.log(error);
          if (error.data.code === 422) {
            toast.error(t(`field_required`), {
              className: "bg-red-500 text-white",
              closeOnClick: true,
              ideProgressBar: false,
              autoClose: 4000,
            });
          } else {
            toast.error(t(`${error.data.error}`), {
              className: "bg-red-500 text-white",
              closeOnClick: true,
              ideProgressBar: false,
              autoClose: 4000,
            });
          }
        });
    }
    // // companyUpdata
    else {
      company
        .updateCompany(id, {...dataUser, email: dataUser.email.toLowerCase()})
        .then((res) => {
          toast.success(t("company.updata"), {
            className: "bg-green-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
          history.push("/home/company");
        })
        .catch((err) => {
          if (err.data.code === 422) {
            toast.error(t(`field_required`), {
              className: "bg-red-500 text-white",
              closeOnClick: true,
              ideProgressBar: false,
              autoClose: 4000,
            });
          } else {
            toast.error(t(`${err.data.error}`), {
              className: "bg-red-500 text-white",
              closeOnClick: true,
              ideProgressBar: false,
              autoClose: 4000,
            });
          }
        });
    }
  };

  const handleChange = (e) => {
    setDataUser({
      ...dataUser,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    if (dataUser?.inn?.length > 6) {
      axios
        .get(
          `/company/check/inn?inn=${dataUser.inn}&company_id=${
            id ? id : "856c0bf8-d956-4430-b63a-99105822e869"
          }`
        )
        .then((res) => {
          console.log(res);
          setCheckInn(false);
        })
        .catch((err) => {
          console.log(err);
          setCheckInn(true);
        });
    }
  }, [dataUser.inn]);
  useEffect(() => {
    setDataUser({...getData});
  }, [getData]);
  return (id?.length && getitemData) || createcompany ? (
    <div className="bg-white w-3/5" style={{borderRadius: "8px"}}>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            padding: "16px 20px",
            boxShadow: "inset 0px -1px 0px #EBEDEE",
            background: "#fff",
            borderRadius: "8px 8px 0 0",
          }}
        >
          <h1
            className="text-black"
            style={{
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "24px",
              color: "#1A2024",
            }}
          >
            {id ? "Общие сведения" : "Добавить новую компанию"}
          </h1>
        </div>
        <div className="p-7">
          <div className="w-full py-4 flex items-baseline">
            <div className="w-1/3">
              {t("Название компании")}
              <span style={{marginLeft: "5px", color: "red"}}>*</span>
            </div>
            <div className="w-2/3">
              <Input
                id="name"
                name="name"
                type="text"
                onChange={handleChange}
                defaultValue={getData?.name}
                values={dataUser.name}
              ></Input>
            </div>
          </div>
          <div className="w-full py-4 flex items-baseline">
            <div className="w-1/3">
              {t("ИНН")}
              <span style={{marginLeft: "5px", color: "red"}}>*</span>
            </div>
            <div className="w-2/3">
              {/* <Input {...formik.getFieldProps('inn')}></Input> */}

              <InputMask
                id="inputmask"
                name="inn"
                onChange={handleChange}
                values={dataUser.inn}
                defaultValue={getData?.inn}
                className="w-full focus-within:outline-none
                  focus-within:ring-1
                  focus-within:ring-blue-600
                  transition ease-linear"
                mask={`999999999999`}
                maskChar={null}
              />
              {checkInn && (
                <Typography
                  style={{
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: "red",
                    marginTop: "6px",
                    fontWeight: "400",
                  }}
                >
                  Неправильный ИНН
                </Typography>
              )}
            </div>
          </div>
          <div className="w-full py-4 flex items-baseline">
            <div className="w-1/3">
              {t("Контактное лицо")}
              <span style={{marginLeft: "5px", color: "red"}}>*</span>
            </div>
            <div className="w-2/3">
              <Input
                id="ownername"
                type="text"
                name="owner_name"
                onChange={handleChange}
                defaultValue={getData?.owner_name}
                values={dataUser.owner_name}
              ></Input>
            </div>
          </div>
          <div className="w-full py-4 flex items-baseline">
            <div className="w-1/3">
              {t("Номер телефона")}
              <span style={{marginLeft: "5px", color: "red"}}>*</span>
            </div>
            <div className="w-2/3">
              <Input
                id="phone"
                name="phone"
                onChange={handleChange}
                defaultValue={getData?.phone}
                values={dataUser.phone}
              ></Input>
            </div>
          </div>
          <div className="w-full py-4 flex items-baseline">
            <div className="w-1/3">
              {t("Email")}
              <span style={{marginLeft: "5px", color: "red"}}>*</span>
            </div>
            <div className="w-2/3">
              <Input
                id="email"
                name="email"
                type="email"
                onChange={handleChange}
                defaultValue={getData?.email}
                values={dataUser.email}
              ></Input>
            </div>
          </div>
          {/* Contact number */}
          <div className="w-full py-4 flex items-baseline">
            <div className="w-1/3">
              {t("contract_number")}
              <span style={{marginLeft: "5px", color: "red"}}>*</span>
            </div>
            <div className="w-2/3">
              <Input
                id="contract_number"
                name="contract_number"
                type="text"
                onChange={handleChange}
                defaultValue={getData?.contract_number}
                values={dataUser.contract_number}
              ></Input>
            </div>
          </div>
          <div className="w-full py-4 flex items-baseline">
            <div className="w-1/3">
              {"Дата"}
              <span style={{marginLeft: "5px", color: "red"}}>*</span>
            </div>
            <div className="w-2/3">
              <Input
                id="doc_date"
                name="doc_date"
                type="date"
                placeholder="Выберите дату"
                onChange={(val) => {
                  val === null
                    ? setDataUser((old) => ({
                        ...old,
                        doc_date: moment(new Date()),
                      }))
                    : setDataUser((old) => ({
                        ...old,
                        doc_date: moment(val).format("YYYY-MM-DD"),
                      }));
                }}
                defaultValue={moment(new Date()).format("YYYY-MM-DD")}
              ></Input>
            </div>
          </div>
          <div>
            <Typography
              style={{
                fontWeight: "500",
                color: "#000",
                fontSize: "16px",
                marginTop: "10px",
              }}
            >
              Реквизиты компании
            </Typography>
          </div>
          {/* kpp */}
          <div className="w-full py-4 flex items-baseline">
            <div className="w-1/3">
              {t("kpp")}
              <span style={{marginLeft: "5px", color: "red"}}>*</span>
            </div>
            <div className="w-2/3">
              <InputMask
                id="second_inputmask"
                name="kpp"
                onChange={(e) => {
                  setDataUser({
                    ...dataUser,
                    kpp: Number(e.target.value),
                  });
                }}
                values={dataUser.kpp}
                defaultValue={getData?.kpp}
                className="w-full focus-within:outline-none
                focus-within:ring-1
                focus-within:ring-blue-600
                transition ease-linear"
                mask={`999999999999`}
                maskChar={null}
              />
            </div>
          </div>
          {/* bik */}
          <div className="w-full py-4 flex items-baseline">
            <div className="w-1/3">
              {t("bik")}
              <span style={{marginLeft: "5px", color: "red"}}>*</span>
            </div>
            <div className="w-2/3">
              <Input
                id="bik"
                name="bik"
                onChange={handleChange}
                defaultValue={getData?.bik}
                values={dataUser.bik}
                maxLength={9}
              />
            </div>
          </div>
          {/* name_of_bank */}
          <div className="w-full py-4 flex items-baseline">
            <div className="w-1/3">
              {t("name_of_bank")}
              <span style={{marginLeft: "5px", color: "red"}}>*</span>
            </div>
            <div className="w-2/3">
              <Input
                id="name_of_bank"
                name="name_of_bank"
                type="text"
                onChange={handleChange}
                defaultValue={getData?.name_of_bank}
                values={dataUser.name_of_bank}
              ></Input>
            </div>
          </div>
          {/* correspondent_account */}
          <div className="w-full py-4 flex items-baseline">
            <div className="w-1/3">
              {t("correspondent_account")}
              <span style={{marginLeft: "5px", color: "red"}}>*</span>
            </div>
            <div className="w-2/3">
              <Input
                id="correspondent_account"
                name="correspondent_account"
                onChange={handleChange}
                defaultValue={getData?.correspondent_account}
                values={dataUser.correspondent_account}
                className="w-full focus-within:outline-none
                  focus-within:ring-1
                  focus-within:ring-blue-600
                  transition ease-linear"
                minLength={20}
                maxLength={20}
              />
            </div>
          </div>

          {/* bank_subdivision_code */}
          <div className="w-full py-4 flex items-baseline">
            <div className="w-1/3">
              {t("bank_subdivision_code")}
              <span style={{marginLeft: "5px", color: "red"}}>*</span>
            </div>
            <div className="w-2/3">
              <InputMask
                id="second_inputmask"
                name="bank_subdivision_code"
                onChange={handleChange}
                defaultValue={getData?.bank_subdivision_code}
                values={dataUser.bank_subdivision_code}
                className="w-full focus-within:outline-none
                  focus-within:ring-1
                  focus-within:ring-blue-600
                  transition ease-linear"
                mask={`999999999999`}
                maskChar={null}
              />
            </div>
          </div>
          <div className="w-full py-4 flex items-baseline">
            <div className="w-1/3">{t("Заблокировать")}</div>
            <div className="w-2/3">
              <Switch
                id="is_active"
                color="primary"
                checked={dataUser === 0 ? true : false}
                onChange={(val) => {
                  setDataUser((old) => ({
                    ...old,
                    active: val ? 0 : 1,
                  }));
                }}
              />
            </div>
          </div>
          <div className="w-full py-4">
            <div className="flex gap-2 float-right">
              <Button
                onClick={() => history.push("/home/company")}
                style={{
                  color: "blue",
                  border: "1px solid rgba(229, 233, 235, 1)",
                  padding: "20px ,16px",
                  background: "white",
                }}
              >
                Назад
              </Button>
              <Button type="submit">Сохранить</Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  ) : (
    <></>
  );
};

export default CompanyCreate;
