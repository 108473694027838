import React, {useState, useEffect} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {StyledTableCell} from "../../components/DataTable/index";
import MenuItem from "@material-ui/core/MenuItem";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import {useTranslation} from "react-i18next";
import Inquiry from "../../models/inquiry";
import ExcelFile from "../../models/excel";
import Header from "../../components/Header/Header";
import Button from "../../components/Buttons";
import AddIcon from "@material-ui/icons/Add";
import {useHistory, useParams} from "react-router-dom";
import Pagination from "../../components/Pagination";
import {Typography, Menu, Tooltip, CircularProgress} from "@material-ui/core";
import {DownloadOutlined} from "@ant-design/icons";
import axios from "../../utils/axios";
import ChangeStatatus from "../shared/ChangeStatus";
import Cached from "@material-ui/icons/Cached";
import Tag from "../../components/Tag/index";
import {toast} from "react-toastify";
import RangePicker from "../../components/DatePicker/RangePicker";
import {NumberFomat} from "../../functions/numberFomat";
import {Act, Balance} from "../../components/svg/Act";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CopyRight from "../shared/Copyright";
import companiyInquiry from "../../models/companyinquiry";
import SvgIcon from "../../components/UploadWitchComment/Icons";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import TableLoader from "../../components/TableLoading/Index";
import config from "../../config/defaultSettings";
import {useSelector} from "react-redux";

const InquirysItemList = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const permissions = useSelector((state) => state.auth.permissions);
  const [isFliter, setisFliter] = useState(false);
  const [inquerList, setinquerList] = useState([]);
  const [dropList, setDropList] = useState({target: null, id: ""});
  const pageCount = 10;
  const [items, setItems] = useState({});
  const [pageLimit, setPageLimit] = useState(pageCount);
  const [searchTable, setSearchTable] = useState();
  const [getAllCompany, setGetAllCompany] = useState();
  const [getAllResearcher, setGetAllResearcher] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [respondents, setRespondents] = useState();
  const [date, setDate] = useState({
    from_date: "",
    to_date: "",
  });
  const [getFilter, setGetFilter] = useState({
    company: "",
    researcher: "",
  });

  const [projectId, setProjectId] = useState({});
  const [surveyId, setSurveyId] = useState({});
  const [listInquery, setListInquery] = useState(null);
  const [calculateCost, setCalculateCost] = useState(null);
  const [loader, setLoader] = useState(false);
  const [initialValue, setinitialValue] = useState({
    company_id: "",
    // one Steppers
    title: "",
    from_date: moment(new Date()),
    to_date: moment(new Date()).add(30, "d"),
    status_id: "72ee7350-4a2b-4fc2-945c-34faa56b13ca",
    partisipant_role: "respondent",
    study_type: "one_to_one",
    interview_type: "online",
    type_of_inquiry: {
      group_participants: "",
      number_of_groups: "",
      type_of_inquiry: "",
    },
    description: "",
    // two Stepppers
    researcher_id: "",
    // data researchdelAll select
    duration: 30,
    number_of_partisipants: 10,
    os: {},
    device: {1: "smartphone"},
    cost: 0,
    additional_cost: 0,
    researcher_name: "",
    nda_text: "",
    // thwee Steppers
    inquiry_requirement: [
      // {
      //     input_type: 'range',
      //     name: 'attribute',
      //     data_type: 'number',
      //     id: "",
      //     requirement_value:
      //        {
      //             attribute_id: "",
      //             id: "",
      //             value: ""
      //         }
      // }
    ],
    // four Steppers
    inquiry_timetables: [
      // {
      //     dates: "",
      //     end_time: "",
      //     number_of_applicants: 0,
      //     number_of_partisipants: 0,
      //     start_time: ""
      // }
    ],
    nda: {1: ""},
    example_nda: {1: ""},
    connection_info: "",
    total_cost: 0,
  });

  const params = useParams();

  const listRender = [
    {
      title: t("edit"),
      icon: <EditIcon className="bg-blue-100 rounded text-blue-600 p-0.5" />,
      type: "edit",
    },
  ];
  const [show, setShow] = useState(null);
  useEffect(() => {
    setRespondents(permissions.includes(t("Разрешение на респондентов")));
  }, [permissions]);
  const Status = (value) => {
    switch (value) {
      case "72ee7350-4a2b-4fc2-945c-34faa56b13ca":
        return "Черновик";
      case "7b75685f-ee20-4fb9-bc5f-7e40c115e708":
        return "Активный";
      case "2e857fbe-9add-4eae-a8c4-fe57fb384347":
        return "Завершено";
      case "2a98c22e-cbee-49f9-90ef-f56429d96333":
        return "Отменено";
    }
  };
  const Colors = (name) => {
    switch (name) {
      case "72ee7350-4a2b-4fc2-945c-34faa56b13ca":
        return "linear-gradient(0deg, rgba(248, 221, 78, 0.3), rgba(248, 221, 78, 0.3)), #FFFFFF";
      case "7b75685f-ee20-4fb9-bc5f-7e40c115e708":
        return "linear-gradient(0deg, rgba(64, 148, 247, 0.15), rgba(64, 148, 247, 0.15)), #FFFFFF";
      case "2e857fbe-9add-4eae-a8c4-fe57fb384347":
        return "linear-gradient(0deg, rgba(56, 217, 185, 0.2), rgba(56, 217, 185, 0.2)), #FFFFFF";
      case "2a98c22e-cbee-49f9-90ef-f56429d96333":
        return "linear-gradient(0deg, rgba(247, 102, 89, 0.15), rgba(247, 102, 89, 0.15)), #FFFFFF";
      default:
        return "-";
    }
  };
  const TextColor = (name) => {
    switch (name) {
      case "72ee7350-4a2b-4fc2-945c-34faa56b13ca":
        return "#D29404";
      case "7b75685f-ee20-4fb9-bc5f-7e40c115e708":
        return "#4094F7";
      case "2e857fbe-9add-4eae-a8c4-fe57fb384347":
        return "#1AC19D";
      case "2a98c22e-cbee-49f9-90ef-f56429d96333":
        return "#F76659";
      default:
        return "-";
    }
  };
  const [tableHeardName, setTableHeardName] = useState([
    t("number.icon"),
    t("data.form"),
    t("data.to"),
    t("heading"),
    t("description"),
    t("total_cost"),
    t("status"),
    "",
  ]);

  const handleInquiryAdd = () => {
    history.push(`${"/home/inquirys"}`);
  };
  const inquiryItemListGetAll = () => {
    const params = {
      from_date: date.from_date,
      to_date: date.to_date,
      company_id: getFilter.company,
      researcher_id: getFilter.researcher,
      title: searchTable,
      page: pageLimit / pageCount,
      limit: pageCount,
    };
    Inquiry.inquiryGetAllList(params).then((res) => {
      setinquerList(res.data.inquiries);
      setItems(res.data);
    });
  };
  const getExcelFile = () => {
    const param = {
      from_date: date.from_date,
      to_date: date.to_date,
      search: searchTable,
      company_id: getFilter.company,
      researcher_id: getFilter.researcher,
    };
    ExcelFile.inqueryGetAllExcelFile(param)
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMoreClick = (ids, type) => {
    if (type === "edit") {
      checkRespondent(ids);
    }

    if (type === "update_status") {
      setSelectedMenu(ids);
    }
    if (type === "copy_right") {
      setSelectedMenu(ids);
    }
    if (type === "act") {
      axios
        .get(`excel/akt?inquiry_id=${ids.id}`)
        .then((res) => {
          window.location.href = res.data.url;
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
    if (type === "balance") {
      axios
        .get(`/excel/factura?inquiry_id=${ids.id}`)
        .then((res) => {
          window.location.href = res.data.url;
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
    if (type === "excel") {
      axios
        .get(`excel/inquiry-respondents?inquiry_id=${ids.id}`)
        .then((res) => {
          window.location.href = res.data.url;
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
    if (type === "word") {
      axios
        .get(`word/service-order?inquiry_id=${ids.id}`)
        .then((res) => {
          window.location.href = res.data.url;
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  };
  const checkRespondent = (ids) => {
    axios.get(`/inquiry/respondents?inquiry_id=${ids.id}`).then((res) => {
      if (res.data.count >= 1) {
        toast.error("У этого запроса есть Респонденты!", {
          className: "bg-red-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        });
      } else {
        history.push(
          `/home/company/companyinquirycreate/${ids.company_id}/edit/${ids.id}/0`
        );
      }
    });
  };
  const [getSurveyID, setGetSurveyID] = useState(null);
  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedMenu(data);
    setGetSurveyID(data.screener_survey_id);
  };
  const handleCutomRow = (element) => {
    respondents &&
      history.push(
        `${"/home/company/inquiryitem"}/${element.company_id}/${element.id}`
      );
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedMenu(null);
  };
  const getCompany = () => {
    axios
      .get(`/company?limit=1000`)
      .then((res) => {
        setGetAllCompany(() => [
          ...res.data.companies.map((e) => ({
            ...e,
            label: e.name,
          })),
        ]);
      })
      .catch((err) => console.log(err));
  };

  const getResearcher = () => {
    axios
      .get(`/researcher?company_id=${getFilter.company}&limit=1000`)
      .then((res) => {
        setGetAllResearcher(() => [
          ...res.data.researchers.map((e) => ({
            ...e,
            label: e.name,
          })),
        ]);
      })
      .catch((err) => console.log(err));
  };

  const handleNext = (arg) => {
    setActiveStep((prevActiveStep) => arg ?? prevActiveStep + 1);
  };
  const time =
    (new Date(selectedMenu?.to_date).getTime() -
      new Date(selectedMenu?.from_date).getTime()) /
    (1000 * 3600 * 24);

  const copyRight = {
    screener_project_id: projectId.id,
    screener_survey_id: surveyId.id,
    additional_cost: selectedMenu?.additional_cost,
    company_id: selectedMenu?.company_id,
    connection_info: selectedMenu?.connection_info,
    description: selectedMenu?.description,
    device: selectedMenu?.device,
    duration: selectedMenu?.duration,
    example_nda: selectedMenu?.example_nda,
    inquiry_requirement: selectedMenu?.inquiry_requirement,
    nda: selectedMenu?.nda,
    nda_text: selectedMenu?.nda_text,
    interview_type: selectedMenu?.interview_type,
    number_of_partisipants: selectedMenu?.number_of_partisipants,
    os: selectedMenu?.os,
    partisipant_role: selectedMenu?.partisipant_role,
    researcher_id: selectedMenu?.researcher_id,
    type_of_inquiry: selectedMenu?.type_of_inquiry,
    researcher_name: selectedMenu?.researcher_name,
    study_type: selectedMenu?.study_type,
    title: selectedMenu?.title,
    from_date: moment(new Date()).add(12, "hours").format("YYYY-MM-DD"),
    to_date: moment(new Date())
      .add(12, "hours")
      .add(time, "days")
      .format("YYYY-MM-DD"),
    total_cost: calculateCost?.total_cost,

    status_id: selectedMenu?.status_id,
  };
  const onSubmit = (data) => {
    let screenner_projec = "";
    let survey_id = "";
    const project = {
      auto_next: true,
      is_active: true,
      is_archive: true,
      languages: [
        {
          is_active: true,
          language: "ru",
        },
      ],
      multiple_answers: true,
      record_answers: true,
      return_answers: true,
    };

    if (params.inquery_id) {
      handleNext();
      return;
    }
    setLoader(true);
    companiyInquiry
      .createProject(project)
      .then((res) => {
        const survey = {
          title: initialValue.title,
        };
        screenner_projec = res.response_data.id;
        setProjectId(res.response_data);

        companiyInquiry
          .createStroy(survey, res.response_data.id)
          .then((res) => {
            // survey_id = res.response_data.id;
            setSurveyId(res.response_data);
            setinitialValue((el) => ({
              ...el,
              screener_project_id: screenner_projec,
              screener_survey_id: res.response_data.id,
            }));

            companiyInquiry
              .getInquiry(selectedMenu.id)
              .then((res) => {
                setListInquery(res.data);
              })
              .catch((err) => {
                console.log(err);
                setLoader(false);
              });
          })
          .catch((err) => {
            console.log(err);
            setLoader(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
    handleNext();
  };

  useEffect(() => {
    if (listInquery) {
      companiyInquiry
        .calculateCost(
          listInquery.partisipant_role,
          listInquery.duration,
          listInquery.number_of_partisipants,
          listInquery.additional_cost,
          listInquery.company_id,
          listInquery.interview_type === "online" ? "true" : "false"
        )
        .then((res) => {
          setCalculateCost(res.data);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  }, [listInquery]);

  useEffect(() => {
    if (calculateCost) {
      companiyInquiry
        .InquieryCreate(copyRight)
        .then(() => {
          toast.success(`${t("Успешно") + " " + t("скопировано")}`, {
            className: "bg-green-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
          window.location.reload();
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  }, [calculateCost]);

  useEffect(() => {
    inquiryItemListGetAll();
    getCompany();
    getResearcher();
  }, [getFilter]);
  useEffect(() => {
    inquiryItemListGetAll();
  }, [pageLimit]);
  useEffect(() => {
    inquiryItemListGetAll();
  }, [searchTable, getFilter, date]);

  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => handleMoreClick(selectedMenu, "edit")}
          className="mx-1"
        >
          <EditIcon className="bg-blue-100 rounded text-blue-600 p-1 " />
          <span className="ml-2">{t("edit")}</span>
        </MenuItem>
        <MenuItem
          onClick={() => handleMoreClick(selectedMenu, "update_status")}
        >
          <ChangeStatatus
            open={open}
            setOpen={setOpen}
            id={getSurveyID}
            checkActive={true}
            element={selectedMenu}
            t={t}
            setElement={setinquerList}
            closeMenu={setAnchorEl}
            Cached={Cached}
          />
        </MenuItem>
        <MenuItem
          onClick={() => handleMoreClick(selectedMenu, "copy_right")}
          style={loader ? {justifyContent: "center"} : null}
        >
          {loader ? (
            <CircularProgress size="20px" />
          ) : (
            <CopyRight
              open={open}
              setOpen={setOpen}
              id={getSurveyID}
              checkActive={true}
              element={selectedMenu}
              t={t}
              setElement={setinquerList}
              closeMenu={setAnchorEl}
              Cached={FileCopyIcon}
              onSubmit={onSubmit}
            />
          )}
        </MenuItem>
        {show === "FINISHED" && (
          <MenuItem
            onClick={() => handleMoreClick(selectedMenu, "act")}
            className="mx-1"
          >
            <Act className="bg-blue-100 rounded text-blue-600 p-1 " />
            <span className="ml-2">{"АКТ"}</span>
          </MenuItem>
        )}
        {show === "FINISHED" && (
          <MenuItem
            onClick={() => handleMoreClick(selectedMenu, "balance")}
            className="mx-1"
          >
            <Balance className="bg-blue-100 rounded text-blue-600 p-1 " />
            <span className="ml-2">{"Счет-фактура"}</span>
          </MenuItem>
        )}
        {show === "FINISHED" && (
          <MenuItem
            onClick={() => handleMoreClick(selectedMenu, "excel")}
            className="mx-1"
          >
            <FileCopyIcon className="bg-blue-100 rounded text-blue-600 p-1 " />
            <span className="ml-2">{"Детализация"}</span>
          </MenuItem>
        )}
        {show === "FINISHED" && (
          <MenuItem
            onClick={() => handleMoreClick(selectedMenu, "word")}
            className="mx-1"
          >
            <SvgIcon
              name="word"
              className="bg-blue-100 rounded text-blue-600 p-1 "
            />
            <span className="ml-2">{"Форма заказа"}</span>
          </MenuItem>
        )}
        {show === "ACTIVE" && (
          <MenuItem
            // onClick={() => handleMoreClick(selectedMenu, "word")}
            className="mx-1"
          >
            <FindInPageIcon className="bg-blue-100 rounded text-blue-600 " />
            <a
              className="text-black hover:text-black "
              href={`${config.canvasBaseURL}construct/editor/${selectedMenu?.screener_project_id}/${selectedMenu?.screener_survey_id}/${selectedMenu?.company_id}/${selectedMenu?.id}/0`}
            >
              <span className="ml-2">Скриннер</span>
            </a>
          </MenuItem>
        )}
      </Menu>
      <Header
        company={getAllCompany}
        researcher={getAllResearcher}
        filter={true}
        inquiries={true}
        setGetFilter={setGetFilter}
        getFilter={getFilter}
        title={t("Запросы")}
        isFilter={isFliter}
      >
        <Button
          onClick={handleInquiryAdd}
          icon={<AddIcon className="text-white" />}
        >
          {t("add")}
        </Button>
      </Header>
      <div>
        {/*<Header>*/}

        {/*</Header>*/}
        {/*<TeamsList/>*/}
        <div className="fslex p-5 px-5 pb-4" style={{borderRadius: "20px"}}>
          <TableContainer
            elevation={0}
            component={Paper}
            className=" shadow-none rounded-2xl p-5"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginBottom: "16px",
              }}
            >
              {/* {loader ? (
                <div>
                  <CircularProgress size="35px" />
                </div>
              ) : (
                ""
              )} */}
              <span>
                <RangePicker
                  style={{height: "100%", marginRight: "8px"}}
                  hideTimeBlock
                  onChange={(val) => {
                    val[0] === null
                      ? setDate((old) => ({
                          from_date: "",
                          to_date: "",
                        }))
                      : setDate((old) => ({
                          from_date: moment(val[0]).format("YYYY-MM-DD"),
                          to_date: moment(val[1]).format("YYYY-MM-DD"),
                        }));
                  }}
                  className="p-2 w-full"
                />
              </span>
              <input
                name="search"
                value={searchTable}
                onChange={(e) => {
                  setSearchTable(e.target.value);
                }}
                style={{
                  border: "1px solid #E5E9EB",
                  borderRadius: "8px",
                  padding: "8px 12px",
                  maxWidth: "200px",
                }}
                placeholder="Поиск"
              />
              <a
                onClick={() => {
                  getExcelFile();
                }}
                id="download_file"
              >
                <DownloadOutlined />
              </a>
            </div>
            <Table
              elevation={0}
              aria-label="customized table"
              style={{borderRadius: "20px"}}
            >
              <TableHead>
                <TableRow className="text-black font-body">
                  {tableHeardName?.map((element, index) => (
                    <StyledTableCell key={index}>{element}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {inquerList?.map((element, index) => (
                  <TableRow
                    className="text-black font-body hover:bg-gray-100 cursor-pointer"
                    key={index}
                  >
                    <StyledTableCell onClick={() => handleCutomRow(element)}>
                      {parseInt(
                        pageCount * (pageLimit / pageCount - 1) + index + 1
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{whiteSpace: "nowrap"}}
                      onClick={() => handleCutomRow(element)}
                    >
                      {moment(element.from_date).format("DD-MM-YYYY")}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{whiteSpace: "nowrap"}}
                      onClick={() => handleCutomRow(element)}
                    >
                      {moment(element.to_date).format("DD-MM-YYYY")}
                    </StyledTableCell>
                    {/*<StyledTableCell>{element.owner_name}</StyledTableCell>*/}
                    <Tooltip
                      title={
                        <h1 style={{fontSize: "14px", color: "#fff"}}>
                          {element.title}
                        </h1>
                      }
                      placement="top"
                    >
                      <StyledTableCell onClick={() => handleCutomRow(element)}>
                        {element.title}
                      </StyledTableCell>
                    </Tooltip>
                    <Tooltip
                      title={
                        <h1 style={{fontSize: "14px", color: "#fff"}}>
                          {element.description}
                        </h1>
                      }
                      placement="top"
                    >
                      <StyledTableCell>
                        <Typography
                          onClick={() => handleCutomRow(element)}
                          id="text_limit"
                        >
                          {element.description}
                        </Typography>
                      </StyledTableCell>
                    </Tooltip>
                    <StyledTableCell onClick={() => handleCutomRow(element)}>
                      {NumberFomat(element.total_cost)} ₽
                    </StyledTableCell>
                    <StyledTableCell onClick={() => handleCutomRow(element)}>
                      <Tag
                        shape="subtle"
                        color="blue"
                        size="large"
                        style={{
                          width: "150px",
                          background: `${Colors(element.status_id)}`,
                          borderRaduis: "6px",
                          color: `${TextColor(element.status_id)}`,
                        }}
                      >
                        {Status(element.status_id)}
                      </Tag>
                    </StyledTableCell>
                    <StyledTableCell
                      className="w-2   border-gray-300"
                      style={{borderLeft: "1px solid gainsboro"}}
                    >
                      <MoreHorizIcon
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                          handleClick(e, element);
                          setShow(element.status);
                        }}
                        className="cursor-pointer text-blue-500"
                        style={{
                          border: "1px solid gainsboro",
                          borderRadius: "5px",
                        }}
                      />
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className="mt-5 text-black-700"
            // size={'large'}
            count={items.count}
            pageCount={pageCount}
            onChange={(val) => {
              console.log(val);
              setPageLimit(val);
            }}
          />
        </div>
        {/*<Table list={companyList} Tableheadername={Tableheadername}></Table>*/}
      </div>
    </div>
  );
};

export default InquirysItemList;
