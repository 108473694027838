import React, {useEffect, useState} from "react";
import Hearder from "../../components/Header/Header";
import Breadcrumbs from "../../components/Breadcrumb";
import {Formik} from "formik";
import Form from "../../components/Form";
import Input from "../../components/Input";
import Button from "../../components/Buttons";
import {useTranslation} from "react-i18next";
import Select from "../../components/Select";
import Switch from "../../components/Switch/Index";
import request from "../../models/researchers";
import {useParams} from "react-router-dom";
import requests from "../../models/conpany";
import * as Yup from "yup";
import validateForm from "../../functions/validateForm";
import {useHistory} from "react-router-dom";
import requit from "../../models/researchers";
import Admins from "../../models/permissions";

const Indexupdata = ({setUserName, setCompanyName}) => {
  const {t} = useTranslation();
  const parmas = useParams();
  const history = useHistory();
  const [roll, setRoll] = useState({});
  const [check, setCheck] = useState(null);

  const ValidationSchema = Yup.object().shape({
    email: validateForm("email", t),
    inn: validateForm("default", t),
    name: validateForm("default", t),
    professiontitle: validateForm("default", t),
    phone: validateForm("phone_number", t),
  });
  const [initialValue, setinitialValue] = useState({
    companyid: parmas.id,
    email: "",
    name: "",
    phone: "",
    photo: "",
    professiontitle: "",
    roleid: "",
    active: 0,
  });
  const getResearcherRoll = () => {
    const param = {
      client_type_id: "5a3818a9-90f0-44e9-a053-3be0ba1e2c03",
    };
    Admins.getAllRolls(param)
      .then((res) => {
        setRoll(() => [
          ...res.data.roles.map((e) => ({
            ...e,
            label: e.name,
          })),
        ]);
      })
      .catch((err) => console.log(err));
  };
  const Back = () => {
    history.push(`${"/home/company/companyinquiry"}/${parmas.id}`);
  };
  const [InitaliBool, setInitaliBool] = useState(false);
  const [defaultValue, setdefaultValue] = useState([]);
  const handlePush = () => {
    history.push(`${"/home/company/companyinquiry"}/${parmas.id}`);
  };
  const handleReasarcherNameItem = (id) => {
    request.ItemresearcherList(id).then((res) => {
      setCheck(true);
      setUserName(res.data.name);
      setCompanyName(res.data.company_name.name);
      setinitialValue((old) => ({
        ...old,
        email: res.data.email,
        name: res.data.name,
        phone: res.data.phone,
        photo: res.data.photo,
        professiontitle: res.data.profession_title,
        roleid: res.data.role_id,
        active: res.data.active,
      }));
      setInitaliBool(true);
    });
  };
  const handleCompanyName = (id) => {
    requests.companyItemGet(id).then((res) => {
      setdefaultValue(
        [res.data].map((element) => {
          return {
            label: element.name,
            value: element.id,
          };
        })
      );
    });
  };
  const onSubmit = (data) => {
    const sendData = {
      company_id: data.companyid,
      email: data.email.toLowerCase(),
      name: data.name,
      phone: data.phone,
      profession_title: data.professiontitle,
      role_id: initialValue.roleid,
      active: initialValue.active,
    };
    // console.log("_________________________________",sendData)
    requit.researcherUpdate(parmas.comp_id, sendData).then((res) => {
      history.push(`${"/home/company/companyinquiry"}/${parmas.id}`);
    });
  };
  useEffect(() => {
    getResearcherRoll();
  }, []);
  useEffect(() => {
    handleReasarcherNameItem(parmas.comp_id);
  }, [parmas.comp_id]);
  useEffect(() => {
    handleCompanyName(parmas.id);
  }, [parmas.id]);

  return (
    <div>
      <div>
        {/*<Hearder startAdornment={[<Breadcrumbs routes={routes} />]}></Hearder>*/}
        <div className="p-7">
          {defaultValue.length > 0 && InitaliBool && (
            <div className="p-5 bg-white w-3/5" style={{borderRadius: "8px"}}>
              <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                validationSchema={ValidationSchema}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    <div>
                      <h1
                        className="text-black"
                        style={{
                          fontStyle: "normal",
                          fontWeight: "bold",
                          fontSize: "18px",
                          lineHeight: "24px",
                          padding: "16px",
                          color: "rgba(26, 32, 36, 1)",
                        }}
                      >
                        {"Общие сведения"}
                      </h1>
                      <hr />
                      <div className="w-full py-4 flex items-baseline">
                        <div className="w-1/3">{t("full.name")}</div>
                        <div className="w-2/3">
                          <Form.Item name="name" formik={formik}>
                            <Input {...formik.getFieldProps("name")}></Input>
                          </Form.Item>
                        </div>
                      </div>

                      <div className="w-full py-4 flex items-baseline">
                        <div className="w-1/3">{t("position")}</div>
                        <div className="w-2/3">
                          <Form.Item name="professiontitle" formik={formik}>
                            <Input
                              {...formik.getFieldProps("professiontitle")}
                            ></Input>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="w-full py-4 flex items-baseline">
                        <div className="w-1/3">{t("numberPhone")}</div>
                        <div className="w-2/3">
                          <Form.Item name="phone" formik={formik}>
                            <Input {...formik.getFieldProps("phone")}></Input>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="w-full py-4 flex items-baseline">
                        <div className="w-1/3">{t("email.name")}</div>
                        <div className="w-2/3">
                          <Form.Item name="email" formik={formik}>
                            <Input {...formik.getFieldProps("email")}></Input>
                            {/*<phoneNumber {...formik.getFieldProps("email")}></phoneNumber>*/}
                          </Form.Item>
                        </div>
                      </div>
                      <div className="w-full py-4 flex items-baseline">
                        <div className="w-1/3">{t("Название Роли")}</div>
                        <div className="w-2/3">
                          <Form.Item name="roleid" formik={formik}>
                            <Select
                              options={roll}
                              onChange={(e) => {
                                setinitialValue((el) => ({
                                  ...el,
                                  roleid: e.id,
                                }));
                              }}
                              defaultValue={
                                roll?.length >= 1
                                  ? roll.find(
                                      (el) => el.id === initialValue.roleid
                                    )
                                  : {}
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                      {/*  */}
                      <div className="w-full py-4 flex items-baseline">
                        <div className="w-1/3">{t("Название компании")}</div>
                        <div className="w-2/3">
                          <Form.Item name="ownername" formik={formik}>
                            <Select
                              isDisabled
                              defaultValue={defaultValue}
                            ></Select>
                          </Form.Item>
                        </div>
                      </div>
                      {/*  */}
                      <div className="w-full py-4 flex items-baseline">
                        <div className="w-1/3">{t("Заблокировать")}</div>
                        <div className="w-2/3">
                          <Switch
                            checked={initialValue.active === 0 ? true : false}
                            onChange={(val) => {
                              setinitialValue((old) => ({
                                ...old,
                                active: val ? 0 : 1,
                              }));
                            }}
                            id="is_active"
                            color="primary"
                          />
                        </div>
                      </div>

                      <div className="w-full py-4">
                        <Form.Item formik={formik}>
                          <div className="flex gap-2 float-right">
                            <Button
                              onClick={Back}
                              style={{
                                color: "blue",
                                border: "1px solid rgba(229, 233, 235, 1)",
                                padding: "20px ,16px",
                                background: "white",
                              }}
                            >
                              Назад
                            </Button>
                            <Button onClick={() => onSubmit(formik.values)}>
                              Сохранить
                            </Button>
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Indexupdata;
