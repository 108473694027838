import Card from "../../components/Card";
import Button from "../../components/Buttons";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Form from "../../components/Form";

function Context({
  params,
  history,
  formik,
  companies,
  isLoading,
  t,
  roll,
  setInitialValues,
  initialValue,
}) {
  return (
    <div className="w-full grid grid-cols-2 gap-4 box-border font-body text-sm">
      <Card title={t("general.information")} className="row-span-2 ">
        {/* First Name */}
        <div className="w-full py-2 flex items-baseline">
          <div className="w-1/3 text-gray-600 font-medium">Ф.И.О.</div>
          <div className="w-2/3">
            <Form.Item name="name" formik={formik}>
              <Input id="name" type="text" {...formik.getFieldProps("name")} />
            </Form.Item>
          </div>
        </div>
        {/* Position */}
        <div className="w-full py-2 flex items-baseline">
          <div className="w-1/3 text-gray-600 font-medium">{t("position")}</div>
          <div className="w-2/3">
            <Form.Item name="profession_title" formik={formik}>
              <Input
                id="profession_title"
                type="text"
                {...formik.getFieldProps("profession_title")}
              />
            </Form.Item>
          </div>
        </div>
        {/* Phone */}
        <div className="w-full py-2 flex items-baseline">
          <div className="w-1/3 text-gray-600 font-medium">{t("phone")}</div>
          <div className="w-2/3">
            <Form.Item name="phone" formik={formik}>
              <Input
                id="phone"
                type="text"
                {...formik.getFieldProps("phone")}
              />
            </Form.Item>
          </div>
        </div>
        {/* Email */}
        <div className="w-full py-2 flex items-baseline">
          <div className="w-1/3 text-gray-600 font-medium">Email</div>
          <div className="w-2/3">
            <Form.Item name="email" formik={formik}>
              <Input
                id="email"
                type="text"
                {...formik.getFieldProps("email")}
              />
            </Form.Item>
          </div>
        </div>
        {/* password */}
        {!params.id && (
          <div className="w-full py-2 flex items-baseline">
            <div className="w-1/3 text-gray-600 font-medium">Пароль</div>
            <div className="w-2/3">
              <Form.Item name="password" formik={formik}>
                <Input
                  id="password"
                  type="text"
                  {...formik.getFieldProps("password")}
                />
              </Form.Item>
            </div>
          </div>
        )}
        {/* Company */}
        <div className="w-full py-4 flex items-baseline">
          <div className="w-1/3">{t("Название Роли")}</div>
          <div className="w-2/3">
            <Form.Item name="role_id" formik={formik}>
              <Select
                options={roll}
                onChange={(e) => {
                  setInitialValues((el) => ({
                    ...el,
                    role_id: e.id,
                  }));
                }}
                defaultValue={
                  roll?.length >= 1
                    ? roll.find((el) => el.id === initialValue.role_id)
                    : {}
                }
              />
            </Form.Item>
          </div>
        </div>
        {/* ,  */}
        <div className="w-full py-2 flex items-baseline">
          <div className="w-1/3 text-gray-600 font-medium">{t("company")}</div>
          <div className="w-2/3">
            <Form.Item name="company" formik={formik}>
              <Select
                isDisabled={params.id}
                options={companies}
                {...formik.getFieldProps("company")}
                onChange={(val) => {
                  formik.setFieldValue("company", val);
                }}
              />
            </Form.Item>
          </div>
        </div>

        {/* Submit button */}
        <div className="flex space-x-2 justify-end pt-4">
          <Button
            size="large"
            shape="outlined"
            textStyle={{fontWeight: "500", padding: "0 1rem"}}
            onClick={() => history.goBack()}
          >
            {t("back")}
          </Button>
          <Button
            size="large"
            type="submit"
            loading={isLoading}
            textStyle={{fontWeight: "500", padding: "0 1rem"}}
          >
            {t("save")}
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default Context;
