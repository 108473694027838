import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EmailIcon from '@material-ui/icons/Email';
import { Formik } from 'formik';
import Input from '../../../components/Input/index';
import { ToastContainer, toast } from 'react-toastify';
import SendEmail from "../../../models/logout"
// import Img from "../../../assets/icons/download.png"
import {useParams,
  useLocation,
  useHistory,
  useRouteMatch,} from "react-router-dom"
import Img from "../../../assets/icons/download.png";
import config from '../../../config/defaultSettings'

export default function RestorPassword({
  setActivTab
})
  {
  const { t } = useTranslation();
  const parmas = useParams();
  const useL = useLocation();
  const useR = useRouteMatch();
  const history = useHistory();
  const [restoreEmail, setRestoreEmail] = useState({
    base_url: `${config.researcherBaseURL}/auth/new/login`,
    email: ''
  })
  useEffect(() => {
    console.log(restoreEmail.email);
  }, [restoreEmail])
  const handleSubmit = (e) => {
    const url = '/auth/login'
    e.preventDefault()
    // history.push(`${url}${"adasdasdsadsadsdaddd"}`)
    SendEmail.restoreSubmit(restoreEmail)
      .then(res => {
        console.log(res)
      })
      .catch(err => {
      console.log(err);
    })
  }
  return (
       <div className="font-body flex ">
      <div style={{ height: '100vh', width: '100vw' }} className="flex font-body justify-center divide-y bg-red-100">
<div className="w-3/4 h-3/4 my-40  bg-amber-50 m-auto">
    <img className="w-full" src={Img} alt="" />
</div>
      </div>
      <div className="rounded-xl h-21 mt-20 m-auto w-3/4 p-3 bg-white flex justify-between items-center">
        {/* <div className="flex space-x-2 items-center">
            {/* <div style={{ height: '55vh'}} className="rounded-2xl flex justify-center items-center w-4/6 mx-auto   my-10 b bg-white">

            </div> */}
        {/* </div> */}
         <div className="h-1/2 w-3/4  m-auto  my-40  text-2xl rounded-lg bg-white shadow-lg">
          <div></div>
            <div className="p-6">
        <button onClick={() => history.push("/auth/login")}  className="text-gray-400 p-3 -my-5"><ArrowBackIosIcon />{t('back')}</button>
        <p></p> <br />
        <div>
         <h2 className="font-extrabold text-xl mb-6">{t("restorPassword")}</h2>
            <hr />
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-2">
                <label className="mt-4" htmlFor="email_id">{t("Email")}</label>
            <span className="flex items-center space-x-2 p-0.5 bg-blue-100 my-5 rounded-lg">
              <Input
                onChange={(e)=> setRestoreEmail(old => ({...old, email: e.target.value}))}
                icon={<EmailIcon className="text-blue-500" />}

              >
              </Input>
              </span>
              </div><div className="p-6">
                <button disabled={false}
                  type="submit"
                  className="w-full rounded-md bg-blue-500 p-3 text-white font-semibold active:bg-blue-700"
                >
                  {t("panelBack")}
                </button>
              </div>
                <ToastContainer/>
          </form>

          </div>
        </div>
        </div>
</div>

    </div>
      )
}
