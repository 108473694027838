export const DownloadIcon = () => (
  <svg
    width='12'
    height='15'
    viewBox='0 0 12 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.82499 5.5H8.49999V1.33333C8.49999 0.875 8.12499 0.5 7.66666 0.5L4.33332 0.5C3.87499 0.5 3.49999 0.875 3.49999 1.33333L3.49999 5.5L2.17499 5.5C1.43332 5.5 1.05832 6.4 1.58332 6.925L5.40832 10.75C5.73332 11.075 6.25832 11.075 6.58332 10.75L10.4083 6.925C10.9333 6.4 10.5667 5.5 9.82499 5.5ZM0.166656 13.8333C0.166656 14.2917 0.541657 14.6667 0.99999 14.6667L11 14.6667C11.4583 14.6667 11.8333 14.2917 11.8333 13.8333C11.8333 13.375 11.4583 13 11 13L0.99999 13C0.541657 13 0.166656 13.375 0.166656 13.8333Z'
      fill='#0067F4'
    />
  </svg>
)
export const UploadIcon = () => (
  <svg
    width='14'
    height='17'
    viewBox='0 0 14 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5 13.0007H9C9.55 13.0007 10 12.5507 10 12.0007V7.0007H11.59C12.48 7.0007 12.93 5.9207 12.3 5.2907L7.71 0.700703C7.32 0.310703 6.69 0.310703 6.3 0.700703L1.71 5.2907C1.08 5.9207 1.52 7.0007 2.41 7.0007H4V12.0007C4 12.5507 4.45 13.0007 5 13.0007ZM1 15.0007H13C13.55 15.0007 14 15.4507 14 16.0007C14 16.5507 13.55 17.0007 13 17.0007H1C0.45 17.0007 0 16.5507 0 16.0007C0 15.4507 0.45 15.0007 1 15.0007Z'
      fill='#0E73F6'
    />
  </svg>
)
