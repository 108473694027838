import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Inquery from "../../models/inquiry";
import Select from "../../components/Select";
import {STATUSES} from "../../mock/inqueryStatusList";
import Button from "../../components/Buttons";
import axios from "../../utils/axios";
import {toast} from "react-toastify";
import config from "../../config/defaultSettings";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #red",
    borderRadius: "6px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
    width: 380,
  },
}));

export default function TransitionsModal({
  element,
  respondentCount,
  setElement,
  t,
  Cached,
  closeMenu,
  checkActive = false,
  id,
  open,
  setOpen,
}) {
  const classes = useStyles();

  const [status_id, setStatusId] = React.useState(null);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const request = () => {
    Inquery.updateInquiryStatus({
      inquiry_id: element.id,
      researcher_id: element.researcher_id,
      status_id: status_id,
    })
      .then((res) => {
        setElement((el) =>
          el.map((e) => {
            if (e.id === element.id) {
              return {...e, status_id};
            } else return e;
          })
        );

        toast.success("Состояние успешно обновлено", {
          className: "bg-green-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        });
        setOpen(false);
        setStatusId(null);
        closeMenu(null);
      })
      .catch((err) => {
        console.log(err);
        toast.error(t(err.data.error), {
          className: "bg-red-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        });
        setOpen(false);
      });
  };
  const filtered_status = () => {
    switch (element.status_id) {
      // Черновик
      case "72ee7350-4a2b-4fc2-945c-34faa56b13ca":
        return [STATUSES[1]];
      // Активный
      case "7b75685f-ee20-4fb9-bc5f-7e40c115e708":
        return [STATUSES[2], STATUSES[3]];
      // Завершено
      case "2e857fbe-9add-4eae-a8c4-fe57fb384347":
        return [];
      // Отменено
      case "2a98c22e-cbee-49f9-90ef-f56429d96333":
        return [];

      default:
        break;
    }
  };
  const CheckQuestion = (status) => {
    axios
      .get(`${config.managerBaseURL}v1/survey/${id}/all`)
      .then((res) => {
        if (res.questions.length === 0) {
          setOpen(false);
          toast.error("Создайте скринер к запросу", {
            className: "bg-red-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
        } else {
          setStatusId(status);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div onClick={handleOpen}>
        <Cached className="bg-green-100 rounded text-green-600 p-1 my-1" />{" "}
        <span className="ml-2">{t("Изменить статус")}</span>
      </div>
      {element ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h2 id="transition-modal-title">Изменить статус запроса</h2>
              <Select
                className={"my-1 mb-2 w-full"}
                onChange={(e) => {
                  setStatusId(
                    `${
                      checkActive &&
                      e.value === "7b75685f-ee20-4fb9-bc5f-7e40c115e708"
                        ? CheckQuestion(e.value)
                        : e.value
                    }`
                  );
                }}
                options={filtered_status()}
                // defaultValue={[
                //   {
                //     label: filtered_status().filter(
                //       (el) => el.value === element.status_id
                //     )[0]?.label,
                //     value: element.status_id,
                //   },
                // ]}
              />
              {filtered_status().length ? (
                <div className="flex justify-end">
                  <Button onClick={request}>{t("save")}</Button>
                </div>
              ) : (
                <div className="p-2 rounded-lg bg-red-200 text-red-600 text-sm">
                  Запрос{" "}
                  {element.status_id === "2a98c22e-cbee-49f9-90ef-f56429d96333"
                    ? "отменено"
                    : "завершен"}
                  , изменения статуса недоступно
                </div>
              )}
            </div>
          </Fade>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
}
