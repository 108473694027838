import Dashboard from "../views/dashboard/index.jsx";
// import TeamsList from "../views/teams/teamsList/teamsList.jsx";
// import TeamsCreate from "../views/teams/teamsCreate/teamCreate.jsx"
// import researcherCreateItemCompany from "../views/researchers/researcherCreateItemCompany"
// import setting from "../views/settings/setting"
import Compnay from "../views/company/index";
import Respondent from "../views/respondent/Index";
import Attributs from "../views/attributs/Index";
import AttributsCreate from "../views/attributs/Create";
import CompanyInquiryList from "../views/company/InquiryList.jsx";
import CompanyInquiryCreateList from "../views/company/InquiryCreateList";
import InquirysItemList from "../views/Inquirys/InquirysItemList";
import testCoding from "../views/testCoding/testCoding";
import CompnaycreateList from "../views/company/companyCreateList";
import ResearcherItemCompany from "../views/researchers/researcherCreateItemCompany";
import ResearcherUpdate from "../views/researchers/researcherUpdateList";
import ReportsList from "../views/reports/reportsList";
import settingList from "../views/settings/settingList";
import GlobalVariables from "../views/global_variables/globalVariabList";
import GlobalVariablesUpdate from "../views/global_variables/globalVariablesUpdate";
import CompanyInquiryListItem from "../views/company/companresearcherListItem";
import ResultQuestion from "../components/ResultQuestion/ResultQuestion";
import Researchers from "../views/researchers";
import ResearchersCreate from "../views/researchers/Create";
import RespondentList from "../views/respondent/Respondent.jsx";
import TransactionTable from "../views/transaction/TransactionTable.jsx";
import CalendarBooked from "../views/company/Calendar/CalendarBooked.jsx";
import PermissionRoll from "../views/permission/PermissionRoll.jsx";
import TablePermission from "../views/permission/TablePermission.jsx";
import AdminPanel from "../views/AdminPanel/AdminPanel.jsx";
import AdminPanelTable from "../views/AdminPanel/AdminPanelTable.jsx";
import ResearcherPermission from "../views/permission/ResearcherPermission";
import MainAgreement from "../views/content/index.js";
import SingleAgreement from "../views/content/single/index.js";
import UpdateAgreement from "../views/content/updateAgreement/index.js";
import Agreement from "../views/content/Content.js";
import AnswerItem from "../components/TolokaInfoTable/AnswerItem.jsx";

export default [
  {
    component: Dashboard,
    path: "/dashboard",
    exact: true,
    title: "Dashboard",
    permission: "Dashboard",
    children: [],
  },
  {
    component: Compnay,
    path: "/company",
    exact: true,
    title: "company",
    icon: "",
    showSidepanel: true,
    permission: "Компании",
    children: [],
  },
  {
    component: CompnaycreateList,
    path: "/company/create",
    exact: true,
    title: "companycreate",
    icon: "",
    showSidepanel: true,
    permission: "Компании",
    children: [],
  },
  {
    component: CompanyInquiryCreateList,
    path: "/company/companyinquirycreate/:company_id",
    exact: true,
    title: "companyinquirycreate",
    icon: "",
    showSidepanel: true,
    permission: "Компании",
    children: [],
  },
  {
    component: ReportsList,
    path: "/report",
    exact: true,
    title: "report",
    icon: "",
    showSidepanel: true,
    permission: "Отчеты",
    children: [],
  },
  {
    component: CompanyInquiryCreateList,
    path: "/company/companyinquirycreate/:company_id/edit/:inquery_id/:activestep",
    exact: true,
    title: "company inquery edit",
    icon: "",
    showSidepanel: true,
    permission: "Компании",
    children: [],
  },
  {
    component: CompanyInquiryList,
    path: "/company/companyinquiry/:id",
    exact: true,
    title: "inquiry",
    icon: "",
    showSidepanel: true,
    permission: "Компании",
    children: [],
  },
  {
    component: CompanyInquiryListItem,
    path: "/company/inquiryitem/:id/:inquiry_id",
    exact: true,
    title: "inquiry",
    icon: "",
    showSidepanel: true,
    permission: "Компании",
    children: [],
  },
  {
    component: AnswerItem,
    path: "/company/answers/:user_id/:pool_id/:index",
    exact: true,
    title: "inquiry",
    icon: "",
    showSidepanel: true,
    permission: "Компании",
    children: [],
  },
  {
    component: ResultQuestion,
    path: "/question/:survey_id/:respondent_id",
    exact: true,
    title: "question",
    icon: "",
    permission: "Компании",
    children: [],
  },
  {
    component: Researchers,
    path: "/researchers",
    exact: true,
    title: "Researchers",
    permission: "Исследователи",
    children: [],
  },
  {
    component: ResearchersCreate,
    path: "/researchers/create",
    exact: true,
    title: "ResearchersCreate",
    permission: "Исследователи",
    children: [],
  },
  {
    component: ResearchersCreate,
    path: "/researchers/:id",
    exact: true,
    title: "ResearchersEdit",
    permission: "Исследователи",
    children: [],
  },
  {
    component: ResearcherItemCompany,
    path: "/company/researcher/create/:list_id",
    exact: true,
    title: "researcher",
    icon: "",
    showSidepanel: true,
    permission: "Компании",
    children: [],
  },
  {
    component: ResearcherUpdate,
    path: "/company/researcher/update/:comp_id/:id",
    exact: true,
    title: "researcher",
    icon: "",
    showSidepanel: true,
    permission: "Компании",
    children: [],
  },
  {
    component: Respondent,
    path: "/respondents",
    exact: true,
    title: "respondents",
    icon: "",
    showSidepanel: true,
    permission: "Респонденты",
    children: [],
  },
  {
    component: RespondentList,
    path: "/respondents/update/:id",
    exact: true,
    title: "respondents",
    icon: "",
    showSidepanel: true,
    permission: "Респонденты",
    children: [],
  },
  {
    component: InquirysItemList,
    path: "/inquiries",
    exact: true,
    title: "InquirysItemList",
    icon: "",
    showSidepanel: true,
    permission: "Запросы",
    children: [],
  },
  {
    component: testCoding,
    path: "/table-ui",
    exact: true,
    title: "testCoding",
    icon: "",
    showSidepanel: true,
    permission: "Компании",
    children: [],
  },
  {
    component: Attributs,
    path: "/settings/attribut",
    exact: true,
    title: "attributs",
    icon: "",
    showSidepanel: true,
    permission: "Атрибуты",
    children: [],
  },
  {
    component: AttributsCreate,
    path: "/settings/attribut/create",
    exact: true,
    title: "attributscreate",
    icon: "",
    showSidepanel: true,
    permission: "Атрибуты",
    children: [],
  },
  {
    component: AttributsCreate,
    path: "/settings/attribut/update/:id",
    exact: true,
    title: "attributsupdate",
    icon: "",
    showSidepanel: true,
    permission: "Атрибуты",
    children: [],
  },
  {
    component: GlobalVariables,
    path: "/settings/global",
    exact: true,
    title: "GlobalVariables",
    icon: "",
    showSidepanel: true,
    permission: "Переменные",
    children: [],
  },
  {
    component: GlobalVariablesUpdate,
    path: "/settings/global/update",
    exact: true,
    title: "GlobalVariablesupdate",
    icon: "",
    showSidepanel: true,
    permission: "Переменные",
    children: [],
  },
  {
    component: PermissionRoll,
    path: "/settings/role",
    exact: true,
    title: "Permission Roll",
    icon: "",
    showSidepanel: true,
    permission: "Роли",
    children: [],
  },
  {
    component: TablePermission,
    path: "/settings/role/update/:id",
    exact: true,
    title: "Permission Roll",
    icon: "",
    showSidepanel: true,
    permission: "Роли",
    children: [],
  },
  {
    component: ResearcherPermission,
    path: "/researcher/role/update/:id",
    exact: true,
    title: "Permission Roll",
    icon: "",
    showSidepanel: true,
    permission: "Роли",
    children: [],
  },
  {
    component: TablePermission,
    path: "/settings/role/create",
    exact: true,
    title: "Permission Roll",
    icon: "",
    showSidepanel: true,
    permission: "Роли",
    children: [],
  },
  {
    component: ResearcherPermission,
    path: "/researcher/role/create",
    exact: true,
    title: "Permission Roll",
    icon: "",
    showSidepanel: true,
    permission: "Роли",
    children: [],
  },
  {
    component: settingList,
    path: "/settings/setting",
    exact: true,
    title: "settings",
    icon: "",
    showSidepanel: true,
    permission: "Настройки",
    children: [],
  },
  {
    component: TransactionTable,
    path: "/transactions",
    exact: true,
    title: "transactions",
    icon: "",
    showSidepanel: true,
    permission: "Транзакции",
    children: [],
  },
  {
    component: MainAgreement,
    path: "/agreement",
    exact: true,
    title: "agreement",
    icon: "",
    showSidepanel: true,
    permission: "Соглашения",
    children: [],
  },
  {
    component: Agreement,
    path: "/agreement/:id",
    exact: true,
    title: "agreement",
    icon: "",
    showSidepanel: true,
    permission: "Соглашения",
    children: [],
  },
  {
    component: SingleAgreement,
    path: "/single_agreement",
    exact: true,
    title: "agreement",
    icon: "",
    showSidepanel: true,
    permission: "Соглашения",
    children: [],
  },
  {
    component: UpdateAgreement,
    path: "/update/agreement/:id",
    exact: true,
    title: "agreement",
    icon: "",
    showSidepanel: true,
    permission: "Соглашения",
    children: [],
  },
  {
    component: CalendarBooked,
    path: "/calendar/table/:id/:inquiry_id",
    exact: true,
    title: "Calendar Table",
    icon: "",
    showSidepanel: true,
    permission: "Компании",
    children: [],
  },
  {
    component: AdminPanelTable,
    path: "/settings/admin",
    exact: true,
    title: "Admin panel",
    icon: "",
    showSidepanel: true,
    permission: "Админ",
    children: [],
  },
  {
    component: AdminPanel,
    path: "/settings/admin/create",
    exact: true,
    title: "Admin panel",
    icon: "",
    showSidepanel: true,
    permission: "Админ",
    children: [],
  },
  {
    component: AdminPanel,
    path: "/settings/admin/update/:id",
    exact: true,
    title: "Admin panel",
    icon: "",
    showSidepanel: true,
    permission: "Админ",
    children: [],
  },
].map((route) => ({
  ...route,
  path: `/home${route.path}`,
  id: new Date().getTime() + Math.random(),
}));
