import React, { useEffect, useState, useMemo } from 'react'
import './index.scss'
import TableComp from '../../components/TableComp/index'
import axios from '../../utils/axios'
import {
  NumberOfCompanies,
  NumberOfInquiries,
  NumberOfResearches,
  NumberOfRespondents,
  Balance,
} from '../../components/svg/Dashboard'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import AnimatedNumber from 'react-animated-number'

const tableData = [
  {
    counts: 5560,
    delivery: 4600,
    del: 6065,
    somovyvoz: 1577,
    agregators: 1374,
  },
  {
    counts: 5045,
    delivery: 3536,
    del: 8829,
    somovyvoz: 8811,
    agregators: 8861,
  },
  {
    counts: 5560,
    delivery: 4600,
    del: 6065,
    somovyvoz: 1577,
    agregators: 1374,
  },
  {
    counts: 5045,
    delivery: 3536,
    del: 8829,
    somovyvoz: 8811,
    agregators: 8861,
  },
  {
    counts: 5560,
    delivery: 4600,
    del: 6065,
    somovyvoz: 1577,
    agregators: 1374,
  },
  {
    counts: 5045,
    delivery: 3536,
    del: 8829,
    somovyvoz: 8811,
    agregators: 8861,
  },
]

export default function Dashboard() {
  const [list, setList] = useState()
  const { t } = useTranslation()

  useEffect(() => {
    axios
      .get('/dashboard')
      .then((res) => {
        setList(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  const data = useMemo(() => {
    return [
      {
        count: list?.number_of_companies,
        name: t('Number of companies'),
        icon: <NumberOfCompanies />,
      },
      {
        count: list?.number_of_researchers,
        name: t('Number of researchers'),
        icon: <NumberOfResearches />,
      },
      {
        count: list?.number_of_inquiries,
        name: t('Number of inquiries'),
        icon: <NumberOfInquiries />,
      },
      {
        count: list?.number_of_respondents,
        name: t('Number of respondents'),
        icon: <NumberOfRespondents />,
      },
      {
        count: list?.balance_companies.toFixed(0),
        name: t('Balance'),
        icon: <Balance />,
      },
    ]
  }, [list])
  return (
    <div className='p-5 font-body space-y-4'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          gap: '20px',
          marginBottom: '20px',
        }}
      >
        {/* <div className='p-6'>
          <Widgets data={data} />
        </div> */}
        {data.map((el, index) => (
          <div key={index} className='card'>
            <div className='left'>
              <Typography className='count'>
                <AnimatedNumber
                  component='text'
                  value={el.count}
                  style={{
                    transition: 'linear',
                    fontSize: 24,
                    transitionProperty: 'background-color, color, opacity',
                  }}
                  // frameStyle={(perc) =>
                  //   perc === 100 ? {} : { backgroundColor: "#ffeb3b" }
                  // }
                  duration={500}
                  formatValue={(n) => Math.round(n)}
                />
                {/* {el.count == undefined ? 0 : el.count} */}
              </Typography>
              <Typography className='name'>{el.name}</Typography>
            </div>
            <div className='right'>
              <div className='icon'>{el.icon}</div>
            </div>
          </div>
        ))}
      </div>
      <div className='flex gap-x-4'>
        <div className='w-1/4 flex flex-col bg-white shadow-lg h-42 justify-start rounded-md p-8'>
          <span>Total orders this month</span>
          <span className='text-4xl font-bold pt-3'>1,850</span>
          <div>
            <svg
              width='330'
              height='100'
              viewBox='0 0 330 100'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M18.6664 60.6667C9.08093 60.6667 0 81.1417 0 81.1417V91H230.176V0C219.078 0 224.627 40.5708 214.663 40.5708C204.699 40.5708 209.114 20.6014 198.141 20.6014C185.402 20.6014 186.159 71.157 173.547 71.157C159.673 71.157 162.952 40.5708 152.736 40.5708C142.52 40.5708 147.187 60.0347 137.349 60.0347C127.511 60.0347 123.349 30.2069 114.647 30.2069C108.214 30.2069 110.359 40.5708 104.557 40.5708C98.7552 40.5708 100.647 20.6014 88.413 20.6014C76.179 20.6014 74.6655 50.9347 67.9809 50.9347C61.2963 50.9347 64.071 40.5708 53.2244 40.5708C42.3777 40.5708 40.8642 71.157 34.4319 71.157C27.9995 71.157 28.2518 60.6667 18.6664 60.6667Z'
                fill='url(#paint0_linear)'
              />
              <defs>
                <linearGradient
                  id='paint0_linear'
                  x1='115.088'
                  y1='0'
                  x2='115.088'
                  y2='91'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stop-color='#F8C51B' stop-opacity='0.8' />
                  <stop offset='1' stop-color='#F8DD4E' stop-opacity='0.1' />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className='w-1/2 flex items-center bg-white shadow-lg space-x-4 h-42 justify-center rounded-md p-8'>
          <svg
            width='161'
            height='160'
            viewBox='0 0 161 160'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M160.75 80C160.75 124.183 124.933 160 80.75 160C36.5672 160 0.75 124.183 0.75 80C0.75 35.8172 36.5672 0 80.75 0C124.933 0 160.75 35.8172 160.75 80ZM20.75 80C20.75 113.137 47.6129 140 80.75 140C113.887 140 140.75 113.137 140.75 80C140.75 46.8629 113.887 20 80.75 20C47.6129 20 20.75 46.8629 20.75 80Z'
              fill='#1AC19D'
              fill-opacity='0.1'
            />
            <path
              d='M150.5 80C156.023 80 160.564 84.4944 159.876 89.9742C158.463 101.218 154.672 112.073 148.711 121.8C141.002 134.38 129.965 144.582 116.819 151.281C103.674 157.979 88.9316 160.911 74.2233 159.753C59.515 158.596 45.4133 153.393 33.4772 144.721C21.5411 136.049 12.2357 124.245 6.58965 110.615C0.943614 96.984 -0.823067 82.0574 1.48493 67.4853C3.79292 52.9131 10.0857 39.2631 19.6675 28.0442C27.0764 19.3694 36.2287 12.4095 46.4855 7.59131C51.4842 5.2431 57.1621 8.17352 58.8688 13.4261C60.5754 18.6786 57.6457 24.2489 52.7491 26.8033C45.9621 30.3439 39.8871 35.1654 34.8756 41.0331C27.6893 49.4473 22.9697 59.6849 21.2387 70.614C19.5077 81.5431 20.8327 92.738 25.0672 102.961C29.3018 113.184 36.2808 122.037 45.2329 128.541C54.1849 135.045 64.7612 138.947 75.7925 139.815C86.8237 140.683 97.8801 138.484 107.739 133.46C117.599 128.437 125.877 120.785 131.658 111.35C135.69 104.77 138.399 97.5029 139.669 89.9539C140.585 84.5076 144.977 80 150.5 80Z'
              fill='#1AC19D'
            />
            <path
              d='M57.6051 89H61.4148L68.6591 74.6051V71.5455H56.4119V74.5625H64.8409V74.6818L57.6051 89ZM77.3288 89.2386C81.2152 89.2386 83.8572 86.7074 83.8487 83.179C83.8572 79.8381 81.522 77.4347 78.3771 77.4347C76.843 77.4347 75.522 78.0739 74.9084 78.9347H74.8061L75.2578 74.5625H82.8857V71.5455H72.2152L71.3885 80.75L74.6697 81.3381C75.2067 80.6136 76.2464 80.1619 77.2777 80.1619C79.0334 80.1705 80.2947 81.4403 80.2947 83.2727C80.2947 85.0881 79.0589 86.3409 77.3288 86.3409C75.8629 86.3409 74.6783 85.4205 74.593 84.0653H71.0135C71.0817 87.0824 73.7152 89.2386 77.3288 89.2386ZM95.9766 85.7273C95.9851 87.8239 97.3232 89.6051 99.8203 89.6051C102.292 89.6051 103.673 87.8239 103.664 85.7273V84.8068C103.673 82.6932 102.326 80.929 99.8203 80.929C97.3572 80.929 95.9851 82.7102 95.9766 84.8068V85.7273ZM87.0192 75.7386C87.0277 77.8352 88.3658 79.5994 90.8544 79.5994C93.3345 79.5994 94.7152 77.8523 94.7067 75.7386V74.8182C94.7152 72.7045 93.3686 70.9403 90.8544 70.9403C88.4169 70.9403 87.0277 72.7045 87.0192 74.8182V75.7386ZM87.8544 89H90.0788L102.079 71.5455H99.8544L87.8544 89ZM98.4567 84.8068C98.4652 83.9716 98.7976 83.1108 99.8203 83.1108C100.894 83.1108 101.175 83.9716 101.167 84.8068V85.7273C101.175 86.5625 100.86 87.4062 99.8203 87.4062C98.7805 87.4062 98.4652 86.5369 98.4567 85.7273V84.8068ZM89.5163 74.8182C89.5249 74.0085 89.8402 73.1222 90.8544 73.1222C91.9283 73.1222 92.218 73.983 92.2095 74.8182V75.7386C92.218 76.5739 91.8942 77.4176 90.8544 77.4176C89.8487 77.4176 89.5249 76.5739 89.5163 75.7386V74.8182Z'
              fill='#1AC19D'
            />
          </svg>
          <span className='flex flex-col space-y-2'>
            <span>Top Order</span>
            <span className='text-5xl font-bold' style={{ color: '#1AC19D' }}>
              75%
            </span>
          </span>
        </div>
        <div className='w-1/2 flex items-center bg-white shadow-lg space-x-4 h-42 justify-center rounded-md p-8'>
          <svg
            width='161'
            height='160'
            viewBox='0 0 161 160'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M160.75 80C160.75 124.183 124.933 160 80.75 160C36.5672 160 0.75 124.183 0.75 80C0.75 35.8172 36.5672 0 80.75 0C124.933 0 160.75 35.8172 160.75 80ZM20.75 80C20.75 113.137 47.6129 140 80.75 140C113.887 140 140.75 113.137 140.75 80C140.75 46.8629 113.887 20 80.75 20C47.6129 20 20.75 46.8629 20.75 80Z'
              fill='#A23FEE'
              fill-opacity='0.1'
            />
            <path
              d='M45.5 140.622C42.7386 145.405 36.5754 147.091 32.1741 143.754C21.464 135.636 12.9262 124.914 7.41636 112.539C0.544818 97.1052 -1.26434 79.8922 2.24819 63.3671C5.76072 46.8419 14.4146 31.8529 26.9695 20.5484C37.0365 11.4841 49.1972 5.16177 62.2833 2.10157C67.661 0.84398 72.6057 4.89088 73.183 10.3835C73.7603 15.8761 69.7364 20.7155 64.4157 22.196C55.5241 24.6702 47.2776 29.1756 40.3522 35.4113C30.9359 43.8897 24.4455 55.1314 21.8111 67.5253C19.1767 79.9192 20.5336 92.8289 25.6873 104.404C29.4777 112.918 35.1788 120.388 42.2953 126.265C46.5538 129.781 48.2614 135.839 45.5 140.622Z'
              fill='#A23FEE'
            />
            <path
              d='M56.6506 85.9318H65.0284V89H68.5568V85.9318H70.7216V82.9744H68.5568V71.5455H63.9375L56.6506 83.0256V85.9318ZM65.0966 82.9744H60.3665V82.8381L64.9602 75.5682H65.0966V82.9744ZM80.0241 89.3835C84.4134 89.3835 87.0639 86.0426 87.0724 80.2898C87.081 74.5795 84.3963 71.3068 80.0241 71.3068C75.6435 71.3068 72.9844 74.571 72.9759 80.2898C72.9588 86.0256 75.6264 89.375 80.0241 89.3835ZM80.0241 86.3239C78.0213 86.3239 76.7259 84.3125 76.7344 80.2898C76.7429 76.3267 78.0298 74.3324 80.0241 74.3324C82.0099 74.3324 83.3054 76.3267 83.3054 80.2898C83.3139 84.3125 82.0185 86.3239 80.0241 86.3239ZM99.2578 85.7273C99.2663 87.8239 100.604 89.6051 103.102 89.6051C105.573 89.6051 106.954 87.8239 106.945 85.7273V84.8068C106.954 82.6932 105.607 80.929 103.102 80.929C100.638 80.929 99.2663 82.7102 99.2578 84.8068V85.7273ZM90.3004 75.7386C90.3089 77.8352 91.647 79.5994 94.1357 79.5994C96.6158 79.5994 97.9964 77.8523 97.9879 75.7386V74.8182C97.9964 72.7045 96.6499 70.9403 94.1357 70.9403C91.6982 70.9403 90.3089 72.7045 90.3004 74.8182V75.7386ZM91.1357 89H93.3601L105.36 71.5455H103.136L91.1357 89ZM101.738 84.8068C101.746 83.9716 102.079 83.1108 103.102 83.1108C104.175 83.1108 104.457 83.9716 104.448 84.8068V85.7273C104.457 86.5625 104.141 87.4062 103.102 87.4062C102.062 87.4062 101.746 86.5369 101.738 85.7273V84.8068ZM92.7976 74.8182C92.8061 74.0085 93.1214 73.1222 94.1357 73.1222C95.2095 73.1222 95.4993 73.983 95.4908 74.8182V75.7386C95.4993 76.5739 95.1754 77.4176 94.1357 77.4176C93.13 77.4176 92.8061 76.5739 92.7976 75.7386V74.8182Z'
              fill='#A23FEE'
            />
          </svg>

          <span className='flex flex-col space-y-2'>
            <span>Average order</span>
            <span className='text-5xl font-bold' style={{ color: '#A23FEE' }}>
              40%
            </span>
          </span>
        </div>
      </div>
      <div className='grid grid-rows-2 grid-cols-3 grid-flow-col gap-4'>
        <div className='row-span-1 flex flex-col bg-white shadow-lg justify-center rounded-md p-8'>
          <span>Earnings this month</span>
          <span className='text-4xl font-bold pt-3'>$6,250</span>
          <div>
            <svg
              width='232'
              height='92'
              viewBox='0 0 232 92'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 82.6324C1 82.6324 10.0809 62.0335 19.6664 62.0335C29.2518 62.0335 28.9995 72.5872 35.4319 72.5872C41.8642 72.5872 43.3777 41.8162 54.2244 41.8162C65.071 41.8162 62.2963 52.2427 68.9809 52.2427C75.6655 52.2427 77.179 21.726 89.413 21.726C101.647 21.726 99.7552 41.8162 105.557 41.8162C111.359 41.8162 109.214 31.3896 115.647 31.3896C124.349 31.3896 128.511 61.3978 138.349 61.3978C148.187 61.3978 143.52 41.8162 153.736 41.8162C163.952 41.8162 160.673 72.5872 174.547 72.5872C187.159 72.5872 186.402 21.726 199.141 21.726C210.114 21.726 205.699 41.8162 215.663 41.8162C225.627 41.8162 220.078 1 231.176 1'
                stroke='#A23FEE'
                stroke-width='2'
              />
              <path
                d='M19.6664 61.6667C10.0809 61.6667 1 82.1417 1 82.1417V92H231.176V1C220.078 1 225.627 41.5708 215.663 41.5708C205.699 41.5708 210.114 21.6014 199.141 21.6014C186.402 21.6014 187.159 72.157 174.547 72.157C160.673 72.157 163.952 41.5708 153.736 41.5708C143.52 41.5708 148.187 61.0347 138.349 61.0347C128.511 61.0347 124.349 31.2069 115.647 31.2069C109.214 31.2069 111.359 41.5708 105.557 41.5708C99.7552 41.5708 101.647 21.6014 89.413 21.6014C77.179 21.6014 75.6655 51.9347 68.9809 51.9347C62.2963 51.9347 65.071 41.5708 54.2244 41.5708C43.3777 41.5708 41.8642 72.157 35.4319 72.157C28.9995 72.157 29.2518 61.6667 19.6664 61.6667Z'
                fill='url(#paint0_linear)'
              />
              <defs>
                <linearGradient
                  id='paint0_linear'
                  x1='116.088'
                  y1='1'
                  x2='116.088'
                  y2='92'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stop-color='#A23FEE' stop-opacity='0.8' />
                  <stop offset='1' stop-color='#A23FEE' stop-opacity='0.1' />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className='row-span-1 flex flex-col bg-white shadow-lg justify-center rounded-md p-8'>
          <span>Total orders this month</span>
          <span className='text-4xl font-bold pt-3'>$12,750</span>
          <div>
            <svg
              width='232'
              height='92'
              viewBox='0 0 232 92'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 82.6324C1 82.6324 10.0809 62.0335 19.6664 62.0335C29.2518 62.0335 28.9995 72.5872 35.4319 72.5872C41.8642 72.5872 43.3777 41.8162 54.2244 41.8162C65.071 41.8162 62.2963 52.2427 68.9809 52.2427C75.6655 52.2427 77.179 21.726 89.413 21.726C101.647 21.726 99.7552 41.8162 105.557 41.8162C111.359 41.8162 109.214 31.3896 115.647 31.3896C124.349 31.3896 128.511 61.3978 138.349 61.3978C148.187 61.3978 143.52 41.8162 153.736 41.8162C163.952 41.8162 160.673 72.5872 174.547 72.5872C187.159 72.5872 186.402 21.726 199.141 21.726C210.114 21.726 205.699 41.8162 215.663 41.8162C225.627 41.8162 220.078 1 231.176 1'
                stroke='#F2271C'
                stroke-width='2'
              />
              <path
                d='M19.6664 61.6667C10.0809 61.6667 1 82.1417 1 82.1417V92H231.176V1C220.078 1 225.627 41.5708 215.663 41.5708C205.699 41.5708 210.114 21.6014 199.141 21.6014C186.402 21.6014 187.159 72.157 174.547 72.157C160.673 72.157 163.952 41.5708 153.736 41.5708C143.52 41.5708 148.187 61.0347 138.349 61.0347C128.511 61.0347 124.349 31.2069 115.647 31.2069C109.214 31.2069 111.359 41.5708 105.557 41.5708C99.7552 41.5708 101.647 21.6014 89.413 21.6014C77.179 21.6014 75.6655 51.9347 68.9809 51.9347C62.2963 51.9347 65.071 41.5708 54.2244 41.5708C43.3777 41.5708 41.8642 72.157 35.4319 72.157C28.9995 72.157 29.2518 61.6667 19.6664 61.6667Z'
                fill='url(#paint0_linear)'
              />
              <defs>
                <linearGradient
                  id='paint0_linear'
                  x1='116.088'
                  y1='1'
                  x2='116.088'
                  y2='92'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stop-color='#F2271C' stop-opacity='0.8' />
                  <stop offset='1' stop-color='#F2271C' stop-opacity='0.1' />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className='row-span-2 col-span-2 flex flex-col bg-white shadow-lg justify-center rounded-md p-8'>
          <span>Total orders this month</span>
          <span className='text-4xl font-bold pt-3'>1,850</span>
          <div></div>
        </div>
      </div>
      <TableComp
        title='Общий отчет'
        headings={['Число', 'Доставка', 'Доставка', 'Самовывоз', 'Агрегаторы']}
        sourceData={tableData}
        checkboxSelection
      />
      <div></div>
    </div>
  )
}
