import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ClearIcon from "@material-ui/icons/Clear";
import {CircularProgress, Typography} from "@material-ui/core";
import {StyledTableCell} from "../../components/DataTable/index";
import {StyledPropMenu} from "../../components/custom/StyledPropMenu";
import Pagination from "../../components/Pagination";
import Tag from "../../components/Tag";
import axios from "../../utils/axios";
import dateTimeConvert from "../../functions/dateTimeConvert";
import EmptyState from "../../components/EmptyState";
import {pageActionsTypes} from "../../redux/actions/currentPage/pageActionTypes";
import {useDispatch} from "react-redux";
import "antd/dist/antd.css";

const statuses = {
  "72ee7350-4a2b-4fc2-945c-34faa56b13ca": "draft",
  "7b75685f-ee20-4fb9-bc5f-7e40c115e708": "active",
  "2e857fbe-9add-4eae-a8c4-fe57fb384347": "finished",
  "2a98c22e-cbee-49f9-90ef-f56429d96333": "canceled",
};

const Inquiries = ({currentPage, company = false}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState();
  const [companies, setCompanies] = useState();
  const [dropList, setDropList] = useState({target: null, id: ""});

  const pageCount = 10;
  const [pageLimit, setPageLimit] = useState(10);

  useEffect(() => {
    getItems({researcher_id: `${company ? params.comp_id : params.id}`});
    getCompanies();
  }, []);

  //   ******* FUNCTIONS ********
  const getItems = (params = {}) => {
    setIsLoading(true);

    axios.get("/inquiry", {params}).then((res) => {
      setItems(res);
      setIsLoading(false);
    });
  };

  const handleCutomRow = (researcherId) => {
    history.push(
      `${"/home/company/inquiryitem"}/${
        company ? params.comp_id : params.id
      }/${researcherId}`
    );
  };

  const getCompanies = (params = {}) => {
    axios.get("/company", {params}).then((res) => {
      setCompanies(res.data.companies);
    });
  };

  const getCompanyById = (companyId) => {
    for (let i = 0; i < companies?.length; i++) {
      if (companies[i].id === companyId) return companies[i].name;
    }
  };

  // ****** EVENTS *******
  const handleMoreClick = (id, type) => {
    if (type === "edit") {
      console.log("edit");
      setDropList((old) => {
        return {...old, target: null};
      });
    }
    if (type === "delete") {
      console.log("delete");
      setDropList((old) => {
        return {...old, target: null};
      });
    }
  };

  const onRowClick = (id) => {
    history.push(`/home/researchers/${id}`);
  };

  // ******* CONSTANTS *******
  const listRender = [
    {
      title: t("delete"),
      icon: <ClearIcon className="bg-red-100 rounded text-red-600 p-0.5" />,
      type: "delete",
    },
  ];
  return (
    <div style={{borderRadius: "20px"}}>
      <TableContainer
        elevation={0}
        component={Paper}
        className="shadow-none rounded-2xl p-5"
      >
        <Table
          elevation={0}
          aria-label="customized table"
          style={{borderRadius: "20px"}}
        >
          <TableHead>
            <TableRow className="text-black font-body">
              <StyledTableCell>№</StyledTableCell>
              <StyledTableCell>{t("from.date")}</StyledTableCell>
              <StyledTableCell>{t("to.date")}</StyledTableCell>
              <StyledTableCell>{t("title")}</StyledTableCell>
              <StyledTableCell>{t("description")}</StyledTableCell>
              <StyledTableCell>{t("status")}</StyledTableCell>
              <StyledTableCell>{""}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.data ? (
              items?.data?.inquiries?.map(
                (
                  {id, from_date, to_date, title, description, status_id},
                  index
                ) => (
                  <TableRow
                    className="text-black font-body hover:bg-gray-100 cursor-pointer"
                    key={index}
                    onClick={() => {
                      handleCutomRow(id);
                    }}
                  >
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>
                      {dateTimeConvert(from_date)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {dateTimeConvert(to_date)}
                    </StyledTableCell>
                    <StyledTableCell>{title}</StyledTableCell>
                    <StyledTableCell>
                      <Typography id="text_limit">{description}</Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Tag shape="subtle" size="large" style={{width: "150px"}}>
                        <div>{t(statuses[status_id])}</div>
                      </Tag>
                    </StyledTableCell>

                    <StyledTableCell
                      className="w-2   border-gray-300"
                      style={{borderLeft: "1px solid gainsboro"}}
                    >
                      <MoreHorizIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          setDropList({
                            target: e.currentTarget,
                            id: id,
                          });
                        }}
                        className="cursor-pointer text-blue-500"
                        style={{
                          border: "1px solid gainsboro",
                          borderRadius: "5px",
                        }}
                      />

                      <StyledPropMenu
                        anchorEl={dropList.target}
                        open={Boolean(dropList.target)}
                        onClose={(e) => {
                          e.stopPropagation();
                          setDropList((old) => {
                            return {...old, target: null};
                          });
                        }}
                      >
                        <div className="flex  flex-col divide-y font-body text-sm">
                          {listRender.map((el, index) => (
                            <div
                              key={index}
                              className="flex w-full cursor-pointer py-2 mx-2"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMoreClick(id, el.type);
                              }}
                            >
                              <div className="rounded">{el.icon}</div>
                              <div className="my-auto mx-2">{el.title}</div>
                            </div>
                          ))}
                        </div>
                      </StyledPropMenu>
                    </StyledTableCell>
                  </TableRow>
                )
              )
            ) : (
              <> </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <EmptyState isActive={!isLoading && !items?.data.inquiries?.length} />
      {isLoading && (
        <div className="flex justify-center py-2">
          <CircularProgress />
        </div>
      )}
      <Pagination
        className="mt-5 text-black-700"
        size={"large"}
        count={items?.count}
        pageCount={pageCount}
        currentPage={
          currentPage.currentPage === undefined
            ? 1
            : currentPage.currentPage.page
        }
        onChange={(val) => {
          dispatch({
            type: pageActionsTypes.CHILD_CURRENT_PAGE,
            payload: {
              page: parseInt(val / 10),
            },
          });
          setPageLimit(val);
        }}
      />
    </div>
  );
};

export default Inquiries;
