import React, {Component, useEffect} from "react";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import {Button} from "@material-ui/core";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import cls from "./index.module.scss";
import config from "../../config/defaultSettings";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {toast} from "react-toastify";
import axios from "../../utils/axios";

class Agreement extends Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  state = {
    editorState: EditorState.createEmpty(),
    content: "",
  };
  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
  };
  getContent() {
    axios
      .get(`content/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({
          editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(res.data.body).contentBlocks,
              htmlToDraft(res.data.body).entityMap
            )
          ),
          content: res.data.title,
        });
      })
      .catch((err) => console.log(err));
  }
  componentDidMount() {
    this.getContent();
  }
  render() {
    const {editorState, content} = this.state;
    const contentRegistration = () => {
      axios
        .put(`content/${this.props.match.params.id}`, {
          title: content,
          type: "content",
          body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        })
        .then((res) => {
          console.log(res);
          toast.success("Успешно загружено", {
            className: "bg-green-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
          this.props.history.goBack();
        })
        .catch((err) => console.log(err));
    };

    function uploadImageCallBack(file) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", `${config.baseURL}upload/file`);
        xhr.setRequestHeader(
          `Authorization`,
          `Bearer ${localStorage.getItem("token")}`
        );
        xhr.setRequestHeader(
          `platform-id`,
          "7d4a4c38-dd84-4902-b744-0488b80a4c06"
        );
        const data = new FormData();
        data.append("file", file);
        xhr.send(data);
        xhr.addEventListener("load", () => {
          const response = JSON.parse(xhr.responseText);
          console.log(response);
          resolve(response);
        });
        xhr.addEventListener("error", () => {
          const error = JSON.parse(xhr.responseText);
          console.log(error);
          reject(error);
        });
      });
    }
    return (
      <div className={cls.root}>
        <div className={cls.row} id="editor">
          <div className={cls.body}>
            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={this.onEditorStateChange}
              toolbar={{
                image: {
                  uploadCallback: uploadImageCallBack,
                  previewImage: true,
                  inputAccept:
                    "application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,application/.jpg,.png,.jpeg",
                  alt: {
                    present: true,
                    mandatory: false,
                  },
                },
              }}
            />
            <div className={cls.btnGroup}>
              <Button onClick={contentRegistration} className={cls.btn}>
                Сохранить
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Agreement;
