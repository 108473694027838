import axios from '../../utils/axios'
import FileItem from './FileItem'

export default function FilesList({
  files = [],
  setFiles,
  placeholder,
  addUploadedFile,
  setMultipleUrl,
}) {
  // **** FUNCTIONS ****
  const onRemoveClick = (index) => {
    setFiles((old) => old.filter((elm, i) => i !== index))
    setMultipleUrl((old) => old.filter((elm, i) => i !== index))
  }
  return files.length ? (
    <div className='files-list'>
      {files.map((el, i) => (
        <FileItem
          key={i}
          index={i}
          element={el}
          placeholder={placeholder}
          onRemoveClick={onRemoveClick}
          files={files}
          addUploadedFile={addUploadedFile}
        />
      ))}
    </div>
  ) : (
    <></>
  )
}
