export const DollarIcon = ({ arg }) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10 0C4.47768 0 0 4.47768 0 10C0 15.5223 4.47768 20 10 20C15.5223 20 20 15.5223 20 10C20 4.47768 15.5223 0 10 0ZM10 18.3036C5.41518 18.3036 1.69643 14.5848 1.69643 10C1.69643 5.41518 5.41518 1.69643 10 1.69643C14.5848 1.69643 18.3036 5.41518 18.3036 10C18.3036 14.5848 14.5848 18.3036 10 18.3036ZM11.0647 9.48214L10.4978 9.35045V6.35268C11.346 6.46875 11.8705 7 11.9598 7.65179C11.971 7.74107 12.0469 7.8058 12.1362 7.8058H13.1384C13.2433 7.8058 13.3259 7.71429 13.317 7.60938C13.1808 6.21875 12.0357 5.32589 10.5067 5.17188V4.44196C10.5067 4.34375 10.4263 4.26339 10.3281 4.26339H9.70089C9.60268 4.26339 9.52232 4.34375 9.52232 4.44196V5.17857C7.94196 5.33259 6.70536 6.20536 6.70536 7.83482C6.70536 9.34375 7.81696 10.0714 8.98438 10.3504L9.53572 10.4911V13.6763C8.54911 13.5446 7.99554 13.0179 7.8817 12.308C7.8683 12.2232 7.79241 12.1607 7.70536 12.1607H6.67411C6.5692 12.1607 6.48661 12.25 6.49554 12.3549C6.59598 13.5826 7.52679 14.7121 9.51339 14.8571V15.558C9.51339 15.6562 9.59375 15.7366 9.69197 15.7366H10.3259C10.4241 15.7366 10.5045 15.6563 10.5045 15.5558L10.5 14.8482C12.2478 14.6942 13.4978 13.7589 13.4978 12.0804C13.4955 10.5313 12.5112 9.83929 11.0647 9.48214ZM9.53348 9.12054C9.40848 9.08482 9.30357 9.05134 9.19866 9.00893C8.4442 8.73661 8.09375 8.29688 8.09375 7.72991C8.09375 6.91964 8.70759 6.45759 9.53348 6.35268V9.12054ZM10.4978 13.683V10.6987C10.567 10.7188 10.6295 10.7344 10.6942 10.7478C11.75 11.0692 12.1049 11.5156 12.1049 12.2009C12.1049 13.0737 11.4487 13.5982 10.4978 13.683Z'
      fill={arg}
    />
  </svg>
)
