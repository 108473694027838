import React, { useRef, useEffect, forwardRef, useState } from 'react'
import {
  useTable,
  usePagination,
  useRowSelect,
  useColumnOrder,
} from 'react-table'
import { ArrowForward } from '@material-ui/icons'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    width: '100%',
    // borderSpacing: 0,
  },
  thead: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: '-2px',
      height: 2,
      backgroundColor: '#eaeaea',
      width: '100%',
      left: 0,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      height: 2,
      backgroundColor: '#eaeaea',
      width: '100%',
      left: 0,
    },
    '& th': {
      padding: '24px 15px',
    },
  },
  th: {
    border: '1px solid #000',
    margin: 0,
    padding: '8px 12px',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '19px',
    color: '#bdbdbd',
    textAlign: 'left',
  },
  tbody: {
    // borderBottom: `2px solid ${'#fff'}`,
  },
  tr: {
    '&:nth-child(even)': {
      backgroundColor: '#ffff',
    },
    '&:nth-child(odd)': {
      backgroundColor: '#fff',
    },
  },
  td: {
    border: '1px solid #000',
    margin: 0,
    padding: '8px 12px',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '19px',
    color: '#6f6f6f',
    // '&:last-child': {
    //   borderTopRightRadius: 16,
    //   borderBottomRightRadius: 16,
    // },
    // '&:first-child': {
    //   borderTopLeftRadius: 16,
    //   borderBottomLeftRadius: 16,
    // },
  },
  checkbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& input': {
      width: 14,
      height: 18,
    },
  },
  spacer: {
    height: '16px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingTop: 16,
    borderTop: `2px solid ${'#eaeaea'}`,
  },
  pagination: {
    flex: '0 0 30%',
    display: 'flex',
    alignItems: 'center',
  },
  arrowBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    marginRight: 4,
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '& svg': {
      fill: '#71717a',
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 40,
    height: 40,
    padding: 4,
    marginRight: 4,
    border: `1px solid transparent`,
    outline: 'none',
    backgroundColor: 'transparent',
    color: '#6f6f6f',
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 600,
    cursor: 'pointer',
    '&[disabled]': {
      cursor: 'default',
      border: `1px solid #cfcfcf`,
      borderRadius: 16,
    },
  },
  dots: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    marginRight: 4,
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    color: '#6f6f6f',
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 600,
    cursor: 'default',
  },
}))

function SelectPaginationTable({
  columns,
  data,
  pagination = true,
  pageCount: controlledPageCount,
}) {
  const classes = useStyles()
  const [visiblePages, setVisiblePages] = useState([])

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    gotoPage,
    allColumns,
    setPageSize,
    setColumnOrder,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      autoResetHiddenColumns: false,
      initialState: { pageSize: 5 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination,
    useColumnOrder,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns?.push((columns) => columns)
    }
  )

  const filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter((page) => page <= totalPages)
  }

  const getVisiblePages = (page, total) => {
    if (total < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], total)
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total]
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total]
      } else {
        return [1, 2, 3, 4, 5, total]
      }
    }
  }

  // useEffect(() => {
  //   changePage(1)
  // }, [queryParams?.values?.search])

  useEffect(() => {
    changePage(pageIndex + 1)
  }, [controlledPageCount])

  const changePage = (page) => {
    const activePage = page + 1

    if (page === activePage) {
      return
    }

    const visible = getVisiblePages(page, controlledPageCount)
    setVisiblePages(filterPages(visible, controlledPageCount))

    gotoPage(page - 1)
  }

  // Render the UI for your table
  return (
    <div className={classes.root}>
      <table {...getTableProps()} className={classes.table}>
        <thead className={classes.thead}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th className={classes.th}>Имя пользователя</th>

              {headerGroup.headers
                // ?.filter((el) => el.isActive === true && el.id !== 'selection')
                ?.map((column) => (
                  <th {...column?.getHeaderProps()} className={classes.th}>
                    {column?.render('Header')}
                  </th>
                ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className={classes.tbody}>
          {/* <tr className={classes.spacer} /> */}
          {page?.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row?.getRowProps()} className={classes.tr}>
                <td className={classes.td}>{data[0].username}</td>
                {row?.cells?.map((cell) => (
                  <td {...cell?.getCellProps()} className={classes.td}>
                    {cell?.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
          {/* <tr className={classes.spacer} /> */}
        </tbody>
      </table>
    </div>
  )
}

export default SelectPaginationTable
// {pagination && (
//   <div className={classes.footer}>
//     <div className={classes.pagination}>
//       <button
//         onClick={() => {
//           if (pageIndex === 0) return
//           // changePage(pageIndex)
//         }}
//         disabled={!canPreviousPage}
//         className={classes.arrowBtn}
//       >
//         {/* <BackArrowIcon /> */}
//       </button>
//       {visiblePages?.map((page, index, array) => (
//         <button
//           key={index}
//           // onClick={() => changePage(page)}
//           disabled={pageIndex + 1 === page}
//           className={classes.button}
//         >
//           {array[index - 1] + 2 < page ? `...${page}` : page}
//         </button>
//       ))}
//       <button
//         onClick={() => {
//           if (pageIndex === controlledPageCount) return
//           // changePage(pageIndex + 2)
//         }}
//         disabled={!canNextPage}
//         className={classes.arrowBtn}
//       >
//         <ArrowForward />
//       </button>
//     </div>
//   </div>
// )}
