import React, {useState, useEffect} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {StyledTableCell} from "../../components/DataTable/index";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import Pagination from "../../components/Pagination";
import {Typography, Tooltip} from "@material-ui/core";
import axios from "../../utils/axios";
import Tag from "../../components/Tag/index";

const ChangeItemList = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const [historyList, setHistoryList] = useState([]);
  const pageCount = 10;
  const [items, setItems] = useState({});
  const [pageLimit, setPageLimit] = useState(pageCount);
  const [searchTable, setSearchTable] = useState();
  const [date, setDate] = useState({
    from_date: "",
    to_date: "",
  });
  const [getFilter, setGetFilter] = useState({
    company: "",
    researcher: "",
  });

  const params = useParams();

  const [tableHeardName, setTableHeardName] = useState([
    t("number.icon"),
    t("Дата изменение"),
    t("Тип"),
    t("До"),
    t("После"),
  ]);

  const inquiryItemListGetAll = (id) => {
    // const param = {
    //   respondent_id: params.id,
    // };
    // Inquiry.respondentHistories(param).then((res) => {
    //   setHistoryList(res.data);
    // });
    axios
      .get(
        `/respondent-histories?respondent_id=${id}&limit=${pageCount}&page=${
          pageLimit / pageCount
        }${searchTable ? `&description=${searchTable}` : ""}`
      )
      .then((res) => {
        setHistoryList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCutomRow = (id) => {
    axios
      .get(`inquiry/${id}`)
      .then((res) => {
        history.push(
          `/home/company/inquiryitem/${res.data.company_id}/${res.data.id}`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    inquiryItemListGetAll(params.id);
  }, [searchTable, getFilter, date, pageLimit]);
  return (
    <div>
      <div>
        <div className="fslex p-5 px-5 pb-4" style={{borderRadius: "20px"}}>
          <TableContainer
            elevation={0}
            component={Paper}
            className=" shadow-none rounded-2xl p-5"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginBottom: "16px",
              }}
            >
              {/* <span>
                <RangePicker
                  style={{height: "100%", marginRight: "8px"}}
                  hideTimeBlock
                  onChange={(val) => {
                    val[0] === null
                      ? setDate((old) => ({
                          from_date: "",
                          to_date: "",
                        }))
                      : setDate((old) => ({
                          from_date: moment(val[0]).format("YYYY-MM-DD"),
                          to_date: moment(val[1]).format("YYYY-MM-DD"),
                        }));
                  }}
                  className="p-2 w-full"
                />
              </span> */}
              <input
                name="search"
                value={searchTable}
                onChange={(e) => {
                  setSearchTable(e.target.value);
                }}
                style={{
                  border: "1px solid #E5E9EB",
                  borderRadius: "8px",
                  padding: "8px 12px",
                  maxWidth: "200px",
                }}
                placeholder="Поиск"
              />
              {/* <a
                onClick={() => {
                  getExcelFile();
                }}
                id="download_file"
              >
                <DownloadOutlined />
              </a> */}
            </div>
            <Table
              elevation={0}
              aria-label="customized table"
              style={{borderRadius: "20px"}}
            >
              <TableHead>
                <TableRow className="text-black font-body">
                  {tableHeardName?.map((element, index) => (
                    <StyledTableCell key={index}>{element}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {historyList?.respondentHistorys?.map((element, index) => (
                  <TableRow
                    className="text-black font-body hover:bg-gray-100 cursor-pointer"
                    key={index}
                    onClick={() => {
                      if (element?.object_info?.inquiryId) {
                        handleCutomRow(element?.object_info?.inquiryId);
                      }
                    }}
                  >
                    <StyledTableCell>
                      {parseInt(
                        pageCount * (pageLimit / pageCount - 1) + index + 1
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        whiteSpace: "nowrap",
                        display: "flex",
                        alignItems: "center",
                        columnGap: "10px",
                      }}
                    >
                      {moment(element?.created_at).format("YYYY-MM-DD")}
                      <Tag
                        shape="subtle"
                        color="blue"
                        size="large"
                        style={{
                          width: "70px",
                          borderRaduis: "6px",
                        }}
                      >
                        {element.created_at.slice(11, 16)}
                      </Tag>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Tooltip
                        title={
                          <h1 style={{fontSize: "14px", color: "#fff"}}>
                            {element.description}
                          </h1>
                        }
                        placement="top"
                      >
                        <Typography id="text_limit">
                          {element.description}
                        </Typography>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Tooltip
                        title={
                          <h1 style={{fontSize: "14px", color: "#fff"}}>
                            {element?.from}
                          </h1>
                        }
                        placement="top"
                      >
                        <Typography id="text_limit">{element?.from}</Typography>
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Tooltip
                        title={
                          <h1 style={{fontSize: "14px", color: "#fff"}}>
                            {element?.to}
                          </h1>
                        }
                        placement="top"
                      >
                        <Typography id="text_limit">{element?.to}</Typography>
                      </Tooltip>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className="mt-5 text-black-700"
            count={historyList.count}
            pageCount={pageCount}
            onChange={(val) => {
              console.log(val);
              setPageLimit(val);
            }}
          />
        </div>
        {/*<Table list={companyList} Tableheadername={Tableheadername}></Table>*/}
      </div>
    </div>
  );
};

export default ChangeItemList;
