import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {
  StyledTableCell,
  StyledTableRow,
} from '../../components/DataTable/index'
import Pagination from '../../components/Pagination'
import Reports from '../../models/report'
import { Typography } from '@material-ui/core'

const SecondReportTable = () => {
  const { t } = useTranslation()
  const [reportList, setReportList] = useState([])
  const [tableheadername, setTableheadername] = useState([
    t('number.icon'),
    t('Имя респондента'),
    t('Подтвержденные исследования'),
    t('Неподтвержденные исследования'),
    t('Сумма'),
  ])

  const routes = [
    {
      title: t('company.name'),
      link: true,
      route: '/home/report',
    },
  ]
  const [items, setItems] = useState({})
  const pageCount = 10
  const [pageLimit, setPageLimit] = useState(pageCount)

  const reportGetAllList = () => {
    const params = {
      page: pageLimit / pageCount,
      limit: pageCount,
    }
    Reports.reportRespondent(params)
      .then((res) => {
        console.log(res)
        setItems(res.data)
        setReportList(res.data.reports)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    reportGetAllList()
  }, [])
  useEffect(() => {
    reportGetAllList()
  }, [pageLimit])

  return (
    <div>
      <div>
        <div className='fslex p-5 px-5 pb-4' style={{ borderRadius: '20px' }}>
          <TableContainer
            elevation={0}
            component={Paper}
            className=' shadow-none rounded-2xl p-5'
          >
            <Table
              elevation={0}
              aria-label='customized table'
              style={{ borderRadius: '20px' }}
            >
              <TableHead>
                <TableRow className='text-black font-body'>
                  {tableheadername.map((element, index) => (
                    <StyledTableCell
                      style={{ textAlign: 'center' }}
                      key={index}
                    >
                      {element}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reportList?.map((element, index) => (
                  <TableRow
                    className='text-black font-body hover:bg-gray-100 cursor-pointer'
                    key={index}
                  >
                    <StyledTableCell>
                      {parseInt(
                        pageCount * (pageLimit / pageCount - 1) + index + 1
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography style={{ textAlign: 'center' }}>
                        {element?.respondent_name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography style={{ textAlign: 'center' }}>
                        {element?.number_of_inquiries}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography style={{ textAlign: 'center' }}>
                        {element?.number_of_canceled_inquiries}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography style={{ textAlign: 'center' }}>
                        {element?.cost}
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            className='mt-5 text-black-700'
            size={'large'}
            count={items.count}
            pageCount={pageCount}
            onChange={(val) => {
              console.log(val)
              setPageLimit(val)
            }}
          />
        </div>{' '}
      </div>
    </div>
  )
}

export default SecondReportTable
