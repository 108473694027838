import SearchIcon from '@material-ui/icons/Search';

const IconButton = ({
  icon = <SearchIcon />,
  color = "blue",
  className,
  style = {},
  size = 32,
  ...props
  // size = 16
}) => {

  return (
    <div className="inline-block">
      <div 
        className={`
          icon-button
          rounded-md
          flex
          justify-center
          items-center
          transition
          cursor-pointer
          focus:outline-none
          focus:ring 
          focus:z-40
          focus:border-${color}-300
          bg-${color}-100
          ${className}
        `}
        style={{ width: size, height: size, ...style }}
        {...props}
      >
        {/* <VisibilityIcon style={{ fontSize: 16, color: 'red' }} /> */}
        <div className={`flex fill-current text-${color}-600`}>
          {{...icon, props: {...icon.props, style: {...icon.props.style, fontSize: size - 12}}}}
        </div>
      </div>
    </div>
  )
}

export default IconButton
