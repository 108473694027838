import  React, {useState} from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chexbox from "../../components/Checkbox/index";
function App (){
    const [month ,setMonth] = useState([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17, 18,19,20,21,22,23,24,25,26,27,28,29,30])
    const [hover ,sethovers] = useState([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17, 18,19,20,21,22,23])
    return (
        <div className="p-4">
            <table style={{border: "1px solid black"}} className="bg-white">
                {<tb>Время</tb>}{month.map((element) =><th style={{border: "1px solid black"}}><tb style={{padding: "10px"}}>{element +" июля"}</tb></th>)}
                {<tb>Время</tb>}{month.map((element) =><th style={{border: "1px solid black"}}><tb style={{padding: "10px"}}>{element +" июля"}</tb></th>)}
                {hover.map((element, i ) => (
                        <tr style={{border: "1px solid black" ,padding: "15px", cursor: "pointer"}}>
                            <tb className="p-3">
                                {element+":00"}-{element+":30"}
                            </tb>
                            {month.map((el, j) => (
                                <td onClick={() => console.log(element, el)} style={{border: "1px solid black", padding: "20px"}}>
                                </td>
                            ))}
                        </tr>
                ))}
            </table>
        </div>
    )
}
export default App;
