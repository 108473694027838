import request from '../utils/axios'
const base_Url = {
  variables: '/variables',
  inquiryClientInfo: '/inquiry',
  companyVariable: '/company_variables',
}
const Variables = {
  getGlobalVariables: (parmas) =>
    request({
      url: `${base_Url.variables}`,
      method: 'get',
      params: parmas,
    }),
  updateGlobalVariables: (id, data) => {
    const global_variables = [data]
    return request({
      url: `${base_Url.variables}`,
      method: 'put',
      data: {
        global_variables: global_variables,
      },
    })
  },
  updateGlobalVariablesCompany: (id, data) => {
    const global_variables = [data]
    return request({
      url: `${base_Url.companyVariable}/update`,
      method: 'put',
      data: {
        global_variables: global_variables,
      },
    })
  },
  clientInfo: (id) => {
    return request({
      url: `${base_Url.inquiryClientInfo}/${id}`,
      method: 'get',
    })
  },
  getCompanyGlobalVariable: (parmas) =>
    request({
      url: `${base_Url.companyVariable}/all`,
      method: 'get',
      params: parmas,
    }),
}
export default Variables
