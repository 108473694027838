import {useState, useEffect} from "react";

import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {Formik} from "formik";
import * as Yup from "yup";
import SwipeableViews from "react-swipeable-views";
import {useTheme} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import axios from "../../utils/axios";
import Header from "../../components/Header/Header";
import BreadCrumb from "../../components/Breadcrumb";
import validateForm from "../../functions/validateForm";
import Filters from "../../components/Filter";
import {StyledTab, StyledTabs} from "../../components/StyledTabs";
import {TabPanel} from "../../components/Tab/TabBody";
import Context from "./Context";
import Inquiries from "./Inquiries";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import Admins from "../../models/permissions";

function ResearchersCreate() {
  const {t} = useTranslation();
  const history = useHistory();
  const params = useParams();
  const theme = useTheme;
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const currentPage = useSelector((state) => state.counter);
  const [isGettingData, setIsGettingData] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [roll, setRoll] = useState({});
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    company: undefined,
    phone: "",
    password: "",
    profession_title: "",
    role_id: "",
  });

  const getResearcherRoll = () => {
    const param = {
      client_type_id: "5a3818a9-90f0-44e9-a053-3be0ba1e2c03",
    };
    Admins.getAllRolls(param)
      .then((res) => {
        setRoll(() => [
          ...res.data.roles.map((e) => ({
            ...e,
            label: e.name,
          })),
        ]);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (params.id) {
      getProperty(params.id);
    }
    getResearcherRoll();
    getCompanies();
  }, []);

  // **** FUNCTIONS ****
  const getProperty = (id) => {
    setIsGettingData(true);
    axios
      .get(`/researcher/${id}`)
      .then((res) => {
        setInitialValues({
          ...res.data,
          company: {
            label: res.data?.company_name?.name,
            value: res.data?.company_name?.id,
          },
        });
      })
      .catch((err) => {
        console.log("err = >", err);
        toast.error("Произашло ошибка!", {
          className: "bg-red-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        });
      })
      .finally(() => setIsGettingData(false));
  };

  const onSubmit = (values) => {
    setIsLoading(true);
    let data = {
      ...values,
      company_id: values.company.value,
      role_id: initialValues.role_id,
      email: values.email.toLowerCase(),
    };
    if (params.id) {
      axios
        .put(`/researcher/${params.id}`, data)
        .then((res) => {
          history.push("/home/researchers");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      axios
        .post(`/researcher`, data)
        .then((res) => {
          toast.success("Успешно завершено", {
            className: "bg-green-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
          history.push("/home/researchers");
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.data.error, {
            className: "bg-red-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const getCompanies = () => {
    axios.get("/company").then((res) =>
      setCompanies(
        res.data?.companies?.map(({id, name}) => ({
          label: name,
          value: id,
        }))
      )
    );
  };

  // **** CONSTANTS ****
  const ValidationSchema = Yup.object().shape({
    name: validateForm("default", t),
    profession_title: validateForm("default", t),
    email: validateForm("email", t),
    phone: validateForm("phone", t),
    company: validateForm("company", t),
    password: validateForm("password", t),
  });

  const routes = [
    {
      title: t("researchers"),
      link: true,
      route: "/home/researchers",
    },
    {
      title: params.id ? initialValues.name : t("create"),
    },
  ];

  // **** TABS **** //
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => setValue(newValue);

  const handleChangeIndex = (index) => setValue(index);

  const tabLabel = (text, isActive = false) => {
    return <span className="px-1">{text}</span>;
  };
  return (
    <div>
      {!params.id || !isGettingData ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Header startAdornment={[<BreadCrumb routes={routes} />]} />
              <Filters>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  centered={false}
                  aria-label="full width tabs example"
                  TabIndicatorProps={{children: <span className="w-2" />}}
                >
                  <StyledTab
                    label={tabLabel(t("general.information"))}
                    {...a11yProps(0)}
                    style={{width: "100px"}}
                  />
                  <StyledTab
                    label={tabLabel(t("researches"))}
                    {...a11yProps(1)}
                    style={{width: "100px"}}
                  />
                </StyledTabs>
              </Filters>

              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0}>
                  <Context
                    setInitialValues={setInitialValues}
                    initialValue={initialValues}
                    roll={roll}
                    formik={formik}
                    isLoading={isLoading}
                    params={params}
                    history={history}
                    companies={companies}
                    t={t}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Inquiries currentPage={currentPage} />
                </TabPanel>
              </SwipeableViews>
            </form>
          )}
        </Formik>
      ) : (
        <> </>
      )}
    </div>
  );
}

export default ResearchersCreate;
