import request from "../utils/axios"
const base_Url = {
    researchers: "/billing/transaction"
}
const requests =  {
    billingGetAllList: (id) => (
       request({
           url:`${base_Url.researchers}`,
           method: "get",
           params: id
       })
   )
}
export default requests;