import { emptyStateIcon } from "../../assets/icons/index";

function EmptyState({ message = "Нет данных", isActive = false }) {
  if (!isActive) return null;
  return (
    <div
      className={`
              flex flex-col 
              justify-center 
              items-center    
              text-gray-400
              border border-t-0
              border-gray-300
              space-y-2
              py-8 my-0.5
      `}
      style={{ backgroundColor: "#fff" }}
    >
      {emptyStateIcon}
      <h6 className="font-normal">{message}</h6>
    </div>
  );
}

export default EmptyState;
