import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Root from './Root.jsx'
import { useDispatch } from 'react-redux'
import { authActionTypes } from './redux/actions/authActions/authActionTypes'
import './App.scss'
// import { Provider } from "react-redux";
// const bounce = cssTransition({
//     enter: "animate__animated animate__bounceIn",
//     exit: "animate__animated animate__bounceOut"
// });

export default function App() {
  const dispatch = useDispatch()
  const access_token = localStorage.getItem('token')
  useEffect(() => {
    if (!access_token) {
      dispatch({
        type: authActionTypes.AUTH_LOGIN,
        payload: {
          userLogin: '',
          phoneNumber: '',
          accessToken: '',
          refreshToken: '',
          permissions: '',
        },
      })
    }
  }, [])

  return (
    <BrowserRouter>
      <Root />
      <div id='toast'>
        <ToastContainer
          position='top-right'
          autoClose={2000}
          hideProgressBar={true}
          closeOnClick={true}
        />
      </div>
    </BrowserRouter>
  )
}
