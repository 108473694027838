import {Typography} from "antd";
import React, {useState} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {useTranslation} from "react-i18next";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {StyledPropMenu} from "../../components/custom/StyledPropMenu/index";
import {StyledTableCell} from "../../components/DataTable/index";
import {useHistory} from "react-router-dom";

export default function ParentAgreement({list}) {
  const {t} = useTranslation();
  const history = useHistory();
  const [dropList, setDropList] = useState({target: null, id: ""});
  const [tableheadername, setTableheadername] = useState([
    t("number.icon"),
    t("company.name"),
    "",
  ]);
  const listRender = [
    {
      title: t("edit"),
      icon: <EditIcon className="bg-blue-100 rounded text-blue-600 p-0.5" />,
      type: "edit",
    },
  ];
  const handleMoreClick = (id, type) => {
    if (type === "edit") {
      history.push(`/home/agreement/${dropList.id}`);
      setDropList({
        ...dropList,
        target: null,
      });
    }
  };
  return (
    <>
      <div>
        <TableContainer
          elevation={0}
          component={Paper}
          className=" shadow-none rounded-2xl p-5"
        >
          <Table
            elevation={0}
            aria-label="customized table"
            style={{borderRadius: "20px"}}
          >
            <TableHead>
              <TableRow className="text-black font-body">
                {tableheadername.map((element, index) => (
                  <StyledTableCell key={index}>{element}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list?.contents?.map((element, index) => (
                <TableRow
                  className="text-black font-body hover:bg-gray-100 cursor-pointer"
                  key={index}
                >
                  <StyledTableCell>{parseInt(index + 1)}</StyledTableCell>
                  <StyledTableCell>
                    <Typography>{element?.title}</Typography>
                  </StyledTableCell>
                  <StyledTableCell
                    className="w-2   border-gray-300"
                    style={{
                      borderLeft: "1px solid gainsboro",
                    }}
                  >
                    <MoreHorizIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setDropList({
                          target: e.currentTarget,
                          id: element.id,
                        });
                      }}
                      className="cursor-pointer text-blue-500"
                      style={{
                        border: "1px solid gainsboro",
                        borderRadius: "5px",
                      }}
                    />
                    <StyledPropMenu
                      anchorEl={dropList.target}
                      open={Boolean(dropList.target)}
                      onClose={(e) => {
                        e.stopPropagation();
                        setDropList((old) => {
                          return {...old, target: null};
                        });
                      }}
                    >
                      <div className="flex flex-col divide-y font-body text-sm">
                        {listRender.map((el) => (
                          <div
                            className="flex w-full cursor-pointer py-2 mx-2"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleMoreClick(element.id, el.type);
                            }}
                          >
                            <div className="rounded">{el.icon}</div>
                            <div className="my-auto mx-2">{el.title}</div>
                          </div>
                        ))}
                      </div>
                    </StyledPropMenu>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
