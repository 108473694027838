import {ReactComponent as InfoIcon} from "../../assets/icons/infoIcon.svg";
import {ReactComponent as CancelIcon} from "../../assets/icons/cancelIcon.svg";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import logout from "../../models/logout";
import {authActionTypes} from "../../redux/actions/authActions/authActionTypes";

function Popup(props) {
  const {
    top,
    history,
    dispatch,
    bottom,
    left,
    right,
    mode = "light",
    children,
    title = "Popup title title title title title title title",
    isOpen = false,
    minWidth = 250,
    onClose = function () {},
    className,
    check = true,
    ...rest
  } = props;

  // const listItems = children.map((el, idx) => (
  //   <p key={idx}>
  //     <button className="py-1 px-3">
  //       <a href={el.url}>{el.name}</a>
  //     </button>
  //   </p>
  // ));

  const popupStyle = `top-${top} right-${right} left-${left}`;

  const userLogout = () => {
    // const token = localStorage.getItem('tokin')
    logout
      .logout()
      .then((res) => {
        if (res.code === 200) {
          localStorage.removeItem("token");
          dispatch({
            type: authActionTypes.AUTH_LOGIN,
            payload: {
              userLogin: "",
              phoneNumber: "",
              accessToken: "",
              refreshToken: "",
              permissions: "",
            },
          });
          history.push("/auth/login");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    isOpen && (
      <>
        <div onClick={onClose} className="fixed inset-0 z-40"></div>
        <div {...rest} className={`${className} flex`}>
          <div
            {...rest}
            className={`${className} ${popupStyle} flex absolute z-50`}
            style={(minWidth && {minWidth: minWidth}, {bottom: "3px"})}
          >
            <div
              onClick={userLogout}
              className="flex flex-row ease-in-out p-3"
              style={{
                background: mode === "dark" ? "#333" : "#fff",
                borderRadius: "6px",
                boxShadow:
                  "0px 0px 1px rgba(26, 32, 36, 0.32), 0px 40px 64px rgba(91, 104, 113, 0.24)",
              }}
            >
              <div className="mx-1 my-1">
                {check ? <InfoIcon /> : <ExitToAppIcon />}
              </div>
              <div
                className={`${
                  mode === "dark" && "text-gray-200"
                } flex flex-col text-sm font-semibold mx-2 my-1`}
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <h5>{title}</h5>
                <p
                  className={`${
                    mode === "dark" ? "text-gray-300" : "text-gray-500"
                  } font-thin my-1`}
                >
                  {/* {listItems} */}
                </p>
              </div>

              {/* <div className="cursor-pointer mx-1 my-1">
                <CancelIcon onClick={onClose} />
              </div> */}
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default Popup;
