export const AddIcon = () => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11 6.83366H6.83332V11.0003C6.83332 11.4587 6.45832 11.8337 5.99999 11.8337C5.54166 11.8337 5.16666 11.4587 5.16666 11.0003V6.83366H0.99999C0.541657 6.83366 0.166656 6.45866 0.166656 6.00033C0.166656 5.54199 0.541657 5.16699 0.99999 5.16699H5.16666V1.00033C5.16666 0.541992 5.54166 0.166992 5.99999 0.166992C6.45832 0.166992 6.83332 0.541992 6.83332 1.00033V5.16699H11C11.4583 5.16699 11.8333 5.54199 11.8333 6.00033C11.8333 6.45866 11.4583 6.83366 11 6.83366Z'
      fill='white'
    />
  </svg>
)
