import React, { useEffect, useState } from 'react'
import Input from '../../components/Input/index'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import Form from '../../components/Form/index'
// import formPhoneNumber from "../../functions/formPhoneNumber";
import Button from '../../components/Buttons'
import validateForm from '../../functions/validateForm'
// import phoneNumber from "../../components/Input/phoneNumber"
import company from '../../models/conpany'
import { toast } from 'react-toastify'
import Hearder from '../../components/Header/Header'
import { useHistory, useParams } from 'react-router-dom'
import Breadcrumbs from '../../components/Breadcrumb'
import Switch from '../../components/Switch/Index'
import Select from '../../components/Select/index'
import reseacher from '../../models/researchers'
import InputMask from 'react-input-mask'
import Admins from '../../models/permissions'

const ResearcherItemCompany = ({ researcherAdd }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [userName, setUserName] = useState(null)
  const { list_id } = useParams()
  const routes = [
  
    {
      title: t(`${userName === null ? '' : userName}`),
      link: true,
      route: `${'/home/company/companyinquiry'}/${list_id}`,
    },
    {
      title: t('add'),
      link: true,
    },
  ]
  const ValidationSchema = Yup.object().shape({
    email: validateForm('email', t),
    name: validateForm('name', t),
    professiontitle: validateForm('position', t),
    password: validateForm('password', t),
  })
  const [companyname, setcompanyname] = useState([])
  const [roll, setRoll] = useState({})
  const [initialValue, setinitialValue] = useState({
    company_id: list_id,
    password: '',
    professiontitle: '',
    role_id: '',
    email: '',
    name: '',
    phone: '',
    active: 1,
  })
  const getResearcherRoll = () => {
    const param = {
      client_type_id: '5a3818a9-90f0-44e9-a053-3be0ba1e2c03',
    }
    Admins.getAllRolls(param)
      .then((res) => {
        setRoll(() => [
          ...res.data.roles.map((e) => ({
            ...e,
            label: e.name,
          })),
        ])
      })
      .catch((err) => console.log(err))
  }
  const itemCompanyGetdata = (id) => {
    company.companyItemGet(id).then((res) => {
      setUserName(res.data.name)
      setcompanyname(
        [res?.data].map((element) => {
          return {
            label: element.name,
            value: element.id,
          }
        })
      )
    })
  }
  useEffect(() => {
    itemCompanyGetdata(list_id)
  }, [list_id])
  useEffect(() => {
    getResearcherRoll()
  }, [])
  const onSubmit = (data) => {
    const senddata = {
      company_id: data.company_id,
      password: data.password,
      profession_title: data.professiontitle,
      role_id: initialValue.role_id,
      email: data.email,
      name: data.name,
      phone: data.phone,
      active: initialValue.active
    }
    reseacher
      .researcherCreate(senddata)
      .then((res) => {
        console.log(res)
        if (res.code === 201) {
          toast.success(t('company.new.researcher.add'), {
            className: 'bg-green-500 text-white',
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          })
          history.push(`${'/home/company/companyinquiry'}/${list_id}`)
        }
      })
      .catch((err) => {
        console.log(err)
        toast.error(t(`${err.data.error}`), {
          className: 'bg-red-500 text-white',
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        })
        //   }
      })
  }
  return (
    <div>
      <Hearder startAdornment={[<Breadcrumbs routes={routes} />]}></Hearder>
      {companyname.length > 0 && (
        <div className='p-7'>
          <div className='p-5 bg-white w-3/5' style={{ borderRadius: '8px' }}>
            <Formik
              initialValues={initialValue}
              onSubmit={onSubmit}
              validationSchema={ValidationSchema}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <div>
                    <h1
                      className='text-black'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        lineHeight: '24px',
                        paddingBottom: '16px',
                        color: 'rgba(26, 32, 36, 1)',
                      }}
                    >
                      {'Добавить нового сотрудника'}
                    </h1>
                    <hr />
                    <div className='w-full py-4 flex items-baseline'>
                      <div className='w-1/3'>{t('full.name')}</div>
                      <div className='w-2/3'>
                        <Form.Item name='name' formik={formik}>
                          <Input {...formik.getFieldProps('name')}></Input>
                        </Form.Item>
                      </div>
                    </div>
                    <div className='w-full py-4 flex items-baseline'>
                      <div className='w-1/3'>{t('position')}</div>
                      <div className='w-2/3'>
                        <Form.Item name='professiontitle' formik={formik}>
                          <Input
                            {...formik.getFieldProps('professiontitle')}
                          ></Input>
                        </Form.Item>
                      </div>
                    </div>
                    <div className='w-full py-4 flex items-baseline'>
                      <div className='w-1/3'>{t('Телефона')}</div>
                      <div className='w-2/3'>
                        <Form.Item name='phone' formik={formik}>
                          <InputMask
                            id='inputmask'
                            style={{padding:'5px 12px', marginTop: '0' , fontSize: '14px', fontWeight:'400' , color:'#52525b'}}
                            mask={`+9999999999999999`}
                            maskChar={null}
                            {...formik.getFieldProps('phone')}
                          />
                          {/* <Input ></Input> */}
                        </Form.Item>
                      </div>
                    </div>
                    <div className='w-full py-4 flex items-baseline'>
                      <div className='w-1/3'>{t('Email')}</div>
                      <div className='w-2/3'>
                        <Form.Item name='email' formik={formik}>
                          <Input {...formik.getFieldProps('email')}></Input>
                          {/*<phoneNumber {...formik.getFieldProps("email")}></phoneNumber>*/}
                        </Form.Item>
                      </div>
                    </div>
                    <div className='w-full py-4 flex items-baseline'>
                      <div className='w-1/3'>{t('password')}</div>
                      <div className='w-2/3'>
                        <Form.Item name='password' formik={formik}>
                          <Input {...formik.getFieldProps('password')}></Input>
                          {/*<phoneNumber {...formik.getFieldProps("email")}></phoneNumber>*/}
                        </Form.Item>
                      </div>
                    </div>
                    <div className='w-full py-4 flex items-baseline'>
                      <div className='w-1/3'>{t('Название Роли')}</div>
                      <div className='w-2/3'>
                        <Form.Item name='role_id' formik={formik}>
                          <Select
                            options={roll}
                            onChange={(e) => {
                              setinitialValue((el) => ({
                                ...el,
                                role_id: e.id,
                              }))
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='w-full py-4 flex items-baseline'>
                      <div className='w-1/3'>{t('Название компании')}</div>
                      <div className='w-2/3'>
                        <Form.Item name='ownername' formik={formik}>
                          <Select
                            isDisabled
                            defaultValue={companyname}
                          ></Select>
                          {/*<Input {...formik.getFieldProps("ownername")}>*/}

                          {/*</Input>*/}
                        </Form.Item>
                      </div>
                    </div>
                    <div className='w-full py-4 flex items-baseline'>
                      <div className='w-1/3'>{t('Заблокировать')}</div>
                      <div className='w-2/3'>
                       <Switch
                          id='is_active'
                          color='primary'
                          checked={initialValue.active === 0 ? true : false}
                          onChange={(val) => {
                            setinitialValue((old)=> ({
                              ...old,
                              active: val ? 0 : 1
                            }))
                          }}
                        />
                      
                      </div>
                    </div>

                    <div className='w-full py-4'>
                      <Form.Item formik={formik}>
                        <div className='flex gap-2 float-right'>
                          <Button
                            onClick={() => history.push('/home/company')}
                            style={{
                              color: 'blue',
                              border: '1px solid rgba(229, 233, 235, 1)',
                              padding: '20px ,16px',
                              background: 'white',
                            }}
                          >
                            Назад
                          </Button>
                          <Button type='submit'>Сохранить</Button>
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  )
}

export default ResearcherItemCompany
