import { Button, Typography } from '@material-ui/core'
import React from 'react'
import cls from './index.module.scss'
import { AddIcon } from '../../components/svg/AddIcon'
import Dialog from '@material-ui/core/Dialog'
import axios from '../../utils/axios'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'

export default function RemoveCost({ setGetAfterResponse, accountNumber, balance }) {
  const [open, setOpen] = React.useState(false)
  const [amount, setAmount] = React.useState()
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const changeCost = () => {
    axios
      .post(`/respondent/withdraw_money`, {
        transactions: [
          {
            account_number: Number(accountNumber),
            credit: Number(amount),
            currency: 'RUB',
            debit: 0,
            payment_type_id: '989f08a3-f602-428e-9154-3737571a1ae3',
          },
        ],
      })
      .then((res) => {
        console.log(res)
        if (res.code === 200) {
          setAmount(0)
          setOpen(false)
          setGetAfterResponse(true)
          toast.success('Успешно завершено', {
            className: 'bg-green-500 text-white',
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        toast.error(`${err.data.message}`, {
          className: 'bg-red-500 text-white',
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        })
      })
  }
  return (
    <div className={cls.addCost}>
      <div className={cls.btnGroup}>
        <Button
          onClick={() => {
            setOpen(true)
          }}
          className={cls.btn}
        >
          Списать
        </Button>
      </div>
      <div className={cls.popup}>
        <Dialog
          id='addCost'
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <div className='header'>
            <Typography className='costTitle'>Списать</Typography>
          </div>
          <div className='form'>
            <label htmlFor='text'>Сумма</label>
            <InputMask
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value)
              }}
              mask={`99999999`}
              maskChar={null}
            />
          </div>
          <div className='btnGroup'>
            <Button
              onClick={() => {
                setOpen(false)
              }}
              className='btn'
            >
              Отмена
            </Button>
            <Button
              onClick={() => {
                changeCost()
              }}
              className='btnSecond'
            >
              Отправить
            </Button>
          </div>
        </Dialog>
      </div>
    </div>
  )
}
