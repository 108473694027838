import { Button, Typography } from '@material-ui/core'
import React from 'react'
import cls from './index.module.scss'
import { AddIcon } from '../svg/AddIcon'
import Dialog from '@material-ui/core/Dialog'
import axios from '../../utils/axios'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'
import Textarea from '../Textarea/Index'

export default function AddCost({ setReload  = () => {} , accountNumber }) {
  const [open, setOpen] = React.useState(false)
  const [error, setError] = React.useState(true)
  const [amount, setAmount] = React.useState({
    cost: '',
    description: ''
  })
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const changeCost = () => {
    axios
      .post(`/billing/transaction`, {
        transactions: [
          {
            account_number: Number(accountNumber),
            credit: 0,
            currency: 'RUB',
            debit: Number(amount.cost),
            description: amount.description,
            payment_type_id: '989f08a3-f602-428e-9154-3737571a1ae3',
          },
        ],
      })
      .then((res) => {
        if (res.code === 201) {
          setAmount(0)
          setOpen(false)
          toast.success('Успешно завершено', {
            className: 'bg-green-500 text-white',
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          })
          setReload(true)
        }
      })
      .catch((err) => {
        console.log(err)
        toast.error('Произашло ошибка!', {
          className: 'bg-red-500 text-white',
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        })
      })
  }
  return (
    <div className={cls.addCost}>
      <div className={cls.btnGroup}>
        <Button
          onClick={() => {
            setOpen(true)
          }}
          className={cls.btn}
        >
          <AddIcon /> Попольнить счёт
        </Button>
      </div>
      <div className={cls.popup}>
        <Dialog
          id='addCost'
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <div className='header'>
            <Typography className='costTitle'>Попольнить баланс</Typography>
          </div>
          <div className='form'>
            <div>
              <label htmlFor='text'>Цена</label>
              <InputMask
                value={amount.cost}
                onChange={(e) => {
                  setAmount({
                    ...amount,
                    cost: e.target.value
                  })
                }}
                mask={`99999999`}
                maskChar={null}
              />
            </div>
            <div>
              <Textarea
                error={error}
                style={{ marginTop: '10px' }}
                aria-label="minimum height"
                minRows={3}
                placeholder="Комментария"
                onChange={(e) => {
                  if (e.target.value.length < 1) {
                    setError(true)
                    setAmount({
                      ...amount,
                      description: e.target.value
                    })
                  } else {
                    setAmount({
                      ...amount,
                      description: e.target.value
                    })
                    setError(false)
                  }
                }}
                value={amount.description}
              />
            </div>
          </div>
          <div className='btnGroup'>
            <Button
              onClick={() => {
                setOpen(false)
              }}
              className='btn'
            >
              Отмена
            </Button>
            <Button
              disabled={error}
              onClick={() => {
                changeCost()
              }}
              className='btnSecond'
            >
              Отправить
            </Button>
          </div>
        </Dialog>
      </div>
    </div>
  )
}
