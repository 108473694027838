import React, {useEffect, useState} from "react";
import Header from "../../components/Header/Header";
import Filter from "../../components/Filter";
import {StyledTabs, StyledTab} from "../../components/StyledTabs/index";
import {useTranslation} from "react-i18next";
import {TabPanel} from "../../components/Tab/TabBody";
import MessageAgreement from "./Message";
import {Button} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Agreements from "../../models/agreement";
import {pageActionsTypes} from "../../redux/actions/currentPage/pageActionTypes";
import ParentAgreement from "./Agreement";

function MainAgreement() {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [tabvalue, settabvalue] = useState(0);
  const [list, setList] = useState([]);
  const [items, setItems] = useState({});
  const [agreementList, setAgreementList] = useState([]);
  const currentPage = useSelector((state) => state.counter);
  const activeTab = useSelector((state) => state.counter.currentTab);
  const pageCount = 10;
  const [pageLimit, setPageLimit] = useState(10);

  const getAgreementList = () => {
    const param = {
      page:
        currentPage.currentPage === undefined
          ? pageLimit / pageCount
          : currentPage.currentPage.page,
      limit: pageCount,
    };
    Agreements.getAllAgreements(param)
      .then((res) => {
        setList(res.data);
        setItems(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    Agreements.getAllAgreementsList()
      .then((res) => {
        setAgreementList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    settabvalue(activeTab === undefined ? tabvalue : activeTab.tab);
  }, []);
  useEffect(() => {
    getAgreementList();
  }, []);
  useEffect(() => {
    getAgreementList();
  }, [pageLimit]);
  const handleChangeTab = (event, newvalue) => {
    settabvalue(newvalue);
    dispatch({
      type: pageActionsTypes.CURRENT_TAB,
      payload: {
        tab: newvalue,
      },
    });
  };
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  const tabLabel = (text, isActive = false) => {
    return <span className="px-1">{text}</span>;
  };

  const handleClick = () => {
    history.push(`/home/single_agreement`);
  };
  return (
    <>
      <Header
        title={"Соглашения"}
        endAdornment={[
          tabvalue === 1 && (
            <Button id="btn" onClick={handleClick}>
              Отправить
            </Button>
          ),
        ]}
      />
      <Filter>
        <StyledTabs
          value={tabvalue}
          onChange={handleChangeTab}
          centered={false}
          aria-label="full width tabs example"
          TabIndicatorProps={{children: <span className="w-2" />}}
        >
          <StyledTab
            label={tabLabel(t("agreement"))}
            {...a11yProps(0)}
            style={{width: "150px", fontFamily: "san-serif"}}
          />
          <StyledTab
            label={tabLabel(t("email_message"))}
            {...a11yProps(0)}
            style={{width: "200px"}}
          />
        </StyledTabs>
      </Filter>
      <TabPanel value={tabvalue} index={0}>
        <ParentAgreement list={agreementList} />
      </TabPanel>
      <TabPanel value={tabvalue} index={1}>
        <MessageAgreement
          setPageLimit={setPageLimit}
          pageCount={pageCount}
          pageLimit={pageLimit}
          currentPage={currentPage}
          items={items}
          list={list}
        />
      </TabPanel>
    </>
  );
}

export default MainAgreement;
