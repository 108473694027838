import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import Breadcrumbs from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import Input from "../../components/Input/index";
import Form from "../../components/Form";
import validateForm from "../../functions/validateForm";
import Button from "../../components/Buttons";
import * as Yup from "yup";
import Variables from "../../models/globalVariables";
import { toast } from "react-toastify";
import { styled } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const GlobalVariablesUpdate = ({ check = false }) => {
  const loacation = useLocation();
  const history = useHistory();
  const [updateType, setupdateType] = useState(true);
  const isChangable =
    loacation?.state?.is_changable ||
    loacation.state.key === "offline_cost_consultant" ||
    loacation.state.key === "offline_cost_professional";
  const [active, setActive] = useState(
    loacation.state.calculation_type !== "percent"
  );
  const { t } = useTranslation();
  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor:
        theme.palette.mode === "dark" ? "#003892" : "rgb(64 148 247)",
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));
  const handleSubmit = (data) => {
    let params = {
      ...data,
      company_id: loacation.id,
    };
    loacation.id
      ? Variables.updateGlobalVariablesCompany(data.key, params).then((res) => {
          history.push(`/home/company/companyinquiry/${loacation.id}`);
          toast.success(`${t("Переменные") + " " + t("update")}`, {
            className: "bg-green-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
        })
      : Variables.updateGlobalVariables(data.key, data).then((res) => {
          history.push("/home/settings/global");
          toast.success(`${t("Переменные") + " " + t("update")}`, {
            className: "bg-green-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
        });
  };

  const formik = useFormik({
    initialValues: {
      value: loacation?.state.value,
      key: loacation?.state.key,
      calculation_type: loacation?.state.calculation_type,
      is_changable: loacation?.state.is_changable ? 1 : 0,
    },
    onSubmit: handleSubmit,
    validationSchema: Yup.object().shape({
      value: validateForm("default", t),
    }),
  });
  console.log("loacation?.state?. ===>", loacation?.state?.is_changable);

  const routes = [
    {
      title: t("setting"),
      link: false,
      route: "/home/company",
    },
    {
      title: t(updateType ? "Переменные" : "add"),
      link: true,
      route: "/home/settings/global",
    },
    {
      title: t(`${loacation.state.key}`),
      link: true,
      route: "/home/settings/global",
    },
  ];

  return (
    <div>
      <Header startAdornment={[<Breadcrumbs routes={routes} />]}></Header>
      <div className="bg-white m-5 p-5 w-3/5" style={{ borderRadius: "8px" }}>
        <h1
          className="text-black"
          style={{
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "18px",
            lineHeight: "24px",
            padding: "16px",
            color: "rgba(26, 32, 36, 1)",
          }}
        >
          {"Переменные " + t("update")}
        </h1>
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full py-4 flex items-baseline">
            <div
              className="w-1/3 whitespace-nowrap"
              style={{ marginRight: "20px", fontSize: "13px" }}
            >
              {t(loacation.state.key)}
            </div>
            <div className="w-2/3">
              <Form.Item name="value" formik={formik}>
                {loacation.state.key === "base_duration" ? (
                  <Input
                    id="value"
                    name="value"
                    type="number"
                    onChange={(val) => {
                      const data = val.target.value;
                      formik.setFieldValue("value", data);
                    }}
                    value={formik.values.value}
                    step={loacation.state.value === "30" ? "30" : "1"}
                    min={loacation.state.value === "30" ? "30" : "0"}
                  ></Input>
                ) : (
                  <Input
                    id="value"
                    name="value"
                    type="number"
                    min={0}
                    onChange={(val) => {
                      const data = val.target.value;
                      formik.setFieldValue("value", data);
                    }}
                    value={formik.values.value}
                  ></Input>
                )}
              </Form.Item>
            </div>
          </div>
          {loacation?.state?.is_changable ? (
            <div className="flex items-center">
              <p>Процент(%)</p>
              <FormControlLabel
                name="calculation_type"
                id="calculation_type"
                style={{
                  marginRight: "0",
                  marginLeft: "0",
                  width: "100px",
                  justifyContent: "center",
                }}
                control={<MaterialUISwitch sx={{ m: 1 }} />}
                onChange={(e) => {
                  setActive(!active);
                  formik.setFieldValue(
                    "calculation_type",
                    active ? "percent" : "number"
                  );
                }}
                checked={active}
                defaultChecked={active}
              />

              <p>Количество </p>
            </div>
          ) : (
            ""
          )}
          <div className="w-full py-4 flex items-baseline justify-between">
            <div className="w-1/3"></div>
            <div className="w-full justify-between">
              <Button
                type="submit"
                className="float-right bg-blue-600 text-white p-2 w-1/5"
                style={{ borderRadius: "5px" }}
              >
                {t("save")}
              </Button>
              {/* <Button
                onClick={() => history.push('/home/settings/global')}
                className='float-right text-blue-800 p-2 w-1/5'
                style={{
                  borderRadius: '5px',
                  border: '1px solid rgba(229, 233, 235, 1)',
                  marginRight: '10px',
                  color: '#fff',
                }}
              >
                {t('back')}
              </Button> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GlobalVariablesUpdate;
