export const FilterIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.25 1.66C0.35 1.79 5.99 8.99 5.99 8.99V15C5.99 15.55 6.44 16 7 16H9.01C9.56 16 10.02 15.55 10.02 15V8.98C10.02 8.98 15.51 1.96 15.77 1.64C16.03 1.32 16 1 16 1C16 0.45 15.55 0 14.99 0H1.01C0.4 0 0 0.48 0 1C0 1.2 0.06 1.44 0.25 1.66Z'
      fill='#6E8BB7'
    />
  </svg>
)
