import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Respondent from "../../models/respondent";
import Select from "../../components/Select";
import {STATUSES} from "../../mock/tags";
import Button from "../../components/Buttons";
import {toast} from "react-toastify";
import axios from "../../utils/axios";
import {Rate} from "antd";
import {Box, Typography} from "@material-ui/core";
import Textarea from "../../components/Textarea/Index";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #red",
    borderRadius: "6px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
    width: 380,
  },
}));

export default function TransitionsModal({
  element,
  t,
  params,
  Cached,
  closeMenu,
  setGetInquiry,
  getRespondents,
  getTabs,
  setCheckOpen,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [status_id, setStatusId] = React.useState(null);
  const [ratingCommunication, setRatingCommunication] = React.useState(0);
  const [ratingExperience, setRatingExperience] = React.useState(0);
  const [ratingPunctuality, setRatingPunctuality] = React.useState(0);
  const [textareaError, setTextareaError] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [value, setValue] = React.useState({
    communication: ratingCommunication,
    experience: ratingExperience,
    punctuality: ratingPunctuality,
  });

  useEffect(() => {
    setValue({
      communication: ratingCommunication,
      experience: ratingExperience,
      punctuality: ratingPunctuality,
    });
  }, [ratingCommunication, ratingExperience, ratingPunctuality]);
  const userRatingChange = () => {
    sendRequest();
    axios
      .put(`/inquiry/update_rating`, {
        communication: value.communication,
        experience: value.experience,
        inquiry_id: `${params.inquiry_id}`,
        punctuality: value.punctuality,
        user_id: `${element.id}`,
      })
      .then((res) => {
        console.log(res);
        setGetInquiry(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleMsg = (e) => {
    setMsg(e.target.value);
    setTextareaError(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const request = () => {
    sendRequest();
  };
  const descriptionWithRequest = () => {
    if (!msg) return setTextareaError(true);
    sendRequest();
  };

  console.log("status_id", status_id);

  const sendRequest = () => {
    Respondent.updateRespondentStatus({
      respondent_id: element.id,
      data: {
        description: msg,
        inquiry_id: params.inquiry_id,
        status_id: status_id,
      },
    })
      .then((res) => {
        console.log(res);
        getRespondents();
        getTabs();
        // setSuccessStatus(true)
        toast.success("Состояние успешно обновлено", {
          className: "bg-green-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        });
        setOpen(false);
        setStatusId(null);
        closeMenu({target: null, id: "", data: {}});
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          "Запрошенное кол-во респондентов подтверждено, чтобы акцептовать данного респондента отмените ранее одобренного респондента или создайте дополнительный запрос",
          {
            className: "bg-red-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          }
        );
      });
  };

  const filtered_status = () => {
    switch (element.status_id) {
      // Confirmed
      case "e86c9b5b-5c71-48c8-b61f-42eeb51e33c6":
        return [STATUSES[2], STATUSES[3]];
      // Pending
      case "01ee5c38-5f56-4e72-9b5a-926e4adce26a":
        return [STATUSES[0], STATUSES[3]];
      default:
        return [];
    }
  };

  console.log("element", element);

  console.log("filtered_status", filtered_status());

  const body = (
    <Textarea
      aria-label="minimum height"
      minRows={3}
      placeholder="Причина"
      error={textareaError}
      onChange={(e) => handleMsg(e)}
      value={msg}
    />
  );
  return (
    <div>
      <div onClick={handleOpen} className="cursor-pointer">
        <Cached className="bg-green-100 rounded text-green-600 p-1 my-1" />{" "}
        <span className="ml-2">{t("Изменить статус")}</span>
      </div>
      {element ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h2 id="transition-modal-title">Изменить статус запроса</h2>
              <Select
                className={"my-1 mb-2 w-full"}
                onChange={(e) => {
                  setStatusId(e.value);
                }}
                options={filtered_status()}
                // defaultValue={[
                //   {
                //     label: filtered_status().filter(
                //       (el) => (
                //         el.value === element.status_id,
                //         console.log(
                //           "el.value === element.status_id",
                //           el.value === element.status_id
                //         )
                //       )
                //     )[0]?.label,
                //     value: element.status_id,
                //   },
                // ]}
              />
              {status_id === "2e857fbe-9add-4eae-a8c4-fe57fb384347" && (
                <div>
                  <h2 style={{textAlign: "center"}} id="transition-modal-title">
                    Изменить рейтинг
                  </h2>

                  <Box>
                    <Typography
                      style={{
                        fontSize: "14px",
                        lineHeight: "21px",
                        fontWeight: "400",
                        color: "#000",
                      }}
                    >
                      Communication
                    </Typography>

                    <Rate
                      name="rate-1"
                      onChange={(event) => {
                        setRatingCommunication(event);
                      }}
                      value={ratingCommunication}
                    />
                  </Box>
                  {/* experinece */}
                  <Box style={{marginTop: "10px"}} id="change_rating">
                    <Typography
                      style={{
                        fontSize: "14px",
                        lineHeight: "21px",
                        fontWeight: "400",
                        color: "#000",
                      }}
                    >
                      Experience
                    </Typography>
                    <Rate
                      name="simple-controlled-2"
                      value={ratingExperience}
                      onChange={(event) => {
                        setRatingExperience(event);
                      }}
                    />
                  </Box>
                  {/* Punctuality */}
                  <Box style={{marginTop: "10px"}} id="change_rating">
                    <Typography
                      style={{
                        fontSize: "14px",
                        lineHeight: "21px",
                        fontWeight: "400",
                        color: "#000",
                      }}
                    >
                      Punctuality
                    </Typography>
                    <Rate
                      name="simple-controlled-3"
                      value={ratingPunctuality}
                      onChange={(event) => {
                        setRatingPunctuality(event);
                      }}
                    />
                  </Box>
                </div>
              )}
              {status_id === "2a98c22e-cbee-49f9-90ef-f56429d96333" && (
                <Box id="canceled_msg">{body}</Box>
              )}

              {filtered_status().length ? (
                <div
                  className="flex justify-end mt-3"
                  onClick={() => {
                    if (status_id === "2e857fbe-9add-4eae-a8c4-fe57fb384347") {
                      userRatingChange();
                    } else if (
                      status_id === "2a98c22e-cbee-49f9-90ef-f56429d96333"
                    ) {
                      descriptionWithRequest();
                    } else {
                      request();
                    }
                  }}
                >
                  <Button size="large">{t("save")}</Button>
                </div>
              ) : (
                <div className="p-2 rounded-lg bg-red-200 text-red-600 text-sm">
                  Запрос{" "}
                  {status_id === "2a98c22e-cbee-49f9-90ef-f56429d96333"
                    ? "отменено"
                    : "завершен"}
                  , изменения статуса недоступно
                </div>
              )}
            </div>
          </Fade>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
}
