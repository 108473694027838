import request from "../utils/axios"
const base_Url = {
    attributs: '/attribute'
}
const requests = {
    getAttribut: (parmas) => (
        request({
            url: `${base_Url.attributs}`,
            method: "get",
            params: parmas
        })
    ),
    deleteAttibut: (id) => (
        request({
            url: `${base_Url.attributs}/${id}`,
            method: "delete"
        })
    ),
    createAttribut: (data) => (
        request({
            url: `${base_Url.attributs}`,
            method: "post",
            data: data
        })
    ),
    getItemUpdateAttribut: (id) =>(
        request({
            url: `${base_Url.attributs}/${id}`,
            method: "get"
        })
    ),
    updateAttribut: (id, data) =>  (
        request({
            url: `${base_Url.attributs}/${id}`,
            method: "put",
            data: data
        })
    )
}
export default requests;
