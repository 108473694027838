import { useEffect, useState, useMemo } from 'react'
import Card from '../../../components/Card'
import axios from '../../../utils/axios'
import moment from 'moment'
import IconButton from '../../../components/Buttons/IconButton'
import CustomSelect from '../../../components/Select'
import CalendarContent from './CalendarContent'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'

export default function Calendar({ show = true }) {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()

  const [loading, setLoading] = useState(false)
  const [calendar, setCalendar] = useState({})
  const [researcherOptions, setResearcherOptions] = useState([])
  const [researcher, setResearcher] = useState({})
  const [date, setDate] = useState({
    from: moment().startOf('w'),
    to: moment().endOf('w'),
  })

  useEffect(() => {
    getResearchers()
  }, [])

  useEffect(() => {
    if (researcher?.value) {
      getCalendar(params?.id)
    }
  }, [date, researcher])

  const getCalendar = (id) => {
    setLoading(true)
    axios
      .get('/inquiry/calendar', {
        params: {
          company_id: id,
          from_date: date.from.format('YYYY-MM-DD'),
          to_date: date.to.format('YYYY-MM-DD'),
          researcher_id: researcher.value,
          limit: 1000,
        },
      })
      .then((res) => {
        // console.log(res)
        setCalendar({
          ...res.data,
          from_date: date.from.format('YYYY-MM-DD'),
          to_date: date.to.format('YYYY-MM-DD'),
        })
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const getResearchers = () => {
    axios
      .get('/researcher', {
        params: {
          company_id: params.id,
          page: 1,
          limit: 1000,
        },
      })
      .then((res) => {
        console.log(res)
        const options =
          res?.data?.researchers?.map((res) => ({
            value: res.id,
            label: res.name,
          })) ?? []
        setResearcherOptions(options)
        setResearcher(options[0])
      })
  }

  const handleChangeWeek = (type) => {
    if (type === 'right') {
      setDate((old) => ({
        from: old.from.add(1, 'w'),
        to: old.to.add(1, 'w'),
      }))
    } else {
      setDate((old) => ({
        from: old.from.subtract(1, 'w'),
        to: old.to.subtract(1, 'w'),
      }))
    }
  }

  const generatedDateTitle = useMemo(() => {
    const startMonth = date.from.format('MMMM')
    const startYear = date.from.format('YYYY')
    const endMonth = date.to.format('MMMM')
    const endYear = date.to.format('YYYY')
    if (startYear === endYear) {
      return `${
        startMonth !== endMonth
          ? `${date.from.format('MMM').split('.')[0]} - ${
              date.to.format('MMM').split('.')[0]
            }`
          : startMonth
      }, ${startYear}`
    } else {
      return `${date.from.format('MMM')} ${startYear} - ${date.to.format(
        'MMM'
      )} ${endYear}`
    }
  }, [date])

  return (
    <Card title={t('calendar')}>
      <div className='mb-5 flex justify-between items-center'>
        <div className='flex items-center gap-4'>
          <IconButton
            size={40}
            icon={<ChevronLeftIcon style={{ color: '#6E8BB7' }} />}
            style={{ backgroundColor: '#F7F9FB' }}
            onClick={() => handleChangeWeek('left')}
          />
          <div
            className='h-10 px-4 rounded-md flex justify-center items-center font-medium	text-base'
            style={{
              backgroundColor: '#F7F9FB',
              color: '#6E8BB7',
              minWidth: 195,
            }}
          >
            {generatedDateTitle}{' '}
            {/* {date.from.format('DD/MM/YYYY')} ~ {date.to.format('DD/MM/YYYY')} */}
          </div>
          <IconButton
            size={40}
            icon={<ChevronRightIcon style={{ color: '#6E8BB7' }} />}
            style={{ backgroundColor: '#F7F9FB' }}
            onClick={() => handleChangeWeek('right')}
          />
          {show && (
            <CustomSelect
              width={250}
              // height={38}
              options={researcherOptions}
              value={researcher}
              onChange={(elm) => setResearcher(elm)}
            />
          )}
        </div>
      </div>
      <CalendarContent duration={30} calendar={calendar} />
    </Card>
  )
}
