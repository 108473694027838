import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Header from "../../components/Header/Header";
import { useTranslation } from "react-i18next";
import { StyledTableCell } from "../../components/DataTable";
import Variables from "../../models/globalVariables";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { StyledPropMenu } from "../../components/custom/StyledPropMenu";
import { toast } from "react-toastify";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import Pagination from "../../components/Pagination";

const GlobalVariabList = ({
  check = false,
  id,
  setRollGlobalVariable,
  getVariables,
}) => {
  const pageCount = 10;
  const [items, setItems] = useState({});
  const [pageLimit, setPageLimit] = useState(pageCount);
  const { t } = useTranslation();
  const history = useHistory();
  const customRowClik = (id) => {
    // history.push(pathname: `${"/home//settings/global/update"}`)
  };
  const [updateType, setupdateType] = useState(true);
  const [dropList, setDropList] = useState({ target: null, id: "" });
  const [tableheadername, settableheadername] = useState([
    t("number.icon"),
    t("names"),
    t("meaning"),
    "",
  ]);

  const listRender = [
    {
      title: t("edit"),
      icon: <EditIcon className="bg-blue-100 rounded text-blue-600 p-0.5" />,
      type: "edit",
    },
    // {
    //     title: t("delete"),
    //     icon: <ClearIcon className="bg-error-100 rounded text-error-600 p-0.5" />,
    //     type: "delete",
    // },
  ];
  const [valriablesList, setvalriablesList] = useState([]);

  const getGlobalVariables = () => {
    if (!check) {
      Variables.getGlobalVariables().then((res) => {
        setvalriablesList(res.data.global_variables);
      });
    } else {
      const params = {
        company_id: id,
        page: pageLimit / pageCount,
        limit: pageCount,
      };
      Variables.getCompanyGlobalVariable(params).then((res) => {
        setvalriablesList(res.data.global_variables);
        setItems(res.data);
        if (res.data.count) {
          setRollGlobalVariable(true);
        } else {
          setRollGlobalVariable(false);
        }
      });
    }
  };
  const handleMoreClick = (element, type) => {
    if (type === "edit") {
      setDropList((old) => {
        return { ...old, target: null };
      });
      console.log("aaaa ==>", dropList.id);

      const state = {
        ...dropList.id,
        is_changable:
          dropList.id.key === "offline_cost_consultant" ||
          dropList.id.key === "offline_cost_professional" ||
          dropList.id.is_changable,
      };

      if (!check) {
        history.push({
          pathname: "/home/settings/global/update",
          state,
        });
      } else {
        history.push({
          pathname: "/home/settings/global/update",
          state,
          id: id,
        });
      }
      // history.push(`/home/attributs/update/${dropList.id}`)
    }
  };
  useEffect(() => {
    getGlobalVariables();
  }, [getVariables]);
  useEffect(() => {
    getGlobalVariables();
  }, [pageLimit]);
  return (
    <div>
      {!check && <Header title={"Переменные"} />}
      <div className="fslex p-5 px-5 pb-4 rounded-2xl">
        <TableContainer
          elevation={0}
          component={Paper}
          className=" shadow-none rounded-2xl p-5"
        >
          <Table
            elevation={0}
            aria-label="customized table"
            className="rounded-2xl"
            style={{ borderRadius: "20px" }}
          >
            <TableHead>
              <TableRow className="text-black font-body">
                {tableheadername?.map((element, index) => (
                  <StyledTableCell key={index}>{element}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {valriablesList?.map((element, index) => (
                <TableRow
                  onClick={() => customRowClik(element)}
                  className="text-black font-body hover:bg-gray-100 cursor-pointer"
                  key={index}
                >
                  <StyledTableCell>
                    {parseInt(
                      pageCount * (pageLimit / pageCount - 1) + index + 1
                    )}
                  </StyledTableCell>
                  {/* <StyledTableCell>{index + 1}</StyledTableCell> */}
                  <StyledTableCell>
                    {t(
                      element?.is_changable === 1
                        ? element.key + "_" + element.calculation_type
                        : element.key
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{t(element.value)}</StyledTableCell>
                  {/*<StyledTableCell>{t(element.input_type)}</StyledTableCell>*/}
                  <StyledTableCell
                    className="w-2  border-gray-300"
                    style={{ borderLeft: "1px solid gainsboro" }}
                  >
                    <MoreHorizIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setDropList({
                          target: e.currentTarget,
                          id: element,
                        });
                      }}
                      className="cursor-pointer text-blue-500"
                      style={{
                        border: "1px solid gainsboro",
                        borderRadius: "5px",
                      }}
                    />

                    <StyledPropMenu
                      anchorEl={dropList.target}
                      open={Boolean(dropList.target)}
                      onClose={(e) => {
                        e.stopPropagation();
                        setDropList((old) => {
                          return { ...old, target: null };
                        });
                      }}
                    >
                      <div className="flex flex-col divide-y font-body text-sm">
                        {listRender.map((el, index) => (
                          <div
                            key={index}
                            className="flex w-full cursor-pointer py-2 mx-2"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleMoreClick(element, el.type);
                            }}
                          >
                            <div className="rounded">{el.icon}</div>
                            <div className="my-auto mx-2">{el.title}</div>
                          </div>
                        ))}
                      </div>
                    </StyledPropMenu>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          className="mt-5 text-black-700"
          count={items.count}
          pageCount={pageCount}
          onChange={(val) => {
            setPageLimit(val);
          }}
        />
      </div>
    </div>
  );
};

export default GlobalVariabList;
