import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Respondent from "../../models/respondent";
import Select from "../../components/Select";
import {STATUSES} from "../../mock/tags";
import Button from "../../components/Buttons";
import {toast} from "react-toastify";
import axios from "../../utils/axios";
import {Rate} from "antd";
import {Box, Typography} from "@material-ui/core";
import Textarea from "../../components/Textarea/Index";
import {Router, useHistory} from "react-router";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #red",
    borderRadius: "6px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
    width: 380,
  },
}));

export default function TransitionsModal({
  element,
  t,
  params,
  Cached,
  closeMenu,
  setGetInquiry,
  getRespondents,
  getTabs,
  setCheckOpen,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [status_id, setStatusId] = React.useState(null);
  const [ratingCommunication, setRatingCommunication] = React.useState(0);
  const [ratingExperience, setRatingExperience] = React.useState(0);
  const [ratingPunctuality, setRatingPunctuality] = React.useState(0);
  const [textareaError, setTextareaError] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [value, setValue] = React.useState({
    communication: ratingCommunication,
    experience: ratingExperience,
    punctuality: ratingPunctuality,
  });
  const history = useHistory();

  useEffect(() => {
    setValue({
      communication: ratingCommunication,
      experience: ratingExperience,
      punctuality: ratingPunctuality,
    });
  }, [ratingCommunication, ratingExperience, ratingPunctuality]);
  const userRatingChange = () => {
    axios
      .put(`/inquiry/update_rating`, {
        communication: value.communication,
        experience: value.experience,
        inquiry_id: `${params.inquiry_id}`,
        punctuality: value.punctuality,
        user_id: `${element.id}`,
      })
      .then((res) => {
        console.log(res);
        handleClose();
        history.push(
          `/home/company/inquiryitem/${params.id}/${params.inquiry_id}`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleMsg = (e) => {
    setMsg(e.target.value);
    setTextareaError(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <Textarea
      aria-label="minimum height"
      minRows={3}
      placeholder="Причина"
      error={textareaError}
      onChange={(e) => handleMsg(e)}
      value={msg}
    />
  );
  return (
    <div>
      <div onClick={handleOpen} className="cursor-pointer">
        <Cached className="bg-yellow-100 rounded text-yellow-600 p-1 my-1" />{" "}
        <span className="ml-2">{t("Изменить рейтинг")}</span>
      </div>
      {element ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <div>
                <h2 style={{textAlign: "center"}} id="transition-modal-title">
                  Изменить рейтинг
                </h2>

                <Box>
                  <Typography
                    style={{
                      fontSize: "14px",
                      lineHeight: "21px",
                      fontWeight: "400",
                      color: "#000",
                    }}
                  >
                    Communication
                  </Typography>

                  <Rate
                    name="rate-1"
                    onChange={(event) => {
                      setRatingCommunication(event);
                    }}
                    value={ratingCommunication}
                  />
                </Box>
                {/* experinece */}
                <Box style={{marginTop: "10px"}} id="change_rating">
                  <Typography
                    style={{
                      fontSize: "14px",
                      lineHeight: "21px",
                      fontWeight: "400",
                      color: "#000",
                    }}
                  >
                    Experience
                  </Typography>
                  <Rate
                    name="simple-controlled-2"
                    value={ratingExperience}
                    onChange={(event) => {
                      setRatingExperience(event);
                    }}
                  />
                </Box>
                {/* Punctuality */}
                <Box style={{marginTop: "10px"}} id="change_rating">
                  <Typography
                    style={{
                      fontSize: "14px",
                      lineHeight: "21px",
                      fontWeight: "400",
                      color: "#000",
                    }}
                  >
                    Punctuality
                  </Typography>
                  <Rate
                    name="simple-controlled-3"
                    value={ratingPunctuality}
                    onChange={(event) => {
                      setRatingPunctuality(event);
                    }}
                  />
                </Box>
              </div>
              <div
                className="flex justify-end mt-3"
                onClick={() => {
                  userRatingChange();
                }}
              >
                <Button size="large">{t("save")}</Button>
              </div>
            </div>
          </Fade>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
}
