import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import cls from './INN.module.scss'
import { BackArrow }  from "../../svg/BackArrow"
import { Box, Button, Typography } from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function SuccessResponse({
  setCheckINN,
  successCard,
  setSuccessCard,
}) {
  const handleClose = () => {
    setSuccessCard(false)
  }

  return (
    <div className={cls.root}>
      <form>
        <Dialog
          id='client_info'
          open={successCard}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <div className={`${cls.row} ${cls.successPt}`}>
            <a
              onClick={() => {
                setSuccessCard(false)
                setCheckINN(true)
              }}
            >
              <div className={cls.title}>
                <BackArrow />{' '}
                <Typography className={cls.text}>Назад</Typography>
              </div>
            </a>
            <Typography className={cls.header}>
              Ура! Вы - Самозанятый: можете уже сейчас принять участие в
              исследованиях
            </Typography>
            <div className={cls.btnGroup}>
              <Button className={cls.btn}>Принять участие</Button>
            </div>
          </div>
        </Dialog>
      </form>
    </div>
  )
}
