import React from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import Pagination from "../../Pagination/index"
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Paper from "@material-ui/core/Paper";
// import research from "../../../../src/"

import Button from "../../Buttons/index";
import Input from "../../Input/index";
import { StyledPropMenu } from "../../custom/StyledPropMenu/index";
import Modal from "../../Modal/index";
import { StyledTab, StyledTabs } from "../../StyledTabs/index";
import Pagination from "../../Pagination/index";
import dateTimeConvert from "../../../../src/functions/dateTimeConvert";
import convertToInternationalCurrencySystem from "../../../../src/functions/convertToInternationalCurrency";
import Tag from "../../Tag/index";
import { StyledTableCell } from "../../StyledTableCell/index";
import formatPhoneNumber from "../../../../src/functions/formPhoneNumber";
import { useState } from "react";

function TableContext({ list, Tableheadername }) {
  const { t } = useTranslation();
  const { idItem } = useState();
  // const deleteItam = () => {
  //   research.delteItemResearch(d)
  // }
  const listRender = [
    {
      title: t("edit"),
      icon: <EditIcon className="bg-blue-100 rounded text-blue-600 p-0.5" />,
      type: "edit",
    },
    {
      title: t("delete"),
      icon: <ClearIcon className="bg-error-100 rounded text-error-600 p-0.5" />,
      type: "delete",
    },
  ];
  //   const {
  //     statuses,
  //     value,
  //     tabLabel,
  //     itemCounts,
  //     permissions,
  //     items,
  //     pageCount,
  //     paymentData,
  //     handlePaymentType,
  //     handlePaidAmount,
  //     handleReturnedAmount,
  //     handleChange,
  //     a11yProps,
  //     handleModal,
  //     setPaymentData,
  //     setShowModal,
  //     paymentTypes,
  //     onModalSubmit,
  //     showModal,
  //     setDropList,
  //     dropList,
  //     listRender,
  //     handleMoreClick,
  //     setPageLimit,
  //     isLoading,
  //   } = props;
  const handleMoreClick = (id, type) => {
    if (type === "edit") {
      console.log("edit");
      history.push(`/home/dashboard/teamsCreate/${dropList.id}`);
    }
    if (type === "dlete") {
      console.log("dlete");
    }
  };
  const history = useHistory();
  const [valueTabs, setvalueTabs] = useState(0);
  const [dropList, setDropList] = useState({ target: null, id: "" });
  const handleChangeTabs = (e, val) => {
    setvalueTabs(val);
  };
  return (
    <div className="px-5 py-4 w-2/3 m-auto Arial">
      <TableContainer
        elevation={0}
        component={Paper}
        className=" shadow-none rounded-xl "
      >
        <Table aria-label="simple table border">
          <TableHead>
            <TableRow className="bg-gray-50">
              {Tableheadername?.map((e) => (
                <StyledTableCell>{e}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="border-gray-300">
            {list?.map((element, index) => (
              <TableRow
                className={`hover:bg-gray-100 cursor-pointer`}
                key={index + Math.random()}
              >
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{element.name}</StyledTableCell>
                <StyledTableCell>{element.owner_name}</StyledTableCell>
                <StyledTableCell>{element.inn}</StyledTableCell>
                <StyledTableCell className="w-2">
                  <MoreHorizIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropList({
                        target: e.currentTarget,
                        id: element.id,
                      });
                    }}
                    className="cursor-pointer"
                  />
                  <StyledPropMenu
                    anchorEl={dropList.target}
                    open={Boolean(dropList.target)}
                    onClose={(e) => {
                      e.stopPropagation();
                      setDropList((old) => {
                        return { ...old, target: null };
                      });
                    }}
                  >
                    <div className="flex flex-col divide-y font-body text-sm">
                      {listRender.map((el) => (
                        <div
                          className="flex w-full cursor-pointer py-2 mx-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleMoreClick(element.id, el.type);
                          }}
                        >
                          <div className="rounded">{el.icon}</div>
                          <div className="my-auto mx-2">{el.title}</div>
                        </div>
                      ))}
                    </div>
                  </StyledPropMenu>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/*<Pagination*/}
      {/*    className="mt-5"*/}
      {/*    count={items.count}*/}
      {/*    pageCount={pageCount}*/}
      {/*    onChange={(val) => {*/}
      {/*      console.log(val);*/}
      {/*      setPageLimit(val);*/}
      {/*    }}*/}
      {/*    />*/}
    </div>
  );
}

export default TableContext;
