import moment from "moment";
import React, {useMemo} from "react";

export default function FourthStepCalendar({
  data,
  onCellClick,
  tableHeaders,
  classes,
}) {
  const calendar = useMemo(() => {
    return moment().add(12.5, "hours");
  }, []);

  const element = [];

  return (
    <>
      <div className={classes.calendar}>
        <table>
          <tr>
            {tableHeaders.map(
              (el, i) => (
                el?.text.includes("сб") ? element.push(i) : "",
                el?.text.includes("вс") ? element.push(i) : "",
                i === 0 ? (
                  <th
                    style={{
                      position: "sticky",
                      zIndex: "99999",
                      background: element?.find((e) => e === i)
                        ? "rgb(177 177 177)"
                        : "#e8e8e8",
                      left: "-2px",
                      top: "-1px",
                      color: "#000",
                    }}
                    className={classes["time-range-head"]}
                  >
                    {el.text}
                  </th>
                ) : (
                  <th
                    style={{
                      marginLeft: "10px",
                      position: "sticky",
                      zIndex: "999",
                      background: element?.find((e) => e === i)
                        ? "rgb(177 177 177)"
                        : "#e8e8e8",
                      top: "0",
                      color: "#000",
                    }}
                  >
                    {el.text}
                  </th>
                )
              )
            )}
          </tr>
          {data.map((elm, index) => (
            <tr>
              {elm.map((el, index1) =>
                index1 === 0 ? (
                  <td
                    style={{
                      position: "sticky",
                      zIndex: "9999",
                      background: "#fff",
                      left: "-2px",
                      color: "#000",
                    }}
                    className={classes["time-range"]}
                  >
                    <div className={classes["table-cell"]}>{el.text}</div>
                  </td>
                ) : (
                  <td
                    style={
                      calendar.isAfter(moment(`${el.date} ${el.range[1]}`)) ===
                      true
                        ? {
                            marginLeft: "10px",
                            background: "rgb(183 191 205 / 91%)",
                          }
                        : {
                            marginLeft: "10px",
                            // background: element?.find((i) => i === index1)
                            //   ? "#fceea7"
                            //   : "",
                          }
                    }
                    className="long"
                    onClick={() => onCellClick(el, index, index1)}
                  >
                    {el.text}
                  </td>
                )
              )}
            </tr>
          ))}
        </table>
      </div>
    </>
  );
}
