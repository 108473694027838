import React, {useEffect, useState} from 'react'
import Input from '../../components/Input/index'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import Form from '../../components/Form/index'
import cls from './index.module.scss'
// import formPhoneNumber from "../../functions/formPhoneNumber";
import Button from '../../components/Buttons'
import validateForm from '../../functions/validateForm'
import InputMask from 'react-input-mask'
import Switch from '../../components/Switch/Index'

// import phoneNumber from "../../components/Input/phoneNumber"
import {toast} from 'react-toastify'
import {useHistory, useParams} from 'react-router-dom'
import axios from '../../utils/axios'
import {Checkbox, Typography} from '@material-ui/core'
import SvgIcon from '../../components/UploadWitchComment/Icons'
import {useSelector} from 'react-redux'

const RespondentUpdate = ({setAccountNumber, setUserName}) => {
  const {t} = useTranslation()
  const history = useHistory()
  const {id} = useParams()
  const [value, setValue] = useState({
    is_checked: 0,
    user_id: '',
  })
  const permissions = useSelector((state) => state.auth.permissions)
  const [verify, setVerify] = useState()

  const [checkValue, setCheckValue] = useState(false)
  const [getItemData, setGetItemData] = useState(false)

  const ValidationSchema = Yup.object().shape({
    first_name: validateForm('default', t),
    email: validateForm('email', t),
    phone: validateForm('phone', t),
    // phone: validateForm("phone/company/companyinquirycreate/_number", t)
  })
  const [initialValue, setitialValue] = useState({
    first_name: '',
    email: '',
    phone: '',
    company: '',
    position: '',
    balance: 0,
    rating: 0,
    created_at: '',
    description: '',
    active: 1,
    self_employee: 0,
    conformity: 0,
  })
  const label = {inputProps: {'aria-label': 'Checkbox demo'}}

  useEffect(() => {
    axios
      .get(`/respondent/${id}`)
      .then((res) => {
        setitialValue({...res.data})
        setAccountNumber(res.data.account_number)
        setGetItemData(true)
        setUserName(res.data.first_name)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id])

  const handleCheck = (e, id) => {
    setValue(() => ({
      is_checked: e.target.checked === true ? 1 : 0,
      user_id: id,
    }))
    setCheckValue(true)
  }

  const sendCost = (id) => {
    axios
      .put(`/respondent/is-checked`, {
        is_checked: value.is_checked,
        user_id: value.user_id,
      })
      .then((res) => {
        console.log(res)
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (checkValue) {
      sendCost()
      setCheckValue(false)
    }
  }, [checkValue])
  useEffect(() => {
    setVerify(permissions.includes(t('Верификация')))
  }, [permissions])
  const onSubmit = (data) => {
    axios
      .put(`/respondent/${id}`, {
        ...data,
        email: data.email.toLowerCase(),
      })
      .then((res) => {
        setAccountNumber(res.data.account_number)
        toast.success(t('Успешно'), {
          className: 'bg-green-500 text-white',
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        })
        history.goBack()
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return id?.length && getItemData ? (
    <div className='bg-white w-3/5' style={{borderRadius: '8px'}}>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div
              style={{
                padding: '16px 20px',
                boxShadow: 'inset 0px -1px 0px #EBEDEE',
                background: '#fff',
                borderRadius: '8px 8px 0 0',
              }}
            >
              <h1
                className='text-black'
                style={{
                  fontStyle: 'normal',
                  fontWeight: '700',
                  fontSize: '18px',
                  lineHeight: '24px',
                  color: '#1A2024',
                }}
              >
                {id ? 'Общие сведения' : 'Добавить новую компанию'}
              </h1>
            </div>
            <div className='p-7'>
              {initialValue?.is_check === 1 ? (
                <div className={cls.timeRow}>
                  <Typography className={cls.time}>
                    Проверен{' '}
                    <Checkbox
                      {...label}
                      color='primary'
                      defaultChecked={
                        initialValue?.is_check === 1 ? true : false
                      }
                      onChange={(e) => {
                        if (verify) handleCheck(e, id)
                      }}
                      checkedIcon={<SvgIcon name='check' />}
                      icon={<SvgIcon name='notCheck' />}
                      style={verify ? {} : {pointerEvents: 'none'}}
                    />
                  </Typography>
                </div>
              ) : (
                <div
                  className={cls.timeRow}
                  style={{maxWidth: '150px', background: '#F6F8F9'}}
                >
                  <Typography className={cls.time} style={{color: '#9AA6AC'}}>
                    Проверен{' '}
                    <Checkbox
                      {...label}
                      color='primary'
                      defaultChecked={
                        initialValue?.is_check === 1 ? true : false
                      }
                      onChange={(e) => {
                        if (verify) handleCheck(e, id)
                      }}
                      checkedIcon={<SvgIcon name='check' />}
                      icon={<SvgIcon name='notCheck' />}
                      style={verify ? {} : {pointerEvents: 'none'}}
                    />
                  </Typography>
                </div>
              )}
              <div className='w-full py-4 flex items-baseline'>
                <div className='w-1/3'>{t('Фамилия')}</div>
                <div className='w-2/3'>
                  <Form.Item name='last_name' formik={formik}>
                    <Input {...formik.getFieldProps('last_name')}></Input>
                  </Form.Item>
                </div>
              </div>
              <div className='w-full py-4 flex items-baseline'>
                <div className='w-1/3'>{t('Имя')}</div>
                <div className='w-2/3'>
                  <Form.Item name='first_name' formik={formik}>
                    <Input {...formik.getFieldProps('first_name')}></Input>
                  </Form.Item>
                </div>
              </div>
              <div className='w-full py-4 flex items-baseline'>
                <div className='w-1/3'>{t('Отчество')}</div>
                <div className='w-2/3'>
                  <Form.Item name='middle_name' formik={formik}>
                    <Input {...formik.getFieldProps('middle_name')}></Input>
                  </Form.Item>
                </div>
              </div>
              <div className='w-full py-4 flex items-baseline'>
                <div className='w-1/3'>{t('E-mail')}</div>
                <div className='w-2/3'>
                  <Form.Item name='email' formik={formik}>
                    <Input {...formik.getFieldProps('email')}></Input>
                  </Form.Item>
                </div>
              </div>
              <div className='w-full py-4 flex items-baseline'>
                <div className='w-1/3'>{t('Телефон')}</div>
                <div className='w-2/3'>
                  <Form.Item name='phone' formik={formik}>
                    <div
                      className='border bg-white flex space-x-2 items-center rounded-lg text-body px-2 border-gray-200 p-1 w-full font-smaller focus:ring focus:outline-none
                            transition focus:border-blue-300'
                    >
                      <InputMask
                        name='phone'
                        style={{padding: '0 10px'}}
                        {...formik.getFieldProps('phone')}
                        className='w-full text-gray-600'
                        mask={`999999999999`}
                        maskChar={null}
                      />
                    </div>
                  </Form.Item>
                </div>
              </div>
              <div className='w-full py-4 flex items-baseline'>
                <div className='w-1/3'>{t('Название компании')}</div>
                <div className='w-2/3'>
                  <Form.Item name='company' formik={formik}>
                    <Input {...formik.getFieldProps('company')}></Input>
                  </Form.Item>
                </div>
              </div>
              <div className='w-full py-4 flex items-baseline'>
                <div className='w-1/3'>{t('Должность')}</div>
                <div className='w-2/3'>
                  <Form.Item name='position' formik={formik}>
                    <Input {...formik.getFieldProps('position')}></Input>
                    {/*<phoneNumber {...formik.getFieldProps("email")}></phoneNumber>*/}
                  </Form.Item>
                </div>
              </div>
              <div className='w-full py-4 flex items-baseline'>
                <div className='w-1/3'>{t('Текущий баланс')}</div>
                <div className='w-2/3'>
                  <Form.Item name='balance' formik={formik}>
                    <Input
                      disabled={true}
                      {...formik.getFieldProps('balance')}
                    ></Input>
                    {/*<phoneNumber {...formik.getFieldProps("email")}></phoneNumber>*/}
                  </Form.Item>
                </div>
              </div>
              {/* <div className="w-full py-4 flex items-baseline">
                <div className="w-1/3">{t("Рейтинг")}</div>
                <div className="w-2/3">
                  <Form.Item name="rating" formik={formik}>
                    <Input
                      disabled={true}
                      {...formik.getFieldProps("rating")}
                    ></Input>
                    <phoneNumber {...formik.getFieldProps("email")}></phoneNumber>
                  </Form.Item>
                </div>
              </div> */}
              <div className='w-full py-4 flex items-baseline'>
                <div className='w-1/3'>
                  {t('Дата регистрации на платформе')}
                </div>
                <div className='w-2/3'>
                  <Form.Item name='created_at' formik={formik}>
                    <Input
                      disabled={true}
                      {...formik.getFieldProps('created_at')}
                    ></Input>
                    {/*<phoneNumber {...formik.getFieldProps("email")}></phoneNumber>*/}
                  </Form.Item>
                </div>
              </div>
              <div className='w-full py-4 flex items-baseline'>
                <div className='w-1/3'>
                  {t('Самозанятый или готов стать самозанятым')}
                </div>
                <div className='w-2/3'>
                  <Checkbox
                    {...label}
                    color='primary'
                    defaultChecked={
                      initialValue.self_employee === 1 ? true : false
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        'self_employee',
                        e.target.checked === true ? 1 : 0
                      )
                    }}
                  />
                </div>
              </div>
              <div className='w-full py-4 flex items-baseline'>
                <div className='w-1/3'>
                  {t('ФИО, возраст соответствуют ПД')}
                </div>
                <div className='w-2/3'>
                  <Checkbox
                    {...label}
                    color='primary'
                    defaultChecked={
                      initialValue.conformity === 1 ? true : false
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        'conformity',
                        e.target.checked === true ? 1 : 0
                      )
                    }}
                  />
                </div>
              </div>
              <div className='w-full py-4 flex items-baseline'>
                <div className='w-1/3'>{t('Дополнительная информация')}</div>
                <div className='w-2/3'>
                  <Form.Item name='description' formik={formik}>
                    <Input
                      {...formik.getFieldProps('description')}
                      style={{minHeight: '100px', alignItems: 'baseline'}}
                    ></Input>
                  </Form.Item>
                </div>
              </div>
              <div className='w-full py-4'>
                <div className='w-full py-4 flex items-baseline'>
                  <div className='w-1/3'>{t('Заблокировать')}</div>
                  <div className='w-2/3'>
                    <Switch
                      id='is_active'
                      defaultChecked={initialValue.active === 1 ? false : true}
                      onChange={(e) => {
                        formik.setFieldValue('active', e ? 0 : 1)
                      }}
                      color='primary'
                    />
                  </div>
                </div>
                <Form.Item formik={formik}>
                  <div className='flex gap-2 float-right'>
                    <Button
                      onClick={() => history.goBack()}
                      style={{
                        color: 'blue',
                        border: '1px solid rgba(229, 233, 235, 1)',
                        padding: '20px ,16px',
                        background: 'white',
                      }}
                    >
                      Назад
                    </Button>
                    <Button type='submit'>Сохранить</Button>
                  </div>
                </Form.Item>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  ) : (
    <></>
  )
}

export default RespondentUpdate
