import React, { useEffect, useState } from 'react'
import Input from '../../components/Input/index'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import Form from '../../components/Form/index'
// import formPhoneNumber from "../../functions/formPhoneNumber";
import Button from '../../components/Buttons'
import validateForm from '../../functions/validateForm'
// import phoneNumber from "../../components/Input/phoneNumber"
import company from '../../models/conpany'
import { toast } from 'react-toastify'
import Hearder from '../../components/Header/Header'
import { useHistory, useParams } from 'react-router-dom'
import Breadcrumbs from '../../components/Breadcrumb'
import Switch from '../../components/Switch/Index'
import Select from '../../components/Select/index'
import Admins from '../../models/permissions'
import InputMask from 'react-input-mask'

const AdminPanel = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()

  const ValidationSchema = Yup.object().shape({
    email: validateForm('email', t),
    name: validateForm('name', t),
  })
  const [getRoll, setGetRoll] = useState({})
  const [data, getData] = useState(null)
  const [initialValue, setInitialValue] = useState({
    active: 1,
    email: '',
    id: '',
    name: '',
    phone: '',
    role_id: '',
  })
  const getAllRole = () => {
    const param = {
      client_type_id: '5a3818a9-90f0-44e9-a053-3be0ba1e2c11',
    }
    Admins.getAllRolls(param)
      .then((res) => {
        console.log(res)
        setGetRoll(() => [
          ...res.data.roles.map((e) => ({
            ...e,
            label: e.name,
          })),
        ])
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getDataAdmin = () => {
    Admins.getDataAdmin(params.id)
      .then((res) => {
        console.log(res)
        setInitialValue((old) => ({
          ...old,
          active: res.data.active,
          email: res.data.email,
          id: res.data.id,
          name: res.data.name,
          phone: res.data.phone,
          role_id: res.data.role_id,
        }))
        getData(true)
      })
      .catch((err) => console.log(err))
  }

  const updateAdmin = (data) => {
    const sendData = {
      client_type_id: '5a3818a9-90f0-44e9-a053-3be0ba1e2c11',
      role_id: initialValue.role_id,
      email: data.email,
      name: data.name,
      phone: data.phone,
      active: initialValue.active,
    }
    Admins.updateDataAdmin(params.id, sendData)
      .then((res) => {
        console.log(res)
        toast.success(t('Успешно'), {
          className: 'bg-green-500 text-white',
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        })
        history.goBack()
      })
      .catch((err) => {
        toast.error(t(`${err.data.error}`), {
          className: 'bg-red-500 text-white',
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        })
      })
  }

  const CreateAdmin = (data) => {
    const sendData = {
      client_type_id: '5a3818a9-90f0-44e9-a053-3be0ba1e2c11',
      role_id: initialValue.role_id,
      email: data.email,
      name: data.name,
      phone: data.phone,
      password: data.password,
      active: initialValue.active,
    }
    Admins.createDataAdmin(sendData)
      .then((res) => {
        toast.success(t('Успешно'), {
          className: 'bg-green-500 text-white',
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        })
        history.goBack()
      })
      .catch((err) => {
        console.log(err)
        toast.error(t(`${err.data.error}`), {
          className: 'bg-red-500 text-white',
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        })
      })
  }

  const onSubmit = (data) => {
    if (params.id) {
      updateAdmin(data)
    } else {
      CreateAdmin(data)
    }
  }

  useEffect(() => {
    getAllRole()
    if (params.id) {
      getDataAdmin()
    }
  }, [])
  return (
    <div>
      <Hearder title={t('Админ')} />

      {params.id ? (
        data && (
          <div className='p-7'>
            <div className='p-5 bg-white w-3/5' style={{ borderRadius: '8px' }}>
              <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                validationSchema={ValidationSchema}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    <div>
                      <h1
                        className='text-black'
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          fontSize: '18px',
                          lineHeight: '24px',
                          paddingBottom: '16px',
                          color: 'rgba(26, 32, 36, 1)',
                        }}
                      >
                        {'Изминение роли сотрудника'}
                      </h1>
                      <hr />
                      <div className='w-full py-4 flex items-baseline'>
                        <div className='w-1/3'>{t('full.name')}</div>
                        <div className='w-2/3'>
                          <Form.Item name='name' formik={formik}>
                            <Input {...formik.getFieldProps('name')}></Input>
                          </Form.Item>
                        </div>
                      </div>
                      <div className='w-full py-4 flex items-baseline'>
                        <div className='w-1/3'>{t('Телефона')}</div>
                        <div className='w-2/3'>
                          <Form.Item name='phone' formik={formik}>
                            <InputMask
                              id='inputmask'
                              style={{ maxHeight: '33px', marginTop: '0' }}
                              className='text-gray-600'
                              mask={`+9999999999999999`}
                              maskChar={null}
                              {...formik.getFieldProps('phone')}
                            />
                            {/* <Input ></Input> */}
                          </Form.Item>
                        </div>
                      </div>
                      <div className='w-full py-4 flex items-baseline'>
                        <div className='w-1/3'>{t('Email')}</div>
                        <div className='w-2/3'>
                          <Form.Item name='email' formik={formik}>
                            <Input {...formik.getFieldProps('email')}></Input>
                            {/*<phoneNumber {...formik.getFieldProps("email")}></phoneNumber>*/}
                          </Form.Item>
                        </div>
                      </div>

                      <div className='w-full py-4 flex items-baseline'>
                        <div className='w-1/3'>{t('Название роли')}</div>
                        <div className='w-2/3'>
                          <Form.Item formik={formik}>
                            <Select
                              options={getRoll}
                              onChange={(e) => {
                                setInitialValue((el) => ({
                                  ...el,
                                  role_id: e.id,
                                }))
                              }}
                              defaultValue={
                                getRoll?.length >= 1
                                  ? getRoll.filter(
                                      (el) => el.id === initialValue.role_id
                                    )[0]
                                  : {}
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className='w-full py-4 flex items-baseline'>
                        <div className='w-1/3'>{t('Заблокировать')}</div>
                        <div className='w-2/3'>
                          <Switch
                            id='is_active'
                            defaultChecked={
                              initialValue.active === 1 ? false : true
                            }
                            onChange={(e) => {
                              setInitialValue({
                                ...initialValue,
                                active: e ? 0 : 1,
                              })
                            }}
                            color='primary'
                          />
                        </div>
                      </div>

                      <div className='w-full py-4'>
                        <Form.Item formik={formik}>
                          <div className='flex gap-2 float-right'>
                            <Button
                              onClick={() => history.goBack()}
                              style={{
                                color: 'blue',
                                border: '1px solid rgba(229, 233, 235, 1)',
                                padding: '20px ,16px',
                                background: 'white',
                              }}
                            >
                              Назад
                            </Button>
                            <Button type='submit'>Сохранить</Button>
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        )
      ) : (
        <div className='p-7'>
          <div className='p-5 bg-white w-3/5' style={{ borderRadius: '8px' }}>
            <Formik
              initialValues={initialValue}
              onSubmit={onSubmit}
              validationSchema={ValidationSchema}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <div>
                    <h1
                      className='text-black'
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        lineHeight: '24px',
                        paddingBottom: '16px',
                        color: 'rgba(26, 32, 36, 1)',
                      }}
                    >
                      {'Добавить нового сотрудника'}
                    </h1>
                    <hr />
                    <div className='w-full py-4 flex items-baseline'>
                      <div className='w-1/3'>{t('full.name')}</div>
                      <div className='w-2/3'>
                        <Form.Item name='name' formik={formik}>
                          <Input {...formik.getFieldProps('name')}></Input>
                        </Form.Item>
                      </div>
                    </div>
                    <div className='w-full py-4 flex items-baseline'>
                      <div className='w-1/3'>{t('Телефона')}</div>
                      <div className='w-2/3'>
                        <Form.Item name='phone' formik={formik}>
                          <InputMask
                            id='inputmask'
                            style={{ maxHeight: '33px', marginTop: '0' }}
                            className='text-gray-600'
                            mask={`+9999999999999999`}
                            maskChar={null}
                            {...formik.getFieldProps('phone')}
                          />
                          {/* <Input ></Input> */}
                        </Form.Item>
                      </div>
                    </div>
                    <div className='w-full py-4 flex items-baseline'>
                      <div className='w-1/3'>{t('Email')}</div>
                      <div className='w-2/3'>
                        <Form.Item name='email' formik={formik}>
                          <Input {...formik.getFieldProps('email')}></Input>
                          {/*<phoneNumber {...formik.getFieldProps("email")}></phoneNumber>*/}
                        </Form.Item>
                      </div>
                    </div>

                    <div className='w-full py-4 flex items-baseline'>
                      <div className='w-1/3'>{t('Password')}</div>
                      <div className='w-2/3'>
                        <Form.Item name='passwrod' formik={formik}>
                          <Input {...formik.getFieldProps('password')}></Input>
                          {/*<phoneNumber {...formik.getFieldProps("email")}></phoneNumber>*/}
                        </Form.Item>
                      </div>
                    </div>
                    <div className='w-full py-4 flex items-baseline'>
                      <div className='w-1/3'>{t('Название Роли')}</div>
                      <div className='w-2/3'>
                        {/* <Form.Item name='name' formik={formik}> */}
                        <Select
                          options={getRoll}
                          onChange={(e) => {
                            setInitialValue((el) => ({
                              ...el,
                              role_id: e.id,
                            }))
                          }}
                          defaultValue={
                            getRoll?.length >= 1
                              ? getRoll.filter(
                                  (el) => el.id === initialValue.role_id
                                )[0]
                              : {}
                          }
                        />
                      </div>
                    </div>
                    <div className='w-full py-4 flex items-baseline'>
                      <div className='w-1/3'>{t('Заблокировать')}</div>
                      <div className='w-2/3'>
                        <Switch
                          id='is_active'
                          defaultChecked={
                            initialValue.active === 1 ? false : true
                          }
                          onChange={(e) => {
                            setInitialValue({
                              ...initialValue,
                              active: e ? 0 : 1,
                            })
                          }}
                          color='primary'
                        />
                      </div>
                    </div>

                    <div className='w-full py-4'>
                      <Form.Item formik={formik}>
                        <div className='flex gap-2 float-right'>
                          <Button
                            onClick={() => history.goBack()}
                            style={{
                              color: 'blue',
                              border: '1px solid rgba(229, 233, 235, 1)',
                              padding: '20px ,16px',
                              background: 'white',
                            }}
                          >
                            Назад
                          </Button>
                          <Button type='submit'>Сохранить</Button>
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  )
}

export default AdminPanel
