import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Header from "../../components/Header/Header";
import Breadcrumbs from "../../components/Breadcrumb";
import axios from "../../utils/axios";
import {useParams} from "react-router";

import Input from "../Input";
const AnswerItem = () => {
  const {t} = useTranslation();
  const params = useParams();
  const [items, setItems] = useState();
  const item = items?.items[0]?.solutions[0]?.output_values;
  const routes = [
    {
      title: "Ответы",
      link: true,
      route: `${"/home/company/inquiryitem/answers"}/${params.id}`,
    },
  ];

  const getToloka = () => {
    const data = {
      sort: "created",
      pool_id: params.pool_id,
      user_id: params.user_id,
    };
    axios
      .get("/toloka/responses", {params: data})
      .then((res) => {
        console.log("res", res);
        setItems(JSON.parse(res.data));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    params.pool_id && getToloka();
  }, [params.user_id, params.pool_id]);

  return (
    <div>
      <Header startAdornment={[<Breadcrumbs routes={routes} />]} />
      <div
        className="w-full py-4 m-5"
        style={{
          background: "#fff",
          padding: "24px",
          margin: "12px",
          maxWidth: "-webkit-fill-available",
        }}
      >
        <h2
          style={{
            fontSize: "24px",
            fontWeight: "700",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Пользователь {params.index}
        </h2>
        {item
          ? Object.entries(item).map(([key, value]) => (
              <div>
                <div className="w-1/3 mt-2 mb-1">{key}</div>
                <div className="w-3/3">
                  <Input value={value} disabled={true} />
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default AnswerItem;
