import "./style.scss";
import {useCallback, useState, useEffect} from "react";
import {useDropzone} from "react-dropzone";
import SvgIcon from "./Icons";
import FilesList from "./FileList";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "../../components/Buttons/index";
import axios from "../../utils/axios";
import {useParams} from "react-router-dom";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";

export default function UploadWithComment({
  title = "Drag and drop files here",
  buttonTitle = "Browse",
  style,
  className,
  loading,
  disabled,
  uploadImage,
  setMultipleUrl,
  setUrlNDA,
  nda = false,
  setFiles,
  files,
  setNdaFiles,
  ndaFiles,
  initialValue,
  multipleUrl,
  urlNda,
  placeholder = "Comment...",
  ndaData,
  onChange = function () {},
}) {
  const onDrop = useCallback((acceptedFiles) => {
    uploadImage(acceptedFiles);
    // if (!nda) {
    //   setFiles((old) => [
    //     ...old,
    //     {
    //       file: {
    //         name: link,
    //       },
    //     },
    //   ])
    // } else {
    //   setNdaFiles((old) => [
    //     ...old,
    //     Object.keys(ndaData).map((el) => ({
    //       file: { name: ndaData[el] },
    //     })),
    //   ])
    // }
  }, []);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  return (
    <div className={`upload-with-comment ${className}`} style={style}>
      {/* DROPZONE */}
      <div {...getRootProps()} className="dropzone" style={{height: 130}}>
        <input {...getInputProps()} />
        <MoveToInboxIcon style={{fontSize: "30px"}} />
        <p className="title">
          <h1>Загрузить файл</h1>
          <h1>Допустимый формат: pdf, .doc, .docx до 5 Мб</h1>
        </p>
      </div>

      {/* FILES-LIST */}
      <FilesList
        files={!nda ? files : ndaFiles}
        setFiles={!nda ? setFiles : setNdaFiles}
        placeholder={placeholder}
        addUploadedFile={onChange}
        setMultipleUrl={!nda ? setMultipleUrl : setUrlNDA}
      />

      {/* WRAPPER */}
      <div
        className="wrapper"
        style={{visibility: disabled || loading ? "visible" : "hidden"}}
      >
        {loading && <CircularProgress size={40} />}
      </div>
    </div>
  );
}
