import { combineReducers } from 'redux'
import counterReducer from './counterReducer'
import authReducer from './authReducer'
import steppersItem from './companyInquiryCreateAllData'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

// import companyName from "./companyName"

const authPersistConfig = {
  key: 'auth',
  storage,
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  counter: counterReducer,
  // regstratsiya: regstratsiya,
  steppersItemData: steppersItem,
  // compName: companyName
})

export default rootReducer
