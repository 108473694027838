export const STATUSES = [
    {
        value: '72ee7350-4a2b-4fc2-945c-34faa56b13ca',
        label: 'Черновик'
    },
    {
        value: '7b75685f-ee20-4fb9-bc5f-7e40c115e708',
        label: 'Активный'
    },
    {
        value: '2e857fbe-9add-4eae-a8c4-fe57fb384347',
        label: 'Завершено'
    },
    {
        value: '2a98c22e-cbee-49f9-90ef-f56429d96333',
        label: 'Отменено'
    }
]