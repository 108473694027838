import React, {useState, useEffect} from 'react'
import Header from '../../components/Header/Header'
import Breadcrumbs from '../../components/Breadcrumb'
import {useTranslation} from 'react-i18next'
import {useHistory, useParams} from 'react-router-dom'
import Filter from '../../components/Filter'
import {StyledTabs, StyledTab} from '../../components/StyledTabs/index'
import {TabPanel} from '../../components/Tab/TabBody'
import ResearcherItemCompany from './researcherCreateItemCompany'
import Indexupdata from './indexupdata'
import Inquiries from './Inquiries'
import {useSelector} from 'react-redux'
import Calendar from '../company/Calendar'

const ResearcherUpdateList = () => {
  const {t} = useTranslation()
  const parmas = useParams()
  const history = useHistory()
  const [userName, setUserName] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [tabvalue, settabvalue] = useState(0)
  const currentPage = useSelector((state) => state.counter)

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    }
  }
  const tabLabel = (text, isActive = false) => {
    return <span className='px-1'>{text}</span>
  }

  const [updateType, setupdateType] = useState(true)
  const handleChangeTab = (event, newvalue) => {
    settabvalue(newvalue)
  }
  const routes = [
    {
      title: t(`${companyName === undefined ? 'User' : companyName}`),
      link: true,
      route: `${'/home/company/companyinquiry'}/${parmas.id}`,
    },
    {
      title: userName === undefined ? 'User' : userName,
      link: true,
    },
  ]
  return (
    <div>
      <Header startAdornment={[<Breadcrumbs routes={routes} />]}></Header>
      <Filter>
        <StyledTabs
          value={tabvalue}
          onChange={handleChangeTab}
          centered={false}
          aria-label='full width tabs example'
          TabIndicatorProps={{children: <span className='w-2' />}}
        >
          <StyledTab
            label={tabLabel(t('company.info'))}
            {...a11yProps(0)}
            style={{width: '100px'}}
          />
          <StyledTab
            label={tabLabel(t('Исследования'))}
            {...a11yProps(0)}
            style={{width: '100px'}}
          />
          <StyledTab
            label={tabLabel(t('Календарь'))}
            {...a11yProps(0)}
            style={{width: '100px'}}
          />
        </StyledTabs>
      </Filter>
      <div>
        <TabPanel value={tabvalue} index={0}>
          <Indexupdata
            setCompanyName={setCompanyName}
            setUserName={setUserName}
          />
        </TabPanel>
        <TabPanel value={tabvalue} index={1}>
          <Inquiries currentPage={currentPage} company={true} />
        </TabPanel>
        <TabPanel value={tabvalue} index={2}>
          <Calendar show={false} />
        </TabPanel>
      </div>
    </div>
  )
}

export default ResearcherUpdateList
