import React from 'react'
import Header from '../../components/Header/Header'
import cls from './index.module.scss'
import AdminPanelPermission from './AdminPanelPermission'

export default function TablePermission() {
  return (
    <div className={cls.root}>
      <Header space={true} />
      <AdminPanelPermission />
    </div>
  )
}
