// dependency
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

// import {Formik} from  "formik"
// import *as Yup from "yup"

// components
import Button from "../Buttons/index";
import Input from "../Input";
import Select from "../Select";

// style class
import cls from "./test.module.scss";

// Service model
import Company from "../../models/companyinquiry";
import Attribut from "../../models/attributs";
import axios from "../../utils/axios";
// import ResSearechs from "../../models/researchers";

// functions
import UploadWithComment from "../UploadWitchComment";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import Switch from "../../components/Switch/Index";

export default function StepperTwoList({
  handleNext,
  handleBack,
  operationSistems,
  devices,
  researchersList,
  setinitialValue,
  initialValue,
  totalAmount,
  totalCostWithoutNds,
  calculateAmount,
  files,
  setFiles,
  ndaFiles,
  setNdaFiles,
  activeStep,
  handleBlur,
  disableTime,
  next,
  region,
  params,
  audience,
  is_supported,
  getRegions,
  attributeValue,
  getInterests,
  interests,
  active,
  setActive,
}) {
  const [stepCount, setstepCount] = useState(0);
  const [defoultstepCount, setdefoultstepCount] = useState(30);
  const [secondDefoultstepCount, setSecondDefoultstepCount] = useState(100);
  const [checkStauts, setCheckStatus] = useState(false);
  const company = useParams();
  const {t} = useTranslation();
  const [globalVariable, setGlobalVariable] = useState(null);
  const [link, setLink] = useState();
  const [multipleUrl, setMultipleUrl] = useState([]);
  const [urlNDA, setUrlNDA] = useState([]);
  const [getDevice, setGetDevice] = useState([]);
  const [getCountry, setCountry] = useState([]);
  const [getInterest, setInterest] = useState([]);
  const [getOS, setGetOS] = useState([]);
  const [attributslist, setattibutslist] = useState([]);
  const [checkValue, setCheckValue] = useState(false);
  const [promocode, setPromocode] = useState(false);
  const [promocodeSum, setPromocodeSumm] = useState(false);

  console.log("active", active);

  useEffect(() => {
    setUrlNDA([...urlNDA, initialValue.example_nda]);
  }, [company.activestep]);

  const uploadImage = (props) => {
    const file = props[0];

    const formData = new FormData();
    formData.append(`file`, file);
    axios
      .post(`/upload/file`, formData)
      .then((res) => {
        setMultipleUrl((old) => [...old, res.data.url]);
        setFiles((old) => [
          ...old,
          {
            file: {
              name: res.data.url,
            },
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Допустимый размер файла не более 5 МБ", {
          className: "bg-red-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        });
      });
  };
  const makeObject = (arr) => {
    let linksObject = {};
    arr.forEach((elm, i) => {
      linksObject[i + 1] = elm;
    });
    return linksObject;
  };

  const filterDevce = () => {
    for (let i = 0; i < 3; i++) {
      let result = devices.filter(
        (el, ind) => el.value === initialValue.device[ind]
      )?.[i];
    }
  };

  const data = makeObject(multipleUrl);
  const ndaData = makeObject(urlNDA);
  const device = makeObject(getDevice.map((el) => el.value));
  const country = getCountry.map((el) => el);
  const interest = getInterest.map((el) => el);
  const districts = attributeValue?.map((el) => el);
  const byInterest = interests?.map((el) => el);
  const os = makeObject(getOS.map((el) => el.value));

  const secondUploadImage = (props) => {
    const file = props[0];

    const formData = new FormData();
    formData.append(`file`, file);
    axios
      .post(`/upload/file`, formData)
      .then((res) => {
        setUrlNDA((old) => [...old, res.data.url]);
        setNdaFiles((old) => [
          ...old,
          {
            file: {
              name: res.data.url,
            },
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Допустимый размер файла не более 5 МБ", {
          className: "bg-red-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        });
      });
  };
  // Device
  useEffect(() => {
    setinitialValue({
      ...initialValue,
      device: Boolean(device[1]) ? device : {1: "smartphone"},
    });
  }, [getDevice]);

  useEffect(() => {
    const param = {
      requirement_attribute_id: "1a2eeeac-5722-4908-823c-ea8c0fe47e57",
    };
    if (next && activeStep === 1) {
      getInterests(param);
    }
  }, [activeStep, next]);

  // Country;
  useEffect(() => {
    setinitialValue((prev) => ({
      ...prev,
      inquiry_info: {
        district: Boolean(country[1]) ? country : districts,
        by_interest: prev?.inquiry_info?.by_interest,
      },
    }));
  }, [initialValue.region, attributeValue, getCountry]);

  //Interests
  useEffect(() => {
    setinitialValue((prev) => ({
      ...prev,
      inquiry_info: {
        district: prev?.inquiry_info?.district,
        by_interest: Boolean(interest[1]) ? interest : byInterest,
      },
    }));
  }, [initialValue.audience, interests, getInterest]);

  // OS
  useEffect(() => {
    setinitialValue({
      ...initialValue,
      os: Boolean(os[1]) ? os : {1: "windows"},
    });
  }, [getOS]);

  useEffect(() => {
    if (checkValue) {
      calculateAmount();
      setCheckValue(false);
    }
  }, [checkValue]);

  const GlobalCVarebel = () => {
    Company.globalVarebel(company.company_id).then((res) => {
      setGlobalVariable(res.data);
      // setCallFunction(res.data)

      const base_duration = res?.data?.global_variables?.filter(
        (element) => element.key === "base_duration"
      )[0].value;
      setdefoultstepCount(base_duration);
      setSecondDefoultstepCount(50);
      setstepCount((e) => ({base: base_duration, counter: base_duration}));
      if (!company.inquery_id) {
        setinitialValue((el) => ({
          ...el,
          duration: Number(base_duration),
        }));
      }
    });
  };
  const onSubmit = (event) => {
    handleNext();
  };

  function setValue(key, value) {
    setinitialValue((el) => ({...el, [key]: value}));
  }

  const getAllListAttribut = (params) => {
    Attribut.getAttribut(params).then((res) => {
      setattibutslist(res.data.attributes);
    });
  };

  useEffect(() => {
    GlobalCVarebel();
  }, []);

  useEffect(() => {
    if (checkStauts) {
      calculateAmount();
      setCheckStatus(false);
    }
  }, [checkStauts]);
  useEffect(() => {
    calculateAmount({
      promo_code: promocode,
      promo_code_money: promocodeSum,
      promo: active ? true : false,
    });
  }, [active]);

  useEffect(() => {
    if (initialValue.researcher_id) {
      setinitialValue((old) => ({
        ...old,
        researcher_name: researchersList.filter(
          (el) => el.value === initialValue.researcher_id
        )[0]?.label,
      }));
    }
  }, [initialValue.researcher_id]);
  useEffect(() => {
    if (company.inquiry_id) {
      handleBlur("researcher_name", initialValue.researcher_name);
    }
  }, [initialValue]);

  useEffect(() => {
    if (researchersList)
      return setinitialValue((old) => ({
        ...old,
        researcher_id: researchersList?.[0]?.value,
      }));
  }, [researchersList]);
  return (
    <>
      <div className={cls.root}>
        <div className="w-full h-full bg-white m-auto p-5">
          <div className="pt-2 flex w-full ">
            <div className=" w-full">
              {!next && (
                <>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">
                      {t("Инициатор запроса")}
                      <span
                        style={{
                          color: "red",
                          marginLeft: "2px",
                        }}
                      >
                        *
                      </span>
                    </div>
                    <div className="w-2/3">
                      <Select
                        options={researchersList}
                        onChange={(e) => {
                          setinitialValue((el) => ({
                            ...el,
                            researcher_id: e.value,
                          }));
                        }}
                        value={
                          researchersList
                            ? researchersList.filter(
                                (el) => el.value === initialValue.researcher_id
                              )[0]
                            : {}
                        }
                      />
                    </div>
                  </div>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">
                      {t("Устройство для подключения")}
                    </div>
                    <div className="w-2/3">
                      <Select
                        isMulti={true}
                        onChange={(val) => {
                          setGetDevice(val.map((el) => el));
                        }}
                        value={
                          getDevice < 1
                            ? devices.filter(
                                (el, ind) => initialValue.device[ind + 1]
                              )
                            : getDevice.filter((el) => el)
                        }
                        options={devices}
                      />
                    </div>
                  </div>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">{t("Операционная система")}</div>
                    <div className="w-2/3">
                      <Select
                        isMulti={true}
                        onChange={(val) => {
                          setGetOS(val.map((el) => el));
                        }}
                        value={
                          getOS < 1
                            ? operationSistems.filter(
                                (el, ind) => initialValue.os[ind + 1]
                              )
                            : getOS.filter((el) => el)
                        }
                        options={operationSistems}
                      />
                      {/* <Select
                  isMulti={true}
                    onChange={(val) => {
                      setinitialValue((old) => ({...old, os: val.value}));
                    }}
                    options={operationSistems}
                    value={
                      operationSistems.filter(
                        (el) => el.value === initialValue.os
                      )[0]
                    }
                  /> */}
                    </div>
                  </div>
                </>
              )}
              {next ? (
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">{t("Страна")}</div>
                  <div className="w-2/3">
                    <Select
                      onChange={(val) => {
                        setinitialValue((old) => ({
                          ...old,
                          region: val,
                        }));
                        const param = {
                          requirement_attribute_id: val.value,
                        };
                        getRegions(param);
                        setCheckValue(true);
                      }}
                      options={region}
                      defaultValue={
                        region.filter(
                          (el) => el.value === initialValue.region.value
                        )[0]
                      }
                    />
                  </div>
                </div>
              ) : null}
              {next && (
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">{t("Город")}</div>
                  <div className="w-2/3">
                    <Select
                      isMulti={true}
                      onChange={(val) => {
                        setCountry(val.map((el) => el));
                      }}
                      value={
                        getCountry < 1
                          ? attributeValue?.filter(
                              (el, ind) => initialValue.inquiry_info.district
                            )
                          : getCountry.filter((el) => el)
                      }
                      options={attributeValue}
                    />
                  </div>
                </div>
              )}
              {next && (
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">{t("Опрос узкой аудитории")}</div>
                  <div className="w-2/3">
                    <Select
                      onChange={(val) => {
                        setCheckValue(true);
                        setinitialValue((old) => ({
                          ...old,
                          audience: val,
                        }));
                      }}
                      options={audience}
                      defaultValue={
                        audience.filter(
                          (el) => el.value === initialValue.audience.value
                        )[0]
                      }
                    />
                  </div>
                </div>
              )}
              {next &&
              initialValue.audience.value ===
                "1a2eeeac-5722-4908-823c-ea8c0fe47e57" ? (
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">
                    {t("Опросить людей, которые интересуются темой")}
                  </div>
                  <div className="w-2/3">
                    <Select
                      isMulti={true}
                      onChange={(val) => {
                        setInterest(val.map((el) => el));
                      }}
                      value={
                        getInterest < 1
                          ? interests?.filter(
                              (el, ind) =>
                                initialValue.inquiry_info.by_interest[ind + 1]
                            )
                          : getInterest.filter((el) => el)
                      }
                      options={interests}
                    />
                  </div>
                </div>
              ) : null}
              {next ? (
                <>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">{t("Сопровождение")}</div>
                    <div className="w-2/3">
                      <Select
                        onChange={(val) => {
                          setCheckValue(true);
                          setinitialValue((old) => ({
                            ...old,
                            is_supported: val.value,
                          }));
                        }}
                        options={is_supported}
                        defaultValue={
                          is_supported.filter(
                            (el) => el.value === initialValue.is_supported
                          )[0]
                        }
                      />
                    </div>
                  </div>
                  <div className="w-full  flex items-baseline">
                    <div className="w-1/3"></div>
                    <div className="w-2/3">
                      <p style={{fontWeight: "500"}}>{t("support")}</p>
                    </div>
                  </div>
                </>
              ) : null}
              {!next && (
                <>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">
                      {t("Опишите, что необходимо приложить респонденту")}
                    </div>
                    <div className="w-2/3">
                      <Input
                        className="p-0.5"
                        placeholder={
                          "Опишите, что необходимо загрузить респонденту к заявке"
                        }
                        type={"text"}
                        value={initialValue.nda_text}
                        onChange={(e) => {
                          company.inquiry_id
                            ? handleBlur("nda_text", e.target.value)
                            : setValue("nda_text", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">
                      {t("Загрузите шаблон или пример файла для респондента")}
                    </div>
                    <div className="w-2/3">
                      <UploadWithComment
                        link={link}
                        ndaData={ndaData}
                        files={files}
                        setFiles={setFiles}
                        nda={false}
                        initialValue={initialValue}
                        setMultipleUrl={setMultipleUrl}
                        multipleUrl={multipleUrl}
                        uploadImage={uploadImage}
                      />
                    </div>
                  </div>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">
                      {t(
                        "Загрузите пример документа для заполнения или подписания"
                      )}
                    </div>
                    <div className="w-2/3">
                      <UploadWithComment
                        ndaData={ndaData}
                        setNdaFiles={setNdaFiles}
                        ndaFiles={ndaFiles}
                        initialValue={initialValue}
                        nda={true}
                        urlNDA={urlNDA}
                        setUrlNDA={setUrlNDA}
                        uploadImage={secondUploadImage}
                      />
                    </div>
                  </div>
                  <hr className="my-2"></hr>
                </>
              )}
              {!next && (
                <div className="w-full py-4 flex align-center">
                  <div className="w-1/3">{t("Вознаграждение промокодом")}</div>
                  <div className="w-2/3">
                    <Switch
                      defaultChecked={active}
                      onChange={(e) => {
                        setActive(e);
                      }}
                      color="primary"
                    />
                  </div>
                </div>
              )}
              {active && !next ? (
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">{t("Промокод")}</div>
                  <div className="w-2/3">
                    <Input
                      className="p-0.5"
                      placeholder={"Введите промокод"}
                      type={"text"}
                      value={initialValue.promo_code}
                      onChange={(e) => {
                        setValue("promo_code", e.target.value);
                        // setPromocode(e.target.value);
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {active && !next ? (
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">{t("Номинал промокода")}</div>
                  <div className="w-2/3">
                    <Input
                      className="p-0.5"
                      placeholder={"Укажите номинал промокода"}
                      type={"nuber"}
                      value={initialValue.promo_code_money}
                      onChange={(e) => {
                        setValue("promo_code_money", e.target.value);
                        // setPromocodeSumm(e.target.value);
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {!next && (
                <div className="w-full py-4 flex align-center">
                  <div className="w-1/3">{t("Длительность (мин)")}</div>
                  <div className="w-2/3">
                    <Input
                      disabled={disableTime}
                      step={defoultstepCount}
                      min={defoultstepCount}
                      type={"number"}
                      className="p-0.5"
                      value={initialValue.duration}
                      onKeyDown={(e) => {
                        !(e.keyCode === 38 || e.keyCode === 40) &&
                          e.preventDefault();
                      }}
                      onChange={(e) => {
                        // setstepCount((el) => ({...el, base: e.target.value}));
                        setinitialValue((el) => ({
                          ...el,
                          duration: Number(
                            `${
                              parseInt(e.target.value) < 30
                                ? 30
                                : parseInt(e.target.value)
                            }`
                          ),
                        }));
                      }}
                    />
                  </div>
                </div>
              )}
              {next ? (
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">{t("Количество респондентов")}</div>
                  <div className="w-2/3">
                    <Input
                      step={secondDefoultstepCount}
                      min={secondDefoultstepCount}
                      type={"number"}
                      className="p-0.5"
                      value={initialValue.number_of_partisipants}
                      onKeyDown={(e) => {
                        !(e.keyCode === 38 || e.keyCode === 40) &&
                          e.preventDefault();
                      }}
                      onChange={(e) => {
                        setinitialValue((el) => ({
                          ...el,
                          number_of_partisipants: Number(
                            `${
                              parseInt(e.target.value) < 50
                                ? 50
                                : parseInt(e.target.value)
                            }`
                          ),
                        }));
                        setCheckStatus(true);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">{t("Количество респондентов")}</div>
                  <div className="w-2/3">
                    <Input
                      className="p-0.5"
                      type={"number"}
                      min={1}
                      disabled={
                        initialValue.type_of_inquiry.type_of_inquiry === "group"
                          ? true
                          : false
                      }
                      value={
                        initialValue.type_of_inquiry.type_of_inquiry === "group"
                          ? initialValue.type_of_inquiry?.group_participants *
                            initialValue.type_of_inquiry?.number_of_groups
                          : initialValue.number_of_partisipants
                      }
                      onChange={(e) => {
                        setinitialValue((val) => ({
                          ...val,
                          number_of_partisipants: Number(e.target.value),
                        }));
                        setCheckStatus(true);
                      }}
                    />
                  </div>
                </div>
              )}

              {/* <div className='w-full py-4 flex items-baseline'>
                <div className='w-1/3'>{t('Цена')}</div>
                <div className='w-2/3'>
                  <Input
                    type={'number'}
                    disabled
                    value={initialValue.cost}
                    // onBlur={(e) => setCost(e.target.value)}
                    className='p-0.5'
                  />
                </div>
              </div> */}
              {/* <div className='w-full py-4 flex items-baseline'>
                <div className='w-1/3'>{t('Дополнительная цена')}</div>
                <div className='w-2/3'>
                  <Input
                    className='p-0.5'
                    type={'number'}
                    value={initialValue.additional_cost}
                    onChange={(e) => {
                      setAdditionalCost(e.target.value)
                      setCheckStatus(true)
                    }}
                  />
                </div>
              </div> */}
              <div className="w-full py-4 flex items-baseline">
                <div className="w-1/3 text-head">{t("Стоимость рекрута")}</div>
                <div className="w-2/3">
                  <Input
                    type={"number"}
                    className="p-0.5"
                    value={totalCostWithoutNds}
                    disabled
                  />
                </div>
              </div>
              <div className="w-full py-4 justify-between">
                <div className="w-full  float-right gap-4">
                  <Button
                    onClick={onSubmit}
                    // disabled={checkValidation}
                    style={{
                      background: "rgba(64, 148, 247, 1)",
                      paddingLeft: "10px",
                      float: "right",
                      padding: "10px",
                    }}
                  >
                    Продолжить
                  </Button>
                  <Button
                    className="float-right "
                    onClick={() => handleBack(0)}
                    style={{
                      marginRight: "5px",
                      color: "gray",
                      width: "100px",
                      borderRadius: "9px",
                      border: "1px solid rgba(229, 233, 235, 1)",
                      padding: " 10px",
                      background: "white",
                    }}
                  >
                    Назад
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
