import React, { useState , useEffect } from 'react'
import Header from '../../components/Header/Header'
import AddIcon from '@material-ui/icons/Add'
import Button from '../../components/Buttons/index'
import { StyledTabs, StyledTab } from '../../components/StyledTabs/index'
import { TabPanel } from '../../components/Tab/TabBody'
import Filter from '../../components/Filter'
import AdminPanelTableResearcher from './AdminPanelTableResearcher'
import ResearcherTablePermission from './ResearcherTablePermission'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector , useDispatch } from 'react-redux'
import { pageActionsTypes } from '../../redux/actions/currentPage/pageActionTypes'

const PermissionRoll = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [tabvalue, settabvalue] = useState(0)
  const history = useHistory()
  const activeTab = useSelector((state) => state.counter.currentTab)
  useEffect(() => {
    settabvalue(activeTab === undefined ? tabvalue : activeTab.tab)
  }, [])
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    }
  }
  const handleChangeTab = (event, newvalue) => {
    settabvalue(newvalue)
    dispatch({
      type: pageActionsTypes.CURRENT_TAB,
      payload: {
        tab: newvalue,
      },
    })
    // console.log(newvalue)
  }
  const tabLabel = (text, isActive = false) => {
    return <span className='px-1'>{text}</span>
  }

  return (
    <div>
      <Header
        title={'Роли'}
        endAdornment={[
          <Button
            size='large'
            color='primary-600'
            onClick={() => {
              tabvalue === 0
                ? history.push('/home/settings/role/create')
                : history.push('/home/researcher/role/create')
            }}
            icon={<AddIcon style={{ fontSize: '20px' }} />}
          >
            {t('add')}
          </Button>,
        ]}
      />
      <Filter>
        <StyledTabs
          value={tabvalue}
          onChange={handleChangeTab}
          centered={false}
          aria-label='full width tabs example'
          TabIndicatorProps={{ children: <span className='w-2' /> }}
        >
          <StyledTab
            label={tabLabel(t('Админ роли'))}
            {...a11yProps(0)}
            style={{ width: '100px', fontFamily: 'san-serif' }}
          />
          <StyledTab
            label={tabLabel(t('Иследователь роли'))}
            {...a11yProps(0)}
            style={{ width: '200px' }}
          />
        </StyledTabs>
      </Filter>
      <TabPanel value={tabvalue} index={0}>
        <AdminPanelTableResearcher history={history} t={t} />
      </TabPanel>
      <TabPanel value={tabvalue} index={1}>
        <ResearcherTablePermission history={history} t={t} />
      </TabPanel>
    </div>
  )
}

export default PermissionRoll
