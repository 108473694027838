import { Box, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import cls from './index.module.scss'
import Switch from '../../components/Switch/Index'
import { Button } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import Permission from '../../models/permissions'
import axios from '../../utils/axios'
import Form from '../../components/Form/index'

import { Formik } from 'formik'

export default function ResearcherPermission() {
  const params = useParams()
  const history = useHistory()
  const [permissions, setPermissions] = useState([])
  const [selectedPermissions, setSelectedPermissions] = useState([])
  const [show, setShow] = useState(false)
  const [checkSwitch, setCheckSwitch] = useState({
    id: '',
    active: null,
  })

  const [data, setData] = useState({
    status: true,
    name: '',
    description: '',
  })

  const [PermissionOfResearcher, setPermissionOfResearcher] = useState(
    {
      name: 'Компании',
    },
    {
      name: 'Иследование',
    },
    {
      name: 'Календарь',
    }
  )
  // **** FUNCTIONS ****
  const onSwitchChange = (value, id) => {
    if (value) {
      setSelectedPermissions([...selectedPermissions, id])
    } else {
      setSelectedPermissions(selectedPermissions.filter((el) => el !== id))
    }
  }

  const getDataRoll = () => {
    Permission.getRoll(params.id)
      .then((res) => {
        setData({
          ...data,
          name: res.data.name,
        })
        console.log('res =>', res)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const getAllPermissions = () => {
    Permission.getAllPermission('4c21fba6-6d9d-4162-9a15-c4800b760076')
      .then((res) => {
        setPermissions(res.data.childs)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const ActiveOrUnactivePermission = () => {
    axios
      .get(`/role/${params.id}`)
      .then((res) => {
        setSelectedPermissions(res.data.permissions)
      })
      .catch((err) => console.log(err))
  }

  const ChangeStatusPermission = (id) => {
    axios
      .post(`/role/permission`, {
        permission_id: id,
        role_id: params.id,
      })
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
  }
  const DeletePermission = (id) => {
    axios
      .delete(`/role/permission`, {
        data: {
          permission_id: id,
          role_id: params.id,
        },
      })
      .then((res) => {
        ActiveOrUnactivePermission()
      })
      .catch((err) => console.log(err))
  }

  const onSubmit = (values) => {
    if (params.id) {
      axios
        .put(`/role`, {
          name: data.name,
          id: `${params.id}`,
          client_type_id: '5a3818a9-90f0-44e9-a053-3be0ba1e2c03',
        })
        .then((res) => console.log(res))
        .catch((err) => console.log(err))
    } else {
      axios
        .post(`/role`, {
          name: data.name,
          client_type_id: '5a3818a9-90f0-44e9-a053-3be0ba1e2c03',
        })
        .then((res) => console.log(res))
        .catch((err) => console.log(err))
    }
    history.goBack()
  }
  const checkActiveSwitch = (active, id) => {
    setCheckSwitch({
      id: id,
      active: active,
    })
  }
  useEffect(() => {
    getAllPermissions()
  }, [])
  useEffect(() => {
    getDataRoll()
    ActiveOrUnactivePermission()
  }, [params.id])

  return (
    <div className={cls.root}>
      <Formik initialValues={data} onSubmit={onSubmit}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className={cls.row}>
              <Box className={cls.card}>
                <div className={cls.header}>
                  <Typography className={cls.title}>Основные детали</Typography>
                </div>
                <div className={cls.body}>
                  <Typography className={cls.label}>Название</Typography>
                  <Form.Item name='name' formik={formik}>
                    <input
                      type='text'
                      name='name'
                      onChange={(e) => {
                        setData({
                          ...data,
                          name: e.target.value,
                        })
                      }}
                      defaultValue={data.name}
                      placeholder={'Admin'}
                    />
                  </Form.Item>
                </div>
                {!params.id && (
                  <Box className={cls.btnGroup}>
                    {/* <Button className={cls.btnDelete}>Удалить</Button> */}
                    <Button
                      onClick={() => {
                        history.goBack()
                      }}
                      className={cls.btnCancel}
                    >
                      Отменить
                    </Button>
                    <Button type='submit' className={cls.btnSave}>
                      Сохранить
                    </Button>
                  </Box>
                )}
              </Box>

              {/* Control Permissions */}
              {params.id && (
                <Box className={cls.controlRow}>
                  <div className={cls.header}>
                    <Typography className={cls.title}>Разрещении</Typography>
                  </div>
                  <div className={cls.body}>
                    {permissions.length
                      ? permissions
                          .find(
                            (item) =>
                              item.id === 'f54aae91-1657-48d0-a4cf-e3d62a307105'
                          )
                          .childs.map((el, ind) => (
                            <Box key={ind} className={cls.topSide}>
                              <Box className={cls.control}>
                                <div className={cls.left}>
                                  <Typography className={cls.label}>
                                    {el.childs.length >= 1 ? (
                                      <a
                                        onClick={() => {
                                          if (show) {
                                            setShow(false)
                                          } else {
                                            setShow(true)
                                          }
                                        }}
                                      >
                                        {show ? '-' : '+'}
                                      </a>
                                    ) : (
                                      <div
                                        style={{
                                          width: '17px',
                                          height: '17px',
                                        }}
                                      />
                                    )}
                                    {el.name}
                                  </Typography>
                                </div>
                                <div className={cls.right}>
                                  <Switch
                                    checked={selectedPermissions.find(
                                      (item) => item.id === el.id
                                    )}
                                    onChange={(val) => {
                                      checkActiveSwitch(val, el.id)
                                      onSwitchChange(val, el.id)
                                      val
                                        ? ChangeStatusPermission(el.id)
                                        : DeletePermission(el.id)
                                    }}
                                  />
                                  <Typography className={cls.status}>
                                    {/* {checkSwitch.id === el.id &&
                                  checkSwitch.active === true
                                    ? 'Активный'
                                    : 'Неактивный'} */}
                                    Активный
                                  </Typography>
                                </div>
                              </Box>
                              {el.childs.length >= 1 &&
                                show &&
                                el.childs.map((list, ind) => (
                                  <div
                                    style={{
                                      paddingTop: `${ind === 0 && '48px'}`,
                                    }}
                                    key={ind}
                                    className={cls.childSwitch}
                                  >
                                    <div className={cls.left}>
                                      <Typography className={cls.label}>
                                        {list.name}
                                      </Typography>
                                    </div>
                                    <div className={cls.right}>
                                      <Switch
                                        checked={selectedPermissions.find(
                                          (item) => item.id === list.id
                                        )}
                                        onChange={(val) => {
                                          onSwitchChange(val, list.id)
                                          val
                                            ? ChangeStatusPermission(list.id)
                                            : DeletePermission(list.id)
                                        }}
                                      />
                                      <Typography className={cls.status}>
                                        Активный
                                      </Typography>
                                    </div>
                                  </div>
                                ))}
                            </Box>
                          ))
                      : ''}
                    <Box className={cls.btnGroup}>
                      {/* <Button className={cls.btnDelete}>Удалить</Button> */}
                      <Button
                        onClick={() => {
                          history.goBack()
                        }}
                        className={cls.btnCancel}
                      >
                        Отменить
                      </Button>
                      <Button type='submit' className={cls.btnSave}>
                        Сохранить
                      </Button>
                    </Box>
                  </div>
                </Box>
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}
