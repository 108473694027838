import React, { useEffect, useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Pagination from '../../components/Pagination'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Header from '../../components/Header/Header'
import Breadcrumbs from '../../components/Breadcrumb'
import { useTranslation } from 'react-i18next'
import { StyledTableCell } from '../../components/DataTable'
import Variables from '../../models/globalVariables'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { StyledPropMenu } from '../../components/custom/StyledPropMenu'
import ClearIcon from '@material-ui/icons/Clear'
import Admins from '../../models/permissions'
import { toast } from 'react-toastify'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom'
import Button from '../../components/Buttons/index'

const AdminPanelTable = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [updateType, setupdateType] = useState(true)
  const [items,setItems] = useState({})
  const pageCount = 10
  const [pageLimit , setPageLimit]= useState(10)
  const [dropList, setDropList] = useState({ target: null, id: '' })
   const [tableheadername, settableheadername] = useState([
    t('number.icon'),
    t('names'),
    t('E-mail'),
    t('phone'),
    t('Роль'),
  ])

  const [valriablesList, setvalriablesList] = useState([])
  const [getId, setGetId] = useState(null)
  const getGlobalVariables = () => {
    const param = {
      limit: pageCount,
      page: pageLimit / pageCount
    }
    Admins.getAllAdmins(param)
      .then((res) => {
        console.log(res)
        setItems(res.data)
        setvalriablesList(res.data.admins)
        setItems(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const customRowClik = (id) => {
    history.push(`/home/settings/admin/update/${id}`)
  }

  useEffect(() => {
    getGlobalVariables()
  }, [])
  useEffect(() => {
    getGlobalVariables()
  }, [pageLimit])
  return (
    <div>
      <Header
        title={'Админ'}
        endAdornment={[
          <Button
            size='large'
            color='primary-600'
            onClick={() => {
              history.push('/home/settings/admin/create')
            }}
            icon={<AddIcon style={{ fontSize: '20px' }} />}
          >
            {t('add')}
          </Button>,
        ]}
      />

      <div className='fslex p-5 px-5 pb-4 rounded-2xl'>
        <TableContainer
          elevation={0}
          component={Paper}
          className=' shadow-none rounded-2xl p-5'
        >
          <Table
            elevation={0}
            aria-label='customized table'
            className='rounded-2xl'
            style={{ borderRadius: '20px' }}
          >
            <TableHead>
              <TableRow className='text-black font-body'>
                {tableheadername?.map((element, index) => (
                  <StyledTableCell key={index}>{element}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {valriablesList?.map((element, index) => (
                <TableRow
                  onClick={() => customRowClik(element.id)}
                  className='text-black font-body hover:bg-gray-100 cursor-pointer'
                  key={index}
                >
                  <StyledTableCell>  {parseInt(
                      pageCount * (pageLimit / pageCount - 1) + index + 1
                    )}</StyledTableCell>
                  <StyledTableCell>{element.name}</StyledTableCell>
                  <StyledTableCell>{element.email}</StyledTableCell>
                  {/* <StyledTableCell>{element.id}</StyledTableCell> */}
                  <StyledTableCell>{element.phone}</StyledTableCell>
                  <StyledTableCell>{element.role.name}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
              className='mt-5 text-black-700'
              size={'large'}
              count={items.count}
              pageCount={pageCount}
              onChange={(val) => {
                setPageLimit(val)
              }}
            />
      </div>
    </div>
  )
}

export default AdminPanelTable
