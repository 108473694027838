import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "white",
    color: "black",
    fontFamily: "Inter",
    fontWeight: "600",
  },
  body: {
    fontSize: 14,
    fontFamily: "Inter",
    // minWidth: 200
  },
}))(TableCell);
