// dependency
import {useTranslation} from 'react-i18next'
import React, {useState} from 'react'
// components

import Filter from '../../components/Filter'
import Header from '../../components/Header/Header'
import {TabPanel} from '../../components/Tab/TabBody'
import Breadcrumbs from '../../components/Breadcrumb/index'
// import { StyledPropMenu } from "../../components/custom/StyledPropMenu";
import {StyledTabs, StyledTab} from '../../components/StyledTabs/index'
import {Menu} from '@material-ui/core'

// Service models
import RespondentUpdate from './RespondentUpdate'
import RespondentTransaction from './RespondentTransacrion'
import HistoryTable from './HistoryTable'
import ChangeTable from './ChangeTable'
const RespondentList = () => {
  const {t} = useTranslation()
  const [selectedMenu, setSelectedMenu] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [accountNumber, setAccountNumber] = useState()
  const [tabvalue, settabvalue] = useState(0)
  const [userName, setUserName] = useState(null)

  // functions
  const handleChangeTab = (event, newvalue) => {
    settabvalue(newvalue)
  }

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    }
  }
  const tabLabel = (text, isActive = false) => {
    return <span className='px-1'>{text}</span>
  }

  const routes = [
    {
      title: 'Респондент',
      link: true,
      route: '/home/respondents',
    },
    {
      title: userName === undefined ? 'User' : userName,
      link: true,
    },
  ]

  const handleClose = () => {
    setAnchorEl(null)
    setSelectedMenu(null)
  }

  return (
    <div>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      ></Menu>
      <Header
        startAdornment={[<Breadcrumbs isGoBack={true} routes={routes} />]}
      />

      <Filter>
        <StyledTabs
          value={tabvalue}
          onChange={handleChangeTab}
          centered={false}
          aria-label='full width tabs example'
          TabIndicatorProps={{children: <span className='w-2' />}}
        >
          <StyledTab
            label={tabLabel(t('company.info'))}
            {...a11yProps(0)}
            style={{width: '100px', fontFamily: 'san-serif'}}
          />
          <StyledTab
            label={tabLabel(t('Транзакции'))}
            {...a11yProps(0)}
            style={{width: '100px'}}
          />
          <StyledTab
            label={tabLabel(t('История'))}
            {...a11yProps(0)}
            style={{width: '100px'}}
          />
          <StyledTab
            label={tabLabel(t('Изменение'))}
            {...a11yProps(0)}
            style={{width: '100px'}}
          />
        </StyledTabs>
      </Filter>

      <div>
        <TabPanel value={tabvalue} index={0}>
          <RespondentUpdate
            setUserName={setUserName}
            setAccountNumber={setAccountNumber}
          />
        </TabPanel>
        <TabPanel value={tabvalue} index={1}>
          <RespondentTransaction accountNumber={accountNumber} />
        </TabPanel>
        <TabPanel value={tabvalue} index={2}>
          <HistoryTable />
        </TabPanel>
        <TabPanel value={tabvalue} index={3}>
          <ChangeTable />
        </TabPanel>
      </div>
    </div>
  )
}

export default RespondentList
