import {
    Container,
    Box,
    Typography,
    Button,
    makeStyles,
} from '@material-ui/core'
import React, { useState } from 'react'
import cls from './index.module.scss'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import CircularProgress from '@material-ui/core/CircularProgress'
import { WhiteUrecruit } from '../../../components/svg/WhiteUrecruit'
import { useHistory, useParams } from 'react-router-dom'
import axios from '../../../utils/axios'
import iMac from '../../../assets/icons/Imac.png'


const useStyles = makeStyles(() => ({
    errorBorder: {
        '& label + .MuiInput-formControl': {
            border: '1px solid #f71111 !important',
        },
        '& svg': {
            color: '#f71111',
        },
    },
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        '& > * + *': {
            marginLeft: '15px',
        },
        '& .MuiCircularProgress-colorPrimary': {
            width: '20px !important',
            height: '20px !important',
        },
        '& svg': {
            marginLeft: '0 !important',
            color: '#fff',
        },
    },
}))

export default function ResetPassword() {
    const classes = useStyles()
    const params = useParams()
    const history = useHistory()
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [userData, setUserData] = useState({
        newPassword: '',
        password: '',
        showPassword: false,
        showNewPassword: false,
    })
    const handleChange = (prop) => (event) => {
        setUserData({ ...userData, [prop]: event.target.value })
    }
    const handleClickShowPassword = () => {
        setUserData({ ...userData, showPassword: !userData.showPassword })
    }
    const handleClickShoNewPassword = () => {
        setUserData({ ...userData, showNewPassword: !userData.showNewPassword })
    }
    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        if (userData.newPassword !== userData.password) {
            setError(true)
        } else {
            axios
                .put(`/auth/user-password`, {
                    password: userData.newPassword,
                    token: history.location.search.split('=')[1],
                })
                .then((res) => {
                    console.log(res)
                    setIsLoading(false)
                    history.push('/auth/login')
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                })
        }
    }

    return (
        <div className={cls.root}>
            <Container className={cls.container}>
                <div className={cls.row}>
                    <Box className={cls.left}>
                        <img src={iMac} alt='iMAC' />
                        <a href="/auth/login">
                            <WhiteUrecruit />
                        </a>
                    </Box>
                    <Box className={cls.right}>
                        <form onSubmit={handleSubmit} className={cls.formControl}>
                            <div className={cls.row}>
                                <Box className={cls.title}>
                                    <Typography className={cls.text}>
                                        Восстановить пароль
                                    </Typography>
                                </Box>

                                <>
                                    <FormControl
                                        id='password'
                                        className={`${error && classes.errorBorder}`}
                                    >
                                        <label className={cls.password}> Новый пароль</label>
                                        <Input
                                            autoComplete='new-password'
                                            id={`password_input ${error && 'border'}`}
                                            type={userData.showNewPassword ? 'text' : 'password'}
                                            value={userData.newPassword}
                                            name='newPassword'
                                            onChange={handleChange('newPassword')}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={handleClickShoNewPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {userData.showNewPassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {error && (
                                            <Typography className={cls.errorContent}>
                                                Пароль не совпадает
                                            </Typography>
                                        )}
                                    </FormControl>
                                    <FormControl
                                        id='password'
                                        className={`${error && classes.errorBorder}`}
                                    >
                                        <label className={cls.password}>Повторите пароль</label>
                                        <Input
                                            autoComplete='new-password'
                                            id={`password_input ${error && 'border'}`}
                                            type={userData.showPassword ? 'text' : 'password'}
                                            value={userData.password}
                                            name='password'
                                            onChange={handleChange('password')}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {userData.showPassword ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {error && (
                                            <Typography className={cls.errorContent}>
                                                Пароль не совпадает
                                            </Typography>
                                        )}
                                    </FormControl>{' '}
                                </>

                                <Box className={cls.btnGroup}>
                                    <Button type='submit' className={cls.btn}>
                                        {!isLoading ? (
                                            'Продолжить'
                                        ) : (
                                            <div className={classes.root}>
                                                {' '}
                                                <CircularProgress />
                                            </div>
                                        )}
                                    </Button>
                                </Box>
                            </div>
                        </form>
                    </Box>
                </div>
            </Container>
        </div>
    )
}
