import request from '../utils/axios'
const base_Url = {
  transaction: '/billing/transaction',
  pretransaction: '/billing/company/pretransactions'
}
const requests = {
  getAllTransactions: (params) =>
    request({
      url: `${base_Url.transaction}/transfered`,
      method: 'get',
      params: params,
    }),
    getAllPreTransactions: (params) =>
    request({
      url: `${base_Url.pretransaction}`,
      method: 'get',
      params: params,
    }),
}
export default requests
