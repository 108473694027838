import request from '../utils/axios'
const base_Url = {
  report: '/company/report',
  respondent: '/respondent/report',
}
const requests = {
  reportGetAllList: (id) =>
    request({
      url: `${base_Url.report}`,
      method: 'get',
      params: id,
    }),
  reportRespondent: (id) =>
    request({
      url: `${base_Url.respondent}`,
      method: 'get',
      params: id,
    }),
}
export default requests
