import { Switch, Route, Redirect } from 'react-router-dom'
// import UserLayout from "./containers/UserLayout.jsx";
import RouterLayout from './containers/RouterLayout.jsx'
import dashboardRoutes from './routes/dashboard-routes.js'
import EmptyLayout from './containers/EmptyLayout.jsx'
import ConfirmLayout from './containers/ConfirmLayout.jsx'
import fallbackRoutes from './routes/fallback-routes'
import confirmRoutes from './routes/confirm-routes'
import { useSelector } from 'react-redux'
import authRoutes from './routes/auth-routes.js'
import { animated, useTransition } from 'react-spring'
import { useLocation , useHistory} from 'react-router-dom'
import { useEffect } from 'react'
import Fallback403 from '../src/views/exceptions/Fallback403.jsx'

const layouts = [
  {
    component: RouterLayout,
    path: '/home',
    routes: dashboardRoutes,
    private: true,
  },
  {
    component: EmptyLayout,
    path: '/extra',
    routes: fallbackRoutes,
    private: false,
  },
  {
    component: ConfirmLayout,
    path: '/',
    routes: confirmRoutes,
    private: false,
  },
]
const noAccessComponent = () => (
  <>
    <Fallback403 />
  </>
)

function App() {
  const location = useLocation()
  const history = useHistory()
  const token = useSelector((state) => state.auth.accessToken)
  const permissions = useSelector((state) => state.auth.permissions)
  const transitions = useTransition(location, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })
 useEffect(()=>{
   if(token && location.pathname === '/'){
     history.replace('/home/dashboard')
   }
 },[])
  if (!token && location.pathname !== '/reset-password')
    return (
      <Switch>
        {authRoutes.map((route) => (
          <Route
            path={route.path}
            exact={route.exact}
            key={route.id}
            render={(routeProps) => (
              <route.layout history={routeProps.history}>
                <route.component {...routeProps} />
              </route.layout>
            )}
          />
        ))}
        <Redirect from='/' to='/auth/login'></Redirect>
      </Switch>
    )

  return (
    <Switch>
      {layouts.map((layout, index) => (
        <Route
          key={index}
          path={layout.path}
          render={(routeProps) => (
            <layout.component>
              {transitions((props, item) => (
                <animated.div style={props}>
                  <div style={{ position: 'relative' }}>
                    <div
                      style={{
                        position: 'absolute',
                        width: '100%',
                      }}
                    >
                      <Switch location={item}>
                        {layout.routes.map((route) => (
                          <Route
                            key={route.id}
                            path={route.path}
                            component={
                              ![...permissions, 'dashboard'].includes(
                                route.permission
                              )
                                ? noAccessComponent
                                : route.component
                            }
                            exact
                          />
                        ))}
                      </Switch>
                    </div>
                  </div>
                </animated.div>
              ))}
            </layout.component>
          )}
        />
      ))}
      {/* <Redirect to='/home/dashboard' /> */}
    </Switch>
  )
  //   let token=localStorage.getItem('token')
  //  if (!token) return (

  //       <Switch>
  //         <Route path="/auth" component={UserLayout} />
  //         <Route path="/extra" component={EmptyLayout} />
  //         <Route path="/home" component={RouterLayout} />
  //         <Redirect from='/' to="/auth/login"></Redirect>
  //     </Switch>
  //   );
}
export default App
