// dependency
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDropzone } from 'react-dropzone'
import axios from '../../utils/axios'

// components
import Button from '../../components/Buttons'
import Filter from '../../components/Filter'
import Header from '../../components/Header/Header'
import Pagination from '../../components/Pagination'
import { TabPanel } from '../../components/Tab/TabBody'
import Breadcrumbs from '../../components/Breadcrumb/index'
import { StyledTableCell } from '../../components/DataTable/index'
import { StyledTabs, StyledTab } from '../../components/StyledTabs/index'
import { Typography } from '@material-ui/core'

// Service models
import Transactions from '../../models/transaction'
import ExcelFile from '../../models/excel'

// Icons
import AddIcon from '@material-ui/icons/Add'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { DownloadIcon, UploadIcon } from '../../components/svg/TransactionIcon'
import BillingTable from '../../components/BillingTable/BillingTable'

const TransactionTable = () => {
  const pageCount = 10
  const { id } = useParams()
  const history = useHistory()
  const { t } = useTranslation()
  const [items, setItems] = useState({})
  const [tabvalue, settabvalue] = useState(0)
  const [inquerList, setinquerList] = useState([])
  const [pageLimit, setPageLimit] = useState(pageCount)
  const [updateType, setupdateType] = useState(id ? true : false)

  const uploadExcelFile = (props) => {
    const file = props[0]

    const formData = new FormData()
    formData.append(`excel_file`, file)

    axios
      .post(`/excel/import`, formData)
      .then((res) => {
        toast.success('Успешно!', {
          className: 'bg-green-500 text-white',
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        })
      })
      .catch((err) => {
        console.log(err)
        toast.error(t(err.data.error), {
          className: 'bg-red-500 text-white',
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        })
      })
  }

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: uploadExcelFile,
  })

  const [tableHeardName, setTableHeardName] = useState([
    t('number.icon'),
    t('Счет'),
    t('Фамилия'),
    t('Имя'),
    t('Сумма'),
    t('Дата присоединения к оферте'),
    // t('Номер присоединения к оферте')
  ])

  // functions
  const handleChangeTab = (event, newvalue) => {
    settabvalue(newvalue)
  }

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    }
  }

  const handleInquiryAdd = () => {
    history.push(`${'/home/company/companyinquirycreate'}/${id}`)
  }

  const getTransaction = (id) => {
    const params = {
      status: 'pending',
      page: pageLimit / pageCount,
      limit: pageCount,
    }
    Transactions.getAllPreTransactions(params).then((res) => {
      setinquerList(res.data.transactions)
      setItems(res.data)
    })
  }

  const getExcelFile = () => {
    ExcelFile.transactionGetAllExcelFile()
      .then((res) => {
        window.location.href = res.data.url
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const tabLabel = (text, isActive = false) => {
    return <span className='px-1'>{text}</span>
  }

  useEffect(() => {
    getTransaction(id)
  }, [])

  useEffect(() => {
    getTransaction(id)
  }, [pageLimit])

  return (
    <div>
      <Header
        // setSelectStatus={setSelectStatus}
        // filter={tabvalue === 2 ? true : false}
        title={'Транзакции'}
        // endAdornment={[
        //   tabvalue === 0 && (
        //     <Button
        //       size={'medium'}
        //       onClick={handleInquiryAdd}
        //       icon={<AddIcon className='text-white my-1' />}
        //     >
        //       {t('add.cash')}
        //     </Button>
        //   ),
        // ]}
      />

      <Filter>
        <StyledTabs
          value={tabvalue}
          onChange={handleChangeTab}
          centered={false}
          aria-label='full width tabs example'
          TabIndicatorProps={{ children: <span className='w-2' /> }}
        >
          <StyledTab
            label={tabLabel('Респонденты')}
            {...a11yProps(0)}
            style={{ width: '100px', fontFamily: 'san-serif' }}
          />
          <StyledTab
            label={tabLabel(t('Urecruit'))}
            {...a11yProps(0)}
            style={{ width: '100px' }}
          />
          <StyledTab
            label={tabLabel(t('Касса'))}
            {...a11yProps(0)}
            style={{ width: '100px' }}
          />
        </StyledTabs>
      </Filter>
      <div>
        <TabPanel value={tabvalue} index={0}>
          <div className='fslex p-5 px-5 pb-4' style={{ borderRadius: '20px' }}>
            <TableContainer
              id='tableBody'
              elevation={0}
              component={Paper}
              className=' shadow-none rounded-2xl p-5'
              style={{ borderRadius: '10px' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '24px',
                  marginBottom: '16px',
                }}
              >
                <a
                  onClick={() => {
                    getExcelFile()
                  }}
                  style={{
                    padding: '12px',
                    background: 'rgba(0, 103, 244, 0.05)',
                    borderRadius: '8px',
                    display: 'flex',
                    gap: '16px',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <DownloadIcon />{' '}
                  <span
                    style={{
                      fontWeight: '500',
                      lineHeight: '24px',
                      fontSize: '14px',
                      fontFamily: 'Inter',
                      color: '#0067F4',
                    }}
                  >
                    Скачать Excel
                  </span>
                </a>
                <div
                  style={{
                    padding: '12px',
                    background: '#fff',
                    borderRadius: '8px',
                    display: 'flex',
                    gap: '16px',
                    alignItems: 'center',
                    cursor: 'pointer',
                    border: '1px dashed rgba(110, 139, 183, 0.3)',
                  }}
                  {...getRootProps()}
                >
                  <UploadIcon />{' '}
                  <span
                    style={{
                      fontWeight: '500',
                      lineHeight: '24px',
                      fontSize: '14px',
                      fontFamily: 'Inter',
                      color: '#0067F4',
                    }}
                  >
                    Загрузить Excel
                  </span>
                  <input {...getInputProps()} />{' '}
                </div>
              </div>
              <Table elevation={0} aria-label='customized table'>
                <TableHead style={{ borderRadius: '10px' }}>
                  <TableRow className='text-black font-body'>
                    {tableHeardName?.map((element, index) => (
                      <StyledTableCell
                        style={{ fontFamily: 'sans-serif', textAlign: `${index > 3 ? 'center' : 'start' }` }}
                        key={index}
                      >
                        {element}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inquerList?.map((els, index) => (
                    <TableRow
                      className='text-black font-body hover:bg-gray-100 cursor-pointer'
                      // <TableRow onClick={()=> handleCutomRow(els.id)} className="text-black font-body hover:bg-gray-100 cursor-pointer"
                      key={index}
                    >
                      <StyledTableCell>
                        {parseInt(
                          pageCount * (pageLimit / pageCount - 1) + index + 1
                        )}
                      </StyledTableCell>
                      <StyledTableCell style={{whiteSpaec:'nowrap'}}>
                        {els?.correspondent_account}
                      </StyledTableCell>
                      <StyledTableCell>
                        {els?.last_name}
                      </StyledTableCell>
                      <StyledTableCell>
                        {els?.first_name}
                      </StyledTableCell>
                      <StyledTableCell style={{textAlign:'center'}}>
                        <Typography id='text_limit'>{els?.cost} ₽</Typography>
                      </StyledTableCell>
                      <StyledTableCell style={{textAlign:'center'}}>
                        {moment(els?.created_at).format('DD-MM-YYYY')}
                      </StyledTableCell>
                      {/* <StyledTableCell style={{textAlign:'center'}}>
                          {els?.number}
                      </StyledTableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              className='mt-5 text-black-700'
              count={items.count}
              pageCount={pageCount}
              onChange={(val) => {
                setPageLimit(val)
              }}
            />
          </div>
        </TabPanel>
        <TabPanel value={tabvalue} index={1}>
          <BillingTable show={false} panel={false} accountNumber={22222222} />
        </TabPanel>
        <TabPanel value={tabvalue} index={2}>
          <BillingTable show={false} minus={false} accountNumber={11111111} />
        </TabPanel>
      </div>
    </div>
  )
}

export default TransactionTable
