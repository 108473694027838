import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import AddIcon from '@material-ui/icons/Add'
import { CircularProgress } from '@material-ui/core'
import { StyledTableCell } from '../../components/DataTable/index'
import Header from '../../components/Header/Header'
import Pagination from '../../components/Pagination'
import Button from '../../components/Buttons/index'
import Research from '../../models/researchers'
import { DownloadOutlined } from '@ant-design/icons'
import ExcelFile from '../../models/excel'
import { useDispatch, useSelector } from 'react-redux'
import { pageActionsTypes } from '../../redux/actions/currentPage/pageActionTypes'
import moment from 'moment'
import RangePicker from '../../components/DatePicker/RangePicker'

const Researchers = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const pageCount = 10
  const dispatch = useDispatch()
  const currentPage = useSelector((state) => state.counter)
  const [items, setItems] = useState({})
  const [pageLimit, setPageLimit] = useState(pageCount)
  const [searchTable, setSearchTable] = useState()
  const [date, setDate] = useState({
    from_date:'',
    to_date: ''
  })

  //   ******* FUNCTIONS ********
  const getItems = () => {
    const params = {
      from_date: date.from_date,
      to_date: date.to_date,
      search: searchTable,
      page:  currentPage.currentPage === undefined
      ?  pageLimit / pageCount
      : currentPage.currentPage.page,
      limit: pageCount,
    }
    setIsLoading(true)

    Research.researcherGetAllList(params)
      .then((res) => {
        setItems(res.data)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
    // axios.get('/researcher', { params }).then((res) => {
    //   console.log('res', res)
    //   setItems(res.data)
    //   setIsLoading(false)
    // })
  }
  const getExcelFile = () => {
    const param = {
      from_date: date.from_date,
      to_date: date.to_date,
      search: searchTable,
    }
    ExcelFile.researcherGetAllExcelFile(param)
      .then((res) => {
        window.location.href = res.data.url
      })
      .catch((err) => {
        console.log(err)
      })
  }
  //useEffect
  useEffect(() => {
    getItems()
  }, [])
  useEffect(() => {
    getItems()
  }, [pageLimit,date])

  // ****** EVENTS *******
  // const handleMoreClick = (id, type) => {
  //   if (type === 'edit') {
  //     setDropList((old) => {
  //       return { ...old, target: null }
  //     })
  //   }
  //   // if (type === 'delete') {
  //   //   console.log('delete')
  //   //   setDropList((old) => {
  //   //     return { ...old, target: null }
  //   //   })
  //   //   axios
  //   //     .delete(`researcher/${dropList.id}`)
  //   //     .then((res) => {
  //   //       console.log(res)
  //   //       getItems()
  //   //     })
  //   //     .catch((err) => console.log(err))
  //   // }
  // }

  const onRowClick = (company_id , id) => {
    history.push(`/home/company/researcher/update/${id}/${company_id}`)
  }

  // ******* CONSTANTS *******
  // const listRender = [
  //   {
  //     title: t('delete'),
  //     icon: <ClearIcon className='bg-red-100 rounded text-red-600 p-0.5' />,
  //     type: 'delete',
  //   },
  // ]
  useEffect(() => {
    getItems()
  }, [searchTable])
  return (
    <div>
      <Header
        title={t('researchers')}
        endAdornment={[
          <Button
            size='large'
            color='primary-600'
            shape='filled'
            onClick={() => history.push('/home/researchers/create')}
            icon={<AddIcon style={{ fontSize: '20px' }} />}
          >
            {t('add')}
          </Button>,
        ]}
      />

      <div className='fslex p-5 px-5 pb-4' style={{ borderRadius: '20px' }}>
        <TableContainer
          elevation={0}
          component={Paper}
          className='shadow-none rounded-2xl p-5'
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              marginBottom: '16px',
            }}
          >
             <span>
               <RangePicker
               style={{height: '100%', marginRight:'8px'}}
                   hideTimeBlock
                   onChange={(val) => {
                    val[0] === null ? 
                    setDate((old) => ({
                     from_date: '',
                     to_date: ''
                   })) :
                   setDate((old) => ({
                     from_date: moment(val[0]).format('YYYY-MM-DD'),
                     to_date : moment(val[1]).format('YYYY-MM-DD')
                   }))
                   }}
                   className='p-2 w-full'
                  />
               </span>
            <input
              name='search'
              value={searchTable}
              onChange={(e) => {
                setSearchTable(e.target.value)
              }}
              style={{
                border: '1px solid #E5E9EB',
                borderRadius: '8px',
                padding: '8px 12px',

                maxWidth: '200px',
              }}
              placeholder='Поиск'
            />
            <a
              onClick={() => {
                getExcelFile()
              }}
              id='download_file'
            >
              <DownloadOutlined />
            </a>
          </div>
          <Table
            elevation={0}
            aria-label='customized table'
            style={{ borderRadius: '20px' }}
          >
            <TableHead>
              <TableRow className='text-black font-body'>
                <StyledTableCell>№</StyledTableCell>
                <StyledTableCell>{t('name')}</StyledTableCell>
                <StyledTableCell>{t('email')}</StyledTableCell>
                <StyledTableCell>{t('Номер телефона')}</StyledTableCell>
                <StyledTableCell>{t('Профессия')}</StyledTableCell>
                <StyledTableCell>{t('company')}</StyledTableCell>
                {/* <StyledTableCell></StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {items ? (
                items?.researchers?.map(
                  ({ id, name, email, company_name , phone ,profession_title , company_id }, index) => (
                    <TableRow
                      className='text-black font-body hover:bg-gray-100 cursor-pointer'
                      key={index}
                      onClick={() => onRowClick(company_id , id)}
                    >
                      <StyledTableCell>
                        {parseInt(
                          pageCount * (pageLimit / pageCount - 1) + index + 1
                        )}
                      </StyledTableCell>
                      <StyledTableCell>{name}</StyledTableCell>
                      <StyledTableCell>{email}</StyledTableCell>
                      <StyledTableCell>{phone}</StyledTableCell>
                      <StyledTableCell>{profession_title}</StyledTableCell>
                      <StyledTableCell>{company_name.name}</StyledTableCell>
                      {/* <StyledTableCell
                        className='w-2   border-gray-300'
                        style={{ borderLeft: '1px solid gainsboro' }}
                      >
                        <MoreHorizIcon
                          onClick={(e) => {
                            e.stopPropagation()
                            setDropList({
                              target: e.currentTarget,
                              id: id,
                            })
                          }}
                          className='cursor-pointer text-blue-500'
                          style={{
                            border: '1px solid gainsboro',
                            borderRadius: '5px',
                          }}
                        />

                        <StyledPropMenu
                          anchorEl={dropList.target}
                          open={Boolean(dropList.target)}
                          onClose={(e) => {
                            e.stopPropagation()
                            setDropList((old) => {
                              return { ...old, target: null }
                            })
                          }}
                        >
                          <div className='flex  flex-col divide-y font-body text-sm'>
                            {listRender.map((el, index) => (
                              <div
                                key={index}
                                className='flex w-full cursor-pointer py-2 mx-2'
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleMoreClick(id, el.type)
                                }}
                              >
                                <div className='rounded'>{el.icon}</div>
                                <div className='my-auto mx-2'>{el.title}</div>
                              </div>
                            ))}
                          </div>
                        </StyledPropMenu>
                      </StyledTableCell> */}
                    </TableRow>
                  )
                )
              ) : (
                <> </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading && (
          <div className='flex justify-center py-2'>
            <CircularProgress />
          </div>
        )}
        <Pagination
          className='mt-5 text-black-700'
          // size={'large'}
          count={items.count}
          pageCount={pageCount}
          currentPage={
            currentPage.currentPage === undefined
              ? 1
              : currentPage.currentPage.page
          }
          onChange={(val) => {
            dispatch({
              type: pageActionsTypes.CURRENT_PAGE,
              payload: {
                page: parseInt(val / 10),
                limit: 10,
              },
            })
            setPageLimit(val)
          }}
        />
      </div>
    </div>
  )
}

export default Researchers
