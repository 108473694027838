import Login from "../views/login/Login.jsx";
import RegistrationEmail from "../views/login/reg_tabs/RestorePassword";
import AuthLayout from "../containers/UserLayout.jsx";
import RegstratsiyaNewLoginAndPassword from "../views/login/reg_tabs/NewLoginPassword"
import CheckEmail from "../views/login/ConfirmEmail/CheckEmail.js";

export default [
  {
    id: 14,
    component: Login,
    path: "/login",
    exact: false,
    title: "Login",
    icon: "",
    showSidepanel: true,
    permission: "",
    children: []
  },
  {
    id: 134,
    component: RegistrationEmail,
    path: "/registration",
    exact: false,
    title: "registration/email",
    icon: "",
    showSidepanel: true,
    permission: "",
    children: []
  },
  {
    id: 145,
    component: RegstratsiyaNewLoginAndPassword,
    path: "/new/login/:token",
    exact: true,
    title: "CompanyCreate",
    icon: "",
    showSidepanel: true,
    permission: "",
    children: []
  },
  {
    id: 146,
    component:  CheckEmail ,
    path: "/reset-password",
    exact: true,
    title: "Reset Password",
    icon: "",
    showSidepanel: true,
    permission: "",
    children: []
  },


].map((route) => ({ ...route, path: `/auth${route.path}`, layout: AuthLayout, }));
