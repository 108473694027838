// dependency
import moment from "moment";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import React, {useState, useEffect} from "react";
import axios from "../../utils/axios";
import {useParams, useHistory} from "react-router-dom";

// components
import Tag from "../../components/Tag/index";
import Button from "../../components/Buttons";
import Filter from "../../components/Filter";
import Header from "../../components/Header/Header";
import Pagination from "../../components/Pagination";
import {TabPanel} from "../../components/Tab/TabBody";
import Breadcrumbs from "../../components/Breadcrumb/index";
import Variables from "../../models/globalVariables";
import {StyledTableCell} from "../../components/DataTable/index";
// import { StyledPropMenu } from "../../components/custom/StyledPropMenu";
import {StyledTabs, StyledTab} from "../../components/StyledTabs/index";
import ChangeStatatus from "../shared/ChangeStatus";
import {CircularProgress, Menu, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {pageActionsTypes} from "../../redux/actions/currentPage/pageActionTypes";
import MenuItem from "@material-ui/core/MenuItem";
import {Act, Balance} from "../../components/svg/Act";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FindInPageIcon from "@material-ui/icons/FindInPage";
// Service models
import Inquiry from "../../models/inquiry";
// import companyinquiry from "../../models/companyinquiry";
import company from "../../models/conpany";
import ExcelFile from "../../models/excel";
import config from "../../config/defaultSettings";

// Views
import Compayny from "../../views/company/companyCreate";
import ResearchersItemCompanyList from "../researchers/researchersItemCompanyList";
import Calendar from "./Calendar";

// Icons
import AddIcon from "@material-ui/icons/Add";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditIcon from "@material-ui/icons/Edit";
import Cached from "@material-ui/icons/Cached";
import BillingTable from "../../components/BillingTable/BillingTable";
import {DownloadOutlined} from "@ant-design/icons";
import Tooltip from "@material-ui/core/Tooltip";
import GlobalVariabList from "../global_variables/globalVariabList";
import {NumberFomat} from "../../functions/numberFomat";
import CopyRight from "../shared/Copyright";
import companiyInquiry from "../../models/companyinquiry";
import TableLoader from "../../components/TableLoading/Index";
import SvgIcon from "../../components/UploadWitchComment/Icons";
// import ClearIcon from "@material-ui/icons/Clear";

const InquiryList = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const history = useHistory();
  const {t} = useTranslation();
  const pageCount = 10;
  const [items, setItems] = useState({});
  const [loading, setloading] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [pageLimit, setPageLimit] = useState(pageCount);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTable, setSearchTable] = useState();
  const [respondents, setRespondents] = useState();
  // const [renderPage, setrenderPgae] = useState(true)
  // let count = 1;
  const [tableHeardName, setTableHeardName] = useState([
    t("number.icon"),
    t("data.form"),
    t("data.to"),
    t("heading"),
    t("description"),
    t("total_cost"),
    t("status"),
    "",
  ]);
  const [inquerList, setinquerList] = useState([]);
  const [accountNumber, setAccountNumber] = useState();
  const [selectStatus, setSelectStatus] = React.useState({});
  const activeTab = useSelector((state) => state.counter.currentTab);
  const currentPage = useSelector((state) => state.counter.childCurrentPage);
  const permissions = useSelector((state) => state.auth.permissions);
  const [tabvalue, settabvalue] = useState(0);
  const [rollGloablVariable, setRollGlobalVariable] = useState(false);
  const [userName, setUserName] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [respondentCount, setRespondentCount] = useState(null);
  const [projectId, setProjectId] = useState({});
  const [surveyId, setSurveyId] = useState({});
  const [listInquery, setListInquery] = useState(null);
  const [calculateCost, setCalculateCost] = useState(null);
  const [loader, setLoader] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [loadingTable, setLoadingTable] = useState(false);
  const [initialValue, setinitialValue] = useState({
    company_id: "",
    // one Steppers
    title: "",
    from_date: moment(new Date()),
    to_date: moment(new Date()).add(30, "d"),
    status_id: "72ee7350-4a2b-4fc2-945c-34faa56b13ca",
    partisipant_role: "respondent",
    study_type: "one_to_one",
    interview_type: "online",
    description: "",
    // two Stepppers
    researcher_id: "",
    // data researchdelAll select
    duration: 30,
    type_of_inquiry: {
      group_participants: "",
      number_of_groups: "",
      type_of_inquiry: "",
    },
    number_of_partisipants: 10,
    os: {},
    device: {1: "smartphone"},
    cost: 0,
    additional_cost: 0,
    researcher_name: "",
    nda_text: "",
    // thwee Steppers
    inquiry_requirement: [
      // {
      //     input_type: 'range',
      //     name: 'attribute',
      //     data_type: 'number',
      //     id: "",
      //     requirement_value:
      //        {
      //             attribute_id: "",
      //             id: "",
      //             value: ""
      //         }
      // }
    ],
    // four Steppers
    inquiry_timetables: [
      // {
      //     dates: "",
      //     end_time: "",
      //     number_of_applicants: 0,
      //     number_of_partisipants: 0,
      //     start_time: ""
      // }
    ],
    nda: {1: ""},
    example_nda: {1: ""},
    connection_info: "",
    total_cost: 0,
  });

  const params = useParams();
  useEffect(() => {
    settabvalue(activeTab === undefined ? tabvalue : activeTab.tab);
  }, []);
  // functions
  const handleChangeTab = (event, newvalue) => {
    settabvalue(newvalue);
    dispatch({
      type: pageActionsTypes.CURRENT_TAB,
      payload: {
        tab: newvalue,
      },
    });
    // console.log(newvalue)
  };

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  const handleInquiryAdd = () => {
    history.push(`${"/home/company/companyinquirycreate"}/${id}`);
  };
  const handleCutomRow = (researcherId) => {
    respondents &&
      history.push(`${"/home/company/inquiryitem"}/${id}/${researcherId}`);
  };
  useEffect(() => {
    setRespondents(permissions.includes(t("Разрешение на респондентов")));
  }, [permissions]);
  const getAllItem = (id) => {
    company.companyItemGet(id).then((res) => {
      setUserName(res.data.name);
      setAccountNumber(res.data.account_number);
    });
  };
  const inquiryListGetAll = (id) => {
    setloading(true);
    const parmas = {
      title: searchTable,
      status_id: selectStatus.length > 2 ? getStatusId(selectStatus) : "",
      company_id: id,
      page:
        currentPage === undefined ? pageLimit / pageCount : currentPage.page,
      limit: pageCount,
    };
    Inquiry.inquiryGetAllList(parmas).then((res) => {
      setinquerList(res.data.inquiries);
      setItems(res.data);
      setloading(false);
    });
  };

  const getExcelFile = () => {
    const param = {
      search: searchTable,
      company_id: id,
    };
    ExcelFile.inqueryGetAllExcelFile(param)
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleMoreClick = (ids, type) => {
    if (type === "edit") {
      checkRespondent(ids, "edit");
    }
    if (type === "copy_right") {
      setSelectedMenu(ids);
    }

    if (type === "update_status") {
      checkRespondent(ids, "update");
      if (
        ids.title.length > 0 &&
        ids.connection_info.length > 0 &&
        ids.inquiry_requirement[1]?.requirement_values?.[0] &&
        ids.inquiry_requirement[0]?.requirement_values?.[0]
      ) {
        setSelectedMenu(ids);
      } else {
        toast.error("Заполните все необходимые поля!", {
          className: "bg-red-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        });
        setOpen(false);
      }
    }
    if (type === "act") {
      axios
        .get(`excel/akt?inquiry_id=${ids.id}`)
        .then((res) => {
          window.location.href = res.data.url;
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
    if (type === "word") {
      axios
        .get(`word/service-order?inquiry_id=${ids.id}`)
        .then((res) => {
          window.location.href = res.data.url;
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
    if (type === "balance") {
      axios
        .get(`/excel/factura?inquiry_id=${ids.id}`)
        .then((res) => {
          window.location.href = res.data.url;
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
    if (type === "excel") {
      axios
        .get(`excel/inquiry-respondents?inquiry_id=${ids.id}`)
        .then((res) => {
          window.location.href = res.data.url;
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  };
  const checkRespondent = (ids, status) => {
    axios.get(`/inquiry/respondents?inquiry_id=${ids.id}`).then((res) => {
      setRespondentCount(res.data.count);
      if (status === "edit") {
        if (res.data.count >= 1) {
          toast.error("У этого запроса есть Респонденты!", {
            className: "bg-red-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
        } else {
          history.push(
            `/home/company/companyinquirycreate/${id}/edit/${ids.id}/0`
          );
        }
      }
    });
  };

  const [show, setShow] = useState(null);

  const Status = (value) => {
    switch (value) {
      case "72ee7350-4a2b-4fc2-945c-34faa56b13ca":
        return "Черновик";
      case "7b75685f-ee20-4fb9-bc5f-7e40c115e708":
        return "Активный";
      case "2e857fbe-9add-4eae-a8c4-fe57fb384347":
        return "Завершено";
      case "2a98c22e-cbee-49f9-90ef-f56429d96333":
        return "Отменено";
      default:
        return "-";
    }
  };
  const getStatusId = (value) => {
    switch (value) {
      case "Черновик":
        return "72ee7350-4a2b-4fc2-945c-34faa56b13ca";
      case "Активный":
        return "7b75685f-ee20-4fb9-bc5f-7e40c115e708";
      case "Завершено":
        return "2e857fbe-9add-4eae-a8c4-fe57fb384347";
      case "Отменено":
        return "2a98c22e-cbee-49f9-90ef-f56429d96333";
      default:
        return " ";
    }
  };
  const Colors = (name) => {
    switch (name) {
      case "72ee7350-4a2b-4fc2-945c-34faa56b13ca":
        return "linear-gradient(0deg, rgba(248, 221, 78, 0.3), rgba(248, 221, 78, 0.3)), #FFFFFF";
      case "7b75685f-ee20-4fb9-bc5f-7e40c115e708":
        return "linear-gradient(0deg, rgba(64, 148, 247, 0.15), rgba(64, 148, 247, 0.15)), #FFFFFF";
      case "2e857fbe-9add-4eae-a8c4-fe57fb384347":
        return "linear-gradient(0deg, rgba(56, 217, 185, 0.2), rgba(56, 217, 185, 0.2)), #FFFFFF";
      case "2a98c22e-cbee-49f9-90ef-f56429d96333":
        return "linear-gradient(0deg, rgba(247, 102, 89, 0.15), rgba(247, 102, 89, 0.15)), #FFFFFF";
      default:
        return "-";
    }
  };
  const TextColor = (name) => {
    switch (name) {
      case "72ee7350-4a2b-4fc2-945c-34faa56b13ca":
        return "#D29404";
      case "7b75685f-ee20-4fb9-bc5f-7e40c115e708":
        return "#4094F7";
      case "2e857fbe-9add-4eae-a8c4-fe57fb384347":
        return "#1AC19D";
      case "2a98c22e-cbee-49f9-90ef-f56429d96333":
        return "#F76659";
      default:
        return "-";
    }
  };

  const tabLabel = (text, isActive = false) => {
    return <span className="px-1">{text}</span>;
  };

  const handleResearcherAdd = () => {
    history.push(`${"/home/company/researcher/create"}/${id}`);
  };
  const routes = [
    // {
    //   title: t('company.name'),
    //   link: true,
    //   route: '/home/company',
    // },
    {
      title: userName === undefined ? "User" : userName,
      link: true,
    },
  ];
  const handleNext = (arg) => {
    setActiveStep((prevActiveStep) => arg ?? prevActiveStep + 1);
  };
  const time =
    (new Date(selectedMenu?.to_date).getTime() -
      new Date(selectedMenu?.from_date).getTime()) /
    (1000 * 3600 * 24);

  const copyRight = {
    screener_project_id: projectId.id,
    screener_survey_id: surveyId.id,
    additional_cost: selectedMenu?.additional_cost,
    company_id: selectedMenu?.company_id,
    connection_info: selectedMenu?.connection_info,
    description: selectedMenu?.description,
    device: selectedMenu?.device,
    duration: selectedMenu?.duration,
    example_nda: selectedMenu?.example_nda,
    inquiry_requirement: selectedMenu?.inquiry_requirement,
    nda: selectedMenu?.nda,
    nda_text: selectedMenu?.nda_text,
    interview_type: selectedMenu?.interview_type,
    type_of_inquiry: selectedMenu?.type_of_inquiry,
    number_of_partisipants: selectedMenu?.number_of_partisipants,
    os: selectedMenu?.os,
    partisipant_role: selectedMenu?.partisipant_role,
    researcher_id: selectedMenu?.researcher_id,
    researcher_name: selectedMenu?.researcher_name,
    study_type: selectedMenu?.study_type,
    title: selectedMenu?.title,
    from_date: moment(new Date()).add(12, "hours").format("YYYY-MM-DD"),
    to_date: moment(new Date())
      .add(12, "hours")
      .add(time, "days")
      .format("YYYY-MM-DD"),
    total_cost: calculateCost?.total_cost,

    status_id: selectedMenu?.status_id,
  };
  const onSubmit = (data) => {
    let screenner_projec = "";
    const project = {
      auto_next: true,
      is_active: true,
      is_archive: true,
      languages: [
        {
          is_active: true,
          language: "ru",
        },
      ],
      multiple_answers: true,
      record_answers: true,
      return_answers: true,
    };

    if (params.inquery_id) {
      handleNext();
      return;
    }
    setLoader(true);

    companiyInquiry.createProject(project).then((res) => {
      const survey = {
        title: initialValue.title,
      };
      screenner_projec = res.response_data.id;
      setProjectId(res.response_data);

      companiyInquiry
        .createStroy(survey, res.response_data.id)
        .then((res) => {
          // survey_id = res.response_data.id;
          setSurveyId(res.response_data);
          setinitialValue((el) => ({
            ...el,
            screener_project_id: screenner_projec,
            screener_survey_id: res.response_data.id,
          }));

          companiyInquiry
            .getInquiry(selectedMenu.id)
            .then((res) => {
              setListInquery(res.data);
            })
            .catch((err) => {
              console.log(err);
              setLoader(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    });
    handleNext();
  };

  useEffect(() => {
    if (listInquery) {
      companiyInquiry
        .calculateCost(
          listInquery.partisipant_role,
          listInquery.duration,
          listInquery.number_of_partisipants,
          listInquery.additional_cost,
          listInquery.company_id,
          listInquery.interview_type === "online" ? "true" : "false"
        )
        .then((res) => {
          setCalculateCost(res.data);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  }, [listInquery]);

  useEffect(() => {
    if (calculateCost) {
      companiyInquiry
        .InquieryCreate(copyRight)
        .then(() => {
          toast.success(`${t("Успешно") + " " + t("скопировано")}`, {
            className: "bg-green-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
          // window.location.reload();
          inquiryListGetAll(id);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  }, [calculateCost]);

  useEffect(() => {
    inquiryListGetAll(id);
  }, []);

  useEffect(() => {
    inquiryListGetAll(id);
  }, [pageLimit]);
  useEffect(() => {
    inquiryListGetAll(id);
  }, [searchTable]);
  useEffect(() => {
    inquiryListGetAll(id);
  }, [selectStatus]);
  if (id.length > 0) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      getAllItem(id);
    }, [id]);
  }
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [getSurveyID, setGetSurveyID] = useState(null);
  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedMenu(data);
    setGetSurveyID(data.screener_survey_id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedMenu(null);
  };
  const [getVariables, setGetVaribales] = useState({});
  const compnayCreateGlobalVariable = () => {
    Variables.getGlobalVariables().then((res) => {
      axios
        .post(`/company_variables/create`, {
          global_variables: [
            ...res.data.global_variables
              .filter((item) => item.key !== "additional_respondents")
              .map((el) => ({
                ...el,
                company_id: id,
              })),
          ],
        })
        .then((res) => {
          setGetVaribales(res.data.global_variables);
        });
    });
  };
  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => handleMoreClick(selectedMenu, "edit")}
          className="mx-1"
        >
          <EditIcon className="bg-blue-100 rounded text-blue-600 p-1 " />
          <span className="ml-2">{t("edit")}</span>
        </MenuItem>
        <MenuItem
          onClick={() => handleMoreClick(selectedMenu, "update_status")}
        >
          <ChangeStatatus
            respondentCount={respondentCount}
            open={open}
            setOpen={setOpen}
            id={getSurveyID}
            checkActive={true}
            element={selectedMenu}
            t={t}
            setElement={setinquerList}
            closeMenu={setAnchorEl}
            Cached={Cached}
          />
          {/* <Cached className="bg-green-100 rounded text-green-600 p-1 my-1" /> <span className="ml-2">{t('Изменить статус')}</span> */}
        </MenuItem>
        <MenuItem
          onClick={() => handleMoreClick(selectedMenu, "copy_right")}
          style={loader ? {justifyContent: "center"} : null}
        >
          {loader ? (
            <CircularProgress size="20px" />
          ) : (
            <CopyRight
              open={open}
              setOpen={setOpen}
              id={getSurveyID}
              checkActive={true}
              element={selectedMenu}
              t={t}
              setElement={setinquerList}
              closeMenu={setAnchorEl}
              Cached={FileCopyIcon}
              onSubmit={onSubmit}
            />
          )}
        </MenuItem>
        {show === "FINISHED" && (
          <MenuItem
            onClick={() => handleMoreClick(selectedMenu, "act")}
            className="mx-1"
          >
            <Act className="bg-blue-100 rounded text-blue-600 p-1 " />
            <span className="ml-2">{"АКТ"}</span>
          </MenuItem>
        )}
        {show === "FINISHED" && (
          <MenuItem
            onClick={() => handleMoreClick(selectedMenu, "balance")}
            className="mx-1"
          >
            <Balance className="bg-blue-100 rounded text-blue-600 p-1 " />
            <span className="ml-2">{"Счет-фактура"}</span>
          </MenuItem>
        )}
        {show === "FINISHED" && (
          <MenuItem
            onClick={() => handleMoreClick(selectedMenu, "excel")}
            className="mx-1"
          >
            <FileCopyIcon className="bg-blue-100 rounded text-blue-600 p-1 " />
            <span className="ml-2">{"Детализация"}</span>
          </MenuItem>
        )}
        {show === "FINISHED" && (
          <MenuItem
            onClick={() => handleMoreClick(selectedMenu, "word")}
            className="mx-1"
          >
            <SvgIcon
              name="word"
              className="bg-blue-100 rounded text-blue-600 p-1 "
            />
            <span className="ml-2">{"Форма заказа"}</span>
          </MenuItem>
        )}
        {show === "ACTIVE" && (
          <MenuItem
            // onClick={() => handleMoreClick(selectedMenu, "word")}
            className="mx-1"
          >
            <FindInPageIcon className="bg-blue-100 rounded text-blue-600 " />
            <a
              className="text-black hover:text-black "
              href={`${config.canvasBaseURL}construct/editor/${selectedMenu?.screener_project_id}/${selectedMenu?.screener_survey_id}/${selectedMenu?.company_id}/${selectedMenu?.id}/0`}
            >
              <span className="ml-2">Скриннер</span>
            </a>
          </MenuItem>
        )}
      </Menu>
      <Header
        setSelectStatus={setSelectStatus}
        filter={tabvalue === 2 ? true : false}
        startAdornment={[<Breadcrumbs routes={routes} />]}
        endAdornment={
          tabvalue === 2
            ? [
                <Button
                  size={"medium"}
                  onClick={handleInquiryAdd}
                  icon={<AddIcon className="text-white my-1" />}
                >
                  {t("add")}
                </Button>,
              ]
            : rollGloablVariable
            ? ""
            : [
                <Button
                  size={"medium"}
                  onClick={() => {
                    tabvalue === 5
                      ? compnayCreateGlobalVariable()
                      : handleResearcherAdd();
                  }}
                  icon={<AddIcon className="text-white my-1" />}
                >
                  {t("add")}
                </Button>,
              ]
        }
      />

      <Filter>
        <StyledTabs
          value={tabvalue}
          onChange={handleChangeTab}
          centered={false}
          aria-label="full width tabs example"
          TabIndicatorProps={{children: <span className="w-2" />}}
        >
          <StyledTab
            label={tabLabel(t("company.info"))}
            {...a11yProps(0)}
            style={{width: "100px", fontFamily: "san-serif"}}
          />
          <StyledTab
            label={tabLabel(t("Сотрудники"))}
            {...a11yProps(0)}
            style={{width: "100px"}}
          />
          <StyledTab
            label={tabLabel("Запросы")}
            {...a11yProps(0)}
            style={{width: "100px"}}
          />
          <StyledTab
            label={tabLabel(t("Календарь"))}
            {...a11yProps(0)}
            style={{width: "100px"}}
          />
          <StyledTab
            label={tabLabel(t("Транзакции"))}
            {...a11yProps(0)}
            style={{width: "100px"}}
          />
          <StyledTab
            label={tabLabel(t("Переменные"))}
            {...a11yProps(0)}
            style={{width: "100px"}}
          />
        </StyledTabs>
      </Filter>

      <div>
        <TabPanel value={tabvalue} index={0}>
          <Compayny />
        </TabPanel>
        <TabPanel value={tabvalue} index={1}>
          <ResearchersItemCompanyList t={t} />
        </TabPanel>
        <TabPanel value={tabvalue} index={3}>
          <Calendar />
        </TabPanel>
        <TabPanel value={tabvalue} index={2}>
          <div className="fslex p-5 px-5 pb-4" style={{borderRadius: "20px"}}>
            <TableContainer
              elevation={0}
              component={Paper}
              className=" shadow-none rounded-2xl p-5"
              style={{borderRadius: "10px"}}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  marginBottom: "16px",
                }}
              >
                <input
                  name="search"
                  value={searchTable}
                  onChange={(e) => {
                    setSearchTable(e.target.value);
                  }}
                  style={{
                    border: "1px solid #E5E9EB",
                    borderRadius: "8px",
                    padding: "8px 12px",
                    maxWidth: "200px",
                  }}
                  placeholder="Поиск"
                />
                <a
                  onClick={() => {
                    getExcelFile();
                  }}
                  id="download_file"
                >
                  <DownloadOutlined />
                </a>
              </div>
              <Table elevation={0} aria-label="customized table">
                <TableHead style={{borderRadius: "10px"}}>
                  <TableRow className="text-black font-body">
                    {tableHeardName?.map((element, index) => (
                      <StyledTableCell
                        style={{fontFamily: "sans-serif"}}
                        key={index}
                      >
                        {element}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inquerList?.map((els, index) => (
                    <TableRow
                      className="text-black font-body hover:bg-gray-100 cursor-pointer"
                      // <TableRow onClick={()=> handleCutomRow(els.id)} className="text-black font-body hover:bg-gray-100 cursor-pointer"
                      key={index}
                    >
                      <StyledTableCell onClick={() => handleCutomRow(els.id)}>
                        {parseInt(
                          pageCount * (pageLimit / pageCount - 1) + index + 1
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{whiteSpace: "nowrap"}}
                        onClick={() => handleCutomRow(els.id)}
                      >
                        {moment(els.from_date).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{whiteSpace: "nowrap"}}
                        onClick={() => handleCutomRow(els.id)}
                      >
                        {moment(els.to_date).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      {/*<StyledTableCell>{element.owner_name}</StyledTableCell>*/}

                      <StyledTableCell onClick={() => handleCutomRow(els.id)}>
                        <Tooltip
                          title={
                            <h1 style={{fontSize: "14px", color: "#fff"}}>
                              {els.title}
                            </h1>
                          }
                          placement="top"
                        >
                          <Typography id="text_limit">{els.title}</Typography>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleCutomRow(els.id)}>
                        <Tooltip
                          title={
                            <h1 style={{fontSize: "14px", color: "#fff"}}>
                              {els.description}
                            </h1>
                          }
                          placement="top"
                        >
                          <Typography id="text_limit">
                            {els.description}
                          </Typography>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleCutomRow(els.id)}>
                        {NumberFomat(els.total_cost)} ₽
                      </StyledTableCell>
                      <StyledTableCell>
                        <Tag
                          shape="subtle"
                          color={Colors(els.status_id)}
                          size="large"
                          style={{
                            width: "150px",
                            background: `${Colors(els.status_id)}`,
                            borderRaduis: "6px",
                            color: `${TextColor(els.status_id)}`,
                          }}
                        >
                          {Status(els.status_id)}
                        </Tag>
                      </StyledTableCell>
                      <StyledTableCell
                        className="w-2   border-gray-300"
                        style={{borderLeft: "1px solid gainsboro"}}
                      >
                        <MoreHorizIcon
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(e) => {
                            handleClick(e, els);
                            setShow(els.status);
                          }}
                          className="cursor-pointer text-blue-500"
                          style={{
                            border: "1px solid gainsboro",
                            borderRadius: "5px",
                          }}
                        />
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              className="mt-5 text-black-700"
              count={items.count}
              pageCount={pageCount}
              currentPage={currentPage === undefined ? 1 : currentPage.page}
              onChange={(val) => {
                dispatch({
                  type: pageActionsTypes.CHILD_CURRENT_PAGE,
                  payload: {
                    page: parseInt(val / 10),
                  },
                });
                setPageLimit(val);
              }}
            />
          </div>
        </TabPanel>
        <TabPanel value={tabvalue} index={4}>
          <BillingTable accountNumber={accountNumber} />
        </TabPanel>
        <TabPanel value={tabvalue} index={5}>
          <GlobalVariabList
            setRollGlobalVariable={setRollGlobalVariable}
            getVariables={getVariables}
            check={true}
            id={id}
          />
        </TabPanel>
      </div>
    </div>
  );
};

export default InquiryList;
