const initialState =
    {
        company_id: "",
        // one Steppers
        title: "",
        from_date: "",
        to_date: "",
        status_id: "",
        partisipant_role: "",
        study_type: "",
        interview_type: "",
        description: "",
            screener_survey_id: "",
            screener_project_id: "",
        // two Stepppers
        researcher_id: "",
        // data researchdelAll select
        duration: null,
        number_of_partisipants: null,
        os: "",
        device: "",
        cost: 0,
        additional_cost: null,
        inquery_id: null,
        // thwee Steppers


        inquiry_requirement: [
            // {
            //     requirement_attribute: {
            //         id: ""
            //     },
            //     requirement_value: {
            //         attribute_id: "",
            //         id: "",
            //         value: ""
            //     }
            // }
        ],
        // four Steppers
        inquiry_timetables: [
            // {
            //     dates: "",
            //     end_time: "",
            //     number_of_applicants: 0,
            //     number_of_partisipants: 0,
            //     start_time: ""
            // }
        ],
        // not required
        nda: "",
        connection_info: "",


    }
    const companyInquiryCreateAllData = (state= initialState, action) => {
        const {payload} = action;
        switch (action.type) {
                case "StepperOneInsideDate":
                        return {
                                ...state,
                                company_id: payload.company_id,
                                // one Steppers
                                title: payload.title,
                                from_date: payload.from_date,
                                to_date: payload.to_date,
                                status_id: payload.status_id,
                                partisipant_role: payload.partisipant_role,
                                study_type: payload.study_type,
                                interview_type: payload.interview_type,
                                description: payload.description,
                        };
                case "StepperTwoInsideData":
                        console.log("StepperTwoInsideData===>", payload)
                        return {
                                ...state,
                                researcher_id: payload.researcher_id,
                                // data researchdelAll select
                                duration: parseInt(payload.duration),
                                number_of_partisipants: parseInt(payload.number_of_partisipants),
                                os: payload.os,
                                device: payload.device,
                                additional_cost: parseInt(payload.additional_cost),
                        };
                case "StepperThreeInsideData":
                        return {
                                ...state,
                                inquiry_requirement: [ ...payload.inquiry_requirement ]
                        }
                case "SCREENNER":
                        console.log("SCREENNER===>", payload)
                        return {
                                ...state,
                                screener_project_id: payload.screener_project_id,
                                screener_survey_id: payload.screener_survey_id
                        };
                case "StepperTreeInsideData":
                        console.log("StepperTreeInsideData ===>", payload, state)

                        return {
                                ...state,
                                inquiry_requirement: payload.map((element) => ({requirement_attribute: element.requirement_attribute, requirement_value: element.requirement_value}))
                        }
                case 'SET_INQUIRY_ID': {
                        return {
                                ...state,
                                inquery_id: payload
                        }
                }
                default: return state;


        }
    }
    export default companyInquiryCreateAllData;

