import request from "../utils/axios";
const base_Url = {
  company: "/excel",
  csv: "/csv",
};

const requests = {
  companyGetAllExcelFile: (params) =>
    request({
      url: `${base_Url.company}/companies`,
      method: "get",
      params: params,
    }),
  tolokaGetAllExcelFile: (params) =>
    request({
      url: `/toloka/responses_excel`,
      method: "get",
      params: params,
    }),
  inqueryGetAllExcelFile: (params) =>
    request({
      url: `${base_Url.company}/inquiries`,
      method: "get",
      params: params,
    }),
  respondentGetAllExcelFile: (params) =>
    request({
      url: `${base_Url.company}/respondents`,
      method: "get",
      params: params,
    }),
  researcherGetAllExcelFile: (params) =>
    request({
      url: `${base_Url.company}/researchers`,
      method: "get",
      params: params,
    }),
  transactionGetAllExcelFile: (params) =>
    request({
      url: `${base_Url.csv}/transaction/transfered`,
      method: "get",
      params: params,
    }),
  transactionExcelFile: (num) =>
    request({
      url: `${base_Url.company}/transaction?account_number=${num}`,
      method: "get",
    }),
};
export default requests;
