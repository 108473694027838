import Fallback404 from '../views/exceptions/Fallback404.jsx'
import Fallback502 from '../views/exceptions/Fallback503.jsx'
import Fallback403 from '../views/exceptions/Fallback403.jsx'
import TransactionsCheckList from '../components/TransactionsCheckList/TransactionsCheckList.jsx'
import UserInformation from '../views/UserInformation/UserInformation.js'
import ResetPassword from '../views/login/ConfirmEmail/ResetPassword.js'

export default [
  {
    id: 1,
    component: Fallback404,
    path: '/fallback-404',
    exact: true,
    title: 'Not found',
    showSidepanel: true,
  },
  {
    id: 13,
    component: Fallback403,
    path: '/fallback-403',
    exact: true,
    title: 'Not found',
    showSidepanel: true,
  },
  {
    id: 2,
    component: Fallback502,
    path: '/fallback-502',
    exact: true,
    title: 'Service Unavailable',
    showSidepanel: true,
  },
  {
    id: 17,
    component: TransactionsCheckList,
    path: '/company/transaction/checklist/:id',
    exact: true,
    title: ' transactions check list',
    showSidepanel: true,
  },
  {
    id: 177,
    component: UserInformation,
    path: "/user/information/:id",
    exact: true,
    title: "User Information",
    showSidepanel: true
  },
].map((route) => ({ ...route, path: `/extra${route.path}` }))
