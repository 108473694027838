import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import SettingsInputCompositeIcon from "@material-ui/icons/SettingsInputComposite";
import {AdminIcon} from "../svg/AdminIcon";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import {
  CompanyIcon,
  PartnerIcon,
  Respondent,
  InquiryIcon,
} from "../svg/SidebarIcon";

const menu = [
  {
    title: "Дашборд",
    path: "/home/dashboard",
    isActive: false,
    icon: <DashboardIcon style={{color: "rgba(110, 139, 183, 1)"}} />,
  },
  {
    title: "Компании",
    path: "/home/company",
    isActive: false,
    icon: <CompanyIcon />,
  },
  {
    title: "Сотрудники",
    path: "/home/researchers",
    isActive: false,
    icon: <PartnerIcon />,
  },
  {
    title: "Респонденты",
    path: "/home/respondents",
    isActive: false,
    icon: <Respondent />,
  },
  {
    title: "Запросы",
    path: "/home/inquiries",
    isActive: false,
    icon: <InquiryIcon />,
  },
  {
    title: "Отчеты",
    path: "/home/report",
    isActive: false,
    icon: <AssessmentIcon style={{color: "rgba(110, 139, 183, 1)"}} />,
  },
  {
    title: "Соглашения",
    path: "/home/agreement",
    isActive: false,
    icon: <NoteAddOutlinedIcon style={{color: "rgba(110, 139, 183, 1)"}} />,
  },
  {
    title: "Транзакции",
    path: "/home/transactions",
    isActive: false,
    icon: <MonetizationOnIcon style={{color: "rgba(110, 139, 183, 1)"}} />,
  },
  // {
  //   title: "Атрибут",
  //   path: "/home/attributs",
  //   isActive: false,
  //   icon: <FilterIcon style={{color: "rgba(110, 139, 183, 1)"}}/>
  // },
  {
    title: "Settings",
    path: "/home/settings",
    isActive: true,
    icon: <SettingsIcon style={{color: "rgba(110, 139, 183, 1)"}} />,
    children: [
      // {
      //   title: "At",
      //   path: "/home/settings/setting",
      //   isActive: false,
      //   icon: <SettingsIcon style={{color: "rgba(110, 139, 183, 1)"}} />
      // },
      {
        title: "Атрибуты",
        path: "/home/settings/attribut",
        isActive: false,
        icon: (
          <SettingsInputCompositeIcon
            style={{color: "rgba(110, 139, 183, 1)"}}
          />
        ),
      },
      {
        title: "Переменные",
        path: "/home/settings/global",
        isActive: false,
        icon: <SettingsIcon style={{color: "rgba(110, 139, 183, 1)"}} />,
      },
      {
        title: "Админ",
        path: "/home/settings/admin",
        isActive: false,
        icon: <AdminIcon style={{color: "rgba(110, 139, 183, 1)"}} />,
      },
      {
        title: "Роли",
        path: "/home/settings/role",
        isActive: false,
        icon: <AccountBoxIcon style={{color: "rgba(110, 139, 183, 1)"}} />,
      },
    ],
  },
];

export default menu;
