export const Charger = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20 15H18V13H20C20.55 13 21 13.45 21 14C21 14.55 20.55 15 20 15Z'
      fill='#0067F4'
    />
    <path
      d='M20 19H18V17H20C20.55 17 21 17.45 21 18C21 18.55 20.55 19 20 19Z'
      fill='#0067F4'
    />
    <path
      d='M14 12C12.9 12 12 12.9 12 14H10V18H12C12 19.1 12.9 20 14 20H17V12H14Z'
      fill='#0067F4'
    />
    <path
      d='M4 5C4 5.55 4.45 6 5 6H8.5C9.33 6 10 6.67 10 7.5C10 8.33 9.33 9 8.5 9H7C4.79 9 3 10.79 3 13C3 15.21 4.79 17 7 17H9V15H7C5.9 15 5 14.1 5 13C5 11.9 5.9 11 7 11H8.5C10.43 11 12 9.43 12 7.5C12 5.57 10.43 4 8.5 4H5C4.45 4 4 4.45 4 5Z'
      fill='#0067F4'
    />
  </svg>
)
export const Calendar = () => (
  <svg
    width='20'
    height='22'
    viewBox='0 0 20 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18 2H17V1C17 0.45 16.55 0 16 0C15.45 0 15 0.45 15 1V2H5V1C5 0.45 4.55 0 4 0C3.45 0 3 0.45 3 1V2H2C0.9 2 0 2.9 0 4V20C0 21.1 0.9 22 2 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM17 20H3C2.45 20 2 19.55 2 19V7H18V19C18 19.55 17.55 20 17 20Z'
      fill='#0067F4'
    />
  </svg>
)
