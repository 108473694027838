import logo from "../assets/icons/logo_blue.png";
const defaultSetting = {
  baseURL: process.env.REACT_APP_URL || "https://api.admin.urecruit.ru/v1/",
  canvasBaseURL:
    process.env.REACT_APP_CANVAS_BASE_URL ||
    "https://admin.canvas.urecruit.ru/",
  managerBaseURL:
    process.env.REACT_APP_MANAGER_BASE_URL ||
    "https://api.manager.urecruit.ru/",
  researcherBaseURL:
    process.env.REACT_APP_RESEARCHER_BASE_URL ||
    "https://researcher.urecruit.ru",
  mainBaseURL: process.env.REACT_APP_BASE_URL || "https://admin.urecruit.ru",
  tolokaUrl:
    process.env.REACT_APP_TOLOKA_URL ||
    "https://sandbox.toloka.yandex.com/api/v1/assignments",
  // baseURL: process.env.REACT_BASE_URL || "http://192.168.1.137:8090/v1/",
  mode: "default", // mode => default | light | semi-dark
  avatar: "",
  fixedHeader: true, // sticky header
  fixSiderbar: true, // sticky sidebar
  project: {
    title: "Urecruit",
    logo: logo,
    createdBy: "Udevs company",
  },
};

document.title = defaultSetting.project.title;

export default defaultSetting;
